import React, { createContext, PropsWithChildren, useMemo } from "react";
import { navInitialState, reducer, NavInitialStateType, NavActionTypes } from '../../reducers/NavReducer';

export const NavContext = createContext<NavInitialStateType>(navInitialState);

export default function NavProvider({children}: PropsWithChildren<{}>) {
    const [state, dispatch] = React.useReducer(reducer, navInitialState);

    const { mobileMenuState, desktopMenuState } = state;

    const contextValue = useMemo(() => {
        return {
            mobileMenuState, desktopMenuState,
            handleMobileMenu: (status: boolean) => {
                dispatch({ type: NavActionTypes.SetMobileMenuState, payload: status });
            },
            handleDesktopMenu: (status: boolean) => {
                dispatch({ type: NavActionTypes.SetDesktopMenuState, payload: status });
            }
        }
    }, [state, dispatch]);

    return(
        <NavContext.Provider value={contextValue}>
            { children }
        </NavContext.Provider>
    )
}
