import { forwardRef, PropsWithChildren, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { Popover } from '../popover'
import { ClearButton } from './clear-button'
import { FilterDisplayProps } from './display'
import { ChevronDownIcon } from 'lucide-react'

type GroupDisplayProps = {
  id: string
  className?: string
  popOverContent: ReactNode
  defaultOpen?: boolean
  noPopover?: boolean
  onInit?: (element: HTMLElement) => void
  onDestroy?: () => void
} & Omit<FilterDisplayProps, 'label'>

export const GroupDisplay = forwardRef<
  any,
  PropsWithChildren<GroupDisplayProps>
>(
  (
    {
      children,
      clearable,
      defaultOpen = false,
      onClear,
      className,
      popOverContent,
      noPopover,
      ...props
    },
    ref
  ) => {
    return (
      <div ref={ref} {...props} className="relative h-[50px] p-px">
        {clearable && <ClearButton onClick={onClear} />}
        <div
          className={twMerge(
            'border-gray-light flex h-[50px] items-center gap-3 rounded border border bg-white bg-white px-3 py-3',
            className
          )}
        >
          {children}
          {!noPopover && (
            <Popover
              content={popOverContent}
              defaultOpen={defaultOpen}
              modal
              contentProps={{ style: { zIndex: 100 }, align: 'center' }}
              triggerProps={{ className: 'flex items-center p-1' }}
            >
              <ChevronDownIcon width={18} className="text-gray" />
            </Popover>
          )}
        </div>
      </div>
    )
  }
)
