import { PropsWithChildren, ReactNode } from 'react'
import { useNavigation } from './navigation/use-navigation'
import { cn } from './utils'

type Props = { title?: string; extra?: ReactNode }
export const PageHeader = ({
  children,
  title,
  extra,
}: PropsWithChildren<Props>) => {
  const navigation = useNavigation()
  const paddingLeft = navigation.margin + navigation.paddedSize
  const style: any = {}
  style.transition = 'all 300ms ease'
  style.paddingLeft = paddingLeft

  return (
    <>
      {/* title */}
      <header
        className={cn('text-gray-dark space-y-3 px-3 py-10', '')}
        style={style}
      >
        <div className="flex items-start gap-6">
          {title && (
            <h2 className="text-gray-darkest py-2 text-3xl font-semibold">
              {title}
            </h2>
          )}
          {extra}
        </div>
        {children}
      </header>
    </>
  )
}
