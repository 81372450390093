import type { IconProps } from './types'

export function NavigationDashboardIcon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3913 24.6956H9.04348C8.46718 24.6956 8 25.1627 8 25.739V30.9564C8 31.5327 8.46718 31.9999 9.04348 31.9999H17.3913C17.9676 31.9999 18.4348 31.5327 18.4348 30.9564V25.739C18.4348 25.1627 17.9676 24.6956 17.3913 24.6956Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6087 32H30.9565C31.5328 32 32 31.5328 32 30.9565V19.4783C32 18.902 31.5328 18.4348 30.9565 18.4348H22.6087C22.0324 18.4348 21.5652 18.902 21.5652 19.4783V30.9565C21.5652 31.5328 22.0324 32 22.6087 32Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6087 15.3044L30.9565 15.3044C31.5328 15.3044 32 14.8373 32 14.261V9.04357C32 8.46728 31.5328 8.0001 30.9565 8.0001H22.6087C22.0324 8.0001 21.5652 8.46728 21.5652 9.04357V14.261C21.5652 14.8373 22.0324 15.3044 22.6087 15.3044Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3913 8H9.04348C8.46718 8 8 8.46718 8 9.04348V20.5217C8 21.098 8.46718 21.5652 9.04348 21.5652H17.3913C17.9676 21.5652 18.4348 21.098 18.4348 20.5217V9.04348C18.4348 8.46718 17.9676 8 17.3913 8Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
