import { ChevronLeftIcon, Logo } from '@upper/icons'
import Link from 'next/link'
import * as React from 'react'

type LayoutProps = {
  children: React.ReactNode
  appName?: string
  background?: React.ReactNode
  noCredentials?: boolean
  noDescription?: boolean
  description?: string
  noBackToUpper?: boolean
}

export default function Layout({
  children,
  appName,
  background,
  noCredentials = false,
  noDescription = false,
  noBackToUpper = false,
  description,
}: LayoutProps) {
  return (
    <div className="md:bg-gray-lightest flex min-h-screen flex-col md:relative md:overflow-hidden">
      <div className="hidden md:block">{background}</div>

      <div className="mt-16 mb-12 flex-1 md:relative md:mt-48 md:flex md:items-start md:space-x-8 md:px-24">
        <div className="mx-auto max-w-md px-8 md:mx-0 md:w-[270px] md:px-0 md:pt-12">
          <AppLogo appName={appName} />
          {!noDescription && (
            <p className="text-gray-dark mt-5 text-sm md:mt-12">
              {description ||
                'UPPER is a closed network of top vetted freelancers in engineering, design and product management.'}
            </p>
          )}
          {!noBackToUpper && (
            <div className="mt-5 hidden md:block">
              <a
                href="https://upper.co/"
                className="text-blue inline-flex items-center text-sm underline hover:no-underline"
              >
                <ChevronLeftIcon className="mr-2 h-4 w-4" />
                back to upper
              </a>
            </div>
          )}
        </div>

        <div className="mx-auto mt-16 max-w-md px-8 md:mx-0 md:mt-0 md:w-[370px] md:overflow-hidden md:rounded-md md:bg-white md:px-0 md:shadow-lg">
          <div className="md:px-8 md:py-12">{children}</div>
          <div className="bg-gray-lightest hidden p-8 md:block">
            <RegisterSection noCredentials={noCredentials} />
          </div>
        </div>
      </div>

      <div className="bg-gray-lightest md:hidden">
        <div className="mx-auto max-w-md px-8">
          <div className="py-8">
            <RegisterSection noCredentials={noCredentials} />
          </div>
        </div>
      </div>
    </div>
  )
}

function AppLogo({ appName }: { appName?: string }) {
  return (
    <div className="flex align-text-top">
      <Logo className="text-blue h-auto w-[98px]" />
      {appName && (
        <h4 className="text-blue ml-2 -mt-1 text-xs font-bold uppercase tracking-widest">
          {appName}
        </h4>
      )}
    </div>
  )
}

function RegisterSection({ noCredentials }: { noCredentials?: boolean }) {
  if (noCredentials) {
    return (
      <p>
        <strong>No credentials?</strong> Please{' '}
        <a
          href="mailto:contact@upper.co"
          className="text-blue underline hover:no-underline"
        >
          contact us
        </a>
      </p>
    )
  }

  return (
    <>
      <h4 className="font-bold leading-none">Don&apos;t have an account?</h4>
      <p className="leading-tight">
        <Link
          href="/register"
          className="text-blue underline hover:no-underline"
        >
          Register for an UPPER account here
        </Link>
      </p>
    </>
  )
}
