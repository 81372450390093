import { UseToggle } from '@upper/hooks'
import { createContext } from 'react'

export type Context = {
  size: number
  margin: number
  paddedSize: number
  condensed: UseToggle
}
export const Context = createContext<Context>(undefined as any)
