import { ArrowDownIcon, ArrowUpIcon } from '@upper/icons'
import {
  addMonths,
  addYears,
  endOfMonth,
  isBefore,
  isSameMonth,
  isSameYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { classNames } from './classnames'

const monthsInAYear = 12

type DateValue = Date
type Props = {
  after?: DateValue
  disablePast?: boolean
  value?: DateValue
  onChange?: (date?: [Date, Date]) => void
}

// todo: make it global?
const locale = 'en-DE'
const currentDate = new Date()

export const MonthPicker = ({ after, disablePast, value, onChange }: Props) => {
  const today = startOfDay(currentDate)
  const date = useMemo(() => value, [value])
  const [calendarDate, setCalendarDate] = useState(
    date ? startOfYear(date) : startOfYear(today)
  )
  const startOfMonthDate = startOfMonth(today)
  const startOfCalendarMonthDate = startOfYear(startOfMonthDate)

  console.log(date, calendarDate)

  const months = useMemo(() => {
    const months = []
    for (let i = 0; i < monthsInAYear; i++) {
      months.push(i)
    }
    return months
  }, [])

  const handleYearChange = useCallback(
    (amount: number) => {
      setCalendarDate(addYears(calendarDate, amount))
    },
    [calendarDate]
  )

  const handleMonthReset = useCallback(() => {
    setCalendarDate(startOfCalendarMonthDate)
  }, [startOfCalendarMonthDate])

  return (
    <div className="font-mono text-sm">
      <header tabIndex={0}>
        <div className="flex items-center justify-between gap-2">
          <button
            className="text-lg text-gray-dark hover:text-gray-darkest p-2 rounded-md"
            onClick={() => handleYearChange(-1)}
          >
            <ArrowUpIcon className="transform -rotate-90" />
          </button>
          <button onClick={handleMonthReset}>
            <h6 className="text-center font-medium font-sans text-base text-gray-dark p-2">
              {Intl.DateTimeFormat(locale, { year: 'numeric' }).format(
                calendarDate
              )}
            </h6>
          </button>
          <button
            className="text-lg text-gray-dark hover:text-gray-darkest p-2 rounded-md"
            onClick={() => handleYearChange(1)}
          >
            <ArrowDownIcon className="transform -rotate-90" />
          </button>
        </div>
      </header>
      <div className="mt-3 space-y-1 grid grid-cols-3 grid-rows-4 gap-x-6 gap-y-1">
        {months.map((month, mi) => {
          const monthDate = addMonths(calendarDate, month)
          const isPastDate = disablePast
            ? isBefore(startOfMonthDate, today)
            : false
          const dateIsSameMonth = date ? isSameMonth(date, monthDate) : false
          const dateIsSameYear = date ? isSameYear(date, monthDate) : false
          const dateIsSelected = date && dateIsSameMonth && dateIsSameYear

          return (
            <button
              key={`m-${mi}`}
              tabIndex={0}
              className={classNames(
                'px-2 py-1 rounded',
                (isPastDate || dateIsSelected) && 'pointer-events-none',
                dateIsSameMonth && dateIsSameYear
                  ? 'text-white bg-blue'
                  : isPastDate || !dateIsSameMonth
                  ? 'text-gray'
                  : 'text-gray-darkest'
              )}
              onClick={() => {
                onChange?.([startOfMonth(monthDate), endOfMonth(monthDate)])
                // setCalendarDate()
              }}
            >
              {Intl.DateTimeFormat(locale, { month: 'short' }).format(
                monthDate
              )}
            </button>
          )
        })}
      </div>
    </div>
  )
}

export type { Props as MonthPickerProps }
