/* eslint-disable @typescript-eslint/no-namespace */
import { HTMLAttributes } from 'react'
import { ActionStatusIcon } from './action-status'
import { AgencyIcon } from './agency'
import { CalendarIcon } from './calendar'
import { CalendarCheckIcon } from './calendar-check'
import { ChatBubbleIcon } from './chat-bubble'
import { CompanyIcon } from './company'
import { ContractTypeIcon } from './contract-type'
import { CountryIcon } from './country'
import { CurrentDateIcon } from './current-date'
import { EndDateIcon } from './end-date'
import { EngagementStatusesIcon } from './engagement-statuses'
import { ExcludeTalentsIcon } from './exclude-talents'
import { HasEmailIcon } from './has-email'
import { HistoryIcon } from './history'
import { JobIcon } from './job'
import { LanguageIcon } from './language'
import { NextStepsIcon } from './next-steps'
import { PausedSourcingIcon } from './paused-sourcing'
import { PrimaryPositionIcon } from './primary-position'
import { ProjectIcon } from './project'
import { ProjectAgreementIcon } from './project-agreement'
import { PublicProfileIcon } from './public-profile'
import { RateIcon } from './rate-icon'
import { SearchIcon } from './search'
import { SkillsIcon } from './skills'
import { StartDateIcon } from './start-date'
import { StatusIcon } from './status'
import { TeamIcon } from './team'
import { CompanyOffIcon } from './company-off'

export type IconProps = HTMLAttributes<SVGElement>

export namespace Icons {
  export const CompanyOff = CompanyOffIcon
  export const Company = CompanyIcon
  export const Project = ProjectIcon
  export const Skills = SkillsIcon
  export const PausedSourcing = PausedSourcingIcon
  export const Team = TeamIcon
  export const Status = StatusIcon
  export const Search = SearchIcon
  export const Job = JobIcon
  export const ProjectAgreement = ProjectAgreementIcon
  export const StartDate = StartDateIcon
  export const EndDate = EndDateIcon
  export const PrimaryPosition = PrimaryPositionIcon
  export const Country = CountryIcon
  export const Language = LanguageIcon
  export const HasEmail = HasEmailIcon
  export const PublicProfile = PublicProfileIcon
  export const CurrentDate = CurrentDateIcon
  export const EngagementStatuses = EngagementStatusesIcon
  export const CalendarCheck = CalendarCheckIcon
  export const Calendar = CalendarIcon
  export const ActionStatus = ActionStatusIcon
  export const NextSteps = NextStepsIcon
  export const ExcludeTalents = ExcludeTalentsIcon
  export const Rate = RateIcon
  export const ChatBubble = ChatBubbleIcon
  export const History = HistoryIcon
  export const ContractType = ContractTypeIcon
  export const Agency = AgencyIcon
}
