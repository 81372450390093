import { FormErrorMessage, TimeSlotPicker } from '@upper/ui'
import { useField } from 'formik'

type FormikTimeSlotPickerProps = {
  name: string
}

export function FormikTimeSlotPicker({ name }: FormikTimeSlotPickerProps) {
  const [_, meta, helpers] = useField(name)

  const { value } = meta
  const { setValue } = helpers

  return (
    <div>
      <TimeSlotPicker
        value={value}
        onChange={(value) => {
          setValue(value)
        }}
      />
      {meta.error && meta.touched && (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      )}
    </div>
  )
}
