import { LucideProps } from 'lucide-react'

export const CalendarWeek = (props: LucideProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6667 2.66669H3.33333C2.59695 2.66669 2 3.26364 2 4.00002V13.3334C2 14.0697 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0697 14 13.3334V4.00002C14 3.26364 13.403 2.66669 12.6667 2.66669Z"
      stroke="#46547A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 6.66669H14"
      stroke="#46547A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 10.6667H12"
      stroke="#46547A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6665 1.33331V3.99998"
      stroke="#46547A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.3335 1.33331V3.99998"
      stroke="#46547A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
