import { forwardRef, PropsWithChildren, ReactNode } from 'react'
import { classNames } from '../classnames'
import { Tooltip } from '../tooltip'
import { ClearButton } from './clear-button'

type Props = {
  id?: string
  label: string
  tooltip?: string | ReactNode
  hasSelection?: boolean
  clearable?: boolean
  icon?: ReactNode
  className?: string
  onClear?: () => void
}

export const FilterDisplay = forwardRef<any, PropsWithChildren<Props>>(
  (
    {
      children,
      id,
      label,
      icon,
      className,
      hasSelection,
      clearable,
      tooltip,
      onClear,
    },
    ref
  ) => {
    const contentMarkup = (
      <div
        id={id}
        ref={ref}
        className={classNames(
          'p-3 rounded group appearance-none',
          'relative cursor-pointer z-auto',
          'focus:ring-2 focus:ring-offset-2 focus:ring-blue outline-none',
          hasSelection
            ? 'bg-blue-lightest text-blue border border-blue-lightest'
            : 'bg-white text-gray-dark border border-gray-light',
          className
        )}
      >
        {hasSelection && clearable && <ClearButton onClick={onClear} />}
        <div
          className={classNames(
            'w-full overflow-hidden leading-6 relative z-[1] pointer-events-none',
            'text-sm overflow-ellipsis whitespace-normal text-left flex gap-3 items-start',
            'group-hover:overflow-visible group-hover:whitespace-normal'
          )}
        >
          {icon && (
            <span className="w-6 h-6 flex items-center justify-center flex-none pointer-events-none">
              {icon}
            </span>
          )}
          {hasSelection && label}
          {children}
        </div>
      </div>
    )
    return tooltip && !hasSelection ? (
      <TooltipContent tooltip={tooltip}>{contentMarkup}</TooltipContent>
    ) : (
      contentMarkup
    )
  }
)

const TooltipContent = ({children, tooltip}:any)=>{
  return (
    <Tooltip triggerProps={{ asChild: true }} content={tooltip}>
      {children}
    </Tooltip>
  )
}

export type { Props as FilterDisplayProps }
