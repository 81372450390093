export function ProjectIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0625 3C16.3125 3.03125 12.5625 3.28125 10.2188 4.53125C10.0938 4.59375 9.875 4.59375 9.75 4.53125C7.40625 3.28125 3.65625 3.03125 2.90625 3C1.84375 3 1 3.84375 1 4.875V14.125C1 15.125 1.78125 15.9062 2.78125 15.9688C3.875 16.0312 6.59375 16.2188 8.46875 16.9062C8.65625 16.9688 8.8125 17 8.96875 17H10.9688C11.1562 17 11.3125 16.9688 11.5 16.9062C13.375 16.2188 16.0938 16.0312 17.1875 15.9688C18.1875 15.9062 18.9688 15.125 18.9688 14.125V4.875C19 3.84375 18.125 3 17.0625 3ZM9.5 15.5312C9.5 15.8125 9.25 16.0312 9 16.0312C8.9375 16.0312 8.90625 16 8.84375 16C6.875 15.25 4.1875 15.0312 2.84375 14.9688C2.375 14.9375 2 14.5938 2 14.125V4.875C2 4.40625 2.40625 4.03125 2.90625 4.03125C3.5 4.03125 6.71875 4.25 8.90625 5.21875C9.25 5.375 9.46875 5.71875 9.46875 6.09375L9.5 9.21875V15.5312ZM18 14.125C18 14.5938 17.5938 14.9375 17.125 14.9688C15.7812 15.0312 13.0938 15.25 11.125 16C11.0625 16 11.0312 16.0312 10.9688 16.0312C10.7188 16.0312 10.4688 15.8125 10.4688 15.5312V9.21875L10.5 6.09375C10.5 5.71875 10.7188 5.375 11.0625 5.21875C13.25 4.25 16.4688 4.03125 17.0625 4.03125C17.5625 4.03125 17.9688 4.40625 17.9688 4.875V14.125H18Z"
        fill="currentColor"
      />
    </svg>
  )
}
