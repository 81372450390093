import { ModalsContext } from '@upper/providers'
import { useContext } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useModals<T = any>(name: string) {
  const modals = useContext(ModalsContext)
  if (!modals) {
    throw new Error('You have to wrap the app in ModalProvider.')
  }

  const { state, show, hide } = modals
  const visible = !!state[name]

  return {
    visible,
    show: (payload?: T) => show<T>(name, payload),
    hide: () => hide(name),
  }
}
