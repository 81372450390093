import { useElementSize, useResponsive, useTimeCursor } from '@upper/hooks'
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@upper/sapphire/ui'
import {
  addDays,
  addHours,
  addMinutes,
  differenceInMinutes,
  eachDayOfInterval,
  isBefore,
  isSameDay,
  isWeekend,
  startOfDay,
  startOfWeek,
} from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { formatDate } from 'date-fns/format'
import { AnimatePresence, motion } from 'framer-motion'
import { formatDateRange } from 'little-date'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarFoldIcon,
  EllipsisIcon,
  InfoIcon,
  TrashIcon,
} from 'lucide-react'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import timezones from 'timezones-list'
import { v4 as uuid } from 'uuid'
import { classNames } from './classnames'
import { Select } from './select'

export type TimePickerProps = {
  value: TimeBlock[]
  onChange: (value: TimeBlock[]) => void
}

const TIME_FRAGMENT = 0.25 // 15 minutes
const MINUTES_PER_TIME_FRAGMENT = 60 * TIME_FRAGMENT
const MINUTES_PER_TIME_FRAGMENT_STEP = 15
const PIXELS_PER_MINUTE = 2
const TOTAL_HOURS = 24
const START_HOUR = 0

const dayTopPadding = 1 // px

export function TimePicker({ value, onChange }: TimePickerProps) {
  const headerRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const [selectedTimezone, setSelectedTimezone] = useState(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [week, setWeek] = useState(0)

  const [weekStartsOn, setWeekStartsOn] = useState<'monday' | 'sunday'>(
    'monday'
  )
  const [defaultMeetingDuration, setDefaultMeetingDuration] = useState(60)

  const { currentTime, minutesSinceStartOfDay } =
    useTimeCursor(selectedTimezone)
  const { isSmallScreen } = useResponsive()
  const { width } = useElementSize(contentRef)

  const { daysList, timeIntervals } = useMemo(() => {
    const startDate = startOfWeek(new Date(), {
      weekStartsOn: weekStartsOn === 'monday' ? 1 : 0,
    })
    const endDate = addDays(startDate, 6)
    return {
      daysList: eachDayOfInterval({
        start: addDays(startDate, week * 7),
        end: addDays(endDate, week * 7),
      }),
      timeIntervals: [...Array(1 + (TOTAL_HOURS - START_HOUR) * 4).keys()].map(
        (i) => addMinutes(startDate, START_HOUR * 60 + 60 * TIME_FRAGMENT * i)
      ),
    }
  }, [week, weekStartsOn])

  const addTimeBlock = useCallback(
    (timeBlock: TimeBlock) => {
      onChange([...value, timeBlock])
    },
    [onChange, value]
  )

  const removeTimeBlock = useCallback(
    (id: string) => {
      onChange(value.filter((timeBlock) => timeBlock.id !== id))
    },
    [onChange, value]
  )

  const updateTimeBlock = useCallback(
    (timeBlock: TimeBlock) => {
      onChange(value.map((tb) => (tb.id === timeBlock.id ? timeBlock : tb)))
    },
    [onChange, value]
  )

  const scrollToElement = useCallback((element: HTMLElement) => {
    const container: HTMLElement =
      scrollContainerRef.current as unknown as HTMLElement
    if (container && element) {
      container.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      })
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('schedule-time-08:00')
      if (element) {
        scrollToElement(element as HTMLElement)
      }
    }, 600)
  }, [scrollToElement])

  const optionsMarkup = (
    <>
      <Select
        label="Timezone"
        className={twMerge('w-full text-sm md:hidden')}
        value={selectedTimezone}
        onChange={(event) => {
          const newTimezone = event.target.value
          onChange(
            value.map((timeBlock) => {
              const zonedTime = toZonedTime(timeBlock.date, newTimezone)
              return {
                ...timeBlock,
                date: zonedTime,
              }
            })
          )
          setSelectedTimezone(newTimezone)
        }}
      >
        {timezones
          .sort((a, b) => a.tzCode.localeCompare(b.tzCode))
          .map((timezone) => (
            <option key={timezone.tzCode} value={timezone.tzCode}>
              {timezone.tzCode} ({timezone.utc})
            </option>
          ))}
      </Select>
      <Select
        label="Week starts on"
        value={weekStartsOn}
        onChange={(event) => setWeekStartsOn(event.target.value as any)}
        className={twMerge('w-full text-sm')}
      >
        <option value="monday">Monday</option>
        <option value="sunday">Sunday</option>
      </Select>
      <Select
        className={twMerge('w-full text-sm')}
        label="Default meeting length"
        value={defaultMeetingDuration}
        onChange={(event) =>
          setDefaultMeetingDuration(parseInt(event.target.value))
        }
      >
        <option value={15}>15 minutes</option>
        <option value={30}>30 minutes</option>
        <option value={45}>45 minutes</option>
        <option value={60}>1 hour</option>
      </Select>
    </>
  )

  return (
    <>
      <header
        ref={headerRef}
        className="flex flex-none justify-between border-b border-slate-200 bg-white p-2"
      >
        <div className="text-gray-dark flex items-center gap-2 text-center text-sm leading-tight md:p-0">
          <Button size="icon" onClick={() => setWeek(0)} disabled={week === 0}>
            <CalendarFoldIcon size={16} />
          </Button>
          <Button
            size="icon"
            onClick={() => setWeek((w) => (w - 1 < 0 ? 0 : w - 1))}
            disabled={week === 0}
          >
            <ArrowLeftIcon size={16} />
          </Button>
          <span className="font-mono-chivo">
            {formatDateRange(daysList[0], daysList[6], {
              includeTime: false,
            })}
          </span>
          <Button
            size="icon"
            onClick={() => setWeek((w) => (w + 1 <= 3 ? w + 1 : w))}
            disabled={week === 3}
            className={twMerge(week === 3 && 'hidden')}
          >
            <ArrowRightIcon size={16} />
          </Button>
          <Button
            size="icon"
            tooltip="You can only schedule up to 4 weeks into the future."
            className={twMerge(week < 3 && 'hidden')}
          >
            <InfoIcon size={16} />
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <Select
            className={twMerge('hidden h-10 max-w-[300px] text-sm md:block')}
            fieldClassName={twMerge('!py-2 h-10')}
            value={selectedTimezone}
            onChange={(event) => {
              const newTimezone = event.target.value
              onChange(
                value.map((timeBlock) => {
                  const zonedTime = toZonedTime(timeBlock.date, newTimezone)
                  return {
                    ...timeBlock,
                    date: zonedTime,
                  }
                })
              )
              setSelectedTimezone(newTimezone)
            }}
          >
            {timezones
              .sort((a, b) => a.tzCode.localeCompare(b.tzCode))
              .map((timezone) => (
                <option key={timezone.tzCode} value={timezone.tzCode}>
                  {timezone.tzCode} ({timezone.utc})
                </option>
              ))}
          </Select>
          <Popover>
            <PopoverTrigger asChild>
              <Button size="icon">
                <EllipsisIcon size={16} />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className="flex max-w-[240px] flex-col gap-2"
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              {optionsMarkup}
            </PopoverContent>
          </Popover>
        </div>
      </header>
      <div
        ref={scrollContainerRef}
        className={twMerge(
          'bg-gray-lightest relative w-full snap-x snap-mandatory scroll-pl-14 overflow-auto'
        )}
      >
        <div
          id="time-cursor"
          className="pointer-events-none absolute top-16 left-0 right-0 z-40 h-full flex-none"
          style={{
            width: width,
          }}
        >
          <AnimatePresence>
            {minutesSinceStartOfDay && (
              <motion.div
                className="absolute w-full"
                style={{
                  height: 1,
                }}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  top: minutesSinceStartOfDay * PIXELS_PER_MINUTE,
                }}
                exit={{ opacity: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 20,
                }}
              >
                <div
                  className="relative"
                  style={{ marginTop: -15, marginLeft: -48 }}
                >
                  <span className="font-mono-chivo relative sticky left-1 z-10 rounded-full bg-blue-200 p-0.5 px-1 text-xs text-xs text-blue-700">
                    {formatDate(currentTime, 'HH:mm')}
                  </span>
                  <span
                    className="relative block h-px w-full bg-blue-300"
                    style={{ top: -11 }}
                  ></span>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div
          className="pointer-events-none absolute left-0 top-16 z-[0] flex-none"
          style={{ width: width }}
        >
          {timeIntervals.map((hours) => (
            <div
              key={hours.toISOString()}
              id={`schedule-time-${formatDate(hours, 'HH:mm')}`}
              className={twMerge('absolute w-full overflow-hidden')}
              style={{
                top:
                  (hours.getHours() * 60 + hours.getMinutes()) *
                  PIXELS_PER_MINUTE,
                height: MINUTES_PER_TIME_FRAGMENT * PIXELS_PER_MINUTE,
              }}
            >
              <div
                className={twMerge(
                  'absolute top-0 h-px w-full bg-slate-200',
                  hours.getMinutes() !== 0 && 'opacity-50'
                )}
              />
            </div>
          ))}
        </div>

        <div
          ref={contentRef}
          className={twMerge(
            'grid min-w-fit flex-none grid-cols-[auto,repeat(7,minmax(80px,1fr))] gap-x-0.5',
            isSmallScreen && 'grid-cols-[auto,repeat(7,minmax(200px,1fr))]'
          )}
        >
          <div className="pointer-events-none relative sticky left-0 z-20 flex-none snap-start">
            <div className="mt-16">
              {timeIntervals.map((hours) => (
                <div
                  key={hours.toISOString()}
                  className={twMerge(
                    'relative w-full px-2 text-right text-xs',
                    'text-gray-dark font-mono-chivo',
                    'last:hidden',
                    hours.getMinutes() !== 0 && 'opacity-0'
                  )}
                  style={{
                    height: MINUTES_PER_TIME_FRAGMENT * PIXELS_PER_MINUTE,
                  }}
                >
                  {formatDate(hours, 'HH:mm')}
                </div>
              ))}
            </div>
          </div>

          {daysList.map((day: Date, idx: number) => {
            const isOnWeekend = isWeekend(day)
            const isPast = isBefore(startOfDay(day), startOfDay(new Date()))
            const isToday = isSameDay(startOfDay(day), startOfDay(new Date()))

            return (
              <div
                key={`${idx}`}
                className={twMerge(
                  (isPast || isToday) &&
                    'pointer-events-none !bg-slate-400/20 opacity-50',
                  'user-select-none min-h-min flex-none snap-start pt-6 pb-3',
                  isToday && 'bg-blue-200/40',
                  isSmallScreen && 'w-[200px]'
                )}
                unselectable="on"
              >
                <div className="sticky top-0 z-20 h-10 px-1 py-1 text-left leading-none">
                  <h3
                    className={twMerge(
                      'font-medium uppercase text-blue-600',
                      isOnWeekend && 'text-slate-500',
                      isPast && 'text-blue-600/50'
                    )}
                  >
                    {formatDate(day, 'E')}
                  </h3>
                  <p className="font-mono-chivo text-xs text-slate-500">
                    {formatDate(day, 'dd.MM')}
                  </p>
                </div>
                <TimePickerDay
                  day={day}
                  defaultMeetingDuration={defaultMeetingDuration}
                  timeBlocks={value?.filter((timeBlock) =>
                    isSameDay(timeBlock.date, day)
                  )}
                  addTimeBlock={addTimeBlock}
                  removeTimeBlock={removeTimeBlock}
                  updateTimeBlock={updateTimeBlock}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

type TimePickerDayProps = {
  day: Date
  defaultMeetingDuration: number
  timeBlocks: TimeBlock[]
  addTimeBlock: (timeBlock: TimeBlock) => void
  updateTimeBlock: (timeBlock: TimeBlock) => void
  removeTimeBlock: (id: string) => void
}

type TimeBlock = {
  id: string
  date: Date
  duration: number
}

const TimePickerDay = ({
  day,
  defaultMeetingDuration,
  timeBlocks,
  addTimeBlock,
  removeTimeBlock,
  updateTimeBlock,
}: TimePickerDayProps) => {
  const selfRef = React.useRef<HTMLDivElement>(null)
  const [downPointerPosition, setDownPointerPosition] = useState<{
    x: number
    y: number
  }>({ x: 0, y: 0 })

  return (
    <div
      ref={selfRef}
      className={classNames(
        'relative h-full rounded-xl',
        'transition-all duration-100',
        'w-90vw focus-none user-select-none no-select outline-none hover:bg-slate-400/10 md:w-auto'
      )}
      style={{
        height: (TOTAL_HOURS - START_HOUR) * 60 * PIXELS_PER_MINUTE,
      }}
      unselectable="on"
    >
      <div
        className="absolute inset-0 z-[0]"
        onPointerDown={(e) => {
          e.stopPropagation()
          const rect = e.currentTarget.getBoundingClientRect()
          setDownPointerPosition({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
          })
        }}
        onPointerUp={(e) => {
          e.stopPropagation()
          const rect = e.currentTarget.getBoundingClientRect()
          const y = e.clientY - rect.top
          const x = e.clientX - rect.left
          if (y !== downPointerPosition.y || x !== downPointerPosition.x) {
            return
          }
          const startMinutes =
            Math.floor(y / PIXELS_PER_MINUTE / MINUTES_PER_TIME_FRAGMENT) *
            MINUTES_PER_TIME_FRAGMENT

          const startDate = addMinutes(day, startMinutes)
          const duration = defaultMeetingDuration

          addTimeBlock({ id: uuid(), date: startDate, duration })
        }}
        unselectable="on"
      />
      <AnimatePresence>
        {timeBlocks.map((timeBlock) => (
          <TimePickerTimeBlock
            key={timeBlock.id}
            timeBlock={timeBlock}
            otherDayBlocks={timeBlocks}
            onRemove={() => removeTimeBlock(timeBlock.id)}
            onUpdate={(timeBlock) => updateTimeBlock(timeBlock)}
            dayRef={selfRef}
          />
        ))}
      </AnimatePresence>
    </div>
  )
}

const TimePickerTimeBlock = ({
  timeBlock,
  otherDayBlocks,
  onRemove,
  onUpdate,
  dayRef,
}: {
  timeBlock: TimeBlock
  otherDayBlocks: TimeBlock[]
  onRemove: () => void
  onUpdate: (timeBlock: TimeBlock) => void
  dayRef: React.RefObject<HTMLDivElement>
}) => {
  const selfRef = React.useRef<HTMLDivElement>(null)

  const [from, setFrom] = useState<Date>(timeBlock.date)

  const startOfToday = startOfDay(timeBlock.date)

  const [initialDragDistance, setInitialDragDistance] = useState(0)

  const [fromMinutes, setFromMinutes] = React.useState(
    differenceInMinutes(timeBlock.date, startOfToday)
  )

  const [duration, setDuration] = React.useState(timeBlock.duration)

  const [isDragging, setIsDragging] = useState(false)
  const [isResizing, setIsResizing] = useState(false)

  const handlePointerMove = React.useCallback(
    (e: React.PointerEvent) => {
      e.stopPropagation()
      e.preventDefault()

      if (isResizing) {
        const rect = selfRef.current?.getBoundingClientRect()
        const newMinutes =
          Math.floor(
            Math.floor((e.clientY - (rect?.top ?? 0)) / PIXELS_PER_MINUTE) /
              MINUTES_PER_TIME_FRAGMENT_STEP
          ) * MINUTES_PER_TIME_FRAGMENT_STEP
        if (newMinutes <= TOTAL_HOURS * 60 && newMinutes >= 15) {
          setDuration(newMinutes)
        }
      }
      if (isDragging) {
        const dayRect = dayRef.current?.getBoundingClientRect()

        const moveMinutes =
          Math.floor(
            Math.floor(
              (e.clientY - (dayRect?.top ?? 0) - initialDragDistance) /
                PIXELS_PER_MINUTE
            ) / MINUTES_PER_TIME_FRAGMENT_STEP
          ) * MINUTES_PER_TIME_FRAGMENT_STEP

        if (moveMinutes >= 0 && moveMinutes + duration <= TOTAL_HOURS * 60) {
          setFromMinutes(moveMinutes)
          setFrom(addMinutes(startOfToday, moveMinutes))
        }
      }
    },
    [
      isResizing,
      isDragging,
      dayRef,
      initialDragDistance,
      duration,
      startOfToday,
      setFromMinutes,
      setFrom,
      setDuration,
    ]
  )

  const preventScroll = React.useCallback((e: TouchEvent) => {
    e.preventDefault()
  }, [])

  useEffect(() => {
    const element = selfRef.current
    if (element) {
      element.addEventListener('touchmove', preventScroll, { passive: false })
    }
    return () => {
      // Cleanup scroll lock on unmount
      document.body.style.overflow = ''
      // Cleanup touchmove listener
      if (element) {
        element.removeEventListener('touchmove', preventScroll)
      }
    }
  }, [preventScroll])

  const handleMovePointerDown = React.useCallback(
    (e: React.PointerEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()
      setIsDragging(true)
      setInitialDragDistance(
        e.clientY - (selfRef.current?.getBoundingClientRect().top ?? 0)
      )

      selfRef.current?.setPointerCapture(e.pointerId)
      document.body.style.overflow = 'hidden' // Prevent body scrolling
    },
    []
  )

  const handleResizePointerDown = React.useCallback(
    (e: React.PointerEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()

      setIsResizing(true)
      selfRef.current?.setPointerCapture(e.pointerId)
      document.body.style.overflow = 'hidden' // Prevent body scrolling
    },
    []
  )

  const handlePointerUp = React.useCallback(
    (e: React.PointerEvent) => {
      setIsResizing(false)
      setIsDragging(false)

      // Only try to release pointer capture if we still have it
      try {
        if (selfRef.current?.hasPointerCapture(e.pointerId)) {
          selfRef.current?.releasePointerCapture(e.pointerId)
        }
      } catch (err) {
        console.warn('Error releasing pointer capture:', err)
      }

      onUpdate({ ...timeBlock, date: from, duration })
      document.body.style.overflow = '' // Restore body scrolling
    },
    [duration, from, onUpdate, timeBlock]
  )

  return (
    <motion.div
      ref={selfRef}
      className={twMerge(
        'absolute left-0 z-10 w-full p-0.5 hover:z-40',
        (isResizing || isDragging) && 'z-30'
      )}
      style={{
        top: fromMinutes * PIXELS_PER_MINUTE + dayTopPadding,
        height: duration * PIXELS_PER_MINUTE,
      }}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 20,
      }}
    >
      <div
        className={twMerge(
          'transition-all duration-300',
          'group relative flex !h-full w-full items-start overflow-clip rounded-lg bg-blue-200 p-1 ring-1 ring-blue-300',
          (isResizing || isDragging) && 'ring-1 ring-blue-500'
        )}
      >
        <button
          type="button"
          className="absolute inset-0 z-[0] cursor-move"
          aria-label="Move time block"
          onPointerDown={handleMovePointerDown}
          onPointerUp={handlePointerUp}
        />
        <span className="text-xs text-blue-700">
          {formatDate(addHours(from, START_HOUR), 'HH:mm')} -{' '}
          {formatDate(
            addHours(addMinutes(from, duration), START_HOUR),
            'HH:mm'
          )}
        </span>
        <button
          type="button"
          className="z-10 ml-auto rounded-full p-0.5 text-blue-400 transition-all duration-300 hover:text-blue-500"
          onClick={() => onRemove()}
          aria-label="Remove time block"
        >
          <TrashIcon strokeWidth={1} absoluteStrokeWidth size={12} />
        </button>
        <button
          type="button"
          className={twMerge(
            'absolute left-0 bottom-0 z-20 h-2 w-full cursor-s-resize overflow-clip px-1.5 py-0.5 text-white transition-all duration-300'
          )}
          onPointerDown={handleResizePointerDown}
          onPointerUp={handlePointerUp}
          aria-label="Resize time block"
        >
          <span
            className={twMerge(
              'mx-auto block h-0.5 w-6 rounded-full bg-blue-300',
              (isResizing || isDragging) && 'bg-blue-500'
            )}
          ></span>
        </button>
      </div>
    </motion.div>
  )
}
