import type { IconProps } from './types'

export function StaffingIcon(props: IconProps) {
  return (
    <svg
      width="29"
      height="18"
      viewBox="0 0 29 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 16.6522C1 14.9917 1.65963 13.3993 2.83377 12.2251C4.00791 11.051 5.60038 10.3914 7.26087 10.3914C8.92135 10.3914 10.5138 11.051 11.688 12.2251C12.8621 13.3993 13.5217 14.9917 13.5217 16.6522H1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.26082 8.82609C9.42193 8.82609 11.1739 7.07416 11.1739 4.91304C11.1739 2.75193 9.42193 1 7.26082 1C5.09971 1 3.34778 2.75193 3.34778 4.91304C3.34778 7.07416 5.09971 8.82609 7.26082 8.82609Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1739 8.82609C19.335 8.82609 21.0869 7.07416 21.0869 4.91304C21.0869 2.75193 19.335 1 17.1739 1C15.0128 1 13.2609 2.75193 13.2609 4.91304C13.2609 7.07416 15.0128 8.82609 17.1739 8.82609Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7948 11.5443C19.8124 10.8482 18.6503 10.4499 17.4474 10.3969C16.2446 10.344 15.0519 10.6387 14.0122 11.2459"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 14L21 17"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 17L28 10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
