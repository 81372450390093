import * as RHO from '@radix-ui/react-hover-card'
import { forwardRef, PropsWithChildren, ReactNode } from 'react'

type HoverCardProps = {
  content?: ReactNode
  arrowProps?: RHO.HoverCardArrowProps
  triggerProps?: RHO.HoverCardTriggerProps
  contentProps?: RHO.HoverCardContentProps
  openDelay?: number
  closeDelay?: number
}
export const HoverCard = forwardRef<any, PropsWithChildren<HoverCardProps>>(
  (
    {
      openDelay = 100,
      closeDelay = 100,
      children,
      content,
      arrowProps = {},
      triggerProps = {},
      contentProps = {},
    },
    forwardedRef
  ) => {
    return (
      <RHO.Root openDelay={openDelay} closeDelay={closeDelay}>
        <RHO.Trigger {...triggerProps}>{children}</RHO.Trigger>
        <RHO.Portal>
          <RHO.Content
            sideOffset={4}
            collisionPadding={8}
            style={{ zIndex: 100 }}
            className="rounded-lg bg-white p-1 shadow-lg"
            {...contentProps}
            ref={forwardedRef}
          >
            {content}
            <RHO.Arrow className="fill-current text-white" {...arrowProps} />
          </RHO.Content>
        </RHO.Portal>
      </RHO.Root>
    )
  }
)
