import { IconProps, IconVariant } from './types'

export function FilePlusIcon({
  variant = IconVariant.SOLID,
  ...props
}: IconProps) {
  return (
    variant === IconVariant.SOLID && (
      <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M11.2686 3.62109L8.58891 0.941406C8.45219 0.832031 8.28813 0.75 8.12406 0.75H7.96V4.25H11.46V4.08594C11.4327 3.92188 11.378 3.75781 11.2686 3.62109ZM7.085 4.46875V0.75H1.61625H1.58891C1.23344 0.75 0.932656 1.05078 0.932656 1.40625H0.96V14.0938V14.1211C0.96 14.4766 1.23344 14.7773 1.58891 14.7773C1.58891 14.7773 1.58891 14.75 1.61625 14.75H10.8038C11.1592 14.75 11.46 14.4766 11.46 14.1211C11.46 14.1211 11.46 14.1211 11.46 14.0938V5.125H7.74125C7.35844 5.125 7.085 4.85156 7.085 4.46875ZM9.05375 9.28125V9.71875C9.05375 9.96484 8.835 10.1562 8.61625 10.1562H6.86625V11.9062C6.86625 12.1523 6.6475 12.3438 6.42875 12.3438H5.99125C5.74516 12.3438 5.55375 12.1523 5.55375 11.9062V10.1562H3.80375C3.55766 10.1562 3.36625 9.96484 3.36625 9.71875V9.28125C3.36625 9.0625 3.55766 8.84375 3.80375 8.84375H5.55375V7.09375C5.55375 6.875 5.74516 6.65625 5.99125 6.65625H6.42875C6.6475 6.65625 6.86625 6.875 6.86625 7.09375V8.84375H8.61625C8.835 8.84375 9.05375 9.0625 9.05375 9.28125Z"
          fill="currentColor"
        />
      </svg>
    )
  )
}
