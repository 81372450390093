import { EyeIcon } from '@upper/icons'
import * as React from 'react'
import { FieldBase, FieldBaseProps } from './field-base'
import { FormErrorMessage } from './form-error-message'
import { FormLabel } from './form-label'
import type { InvalidProps } from './types'

export type SimplePasswordFieldProps = {
  label?: React.ReactNode
  value: string
  extra?: React.ReactNode
} & InvalidProps &
  FieldBaseProps &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'type' | 'defaultValue'>
type Ref = HTMLInputElement

export const SimplePasswordField = React.forwardRef<
  Ref,
  SimplePasswordFieldProps
>(
  (
    {
      id,
      name,
      value,
      className,
      label,
      isInvalid,
      invalidMessage,
      extra,
      onChange,
      ...restProps
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false)

    return (
      <div>
        {label && <FormLabel htmlFor={id || name}>{label}</FormLabel>}
        <FieldBase
          ref={ref}
          isInvalid={isInvalid !== undefined}
          {...restProps}
          extra={
            <div className="inset-y-0 flex items-center px-3 h-12">
              <button
                type="button"
                className="inline-flex items-center text-sm underline bg-white rounded-md text-gray hover:no-underline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light"
                onClick={() => {
                  setIsPasswordVisible(
                    (prevIsPasswordVisible) => !prevIsPasswordVisible
                  )
                }}
              >
                <EyeIcon className="w-5 h-5 mr-1" />
                {isPasswordVisible ? 'hide' : 'show'}
              </button>
            </div>
          }
        >
          <input
            id={id || name}
            name={name}
            type={isPasswordVisible ? 'text' : 'password'}
            value={value}
            onChange={onChange}
          />
        </FieldBase>
        {/* field extra */}
        {extra}
        {/* error */}
        {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
      </div>
    )
  }
)
