import type { IconProps } from './types'

export function HourglassIcon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.0003 11.6667C29.0003 13.9652 28.0872 16.1696 26.4619 17.7949C24.8366 19.4202 22.6322 20.3333 20.3337 20.3333C18.0351 20.3333 15.8307 19.4202 14.2054 17.7949C12.5801 16.1696 11.667 13.9652 11.667 11.6667V5H29.0003V11.6667Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0003 28.9997C29.0003 26.7011 28.0872 24.4967 26.4619 22.8714C24.8366 21.2461 22.6322 20.333 20.3337 20.333C18.0351 20.333 15.8307 21.2461 14.2054 22.8714C12.5801 24.4967 11.667 26.7011 11.667 28.9997V35.6663H29.0003V28.9997Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 5.00098H31.6667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 35.668H31.6667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.667 30.3345H16.3337L20.3337 26.3345L24.3337 30.3345H29.0003"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5283 17.001H27.139"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
