import * as React from 'react'
import { classNames } from './classnames'
import { FormErrorMessage } from './form-error-message'
import { FormLabel } from './form-label'
import type { InvalidProps } from './types'

const CLASSNAME_FOR_TYPE: Record<string, string> = {
  radio: '',
  checkbox: 'rounded',
}

export type CheckboxProps = InvalidProps &
  React.ComponentPropsWithoutRef<'input'>
type Ref = HTMLInputElement

export const Checkbox = React.forwardRef<Ref, CheckboxProps>(
  (
    {
      className,
      children,
      isInvalid,
      invalidMessage,
      disabled,
      type = 'checkbox',
      ...restProps
    },
    ref
  ) => {
    return (
      <div>
        <FormLabel
          className={classNames(className, 'flex items-center')}
          required={restProps.required}
          withoutBottomMargin
        >
          <input
            ref={ref}
            type={type}
            disabled={disabled}
            className={classNames(
              'border-gray border',
              isInvalid
                ? 'border-red focus:ring-red'
                : 'border-gray-light focus:ring-blue-light',
              'text-blue h-6 w-6 disabled:opacity-50',
              CLASSNAME_FOR_TYPE[type] ?? CLASSNAME_FOR_TYPE['checkbox']
            )}
            {...restProps}
          />
          {children && (
            <span
              className={classNames(
                'ml-3',
                disabled ? 'text-gray' : 'text-gray-dark'
              )}
            >
              {children}
            </span>
          )}
        </FormLabel>
        {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
      </div>
    )
  }
)
