import { IconProps } from './types'

export function SlackIcon(props: IconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.1953 7.16016C13.0312 6.64062 12.457 6.36719 11.9375 6.53125L10.6797 6.94141L9.85938 4.48047L11.0898 4.07031C11.6367 3.90625 11.9102 3.33203 11.7188 2.8125C11.5547 2.29297 11.0078 2.01953 10.4609 2.18359L9.23047 2.59375L8.79297 1.30859C8.62891 0.789062 8.05469 0.515625 7.53516 0.679688C7.01562 0.871094 6.74219 1.41797 6.90625 1.9375L7.34375 3.22266L4.80078 4.07031L4.36328 2.78516C4.19922 2.26562 3.625 1.99219 3.10547 2.18359C2.58594 2.34766 2.3125 2.89453 2.47656 3.41406L2.91406 4.69922L1.65625 5.10938C1.13672 5.30078 0.863281 5.84766 1.02734 6.36719C1.16406 6.77734 1.54688 7.02344 1.95703 7.05078C2.14844 7.07812 2.28516 6.99609 3.54297 6.58594L4.36328 9.04688L3.13281 9.45703C2.61328 9.62109 2.3125 10.1953 2.50391 10.7148C2.64062 11.125 3.02344 11.3711 3.40625 11.3984C3.59766 11.4258 3.73438 11.3438 4.99219 10.9336L5.42969 12.2188C5.56641 12.6836 6.08594 13.0117 6.6875 12.8477C7.20703 12.6836 7.48047 12.1094 7.31641 11.5898L6.87891 10.3047L9.42188 9.45703L9.85938 10.7422C10.0234 11.207 10.543 11.5352 11.1172 11.3711C11.6367 11.1797 11.9102 10.6328 11.7461 10.1133L11.3086 8.82812L12.5664 8.41797C13.0859 8.25391 13.3594 7.67969 13.1953 7.16016ZM6.25 8.44531L5.40234 5.98438L7.97266 5.13672L8.79297 7.59766L6.25 8.44531Z"
        fill="currentColor"
      />
    </svg>
  )
}
