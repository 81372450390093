import * as React from 'react'
import { classNames } from './classnames'

type FormLabelProps = React.ComponentPropsWithoutRef<'label'> & {
  required?: boolean
  withoutBottomMargin?: boolean
}

export function FormLabel({
  className,
  withoutBottomMargin,
  ...restProps
}: FormLabelProps) {
  return (
    <label
      className={classNames(
        'flex gap-x-1 text-sm leading-snug text-slate-800/60',
        withoutBottomMargin ? '' : 'mb-2',
        restProps.required && 'font-semibold',
        className
      )}
      {...restProps}
      children={
        <>
          {restProps.children}
          {restProps.required && (
            <span className="font-mono text-xs leading-4 text-slate-800/40">
              *
            </span>
          )}
        </>
      }
    />
  )
}
