import { IconProps } from '.'

export function HasEmailIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.26 4H3.26001C2.41626 4 1.76001 4.6875 1.76001 5.5V14.5C1.76001 15.3438 2.41626 16 3.26001 16H16.26C17.0725 16 17.76 15.3438 17.76 14.5V5.5C17.76 4.6875 17.0725 4 16.26 4ZM3.26001 5H16.26C16.51 5 16.76 5.25 16.76 5.5V6.8125C16.0725 7.375 15.0725 8.1875 12.0413 10.5938C11.51 11.0312 10.4788 12.0312 9.76001 12C9.01001 12.0312 7.97876 11.0312 7.44751 10.5938C4.41626 8.1875 3.41626 7.375 2.76001 6.8125V5.5C2.76001 5.25 2.97876 5 3.26001 5ZM16.26 15H3.26001C2.97876 15 2.76001 14.7812 2.76001 14.5V8.09375C3.44751 8.6875 4.57251 9.59375 6.82251 11.375C7.47876 11.9062 8.60376 13.0312 9.76001 13C10.885 13.0312 12.01 11.9062 12.6663 11.375C14.9163 9.59375 16.0413 8.6875 16.76 8.09375V14.5C16.76 14.7812 16.51 15 16.26 15Z"
        fill="currentColor"
      />
    </svg>
  )
}
