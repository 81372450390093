import { ZIndexContext } from '@upper/providers'
import { useContext, useEffect, useRef } from 'react'

export function useZIndex(prefix: string, start = 0) {
  const zIndexManager = useContext(ZIndexContext)

  const indexRef = useRef<number | null>(null)
  if (!zIndexManager) {
    throw new Error(
      'No z-index manager was provided. The application must be wrapped in an <ZIndexProvider> component.'
    )
  }

  if (!indexRef.current) {
    indexRef.current = zIndexManager?.nextIndex(prefix, start) || 0
  }

  useEffect(() => {
    return () => {
      zIndexManager?.clearIndex(prefix)
    }
  }, [prefix, zIndexManager])

  return indexRef.current
}
