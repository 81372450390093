import type { IconProps } from './types'

export function ClockIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.5"
        d="M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM10.875 10.0312L10.25 10.8125C10.1562 10.9375 10.0312 11.0312 9.84375 11.0312C9.75 11.0312 9.625 10.9688 9.5625 10.9062L7.46875 9.34375C7.15625 9.125 7 8.78125 7 8.375V3.5C7 3.25 7.21875 3 7.5 3H8.5C8.75 3 9 3.25 9 3.5V8L10.8125 9.34375C10.9062 9.4375 11 9.5625 11 9.71875C11 9.84375 10.9375 9.96875 10.875 10.0312Z"
        fill="currentColor"
      />
    </svg>
  )
}
