import { createContext } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Context<T = any> = {
  state: Record<string, T | undefined>
  add: (name: string) => void
  remove: (name: string) => void
  show: <T>(name: string, payload?: T) => void
  hide: (name: string) => void
  // toggle: (name: string) => void
}
export const Context = createContext<Context | undefined>(undefined)
