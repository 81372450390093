import type { IconProps } from './types'

export function NavigationMyReferralsIcon(props: IconProps) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 23.026a6.01 6.01 0 1112.02 0H8zM14.01 15.513a3.756 3.756 0 100-7.513 3.756 3.756 0 000 7.513zM23.527 15.513a3.756 3.756 0 100-7.513 3.756 3.756 0 000 7.513zM27.003 18.122a6.015 6.015 0 00-6.511-.286M28.04 23.12h-1.789a1.181 1.181 0 00-.44 2.276l1.816.726a1.181 1.181 0 01-.44 2.277H25.4M26.72 29.28v-.88M26.72 23.12v-.88"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.72 31.04a5.28 5.28 0 100-10.56 5.28 5.28 0 000 10.56z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
