import type { IconProps } from './types'

export function HamburgerIcon(props: IconProps) {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="10" height="2" rx="1" fill="currentColor" />
      <rect y="4" width="14" height="2" rx="1" fill="currentColor" />
      <rect y="8" width="7" height="2" rx="1" fill="currentColor" />
    </svg>
  )
}
