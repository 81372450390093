import { EngagementsQuery } from '@upper/graphql/studio';
import Utils from '../utils/Utils';

type JobDataPayload = {
    jobId?: string,
    jobName?: string
}

type ApplyDataPayload = {
    candidateId?: string,
    candidateName?: string
}

type EngagementDataPayload = {
  engagement?: EngagementsQuery['engagements'][0]
}

export type ModalContentProps = {
  onCloseRequest?: () => void
  additionalData?: JobDataPayload & ApplyDataPayload & EngagementDataPayload
  type?: 'modal' | 'alert'
}

export type ModalContent = {
  ModalContentNode: React.ComponentType<ModalContentProps>
  additionalData?: JobDataPayload & ApplyDataPayload & EngagementDataPayload
  type?: 'modal' | 'alert'
}

export type ModalsInitialStateType = {
    modals: any[],
    currentZ: number,
    open: (payload: ModalContent) => void,
    close: (id: string) => void,
}

export const modalsInitialState: ModalsInitialStateType = {
    modals: [],
    currentZ: 200,
    open: null,
    close: null,
};

export enum ModalsActionTypes {
    Open = 'OPEN_MODAL',
    Close = 'CLOSE_MODAL',
}

export type ModalsAction = {
    type: ModalsActionTypes,
    payload: ModalContent | string 
}

export const reducer = (state: ModalsInitialStateType, action: ModalsAction) => {
    const { modals, currentZ } = state;
    switch (action.type) {
      case ModalsActionTypes.Open:
        const newZ: number = currentZ + 1;
        return { ...state, currentZ: newZ, modals: [...modals, {
            zIndex: newZ,
            modalContent: action.payload,
            id: Utils.uuid()
        }] }
      case ModalsActionTypes.Close:
            const index = modals.findIndex((entry) => entry.id === action.payload);
			if (index !== -1) {
                const newZ: number = currentZ - 1;
				const newModals = [...modals];
				newModals.splice(index, 1);
				return { ...state, currentZ: newZ, modals: newModals };
			}
        return state;
      default:
        return state;
    }
};