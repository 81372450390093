import { useToggle } from '@upper/hooks'
import * as Icons from '@upper/sapphire/icons'
import { Tooltip, TooltipContent, TooltipTrigger, cn } from '@upper/sapphire/ui'
import { formatCurrency } from '@upper/utils'
import React, { HTMLAttributes, PropsWithChildren, ReactNode } from 'react'
import { Spinner } from '../spinner'
import { Text } from '../text'

type Props = {
  name?: string | ReactNode
  clientAmount?: number
  talentAmount?: number
  totalHours?: number
  totalExpenses?: number
  folded?: boolean
  canSendReport?: boolean
  sendingReport?: boolean
  onSendReport?: () => void
}
export const CompanyCard = ({
  children,
  name,
  clientAmount,
  talentAmount,
  totalHours,
  totalExpenses,
  folded: propsFolded = false,
  canSendReport = true,
  sendingReport = false,
  onSendReport,
}: PropsWithChildren<Props>) => {
  const folded = useToggle(propsFolded)

  return (
    <>
      <header className="bg-blue flex items-center gap-2 rounded-2xl py-1 pl-6 pr-2 text-white">
        <Text variant="h4">{name}</Text>
        <div className="ml-auto flex items-center gap-6">
          {canSendReport && (
            <SendReportButton loading={sendingReport} onClick={onSendReport} />
          )}
          {(totalExpenses ?? 0) > 0 && (
            <Tooltip>
              <TooltipTrigger className="font-mono text-sm text-white/80">
                {formatCurrency(totalExpenses)}
              </TooltipTrigger>
              <TooltipContent>Total expenses</TooltipContent>
            </Tooltip>
          )}
          <Tooltip>
            <TooltipContent>Talent / Client</TooltipContent>
            <TooltipTrigger asChild>
              <p className="font-mono text-sm text-white/80">
                <span>{formatCurrency(talentAmount, 0)}</span>
                <span className="mx-2">/</span>
                <span className="font-bold text-white/100">
                  {formatCurrency(clientAmount, 0)}
                </span>
              </p>
            </TooltipTrigger>
          </Tooltip>
        </div>
        <div className="flex w-[152px] items-center justify-between gap-2 pl-2">
          <p className="font-mono">{(totalHours ?? 0).toFixed(2)}h</p>
          <FoldToggleButton folded={folded.value} onClick={folded.toggle} />
        </div>
      </header>
      <CompanyCardContext.Provider value={{ folded: folded.value }}>
        <div className="space-y-5">{children}</div>
      </CompanyCardContext.Provider>
    </>
  )
}

type SendReportButtonProps = HTMLAttributes<HTMLButtonElement> & {
  loading?: boolean
}

const SendReportButton = ({ loading, ...props }: SendReportButtonProps) => {
  return (
    <button
      {...props}
      disabled={loading}
      className={cn(
        'bg-gray-lightest text-blue hover:bg-blue-lightest flex items-center gap-2.5 rounded px-3 py-2 text-sm font-light transition-colors',
        loading && 'pointer-events-none'
      )}
    >
      Send Report
      {!loading && <Icons.Check />}
      {loading && <Spinner className="!text-blue h-4 w-4" />}
    </button>
  )
}

type FoldToggleButtonProps = HTMLAttributes<HTMLButtonElement> & {
  folded?: boolean
}
const FoldToggleButton = ({
  folded,
  className,
  ...props
}: FoldToggleButtonProps) => {
  return (
    <Tooltip>
      <TooltipContent>{folded ? 'Unfold' : 'Fold'}</TooltipContent>
      <TooltipTrigger asChild>
        <button
          {...props}
          className={cn('rounded-full bg-white/10 p-2', className)}
        >
          {!folded ? <Icons.ChevronsDownUp /> : <Icons.ChevronsUpDown />}
        </button>
      </TooltipTrigger>
    </Tooltip>
  )
}

type CompanyCardContext = {
  folded: boolean
}

export const CompanyCardContext = React.createContext<CompanyCardContext>(
  undefined as any
)
