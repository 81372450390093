import { useField } from 'formik'
import { Select, SelectProps } from '@upper/ui'
import { FormikFieldProps } from './types'

type FormikSelectProps = FormikFieldProps & SelectProps

export function FormikSelect(props: FormikSelectProps) {
  const [field, meta] = useField(props)

  return (
    <Select
      isInvalid={meta.error && meta.touched}
      invalidMessage={meta.error}
      {...field}
      {...props}
    />
  )
}
