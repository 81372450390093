import { IconProps } from '.'

export function ContractTypeIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.6"
        d="M16 9V3C16 2.44772 15.5523 2 15 2H4C3.44772 2 3 2.44772 3 3V17C3 17.5523 3.44772 18 4 18H7"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M6 5H9"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7H13"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13"
        cy="15"
        r="4"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        opacity="0.6"
        d="M13 13V15H14"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M18 4V11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
