import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { Loader2 } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip'
import { cn } from './utils'

const buttonVariants = cva(
  'inline-flex flex-none whitespace-nowrap items-center justify-center rounded-lg text-sm ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-primary-foreground hover:bg-blue-800',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-red-700',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-slate-50 border border-slate-200 shadow-none',
        ghost: 'text-slate-500 hover:bg-slate-600/10 hover:text-slate-700',
        link: 'text-primary underline-offset-2 hover:underline !p-0 !h-auto',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        xs: 'h-8 rounded-md px-2',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'secondary',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  tooltip?: React.ReactNode
  loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'secondary',
      size,
      asChild = false,
      loading = false,
      tooltip,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    const markup = (
      <Comp
        type="button"
        className={cn(
          buttonVariants({ variant, size, className }),
          loading && 'pointer-events-none relative'
        )}
        ref={ref}
        {...props}
      >
        {loading && (
          <span className="absolute inset-0 grid place-content-center rounded-md bg-white/80">
            <Loader2 className="text-primary animate-spin" />
          </span>
        )}
        {children}
      </Comp>
    )
    const tooltipMarkup = (
      <Tooltip>
        <TooltipContent>{tooltip}</TooltipContent>
        <TooltipTrigger asChild>{markup}</TooltipTrigger>
      </Tooltip>
    )
    return tooltip ? tooltipMarkup : markup
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
