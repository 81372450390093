/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { forwardRef, PropsWithChildren, ReactNode } from 'react'

export type DropDownCardProps = {
  content: ReactNode
} & DropdownMenu.DropdownMenuProps

export const DropDownCard = forwardRef<
  any,
  PropsWithChildren<DropDownCardProps>
>(({ open, onOpenChange, children, content }, ref) => {
  return (
    <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger className="inline-flex" ref={ref}>
        {children}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content sideOffset={8} align="start">
        {content}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
})

DropDownCard.displayName = 'DropDownCard'
