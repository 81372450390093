import { useEffect, useRef, useState } from 'react'

export function useSticky(
  options: {
    className?: string
    ratio?: number
  } = { ratio: 1 }
): [React.RefObject<HTMLElement>, boolean] {
  const ref = useRef<HTMLElement>(null)
  const [isStuck, setStuck] = useState(false)

  useEffect(() => {
    const target = ref.current
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < (options?.ratio ?? 1)) {
          if (options.className) target?.classList.add(options.className)
          setStuck(true)
        } else {
          if (options.className) target?.classList.remove(options.className)
          setStuck(false)
        }
      },
      { threshold: [1] }
    )

    if (target) {
      observer.observe(target)
    }

    return () => {
      if (target) {
        observer.unobserve(target)
      }
    }
  }, [options])

  return [ref, isStuck]
}
