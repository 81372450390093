import type { IconProps } from './types'

export function TrashIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.75 13.4375C2.75 14.1758 3.32422 14.75 4.0625 14.75H11.9375C12.6484 14.75 13.25 14.1758 13.25 13.4375V4.25H2.75V13.4375ZM10.1875 6.4375C10.1875 6.21875 10.3789 6 10.625 6C10.8438 6 11.0625 6.21875 11.0625 6.4375V12.5625C11.0625 12.8086 10.8438 13 10.625 13C10.3789 13 10.1875 12.8086 10.1875 12.5625V6.4375ZM7.5625 6.4375C7.5625 6.21875 7.75391 6 8 6C8.21875 6 8.4375 6.21875 8.4375 6.4375V12.5625C8.4375 12.8086 8.21875 13 8 13C7.75391 13 7.5625 12.8086 7.5625 12.5625V6.4375ZM4.9375 6.4375C4.9375 6.21875 5.12891 6 5.375 6C5.59375 6 5.8125 6.21875 5.8125 6.4375V12.5625C5.8125 12.8086 5.59375 13 5.375 13C5.12891 13 4.9375 12.8086 4.9375 12.5625V6.4375ZM13.6875 1.625H10.4062L10.1328 1.13281C10.0234 0.914062 9.80469 0.75 9.55859 0.75H6.41406C6.16797 0.75 5.94922 0.914062 5.83984 1.13281L5.59375 1.625H2.3125C2.06641 1.625 1.875 1.84375 1.875 2.0625V2.9375C1.875 3.18359 2.06641 3.375 2.3125 3.375H13.6875C13.9062 3.375 14.125 3.18359 14.125 2.9375V2.0625C14.125 1.84375 13.9062 1.625 13.6875 1.625Z"
        fill="currentColor"
      />
    </svg>
  )
}
