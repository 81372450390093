import type { IconProps } from './types'

export function ReferIcon(props: IconProps) {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.21 7.75C8.12406 7.75 9.71 6.19141 9.71 4.25C9.71 2.33594 8.12406 0.75 6.21 0.75C4.26859 0.75 2.71 2.33594 2.71 4.25C2.71 6.19141 4.26859 7.75 6.21 7.75ZM8.80766 8.65234L7.5225 13.875L6.6475 10.1562L7.5225 8.625H4.8975L5.7725 10.1562L4.8975 13.875L3.585 8.65234C1.61625 8.73438 0.085 10.3477 0.085 12.3164V13.4375C0.085 14.1758 0.659219 14.75 1.3975 14.75H11.0225C11.7334 14.75 12.335 14.1758 12.335 13.4375V12.3164C12.335 10.3477 10.7764 8.73438 8.80766 8.65234Z"
        fill="currentColor"
      />
    </svg>
  )
}
