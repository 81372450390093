import type { IconProps } from './types'
export const CompressIcon = (props: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.146437 12.3536L3.25 9.25L2.22119 8.28034C1.74869 7.80784 2.08331 7 2.7515 7H6.2515C6.66572 7 7 7.33578 7 7.75V11.25C7 11.9182 6.19366 12.2528 5.72119 11.7803L4.75 10.75L1.64644 13.8536C1.45119 14.0488 1.13459 14.0488 0.939344 13.8536L0.146437 13.0607C-0.0488125 12.8654 -0.0488125 12.5488 0.146437 12.3536V12.3536ZM13.8536 1.64644L10.75 4.75L11.7788 5.71966C12.2513 6.19216 11.9167 7 11.2485 7H7.7485C7.33428 7 7 6.66422 7 6.25V2.75C7 2.08181 7.80634 1.74719 8.27881 2.21966L9.25 3.25L12.3536 0.146437C12.5488 -0.0488125 12.8654 -0.0488125 13.0607 0.146437L13.8536 0.939344C14.0488 1.13459 14.0488 1.45119 13.8536 1.64644V1.64644Z"
      fill="currentColor"
    />
  </svg>
)
