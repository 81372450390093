export type Maybe<T> = T | null

export enum TimeEntryStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Submitted = 'Submitted',
  Rejected = 'Rejected',
}

export enum TimesheetDayStatus {
  None = 'None',
  Approved = 'Approved',
  Pending = 'Pending',
  Submitted = 'Submitted',
}

export interface ExpenseAsset {
  id?: Maybe<string>
  locationUrl?: Maybe<string>
  name?: Maybe<string>
  type?: Maybe<string>
}

export interface TimesheetDay {
  id?: string
  day?: Maybe<Date>
  status?: TimesheetDayStatus
  timeEntries?: TimeEntry[]
  absences?: TimesheetAbsence[]
  expenses?: TimesheetExpense[]
  totalHours?: number
  totalExpenses?: number
}

export interface TimeEntry {
  id?: Maybe<string>
  day?: Maybe<Date>
  hours?: Maybe<number>
  status?: Maybe<TimeEntryStatus>
  notes?: Maybe<string>
}

export interface TimesheetAbsence extends TimeEntry {
  type?: Maybe<string>
}

export interface TimesheetExpense extends TimeEntry {
  amount?: Maybe<number>
  assets?: Maybe<Array<ExpenseAsset>>
  category?: Maybe<string>
}

export interface TimesheetEngagementRevision {
  appliesFrom?: Maybe<string>
  periodEnd?: Maybe<string>
  periodStart?: Maybe<string>
}
