import { IconProps } from '.'

export function CalendarIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M13.5 2H12V0.375C12 0.167906 11.8321 0 11.625 0H11.375C11.1679 0 11 0.167906 11 0.375V2H5V0.375C5 0.167906 4.83209 0 4.625 0H4.375C4.16791 0 4 0.167906 4 0.375V2H2.5C1.67156 2 1 2.67156 1 3.5V14.5C1 15.3284 1.67156 16 2.5 16H13.5C14.3284 16 15 15.3284 15 14.5V3.5C15 2.67156 14.3284 2 13.5 2ZM2.5 3H13.5C13.7757 3 14 3.22431 14 3.5V5H2V3.5C2 3.22431 2.22431 3 2.5 3ZM13.5 15H2.5C2.22431 15 2 14.7757 2 14.5V6H14V14.5C14 14.7757 13.7757 15 13.5 15Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
