import type { IconProps } from './types'
export const ExpandIcon = (props: IconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.64644 8.85356L3.75 11.75L4.77881 12.7197C5.25131 13.1922 4.91669 14 4.2485 14H0.7485C0.334281 14 0 13.6642 0 13.25V9.75C0 9.08181 0.806344 8.74719 1.27881 9.21966L2.25 10.25L5.14644 7.35356C5.34169 7.15831 5.65828 7.15831 5.85353 7.35356L6.64644 8.14647C6.84172 8.34172 6.84172 8.65828 6.64644 8.85356V8.85356ZM7.35356 5.14644L10.25 2.25L9.22119 1.28034C8.74869 0.807844 9.08331 0 9.7515 0H13.2515C13.6657 0 14 0.335781 14 0.75V4.25C14 4.91819 13.1937 5.25281 12.7212 4.78034L11.75 3.75L8.85356 6.64644C8.65831 6.84169 8.34172 6.84169 8.14647 6.64644L7.35356 5.85353C7.15828 5.65828 7.15828 5.34172 7.35356 5.14644V5.14644Z"
      fill="currentColor"
    />
  </svg>
)
