import { IconProps } from '.'

export function EngagementStatusesIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2" y="2" width="16" height="16" rx="1" stroke="currentColor" />
      <rect x="4" y="5" width="4" height="2" stroke="currentColor" />
      <rect x="12" y="5" width="4" height="2" stroke="currentColor" />
      <rect x="12" y="9" width="4" height="2" stroke="currentColor" />
      <rect x="12" y="13" width="4" height="2" stroke="currentColor" />
      <line x1="10" y1="2" x2="10" y2="18" stroke="currentColor" />
    </svg>
  )
}
