import { SpinnerIcon } from '@upper/icons'
import { classNames } from './classnames'

type SpinnerProps = {
  className?: string
  noSize?:boolean
}

export function Spinner({ className, noSize }: SpinnerProps) {
  return (
    <SpinnerIcon
      className={classNames(
        'animate-spin text-gray-dark',
        className,
        !noSize && 'w-12 h-12'
      )}
    />
  )
}
