import type { IconProps } from './types'

export function NavigationOpenPositionsIcon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 15.3809H32"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6087 11.1904H30.9565C31.2333 11.1904 31.4987 11.3008 31.6944 11.4973C31.8901 11.6937 32 11.9602 32 12.238V27.9523C32 28.2302 31.8901 28.4966 31.6944 28.6931C31.4987 28.8896 31.2333 29 30.9565 29H9.04348C8.76673 29 8.50132 28.8896 8.30563 28.6931C8.10994 28.4966 8 28.2302 8 27.9523V12.238C8 11.9602 8.10994 11.6937 8.30563 11.4973C8.50132 11.3008 8.76673 11.1904 9.04348 11.1904H17.3913"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1304 20.6191H29.3912"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1304 23.762H26.7825"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0048 26.381C20.579 25.721 19.1672 25.2411 17.3996 24.5843C16.9008 24.3978 16.9822 23.0904 17.2034 22.8463C17.5552 22.4637 17.8225 22.0107 17.9876 21.5171C18.1526 21.0234 18.2119 20.5003 18.1613 19.9821C18.1915 19.6592 18.1532 19.3334 18.0487 19.0265C17.9443 18.7195 17.7761 18.4383 17.5554 18.2015C17.3347 17.9646 17.0664 17.7775 16.7682 17.6525C16.4701 17.5274 16.149 17.4674 15.826 17.4762C15.503 17.4674 15.1819 17.5274 14.8838 17.6525C14.5856 17.7775 14.3173 17.9646 14.0966 18.2015C13.8759 18.4383 13.7077 18.7195 13.6033 19.0265C13.4988 19.3334 13.4605 19.6592 13.4907 19.9821C13.4401 20.5003 13.4994 21.0234 13.6644 21.5171C13.8295 22.0107 14.0968 22.4637 14.4486 22.8463C14.6698 23.0904 14.7512 24.3978 14.2524 24.5843C12.4869 25.2411 11.073 25.721 10.6472 26.381"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6087 12.7619C22.6087 12.9008 22.5538 13.0341 22.4559 13.1323C22.3581 13.2305 22.2254 13.2857 22.087 13.2857H17.9131C17.7747 13.2857 17.642 13.2305 17.5442 13.1323C17.4463 13.0341 17.3914 12.9008 17.3914 12.7619V9.61905C17.3914 8.92443 17.6662 8.25827 18.1554 7.7671C18.6447 7.27593 19.3082 7 20.0001 7C20.6919 7 21.3555 7.27593 21.8447 7.7671C22.3339 8.25827 22.6087 8.92443 22.6087 9.61905V12.7619Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
