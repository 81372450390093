export default class Utils {
    static uuid(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
            let r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    static groupBy(arr:any[], criteria: any): any {
        const newObj: any = arr.reduce((acc, currentValue) => {
            if (!acc[currentValue[criteria]]) {
              acc[currentValue[criteria]] = [];
            }
            acc[currentValue[criteria]].push(currentValue);
            return acc;
          }, {});
          return newObj;
    }

    static getDatePretty(date: any) {
        if (!date) {
            return '';
        }
        const startDate = new Date(date);
        const startDay = String(startDate.getDate()).padStart(2, '0');
        const startYear = startDate.getFullYear();
        return `${startDay}/${ ('0' + (startDate.getMonth()+1)).slice(-2)}/${startYear}`;
    }

    static isToday(date: Date): boolean {
        if (!(date instanceof Date)) {
            return false;
        }
        const today = new Date()
        return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
    }
}