import { useToggle } from '@upper/hooks'
import { Tooltip, TooltipContent, TooltipTrigger, cn } from '@upper/sapphire/ui'
import { useCallback, useMemo, useState } from 'react'

type Props = {
  value: string
  disabled?: boolean
  error?: string
  onChange: (value: string) => void
  onBlur?: () => void
}
export const EntryNote = ({
  value,
  disabled,
  error,
  onChange,
  onBlur,
}: Props) => {
  const initialValue = useMemo(
    () => value?.replace(/\n/g, '<br />') ?? '',
    [value]
  )
  const [inputValue, setInputValue] = useState(initialValue)
  const isFocused = useToggle()

  const handleInputChange = useCallback(() => {
    isFocused.setFalse()
    setTimeout(() => {
      onChange(inputValue)
    }, 500)
  }, [isFocused, onChange, inputValue])

  const handleInputFocus = useCallback(() => {
    isFocused.setTrue()
  }, [isFocused])

  const markup = (
    <div className="relative h-full w-full">
      <div
        aria-label="Notes"
        tabIndex={0}
        role="textbox"
        className={cn(
          'h-full min-h-[24px] w-full break-all rounded-md border-none p-1 align-top font-mono text-xs outline-none focus:ring-1',
          error && 'outline-destructive outline-offset-0'
        )}
        placeholder="Empty entry"
        dangerouslySetInnerHTML={{
          __html: initialValue,
        }}
        onInput={(e) => {
          setInputValue(e.currentTarget.innerHTML?.replace(/<br\s*\/?>/g, '\n'))
        }}
        onFocus={handleInputFocus}
        onBlur={handleInputChange}
        onPaste={(e) => {
          e.preventDefault()
          const text = e.clipboardData.getData('text/plain')
          document.execCommand('insertHTML', false, text)
        }}
        contentEditable={!disabled}
        aria-disabled={disabled}
      />
      {!isFocused.value && !inputValue && (
        <span className="text-gray pointer-events-none absolute top-0 -z-[0] px-1 py-1 text-xs">
          Write a short note ...
        </span>
      )}
    </div>
  )

  return error ? (
    <Tooltip>
      <TooltipTrigger asChild>{markup}</TooltipTrigger>
      <TooltipContent
        side="bottom"
        className="bg-red-lightest !text-red-darker"
      >
        {error}
      </TooltipContent>
    </Tooltip>
  ) : (
    markup
  )
}
