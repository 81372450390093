import type { IconProps } from './types'
export const EditIcon = (props: IconProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.88393 5.86607C9.80357 5.78571 9.64286 5.78571 9.5625 5.86607L4.09821 11.3304L3.85714 13.6205C3.81696 13.942 4.05803 14.183 4.37946 14.1429L6.66964 13.9018L12.1339 8.4375C12.2143 8.35714 12.2143 8.19643 12.1339 8.11607L9.88393 5.86607ZM13.8616 6.70982C14.2232 6.34821 14.2232 5.74554 13.8616 5.38393L12.6161 4.13839C12.2545 3.77679 11.6518 3.77679 11.2902 4.13839L10.3259 5.10268C10.2455 5.18304 10.2455 5.34375 10.3259 5.42411L12.5759 7.67411C12.6562 7.75446 12.817 7.75446 12.8973 7.67411L13.8616 6.70982Z"
      fill="currentColor"
    />
  </svg>
)
