import { IconProps, IconVariant } from './types'

export function DuplicateIcon({
  variant = IconVariant.SOLID,
  ...props
}: IconProps) {
  return (
    <>
      {variant === IconVariant.SOLID && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.5 0H5.5C4.65625 0 4 0.6875 4 1.5V10.5C4 11.3438 4.65625 12 5.5 12H14.5C15.3125 12 16 11.3438 16 10.5V1.5C16 0.6875 15.3125 0 14.5 0ZM5.5 13C4.09375 13 3 11.9062 3 10.5V4H1.5C0.65625 4 0 4.6875 0 5.5V14.5C0 15.3438 0.65625 16 1.5 16H10.5C11.3125 16 12 15.3438 12 14.5V13H5.5Z"
            fill="currentColor"
          />
        </svg>
      )}
      {variant === IconVariant.LIGHT && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            opacity="0.5"
            d="M14.5 0H4.5C3.65625 0 3 0.6875 3 1.5V3H1.5C0.65625 3 0 3.6875 0 4.5V14.5C0 15.3438 0.65625 16 1.5 16H11.5C12.3125 16 13 15.3438 13 14.5V13H14.5C15.3125 13 16 12.3438 16 11.5V1.5C16 0.6875 15.3125 0 14.5 0ZM12 14.5C12 14.7812 11.75 15 11.5 15H1.5C1.21875 15 1 14.7812 1 14.5V4.5C1 4.25 1.21875 4 1.5 4H3V11.5C3 12.3438 3.65625 13 4.5 13H12V14.5ZM15 11.5C15 11.7812 14.75 12 14.5 12H4.5C4.21875 12 4 11.7812 4 11.5V1.5C4 1.25 4.21875 1 4.5 1H14.5C14.75 1 15 1.25 15 1.5V11.5Z"
            fill="currentColor"
          />
        </svg>
      )}
    </>
  )
}
