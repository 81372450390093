import { useValidatorField } from '@upper/providers'
import * as Icons from '@upper/sapphire/icons'
import { cn } from '@upper/sapphire/ui'
import { hoursToTimeString, timeStringToHours } from '@upper/utils'
import { useCallback, useEffect, useState } from 'react'
import { EntryHeader } from './entry-header'
import { EntryNote } from './entry-note'
import { TimeEntry } from './types'

/**
 * Props for the TimeEntryCard component.
 *
 * @typedef {Object} Props
 *
 * @property {DisplayTimeEntryFragment} data - The time entry data to be displayed in the card.
 * @property {(id: string | null | undefined) => void} [onDelete] - Optional function that is called when the delete action is triggered.
 * @property {(id: string, data: Partial<TimeEntry>) => void} [onUpdate] - Optional function that is called when the time entry data needs to be updated.
 *                                                                            Note: This function is deprecated and will be removed in the release version.
 */
type Props = {
  data: TimeEntry
  disabled?: boolean
  onDelete?: (id: string | null | undefined) => void
  onUpdate?: (id: string, data: Partial<TimeEntry>) => void
}

/**
 * TimeEntryCard Component. This component displays the details of a time entry.
 * It provides the ability to delete the entry or update its duration or note.
 *
 * @example
 * // Usage example
 * <TimeEntryCard
 *    data={timeEntryData}
 *    onDelete={(id) => console.log(`Delete time entry with id ${id}`)} //temporary
 *    onUpdate={(id, data) => console.log(`Update time entry with id ${id}`, data)} //temporary
 * />
 *
 * @param {Props} props - The properties that define the component's behavior and display.
 *
 * @returns {JSX.Element} The rendered TimeEntryCard component.
 */
export const TimeEntryCard = ({
  data,
  disabled,
  onUpdate,
  onDelete,
}: Props): JSX.Element => {
  const validatorField = useValidatorField(data.id ?? '', {
    schemaPath: 'timeEntry',
    defaultValue: data,
  })

  const [time, setTime] = useState(hoursToTimeString(data.hours ?? 0))

  const handleUpdate = useCallback(
    (newData: Partial<TimeEntry>) => {
      onUpdate?.(data.id ?? '', newData)
    },
    [data.id, onUpdate]
  )

  const handleHoursChange = useCallback(() => {
    const hours = timeStringToHours(time)
    handleUpdate({ hours })
    validatorField.onChange({ hours, notes: data.notes })
  }, [time, handleUpdate, validatorField, data.notes])

  const handleNotesChange = useCallback(
    (notes: string) => {
      const hours = timeStringToHours(time)
      handleUpdate({ notes })

      validatorField.onChange({ hours, notes })
    },
    [handleUpdate, time, validatorField]
  )

  useEffect(() => {
    setTime(hoursToTimeString(data.hours ?? 0))
  }, [data.hours])

  return (
    <div
      className={cn(
        'border-gray-light rounded-xl border bg-white',
        disabled && 'pointer-events-none'
      )}
    >
      <EntryHeader
        icon={<Icons.Hourglass />}
        tooltip="Time log"
        value={time}
        onChange={(v) => {
          setTime(v)
        }}
        onBlur={handleHoursChange}
        onDelete={() => onDelete?.(data.id)}
        disabled={disabled}
      />
      <div className="min-h-[20px] px-1 py-1">
        <EntryNote
          value={data.notes ?? ''}
          onChange={handleNotesChange}
          disabled={disabled}
          error={
            validatorField.errors?.find((e: any) => e.path.includes('notes'))
              ?.message
          }
        />
      </div>
    </div>
  )
}
