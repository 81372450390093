import { string } from 'yup';

export const passwordValidation = string().test(
  'password-validation',
  'Password must be at least 8 characters long, contain one uppercase letter, and one special character',
  value => {
    const hasEightCharactersOrMore = (value?.length ?? 0) >= 8;
    const hasOneUppercaseChar = /(.*[A-Z].*)/.test(value ?? '');
    const hasOneSpecialChar = /(.*\W.*)/.test(value ?? '');

    return hasEightCharactersOrMore && hasOneUppercaseChar && hasOneSpecialChar;
  }
);
