import { SimplePasswordField, SimplePasswordFieldProps } from '@upper/ui'
import { useField } from 'formik'
import { FormikFieldProps } from './types'

type FormikPasswordFieldProps = FormikFieldProps &
  Omit<SimplePasswordFieldProps, 'value'>

export function FormikSimplePasswordField(props: FormikPasswordFieldProps) {
  const [field, meta] = useField(props)

  return (
    <SimplePasswordField
      isInvalid={meta.error && meta.touched}
      invalidMessage={meta.error}
      {...field}
      {...props}
    />
  )
}
