import chroma from 'chroma-js'
import { forwardRef, useMemo } from 'react'
import { classNames } from '../classnames'

const COLORS = [
  '#F53636',
  '#F05528',
  '#F17418',
  '#F19A0D',
  '#EBB711',
  '#D8CB0D',
  '#B8D917',
  '#8FE237',
  '#65D562',
  '#04D26F',
]

type Props = {
  label?: string
  value: number | null
  maxValues?: number
  valueTransformer?: (value: number) => string
}
export const RateAverageBadge = forwardRef<any, Props>(
  (
    {
      label,
      value,
      maxValues = 10,
      valueTransformer = defaultRateValueTransformer,
    },
    ref
  ) => {
    const score: null | number = useMemo(() => {
      return value === null || value === 0 ? null : value
    }, [value])

    const color = useMemo(() => {
      const colorRange = chroma.scale(COLORS).colors(maxValues)
      return score === null || score === 0
        ? '#D0D4DF'
        : `${colorRange[Math.max(score, 1) - 1]}`
    }, [maxValues, score])

    const hasScore = score !== null && score > 0

    return (
      <p
        ref={ref}
        className="uppercase text-xs font-mono pointer-events-none rounded-full px-2 py-0.5 bg-gray-light text-gray-dark"
        style={{ backgroundColor: color, color: 'white' }}
      >
        {label && <span>{label} </span>}
        <span
          className={classNames(
            'text-gray font-semibold',
            hasScore && 'text-white/80'
          )}
        >
          {hasScore ? valueTransformer(score) : '—'}
        </span>
      </p>
    )
  }
)

RateAverageBadge.displayName = 'RateAverageBadge'

const defaultRateValueTransformer = (value: number) => {
  return value.toString()
}
