import type { IconProps } from './types'

export function PlusIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.46 6.4375H9.5225V2.5C9.5225 2.03516 9.11234 1.625 8.6475 1.625H7.7725C7.28031 1.625 6.8975 2.03516 6.8975 2.5V6.4375H2.96C2.46781 6.4375 2.085 6.84766 2.085 7.3125V8.1875C2.085 8.67969 2.46781 9.0625 2.96 9.0625H6.8975V13C6.8975 13.4922 7.28031 13.875 7.7725 13.875H8.6475C9.11234 13.875 9.5225 13.4922 9.5225 13V9.0625H13.46C13.9248 9.0625 14.335 8.67969 14.335 8.1875V7.3125C14.335 6.84766 13.9248 6.4375 13.46 6.4375Z"
        fill="currentColor"
      />
    </svg>
  )
}
