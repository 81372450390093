import { DialogClose } from '@radix-ui/react-dialog'
import { useToggle } from '@upper/hooks'
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  buttonVariants,
  cn,
} from '@upper/sapphire/ui'
import axios from 'axios'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import toast from 'react-hot-toast'
import { capitalize, words } from 'voca'
import { Select } from '../select'
import { ExpenseAssetType, UploadExpenseAssetDialogProps } from './expense-card'

export function UploadExpenseAssetDialog({
  expenseId,
  onUploaded,
  onClose,
}: UploadExpenseAssetDialogProps) {
  const isSubmitting = useToggle()
  const [{ type, file }, setExpense] = useState<{
    type: string | null
    file: File | null
  }>({ type: null, file: null })

  const { isDragActive, isDragAccept, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpg', '.jpeg'],
      },
      maxSize: 52428800, // 50MB
      maxFiles: 1,
      multiple: false,
      onDropAccepted(files, event) {
        setExpense({ type: null, file: files[0] })
      },
    })

  const isValid = file && type

  const handleSubmitForm = useCallback(async () => {
    const formData = new FormData()
    if (!file) return
    formData.append('type', type ?? '')
    formData.append('file', file, file.name)
    formData.append('expenseId', expenseId)

    isSubmitting.setTrue()
    try {
      const result = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/expense-assets`,
        formData,
        {
          withCredentials: true,
          headers: {
            'x-app': 'freelancer',
          },
        }
      )
      onUploaded?.(result.data.id)
      isSubmitting.setFalse()
    } catch (error: any) {
      toast.error(error.message)
      isSubmitting.setFalse()
    }
  }, [expenseId, file, isSubmitting, onUploaded, type])

  return (
    <Dialog
      defaultOpen
      onOpenChange={(s) => {
        if (!s) onClose?.()
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload Expense File</DialogTitle>
        </DialogHeader>
        <div className="space-y-3">
          <Select
            label="Type"
            onChange={(e) =>
              setExpense((v) => ({ ...v, type: e.target.value }))
            }
          >
            <option value={undefined}>—</option>
            {Object.values(ExpenseAssetType).map((v) => (
              <option key={v} value={v}>
                {words(capitalize(v)).join(' ')}
              </option>
            ))}
          </Select>
          <div
            {...getRootProps()}
            className={cn(
              'border-gray-light group flex h-28 cursor-pointer flex-col items-center justify-center rounded-md border border-dashed',
              isDragActive ? 'bg-blue-light/10 border-blue-light' : 'bg-white',
              isDragAccept && 'bg-blue-light/10'
            )}
          >
            <input {...getInputProps()} />
            <div className="text-blue flex items-center">
              <span className="text-sm underline group-hover:no-underline">
                {!file ? 'Drop your file here, or browse' : 'Choose other file'}
              </span>
            </div>
            {!file && (
              <small className="text-gray mt-1 text-xs">.pdf, .jpg</small>
            )}
            {file && (
              <div className="mt-3 max-w-full break-words px-3 text-xs">
                {file.name}
              </div>
            )}
          </div>
        </div>
        <DialogFooter>
          <DialogClose
            className={cn(buttonVariants({ variant: 'ghost' }), 'mr-auto')}
          >
            Cancel
          </DialogClose>
          <Button
            variant={'primary'}
            disabled={!isValid}
            onClick={handleSubmitForm}
          >
            Upload
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
