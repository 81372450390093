import { IconProps } from '.'

export function LanguageIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5219 4.31588C10.7407 4.31588 9.33443 3.22213 7.14693 3.22213C6.27193 3.22213 5.45943 3.40963 4.74068 3.69088C4.86568 2.75338 4.11568 1.94088 3.20943 2.00338C2.42818 2.00338 1.80318 2.62838 1.77193 3.40963C1.70943 4.09713 2.14693 4.69088 2.77193 4.90963V17.5971C2.77193 17.8159 2.92818 17.9721 3.14693 17.9721H3.39693C3.58443 17.9721 3.77193 17.8159 3.77193 17.5971V13.8159C4.74068 13.3471 6.02193 12.9096 7.99068 12.9096C9.77193 12.9096 11.1782 14.0034 13.3657 14.0034C14.9594 14.0034 16.2407 13.4409 17.4282 12.6596C17.6469 12.5034 17.7719 12.2846 17.7719 12.0346V3.84713C17.7719 3.28463 17.1782 2.94088 16.6782 3.15963C15.5532 3.69088 13.9907 4.31588 12.5219 4.31588ZM3.27193 2.84713C3.61568 2.84713 3.89693 3.12838 3.89693 3.47213C3.89693 3.84713 3.61568 4.09713 3.27193 4.09713C2.89693 4.09713 2.64693 3.84713 2.64693 3.47213C2.64693 3.12838 2.89693 2.84713 3.27193 2.84713ZM16.7719 11.9096C16.0219 12.4409 14.7407 12.9721 13.3657 12.9721C11.3657 12.9721 9.99068 11.9096 7.99068 11.9096C6.55318 11.9096 4.80318 12.2221 3.77193 12.7221V5.31588C4.49068 4.78463 5.77193 4.22213 7.14693 4.22213C9.14693 4.22213 10.5219 5.31588 12.5219 5.31588C13.9594 5.31588 15.7094 4.72213 16.7719 4.22213V11.9096Z"
        fill="currentColor"
      />
    </svg>
  )
}
