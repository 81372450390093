import { useEffect, useState } from 'react'

export interface UseMounted { mounted: boolean }

export function useMounted(): UseMounted {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return { mounted }
}
