import { PropsWithChildren } from 'react'
import { Context } from './context'
import { ZIndex, globalZIndexGeneratorFactory } from './z-index'

// eslint-disable-next-line @typescript-eslint/ban-types
type ZIndexProviderProps = {}

export function Provider({ children }: PropsWithChildren<ZIndexProviderProps>) {
  const uniqueIdFactory: ZIndex = new ZIndex(globalZIndexGeneratorFactory)

  return <Context.Provider value={uniqueIdFactory}>{children}</Context.Provider>
}
