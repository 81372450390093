import { IconProps } from '.'

export function SearchIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.115 17.0625L14.0837 13.0312C14.0212 12.9688 13.9275 12.9375 13.8337 12.9375H13.49C14.5837 11.75 15.24 10.2188 15.24 8.5C15.24 4.9375 12.3025 2 8.74 2C5.14625 2 2.24 4.9375 2.24 8.5C2.24 12.0938 5.14625 15 8.74 15C10.4275 15 11.99 14.3438 13.1462 13.2812V13.5938C13.1462 13.7188 13.1775 13.8125 13.24 13.875L17.2712 17.9062C17.4275 18.0625 17.6462 18.0625 17.8025 17.9062L18.115 17.5938C18.2712 17.4375 18.2712 17.2188 18.115 17.0625ZM8.74 14C5.6775 14 3.24 11.5625 3.24 8.5C3.24 5.46875 5.6775 3 8.74 3C11.7712 3 14.24 5.46875 14.24 8.5C14.24 11.5625 11.7712 14 8.74 14Z"
        fill="currentColor"
      />
    </svg>
  )
}
