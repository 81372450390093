import type { IconProps } from './types'

export function BoldIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3828 7.82812C11.7344 7.40625 11.9688 6.89062 12.0156 6.30469C12.1797 4.52344 10.7969 3 9.03906 3H4.375C4.16406 3 4 3.1875 4 3.375V4.5C4 4.71094 4.16406 4.875 4.375 4.875H5.10156V11.625H4.375C4.16406 11.625 4 11.8125 4 12V13.125C4 13.3359 4.16406 13.5 4.375 13.5H9.27344C10.9375 13.5 12.4141 12.3047 12.5781 10.6406C12.6953 9.51562 12.1797 8.48438 11.3828 7.82812ZM6.97656 4.875H9.03906C9.64844 4.875 10.1641 5.39062 10.1641 6C10.1641 6.63281 9.64844 7.125 9.03906 7.125H6.97656V4.875ZM9.03906 11.625H6.97656V9H9.03906C9.76562 9 10.3516 9.60938 10.3516 10.3125C10.3516 11.0391 9.76562 11.625 9.03906 11.625Z"
        fill="currentColor"
      />
    </svg>
  )
}
