/* eslint-disable @typescript-eslint/no-namespace */
export function CompanyIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5.34375C9 5.15625 8.8125 4.96875 8.625 4.96875H7.375C7.15625 4.96875 7 5.15625 7 5.34375V6.59375C7 6.8125 7.15625 6.96875 7.375 6.96875H8.625C8.8125 6.96875 9 6.8125 9 6.59375V5.34375ZM12.625 4.96875H11.375C11.1562 4.96875 11 5.15625 11 5.34375V6.59375C11 6.8125 11.1562 6.96875 11.375 6.96875H12.625C12.8125 6.96875 13 6.8125 13 6.59375V5.34375C13 5.15625 12.8125 4.96875 12.625 4.96875ZM8.625 7.96875H7.375C7.15625 7.96875 7 8.15625 7 8.34375V9.59375C7 9.8125 7.15625 9.96875 7.375 9.96875H8.625C8.8125 9.96875 9 9.8125 9 9.59375V8.34375C9 8.15625 8.8125 7.96875 8.625 7.96875ZM12.625 7.96875H11.375C11.1562 7.96875 11 8.15625 11 8.34375V9.59375C11 9.8125 11.1562 9.96875 11.375 9.96875H12.625C12.8125 9.96875 13 9.8125 13 9.59375V8.34375C13 8.15625 12.8125 7.96875 12.625 7.96875ZM8.625 10.9688H7.375C7.15625 10.9688 7 11.1562 7 11.3438V12.5938C7 12.8125 7.15625 12.9688 7.375 12.9688H8.625C8.8125 12.9688 9 12.8125 9 12.5938V11.3438C9 11.1562 8.8125 10.9688 8.625 10.9688ZM12.625 10.9688H11.375C11.1562 10.9688 11 11.1562 11 11.3438V12.5938C11 12.8125 11.1562 12.9688 11.375 12.9688H12.625C12.8125 12.9688 13 12.8125 13 12.5938V11.3438C13 11.1562 12.8125 10.9688 12.625 10.9688ZM17 17.375C17 17.1875 16.8125 17 16.625 17H16V2.75C16 2.34375 15.6562 2 15.25 2H4.75C4.3125 2 4 2.34375 4 2.75V17H3.375C3.15625 17 3 17.1875 3 17.375V18H17V17.375ZM15 17H11V14.3438C11 14.1562 10.8125 13.9688 10.625 13.9688H9.375C9.15625 13.9688 9 14.1562 9 14.3438V17H5V3H15V17Z"
        fill="currentColor"
      />
    </svg>
  )
}
