import { DateField, DateFieldProps, FormErrorMessage } from '@upper/ui'
import { useField } from 'formik'
import { FormikFieldProps } from './types'

type FormikDayPickerProps = FormikFieldProps &
  Omit<DateFieldProps, 'value' | 'onChange'> & { disabled?: boolean }

export function FormikDateField({
  name,
  fast,
  validate,
  disabled,
  className,
  ...restProps
}: FormikDayPickerProps) {
  const [, meta, helpers] = useField({ name })

  const { value, error, touched } = meta
  const { setValue, setTouched } = helpers

  return (
    <div className={className}>
      <DateField
        value={value}
        isInvalid={error && touched}
        onChange={(date) => {
          setValue(date)
        }}
        onClosed={() => setTouched(true)}
        disabled={disabled}
        {...restProps}
      />
      {error && touched && <FormErrorMessage>{error}</FormErrorMessage>}
    </div>
  )
}
