import * as Icons from '@upper/sapphire/icons'
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  buttonVariants,
  cn,
} from '@upper/sapphire/ui'
import { formatCurrency } from '@upper/utils'
import { format } from 'date-fns'
import { HTMLAttributes, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { AbsenceCard } from './absence-card'
import { DayRepresentation, TimeEntryCreateType } from './entries'
import { ExpenseCard } from './expense-card'
import { TimeEntryCard } from './time-entry-card'
import {
  TimeEntry,
  TimeEntryStatus,
  TimesheetAbsence,
  TimesheetDayStatus,
  TimesheetExpense,
} from './types'

type Props = {
  weekDay: DayRepresentation
  folded?: boolean
  isHighlighted?: boolean
  isFreelancer?: boolean
  absenceTypeOptions?: string[]
  expenseCategoryOptions?: string[]
  canCreateEntries?: boolean
  onHoverDateChange?: (date: Date | undefined) => void
  handleSubmit?: (day: DayRepresentation) => void
  handleApprove?: (day: DayRepresentation) => void
  handleUnapprove?: (day: DayRepresentation) => void
  onCreate?: (date: Date, type: TimeEntryCreateType) => void
  onDelete?: (id: string, type: TimeEntryCreateType) => void
  onTimeEntryChange?: (id: string, timeEntry: TimeEntry) => void
  onAbsenceChange?: (id: string, absence: TimesheetAbsence) => void
  onExpenseChange?: (id: string, expense: TimesheetExpense) => void
  onExpenseAssetUpload?: (id?: string | null) => void
  onExpenseAssetDelete?: (id?: string | null, assetId?: string | null) => void
  onExpand?: () => void
}
export const EntriesWeekDay = ({
  weekDay,
  folded,
  isHighlighted,
  isFreelancer,
  absenceTypeOptions,
  expenseCategoryOptions,
  canCreateEntries = false,
  onHoverDateChange,
  handleSubmit,
  handleApprove,
  handleUnapprove,
  onCreate,
  onDelete,
  onTimeEntryChange,
  onAbsenceChange,
  onExpenseChange,
  onExpenseAssetUpload,
  onExpenseAssetDelete,
  onExpand,
}: Props) => {
  const totalTimeEntries = weekDay?.day?.timeEntries?.length ?? 0
  const totalabsences = weekDay?.day?.absences?.length ?? 0
  const totalexpenses = weekDay?.day?.expenses?.length ?? 0

  const hasUnsubmittedEntries =
    !weekDay?.day ||
    weekDay?.day?.timeEntries?.some(
      (te) => te.status === TimeEntryStatus.Pending
    ) ||
    weekDay?.day?.absences?.some(
      (ae) => ae.status === TimeEntryStatus.Pending
    ) ||
    weekDay?.day?.expenses?.some((ex) => ex.status === TimeEntryStatus.Pending)
  const hasEmptyEntries =
    weekDay?.day?.timeEntries?.some((e) => !e.notes) ||
    weekDay?.day?.expenses?.some((e) => !e.notes)

  const isSubmitted = weekDay?.day?.status === TimesheetDayStatus.Submitted
  const isApproved = weekDay?.day?.status === TimesheetDayStatus.Approved

  const hasNoEntries =
    !weekDay?.day ||
    (weekDay?.day?.timeEntries?.length === 0 &&
      weekDay?.day?.expenses?.length === 0 &&
      weekDay?.day?.absences?.length === 0)

  const handleMouseClick = useCallback(() => {
    if (folded) onExpand?.()
  }, [folded, onExpand])

  return (
    <div
      className={cn(
        'group relative snap-center px-2 transition-colors',
        'min-w-[200px] 2xl:min-w-fit',
        isHighlighted && 'bg-gray-lightest',
        hasNoEntries ? 'pb-2' : 'pb-8'
      )}
      key={weekDay.date.getTime()}
      onMouseOver={() => onHoverDateChange?.(weekDay.date)}
      onMouseOut={() => onHoverDateChange?.(undefined)}
      onClick={handleMouseClick}
    >
      <header className="flex items-center gap-2 py-2 pl-2 pr-1">
        <span
          className={cn(
            'text-gray w-full font-semibold',
            weekDay.hasActiveRevision && 'text-blue'
          )}
        >
          {format(weekDay?.date, 'dd MMM')}
        </span>
        {!weekDay.hasActiveRevision && (
          <Tooltip>
            <TooltipTrigger>
              <span className="text-gray">
                <Icons.AlertCircle />
              </span>
            </TooltipTrigger>
            <TooltipContent className="w-300">
              You do not have an active engagement for this day.
            </TooltipContent>
          </Tooltip>
        )}
        <span className="flex-none">
          <DayAction
            canSubmit={
              weekDay.hasActiveRevision &&
              (hasUnsubmittedEntries ||
                weekDay.day?.status === TimesheetDayStatus.Pending)
            }
            isSubmitted={isSubmitted}
            isApproved={isApproved}
            hasEmptyEntries={hasEmptyEntries}
            onSubmit={() => handleSubmit?.(weekDay)}
            onApprove={() => handleApprove?.(weekDay)}
            onMoveBackToPending={() => handleUnapprove?.(weekDay)}
            isFreelancer={isFreelancer}
          />
        </span>
      </header>
      {/* time entries */}
      {!folded && !hasNoEntries && (
        <div className="space-y-2 pb-2">
          {weekDay?.day?.timeEntries?.map((entry) => (
            <TimeEntryCard
              key={entry.id}
              data={entry}
              onDelete={() => onDelete?.(entry.id ?? '', 'TimeLog')}
              onUpdate={(id, data) => onTimeEntryChange?.(id, data)}
              disabled={
                isFreelancer &&
                weekDay.day?.status !== TimesheetDayStatus.Pending
              }
            />
          ))}
          {weekDay?.day?.absences?.map((entry) => (
            <AbsenceCard
              key={entry.id}
              data={entry}
              onDelete={() => onDelete?.(entry.id ?? '', 'Absence')}
              onUpdate={(id, data) => onAbsenceChange?.(id, data)}
              typeOptions={absenceTypeOptions}
              disabled={
                isFreelancer &&
                weekDay.day?.status !== TimesheetDayStatus.Pending
              }
            />
          ))}
          {weekDay?.day?.expenses?.map((entry) => (
            <ExpenseCard
              key={entry.id}
              data={entry}
              onDelete={() => onDelete?.(entry.id ?? '', 'Expense')}
              onUpdate={(id, data) => onExpenseChange?.(id, data)}
              categoryOptions={expenseCategoryOptions}
              disabled={
                isFreelancer &&
                weekDay.day?.status !== TimesheetDayStatus.Pending
              }
              onAssetUploaded={(id) => {
                onExpenseAssetUpload?.(id)
              }}
              onAssetDelete={onExpenseAssetDelete}
            />
          ))}
        </div>
      )}
      {hasNoEntries && (
        <div className="text-gray px-2 pb-2 text-xs">{'No entries'}</div>
      )}
      {folded && !hasNoEntries && (
        <div className="px-1 py-0">
          <p className="text-blue px-2 text-xs">
            <span>
              {[
                totalTimeEntries > 0
                  ? `${totalTimeEntries} time ${
                      totalTimeEntries === 1 ? 'log' : 'logs'
                    }`
                  : null,
                totalabsences > 0
                  ? `${totalabsences} ${
                      totalabsences === 1 ? 'absence' : 'absences'
                    }`
                  : null,
                totalexpenses > 0
                  ? `${totalexpenses} ${
                      totalexpenses === 1 ? 'expense' : 'expenses'
                    }`
                  : null,
              ]
                .filter(Boolean)
                .join(', ')}
            </span>
            {totalTimeEntries > 0 && <span> / </span>}
            {totalTimeEntries > 0 && (
              <span className="text-gray-dark">
                {weekDay?.day?.timeEntries
                  ?.reduce((acc, te) => (acc += te.hours ?? 0), 0)
                  .toFixed(2)}
                h
              </span>
            )}
          </p>
        </div>
      )}
      {!folded &&
        (isFreelancer
          ? weekDay.hasActiveRevision && canCreateEntries
          : true) && (
          <div className="invisible relative flex gap-1 rounded-lg bg-white/30 group-hover:visible">
            <Tooltip>
              <TooltipTrigger asChild>
                <button
                  className="flex w-full items-center justify-center rounded-lg p-1 text-center hover:bg-white"
                  onClick={() => onCreate?.(weekDay.date, 'TimeLog')}
                >
                  <Icons.Plus />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                <span>Add time log</span>
              </TooltipContent>
            </Tooltip>
            <DayEntryAction
              onSelect={(type) => onCreate?.(weekDay.date, type)}
            />
          </div>
        )}
      {!hasNoEntries &&
        ((weekDay.day?.totalHours ?? 0) > 0 ||
          (weekDay.day?.totalExpenses ?? 0) > 0) && (
          <footer
            role="none"
            className="border-t-gray/20 absolute bottom-0 left-2 right-2 border-t px-2 py-1 font-mono text-xs text-black/40"
          >
            {weekDay.day?.totalHours
              ? `${weekDay.day?.totalHours.toFixed(2)}h`
              : ''}
            {(weekDay.day?.totalHours ?? 0) > 0 &&
              (weekDay.day?.totalExpenses ?? 0) > 0 &&
              ` / `}
            {(weekDay.day?.totalExpenses ?? 0) > 0
              ? `${formatCurrency(weekDay.day?.totalExpenses)}`
              : ''}
          </footer>
        )}
    </div>
  )
}

type DayActionProps = {
  hasEmptyEntries?: boolean
  canSubmit?: boolean
  isSubmitted?: boolean
  isApproved?: boolean
  isFreelancer?: boolean
  onSubmit?: () => void
  onApprove?: () => void
  onMoveBackToPending?: () => void
}
const DayAction = ({
  hasEmptyEntries = false,
  canSubmit,
  isSubmitted,
  isApproved,
  isFreelancer,
  onSubmit,
  onApprove,
  onMoveBackToPending,
}: DayActionProps) => {
  const handleSubmittedStatusClick = useCallback(() => {
    onApprove?.()
  }, [onApprove])

  const handleMoveBackToPending = useCallback(() => {
    onMoveBackToPending?.()
  }, [onMoveBackToPending])

  return (
    <>
      {canSubmit && (
        <div className="flex min-w-0 gap-1 transition-all group-hover:min-w-full">
          <Tooltip>
            <TooltipContent className="text-center">
              <span>Submit day</span>
            </TooltipContent>
            <TooltipTrigger asChild>
              <button
                className={cn(
                  buttonVariants({ size: 'xs', variant: 'ghost' }),
                  'h-6 p-1 hover:bg-white'
                )}
                onClick={
                  hasEmptyEntries
                    ? () => {
                        toast.error(
                          'The entries couldn’t be submitted, please check that all the information is correct and try again.',
                          { duration: 3600 }
                        )
                      }
                    : onSubmit
                }
              >
                <Icons.ArrowUpToLine />
              </button>
            </TooltipTrigger>
          </Tooltip>
          <Tooltip>
            <TooltipContent>Pending</TooltipContent>
            <TooltipTrigger
              className={cn(
                'cursor-default rounded-full bg-white p-1 align-top'
              )}
            >
              <Icons.Asterisk />
            </TooltipTrigger>
          </Tooltip>
        </div>
      )}
      {!canSubmit && isSubmitted && (
        <div className="flex min-w-0 gap-1 transition-all group-hover:min-w-full">
          {!isFreelancer && (
            <Tooltip>
              <TooltipContent>Approve</TooltipContent>
              <TooltipTrigger
                className={cn(
                  buttonVariants({ size: 'xs', variant: 'ghost' }),
                  'h-6 p-1 hover:bg-white',
                  'hidden group-hover:block'
                )}
                onClick={handleSubmittedStatusClick}
              >
                <Icons.Check className="text-[#00CA75]" />
              </TooltipTrigger>
            </Tooltip>
          )}
          {isFreelancer && (
            <Tooltip>
              <TooltipContent>Reopen</TooltipContent>
              <TooltipTrigger
                className={cn(
                  buttonVariants({ size: 'xs', variant: 'ghost' }),
                  'h-6 p-1 hover:bg-white',
                  'hidden group-hover:block'
                )}
                onClick={handleMoveBackToPending}
              >
                <Icons.RotateCCW />
              </TooltipTrigger>
            </Tooltip>
          )}
          <Tooltip>
            <TooltipContent>Submitted</TooltipContent>
            <TooltipTrigger
              className={cn(
                'cursor-default rounded-full bg-white p-1 align-top'
              )}
            >
              <Icons.Clock className="text-[#FFC267]" />
            </TooltipTrigger>
          </Tooltip>
        </div>
      )}
      {!canSubmit && !isSubmitted && isApproved && (
        <div className="flex min-w-0 gap-1 transition-all group-hover:min-w-full">
          {!isFreelancer && (
            <Tooltip>
              <TooltipContent>Reopen</TooltipContent>
              <TooltipTrigger
                className={cn(
                  buttonVariants({ size: 'xs', variant: 'ghost' }),
                  'h-6 p-1 hover:bg-white',
                  'hidden group-hover:block'
                )}
                onClick={handleMoveBackToPending}
              >
                <Icons.RotateCCW />
              </TooltipTrigger>
            </Tooltip>
          )}
          {
            <Tooltip>
              <TooltipContent>Approved</TooltipContent>
              <TooltipTrigger className="cursor-default rounded-full bg-white p-1 align-top">
                <Icons.CheckCircle className="text-[#00CA75]" />
              </TooltipTrigger>
            </Tooltip>
          }
        </div>
      )}
    </>
  )
}

type DayEntryActionProps = {
  className?: string
  onSelect?: (type: TimeEntryCreateType) => void
}
const DayEntryAction = ({ className, onSelect }: DayEntryActionProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const handleClose = () => setPopoverOpen(false)

  const handleClick = useCallback(
    (type: TimeEntryCreateType) => {
      handleClose()
      onSelect?.(type)
    },
    [onSelect]
  )

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverContent className="text-gray-dark w-full min-w-0 space-y-1 p-1">
        {/* <DayEntryActionButton onClick={() => handleClick?.('TimeLog')}>
          Add Time Log
        </DayEntryActionButton> */}
        <DayEntryActionButton onClick={() => handleClick?.('Absence')}>
          Add Absence
        </DayEntryActionButton>
        <DayEntryActionButton onClick={() => handleClick?.('Expense')}>
          Add Expense
        </DayEntryActionButton>
      </PopoverContent>
      <PopoverTrigger asChild>
        <Button
          variant={'ghost'}
          className={cn(
            'grid h-auto place-content-center rounded-lg bg-transparent !p-1 !px-1 transition-colors hover:bg-white',
            'absolute right-0',
            className,
            popoverOpen && '!visible !bg-white'
          )}
        >
          <Icons.ChevronDown />
        </Button>
      </PopoverTrigger>
    </Popover>
  )
}

type DayEntryActionButtonProps = HTMLAttributes<HTMLButtonElement>
const DayEntryActionButton = ({
  children,
  className,
  ...props
}: DayEntryActionButtonProps) => (
  <button
    {...props}
    className={cn(
      buttonVariants({ variant: 'secondary' }),
      'h-auto border-none !px-3 !py-1 !text-sm !shadow-none',
      'hover:bg-gray-lightest text-gray-dark grid w-full place-content-center rounded-lg bg-white/80 transition-colors'
    )}
  >
    {children}
  </button>
)
