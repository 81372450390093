import type { IconProps } from './types'

export function UserIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 7.75C9.91406 7.75 11.5 6.19141 11.5 4.25C11.5 2.33594 9.91406 0.75 8 0.75C6.05859 0.75 4.5 2.33594 4.5 4.25C4.5 6.19141 6.05859 7.75 8 7.75ZM10.4336 8.625H9.96875C9.36719 8.92578 8.71094 9.0625 8 9.0625C7.28906 9.0625 6.60547 8.92578 6.00391 8.625H5.53906C3.51562 8.625 1.875 10.293 1.875 12.3164V13.4375C1.875 14.1758 2.44922 14.75 3.1875 14.75H12.8125C13.5234 14.75 14.125 14.1758 14.125 13.4375V12.3164C14.125 10.293 12.457 8.625 10.4336 8.625Z"
        fill="currentColor"
      />
      <path
        d="M1.875 14.3672H14.125V15.0508H1.875V14.3672Z"
        fill="currentColor"
      />
    </svg>
  )
}
