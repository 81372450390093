import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: any;
};

export enum AbilityAction {
  Create = 'Create',
  Delete = 'Delete',
  Manage = 'Manage',
  Read = 'Read',
  ReadOne = 'ReadOne',
  Update = 'Update',
  UpdateStatus = 'UpdateStatus'
}

export type Agency = {
  __typename?: 'Agency';
  acquisitionDate?: Maybe<Scalars['DateTime']>;
  agencyOwner?: Maybe<User>;
  billingAddress?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingCountry?: Maybe<Country>;
  billingCountryId?: Maybe<Scalars['String']>;
  billingCountryText?: Maybe<Scalars['String']>;
  billingStreet?: Maybe<Scalars['String']>;
  billingZip?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hadFreelancers?: Maybe<Scalars['Boolean']>;
  hasFreelancers?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceRecipients?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  referrals?: Maybe<Scalars['String']>;
  representativeEmail?: Maybe<Scalars['String']>;
  representativeFirstName?: Maybe<Scalars['String']>;
  representativeLastName?: Maybe<Scalars['String']>;
  shortname?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  taxMultiplier?: Maybe<Scalars['Float']>;
  timesheetRecipients?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export type AgencyFilters = {
  name?: InputMaybe<Scalars['String']>;
};

export type AgencyInput = {
  acquisitionDate?: InputMaybe<Scalars['DateTime']>;
  agencyOwner?: InputMaybe<UserInput>;
  billingAddress?: InputMaybe<Scalars['String']>;
  billingCity?: InputMaybe<Scalars['String']>;
  billingCountry?: InputMaybe<CountryInput>;
  billingCountryId?: InputMaybe<Scalars['String']>;
  billingCountryText?: InputMaybe<Scalars['String']>;
  billingStreet?: InputMaybe<Scalars['String']>;
  billingZip?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hadFreelancers?: InputMaybe<Scalars['Boolean']>;
  hasFreelancers?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  invoiceRecipients?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownerId?: InputMaybe<Scalars['String']>;
  referrals?: InputMaybe<Scalars['String']>;
  representativeEmail?: InputMaybe<Scalars['String']>;
  representativeFirstName?: InputMaybe<Scalars['String']>;
  representativeLastName?: InputMaybe<Scalars['String']>;
  shortname?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  taxMultiplier?: InputMaybe<Scalars['Float']>;
  timesheetRecipients?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export enum App {
  Admin = 'Admin',
  Client = 'Client',
  Fellow = 'Fellow',
  Freelancer = 'Freelancer',
  Internal = 'Internal',
  PandaDoc = 'PandaDoc',
  Public = 'Public',
  Scout = 'Scout',
  SourcerChromeExtension = 'SourcerChromeExtension',
  Studio = 'Studio',
  TechInterviewer = 'TechInterviewer'
}

export enum AssessmentConclusion {
  Fail = 'Fail',
  Inconclusive = 'Inconclusive',
  Pass = 'Pass'
}

export type AssessmentSection = {
  __typename?: 'AssessmentSection';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<Dimension>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<AssessmentSectionType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssessmentSectionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Array<DimensionInput>>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssessmentSectionType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum AssessmentSectionType {
  Dimensions = 'Dimensions',
  Languages = 'Languages',
  Skills = 'Skills'
}

export enum AssessmentStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Draft = 'Draft',
  InReview = 'InReview',
  Pending = 'Pending'
}

export type AssessmentTemplate = {
  __typename?: 'AssessmentTemplate';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<Dimension>>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Language>>;
  skills?: Maybe<Array<Skill>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<AssessmentType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssessmentTemplateFilters = {
  types?: InputMaybe<Array<AssessmentType>>;
};

export type AssessmentTemplateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Array<DimensionInput>>;
  id?: InputMaybe<Scalars['String']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<LanguageInput>>;
  skills?: InputMaybe<Array<SkillInput>>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssessmentType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum AssessmentType {
  Challenge = 'Challenge',
  ClientEngagementFeedback = 'ClientEngagementFeedback',
  Custom = 'Custom',
  Matching = 'Matching',
  Screening = 'Screening',
  TechInterview = 'TechInterview'
}

export type Asset = {
  __typename?: 'Asset';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationUrl?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssetInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  locationUrl?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AttachInvoiceOutgoingItems = {
  from: Scalars['DateTime'];
  incomingInvoiceId: Scalars['String'];
  to: Scalars['DateTime'];
};

export type AttachInvoiceOutgoingItemsResponse = {
  __typename?: 'AttachInvoiceOutgoingItemsResponse';
  count: Scalars['Float'];
};

export type AuditEntry = {
  __typename?: 'AuditEntry';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inputData?: Maybe<AuditEntryInputData>;
  ip?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originalData?: Maybe<Scalars['JSON']>;
  outputData?: Maybe<Scalars['JSON']>;
  source?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetType?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type AuditEntryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inputData?: InputMaybe<AuditEntryInputDataInput>;
  ip?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  originalData?: InputMaybe<Scalars['JSON']>;
  outputData?: InputMaybe<Scalars['JSON']>;
  source?: InputMaybe<Scalars['String']>;
  targetId?: InputMaybe<Scalars['String']>;
  targetType?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type AuditEntryInputData = {
  __typename?: 'AuditEntryInputData';
  data?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['String']>;
};

export type AuditEntryInputDataInput = {
  data?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CalendlyEvent = {
  __typename?: 'CalendlyEvent';
  createdAt: Scalars['String'];
  endTime: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  startTime: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type Challenge = {
  __typename?: 'Challenge';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Skill>>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type ChallengeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Array<SkillInput>>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ChallengesFilters = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type ClientSignup = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  acquisitionDate?: Maybe<Scalars['DateTime']>;
  airtableId?: Maybe<Scalars['String']>;
  billingAdditionalInfo?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingCountry?: Maybe<Country>;
  billingCountryId?: Maybe<Scalars['String']>;
  billingCountryText?: Maybe<Scalars['String']>;
  billingStreet?: Maybe<Scalars['String']>;
  billingZip?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Contact>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  csm?: Maybe<User>;
  csmId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  em?: Maybe<User>;
  emId?: Maybe<Scalars['String']>;
  hadFreelancers?: Maybe<Scalars['Boolean']>;
  hasFreelancers?: Maybe<Scalars['Boolean']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  invoiceRecipientName?: Maybe<Scalars['String']>;
  invoiceRecipients?: Maybe<Array<Contact>>;
  isActive?: Maybe<Scalars['Boolean']>;
  isClean?: Maybe<Scalars['Boolean']>;
  isSignedMsa?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  jobs?: Maybe<Array<Job>>;
  lastCleanedAt?: Maybe<Scalars['DateTime']>;
  lastCleanedBy?: Maybe<User>;
  lastCleanedById?: Maybe<Scalars['String']>;
  legalNotes?: Maybe<Scalars['String']>;
  logoFileLocation?: Maybe<Scalars['String']>;
  logoFileMimeType?: Maybe<Scalars['String']>;
  logoFileName?: Maybe<Scalars['String']>;
  logoFileSize?: Maybe<Scalars['Float']>;
  logoUrl?: Maybe<Scalars['String']>;
  msaLink?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  procurementNotes?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Project>>;
  referralFellow?: Maybe<User>;
  referralFellowId?: Maybe<Scalars['String']>;
  referrals?: Maybe<Scalars['String']>;
  referredAt?: Maybe<Scalars['DateTime']>;
  reportingId?: Maybe<Scalars['String']>;
  representative?: Maybe<Contact>;
  representativeEmail?: Maybe<Scalars['String']>;
  representativeFirstName?: Maybe<Scalars['String']>;
  representativeId?: Maybe<Scalars['String']>;
  representativeLastName?: Maybe<Scalars['String']>;
  shortname?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  specialInvoicingNotes?: Maybe<Scalars['String']>;
  taxMultiplier?: Maybe<Scalars['Float']>;
  teamtailorId?: Maybe<Scalars['String']>;
  timesheetRecipients?: Maybe<Array<Contact>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export type CompanyCreateHarvestClientOutput = {
  __typename?: 'CompanyCreateHarvestClientOutput';
  company?: Maybe<Company>;
  harvestId?: Maybe<Scalars['String']>;
};

export type CompanyCreateHubspotInput = {
  shouldCreate?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyFilters = {
  hasActiveEngagements?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CompanyHubspotSearchResult = {
  __typename?: 'CompanyHubspotSearchResult';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CompanyHubspotSearchResultDtoHubspotForwardPaging = {
  __typename?: 'CompanyHubspotSearchResultDtoHubspotForwardPaging';
  next?: Maybe<CompanyHubspotSearchResultDtoHubspotPaging>;
};

export type CompanyHubspotSearchResultDtoHubspotPaging = {
  __typename?: 'CompanyHubspotSearchResultDtoHubspotPaging';
  after?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type CompanyHubspotSearchResults = {
  __typename?: 'CompanyHubspotSearchResults';
  paging?: Maybe<CompanyHubspotSearchResultDtoHubspotForwardPaging>;
  results?: Maybe<Array<CompanyHubspotSearchResult>>;
  total?: Maybe<Scalars['Float']>;
};

export type CompanyInput = {
  acquisitionDate?: InputMaybe<Scalars['DateTime']>;
  airtableId?: InputMaybe<Scalars['String']>;
  billingAdditionalInfo?: InputMaybe<Scalars['String']>;
  billingAddress?: InputMaybe<Scalars['String']>;
  billingCity?: InputMaybe<Scalars['String']>;
  billingCountry?: InputMaybe<CountryInput>;
  billingCountryId?: InputMaybe<Scalars['String']>;
  billingCountryText?: InputMaybe<Scalars['String']>;
  billingStreet?: InputMaybe<Scalars['String']>;
  billingZip?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<ContactInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  csm?: InputMaybe<UserInput>;
  csmId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  em?: InputMaybe<UserInput>;
  emId?: InputMaybe<Scalars['String']>;
  hadFreelancers?: InputMaybe<Scalars['Boolean']>;
  hasFreelancers?: InputMaybe<Scalars['Boolean']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  invoiceRecipientName?: InputMaybe<Scalars['String']>;
  invoiceRecipients?: InputMaybe<Array<ContactInput>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isClean?: InputMaybe<Scalars['Boolean']>;
  isSignedMsa?: InputMaybe<Scalars['Boolean']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  jobs?: InputMaybe<Array<JobInput>>;
  lastCleanedAt?: InputMaybe<Scalars['DateTime']>;
  lastCleanedBy?: InputMaybe<UserInput>;
  lastCleanedById?: InputMaybe<Scalars['String']>;
  legalNotes?: InputMaybe<Scalars['String']>;
  logoFileLocation?: InputMaybe<Scalars['String']>;
  logoFileMimeType?: InputMaybe<Scalars['String']>;
  logoFileName?: InputMaybe<Scalars['String']>;
  logoFileSize?: InputMaybe<Scalars['Float']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  msaLink?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  procurementNotes?: InputMaybe<Scalars['String']>;
  projects?: InputMaybe<Array<ProjectInput>>;
  referralFellow?: InputMaybe<UserInput>;
  referralFellowId?: InputMaybe<Scalars['String']>;
  referrals?: InputMaybe<Scalars['String']>;
  referredAt?: InputMaybe<Scalars['DateTime']>;
  reportingId?: InputMaybe<Scalars['String']>;
  representative?: InputMaybe<ContactInput>;
  representativeEmail?: InputMaybe<Scalars['String']>;
  representativeFirstName?: InputMaybe<Scalars['String']>;
  representativeId?: InputMaybe<Scalars['String']>;
  representativeLastName?: InputMaybe<Scalars['String']>;
  shortname?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  specialInvoicingNotes?: InputMaybe<Scalars['String']>;
  taxMultiplier?: InputMaybe<Scalars['Float']>;
  teamtailorId?: InputMaybe<Scalars['String']>;
  timesheetRecipients?: InputMaybe<Array<ContactInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type CompanySummary = {
  __typename?: 'CompanySummary';
  createdAt?: Maybe<Scalars['DateTime']>;
  hiredTeamsCount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  jobsInStaffingCount?: Maybe<Scalars['Float']>;
  openInvoicesTotalCurrentMonth?: Maybe<Scalars['Float']>;
  projectsCount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyTimesheet = {
  __typename?: 'CompanyTimesheet';
  clientTotal?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  id?: Maybe<Scalars['String']>;
  talentTotal?: Maybe<Scalars['Float']>;
  totalHours?: Maybe<Scalars['Float']>;
};

export enum ConditionalOperator {
  And = 'And',
  Or = 'Or'
}

export type Contact = {
  __typename?: 'Contact';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  createdVia?: Maybe<App>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zip?: Maybe<Scalars['String']>;
};

export type ContactFilters = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
};

export type ContactInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  createdVia?: InputMaybe<App>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  code?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CountryFilters = {
  code?: InputMaybe<Scalars['String']>;
  continent?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type CountryInput = {
  code?: InputMaybe<Scalars['String']>;
  continent?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreatePasswordResetTokenResponse = {
  __typename?: 'CreatePasswordResetTokenResponse';
  token: Scalars['String'];
  user: User;
};

export type DateBetweenFilterInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type DeleteManyInput = {
  ids: Array<Scalars['String']>;
};

export type Dimension = {
  __typename?: 'Dimension';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  section?: Maybe<AssessmentSection>;
  sectionId?: Maybe<Scalars['String']>;
  templates?: Maybe<AssessmentTemplate>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DimensionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  section?: InputMaybe<AssessmentSectionInput>;
  sectionId?: InputMaybe<Scalars['String']>;
  templates?: InputMaybe<AssessmentTemplateInput>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum DimensionSeniority {
  Competent = 'Competent',
  Expert = 'Expert',
  Novice = 'Novice',
  Proficient = 'Proficient'
}

export type Document = {
  __typename?: 'Document';
  completedOn?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  createdById?: Maybe<Scalars['String']>;
  engagementDocuments?: Maybe<EngagementDocument>;
  fileLocation?: Maybe<Scalars['String']>;
  fileMimeType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  locationUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pandaDocDocumentId?: Maybe<Scalars['String']>;
  pandaDocFolderId?: Maybe<Scalars['String']>;
  pandaDocMessage?: Maybe<Scalars['String']>;
  pandaDocSubject?: Maybe<Scalars['String']>;
  pandaDocTemplateId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Float']>;
};

export type DocumentInput = {
  completedOn?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: UserInput;
  createdById?: InputMaybe<Scalars['String']>;
  engagementDocuments?: InputMaybe<EngagementDocumentInput>;
  fileLocation?: InputMaybe<Scalars['String']>;
  fileMimeType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  locationUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pandaDocDocumentId?: InputMaybe<Scalars['String']>;
  pandaDocFolderId?: InputMaybe<Scalars['String']>;
  pandaDocMessage?: InputMaybe<Scalars['String']>;
  pandaDocSubject?: InputMaybe<Scalars['String']>;
  pandaDocTemplateId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  version?: InputMaybe<Scalars['Float']>;
};

export enum EmailNotificationTrigger {
  Action = 'Action',
  Create = 'Create',
  StatusChange = 'StatusChange',
  Time = 'Time',
  Update = 'Update'
}

export enum EmailNotificationTriggerEntity {
  Document = 'Document',
  Engagement = 'Engagement',
  Invoice = 'Invoice',
  Job = 'Job',
  Meeting = 'Meeting',
  Referral = 'Referral',
  Talent = 'Talent',
  TalentAssessment = 'TalentAssessment'
}

export type Engagement = {
  __typename?: 'Engagement';
  availability?: Maybe<Scalars['Float']>;
  availabilityInfo?: Maybe<Scalars['String']>;
  availabilityNotes?: Maybe<Scalars['String']>;
  baseCode?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  dealCode?: Maybe<Scalars['String']>;
  dealSource?: Maybe<EngagementDealSource>;
  dealType?: Maybe<EngagementDealType>;
  documents?: Maybe<Array<Document>>;
  extension?: Maybe<Scalars['String']>;
  fellowSpecialFeeNotes?: Maybe<Scalars['String']>;
  filteredTimeEntries?: Maybe<Array<TimeEntry>>;
  hiredDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  intro?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lastCreatedRevision?: Maybe<EngagementRevision>;
  latestRevision?: Maybe<EngagementRevision>;
  matchingCallDate?: Maybe<Scalars['DateTime']>;
  meetings?: Maybe<Array<Meeting>>;
  message?: Maybe<Scalars['String']>;
  messageBy?: Maybe<Scalars['String']>;
  messagePosition?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Note>>;
  placedBy?: Maybe<User>;
  placedById?: Maybe<Scalars['String']>;
  /** @deprecated use talentPublicEngagementProfile */
  profile?: Maybe<TalentProfile>;
  profileId?: Maybe<Scalars['String']>;
  projectAgreementLink?: Maybe<Scalars['String']>;
  receiver?: Maybe<Contact>;
  receiverEmail?: Maybe<Scalars['String']>;
  receiverFirstName?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['String']>;
  receiverLastName?: Maybe<Scalars['String']>;
  receiverRole?: Maybe<Scalars['String']>;
  revisionIds?: Maybe<Array<Scalars['String']>>;
  revisions?: Maybe<Array<EngagementRevision>>;
  sowLink?: Maybe<Scalars['String']>;
  staffingStartedAt?: Maybe<Scalars['DateTime']>;
  statusChangedDate?: Maybe<Scalars['DateTime']>;
  talent?: Maybe<Talent>;
  talentAssessments?: Maybe<Array<TalentAssessment>>;
  talentEmail?: Maybe<Scalars['String']>;
  talentId?: Maybe<Scalars['String']>;
  talentSource?: Maybe<EngagementTalentSource>;
  talentSpecialInvoicingNotes?: Maybe<Scalars['String']>;
  timeEntries?: Maybe<Array<TimeEntry>>;
  tsm?: Maybe<User>;
  type?: Maybe<EngagementType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatRecommendsYou?: Maybe<Scalars['String']>;
};

export type EngagementAttachHarvestProjectOutput = {
  __typename?: 'EngagementAttachHarvestProjectOutput';
  engagement?: Maybe<Engagement>;
  harvestId?: Maybe<Scalars['String']>;
};

export type EngagementCreateHarvestProjectOutput = {
  __typename?: 'EngagementCreateHarvestProjectOutput';
  engagement?: Maybe<Engagement>;
  harvestId?: Maybe<Scalars['String']>;
};

export enum EngagementDealSource {
  DirectSales = 'DirectSales',
  Fellows = 'Fellows',
  Inbound = 'Inbound',
  Partnerships = 'Partnerships',
  Referral = 'Referral'
}

export enum EngagementDealType {
  CrossSell = 'CrossSell',
  Extension = 'Extension',
  New = 'New',
  UpSell = 'UpSell'
}

export type EngagementDocument = {
  __typename?: 'EngagementDocument';
  createdAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['String']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<EngagementDocumentType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EngagementDocumentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  document?: InputMaybe<DocumentInput>;
  documentId?: InputMaybe<Scalars['String']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EngagementDocumentType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum EngagementDocumentType {
  ClientConfirmation = 'ClientConfirmation',
  ExtensionConfirmation = 'ExtensionConfirmation',
  Other = 'Other',
  ProjectAgreement = 'ProjectAgreement',
  ReductionConfirmation = 'ReductionConfirmation',
  StatementOfWork = 'StatementOfWork',
  TalentConfirmation = 'TalentConfirmation'
}

export type EngagementFilters = {
  actionNextSteps?: InputMaybe<EngagementRevisionActionNextSteps>;
  actionStatus?: InputMaybe<EngagementRevisionActionStatus>;
  airtableId?: InputMaybe<Scalars['String']>;
  baseCode?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endingBetween?: InputMaybe<DateBetweenFilterInput>;
  hasProjectAgreement?: InputMaybe<Scalars['Boolean']>;
  hasSignedFrameworkAgreement?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  job?: InputMaybe<EngagementFiltersJob>;
  jobIds?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  placedByIds?: InputMaybe<Array<Scalars['String']>>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  statusChangedDateEnd?: InputMaybe<Scalars['DateTime']>;
  statusChangedDateStart?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<EngagementStatus>>;
  talentId?: InputMaybe<Scalars['String']>;
};

export type EngagementFiltersJob = {
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  emIds?: InputMaybe<Array<Scalars['String']>>;
  expertIds?: InputMaybe<Array<Scalars['String']>>;
  fellowIds?: InputMaybe<Array<Scalars['String']>>;
  hideOldCancelledJobs?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<JobStatus>>;
};

export type EngagementInput = {
  availability?: InputMaybe<Scalars['Float']>;
  availabilityInfo?: InputMaybe<Scalars['String']>;
  availabilityNotes?: InputMaybe<Scalars['String']>;
  baseCode?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  dealCode?: InputMaybe<Scalars['String']>;
  dealSource?: InputMaybe<EngagementDealSource>;
  dealType?: InputMaybe<EngagementDealType>;
  documents?: InputMaybe<Array<DocumentInput>>;
  extension?: InputMaybe<Scalars['String']>;
  fellowSpecialFeeNotes?: InputMaybe<Scalars['String']>;
  filteredTimeEntries?: InputMaybe<Array<TimeEntryInput>>;
  hiredDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  intro?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobInput>;
  jobId?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  lastCreatedRevision?: InputMaybe<EngagementRevisionInput>;
  latestRevision?: InputMaybe<EngagementRevisionInput>;
  matchingCallDate?: InputMaybe<Scalars['DateTime']>;
  meetings?: InputMaybe<Array<MeetingInput>>;
  message?: InputMaybe<Scalars['String']>;
  messageBy?: InputMaybe<Scalars['String']>;
  messagePosition?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Array<NoteInput>>;
  placedBy?: InputMaybe<UserInput>;
  placedById?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<TalentProfileInput>;
  profileId?: InputMaybe<Scalars['String']>;
  projectAgreementLink?: InputMaybe<Scalars['String']>;
  receiver?: InputMaybe<ContactInput>;
  receiverEmail?: InputMaybe<Scalars['String']>;
  receiverFirstName?: InputMaybe<Scalars['String']>;
  receiverId?: InputMaybe<Scalars['String']>;
  receiverLastName?: InputMaybe<Scalars['String']>;
  receiverRole?: InputMaybe<Scalars['String']>;
  revisionIds?: InputMaybe<Array<Scalars['String']>>;
  revisions?: InputMaybe<Array<EngagementRevisionInput>>;
  sowLink?: InputMaybe<Scalars['String']>;
  staffingStartedAt?: InputMaybe<Scalars['DateTime']>;
  statusChangedDate?: InputMaybe<Scalars['DateTime']>;
  talent?: InputMaybe<TalentInput>;
  talentAssessments?: InputMaybe<Array<TalentAssessmentInput>>;
  talentEmail?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
  talentSource?: InputMaybe<EngagementTalentSource>;
  talentSpecialInvoicingNotes?: InputMaybe<Scalars['String']>;
  timeEntries?: InputMaybe<Array<TimeEntryInput>>;
  tsm?: InputMaybe<UserInput>;
  type?: InputMaybe<EngagementType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  whatRecommendsYou?: InputMaybe<Scalars['String']>;
};

export type EngagementLastRevision = {
  __typename?: 'EngagementLastRevision';
  appliesFrom: Scalars['DateTime'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  engagement: Engagement;
  id: Scalars['String'];
  job?: Maybe<Job>;
  jobId: Scalars['String'];
  periodEnd?: Maybe<Scalars['DateTime']>;
  revision: EngagementRevision;
  revisionAction?: Maybe<EngagementRevisionAction>;
  revisionActionId?: Maybe<Scalars['String']>;
  status: EngagementStatus;
  talent: Talent;
  talentId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EngagementLastRevisionFilters = {
  actionNextSteps?: InputMaybe<EngagementRevisionActionNextSteps>;
  actionStatus?: InputMaybe<EngagementRevisionActionStatus>;
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  endingBetween?: InputMaybe<DateBetweenFilterInput>;
  hasDraftRevision?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<EngagementStatus>>;
};

export type EngagementPublicProfile = {
  __typename?: 'EngagementPublicProfile';
  availability?: Maybe<Scalars['Float']>;
  availabilityInfo?: Maybe<Scalars['String']>;
  clientRate?: Maybe<Scalars['Float']>;
  contractType?: Maybe<JobContractType>;
  emFirstName?: Maybe<Scalars['String']>;
  emLastName?: Maybe<Scalars['String']>;
  emProfilePicUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  jobId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodStartNotes?: Maybe<Scalars['String']>;
  profile: PublicProfile;
};

export type EngagementRevision = {
  __typename?: 'EngagementRevision';
  appliesFrom?: Maybe<Scalars['DateTime']>;
  clientRate?: Maybe<Scalars['Float']>;
  commission?: Maybe<Scalars['Float']>;
  commitment?: Maybe<JobCommitment>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOverride?: Maybe<Scalars['String']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  engagementRevisionAction?: Maybe<EngagementRevisionAction>;
  engagementRevisionActionId?: Maybe<Scalars['String']>;
  fellowReferralPercentage?: Maybe<Scalars['Float']>;
  fellowVatPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  periodActualEnd?: Maybe<Scalars['DateTime']>;
  periodActualStart?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  periodStartNotes?: Maybe<Scalars['String']>;
  proposedAt?: Maybe<Scalars['DateTime']>;
  proposedBy?: Maybe<User>;
  proposedById?: Maybe<Scalars['String']>;
  proposedByText?: Maybe<Scalars['String']>;
  rejectionReasons?: Maybe<Array<Scalars['String']>>;
  serviceFee?: Maybe<Scalars['Float']>;
  status?: Maybe<EngagementStatus>;
  statusExplanation?: Maybe<Scalars['String']>;
  statusExtraExplanation?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<EngagementRevisionTag>>;
  talentRate?: Maybe<Scalars['Float']>;
  talentVatPercentage?: Maybe<Scalars['Float']>;
  talentYearlySalary?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EngagementRevisionAction = {
  __typename?: 'EngagementRevisionAction';
  additionalInfo?: Maybe<Scalars['String']>;
  appliesFrom?: Maybe<Scalars['DateTime']>;
  clientAdditionalInfo?: Maybe<Scalars['String']>;
  clientConfirmed?: Maybe<EngagementRevisionActionConfirmation>;
  clientDocument?: Maybe<Document>;
  clientDocumentId?: Maybe<Scalars['String']>;
  clientRate?: Maybe<Scalars['Float']>;
  clientReason?: Maybe<Scalars['String']>;
  commitment?: Maybe<JobCommitment>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  document?: Maybe<Document>;
  /** @deprecated Moved to separate documents per talent & client */
  documentId?: Maybe<Scalars['String']>;
  engagementStatus?: Maybe<EngagementStatus>;
  findNewCandidate?: Maybe<Scalars['Boolean']>;
  findNewJob?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  revision?: Maybe<EngagementRevision>;
  serviceFee?: Maybe<Scalars['Float']>;
  status?: Maybe<EngagementRevisionActionStatus>;
  tags?: Maybe<Array<EngagementRevisionTag>>;
  talentAdditionalInfo?: Maybe<Scalars['String']>;
  talentConfirmed?: Maybe<EngagementRevisionActionConfirmation>;
  talentDocument?: Maybe<Document>;
  talentDocumentId?: Maybe<Scalars['String']>;
  talentRate?: Maybe<Scalars['Float']>;
  talentReason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum EngagementRevisionActionConfirmation {
  No = 'No',
  Pending = 'Pending',
  Yes = 'Yes'
}

export type EngagementRevisionActionInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  appliesFrom?: InputMaybe<Scalars['DateTime']>;
  clientAdditionalInfo?: InputMaybe<Scalars['String']>;
  clientConfirmed?: InputMaybe<EngagementRevisionActionConfirmation>;
  clientDocument?: InputMaybe<DocumentInput>;
  clientDocumentId?: InputMaybe<Scalars['String']>;
  clientRate?: InputMaybe<Scalars['Float']>;
  clientReason?: InputMaybe<Scalars['String']>;
  commitment?: InputMaybe<JobCommitment>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  document?: InputMaybe<DocumentInput>;
  documentId?: InputMaybe<Scalars['String']>;
  engagementStatus?: InputMaybe<EngagementStatus>;
  findNewCandidate?: InputMaybe<Scalars['Boolean']>;
  findNewJob?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  revision?: InputMaybe<EngagementRevisionInput>;
  serviceFee?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<EngagementRevisionActionStatus>;
  tags?: InputMaybe<Array<EngagementRevisionTag>>;
  talentAdditionalInfo?: InputMaybe<Scalars['String']>;
  talentConfirmed?: InputMaybe<EngagementRevisionActionConfirmation>;
  talentDocument?: InputMaybe<DocumentInput>;
  talentDocumentId?: InputMaybe<Scalars['String']>;
  talentRate?: InputMaybe<Scalars['Float']>;
  talentReason?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum EngagementRevisionActionNextSteps {
  FindNewCandidate = 'FindNewCandidate',
  FindNewJob = 'FindNewJob'
}

export enum EngagementRevisionActionStatus {
  Confirmed = 'Confirmed',
  Draft = 'Draft'
}

export type EngagementRevisionFiltersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  engagementId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EngagementRevisionInput = {
  appliesFrom?: InputMaybe<Scalars['DateTime']>;
  clientRate?: InputMaybe<Scalars['Float']>;
  commission?: InputMaybe<Scalars['Float']>;
  commitment?: InputMaybe<JobCommitment>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOverride?: InputMaybe<Scalars['String']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  engagementRevisionAction?: InputMaybe<EngagementRevisionActionInput>;
  engagementRevisionActionId?: InputMaybe<Scalars['String']>;
  fellowReferralPercentage?: InputMaybe<Scalars['Float']>;
  fellowVatPercentage?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  periodActualEnd?: InputMaybe<Scalars['DateTime']>;
  periodActualStart?: InputMaybe<Scalars['DateTime']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  periodStartNotes?: InputMaybe<Scalars['String']>;
  proposedAt?: InputMaybe<Scalars['DateTime']>;
  proposedBy?: InputMaybe<UserInput>;
  proposedById?: InputMaybe<Scalars['String']>;
  proposedByText?: InputMaybe<Scalars['String']>;
  rejectionReasons?: InputMaybe<Array<Scalars['String']>>;
  serviceFee?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<EngagementStatus>;
  statusExplanation?: InputMaybe<Scalars['String']>;
  statusExtraExplanation?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<EngagementRevisionTag>>;
  talentRate?: InputMaybe<Scalars['Float']>;
  talentVatPercentage?: InputMaybe<Scalars['Float']>;
  talentYearlySalary?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum EngagementRevisionTag {
  ClientRateDecrease = 'ClientRateDecrease',
  ClientRateIncrease = 'ClientRateIncrease',
  CommitmentChange = 'CommitmentChange',
  ContractTypeChange = 'ContractTypeChange',
  Extension = 'Extension',
  PeriodStartDecrease = 'PeriodStartDecrease',
  PeriodStartIncrease = 'PeriodStartIncrease',
  Reduction = 'Reduction',
  ServiceFeeDecrease = 'ServiceFeeDecrease',
  ServiceFeeIncrease = 'ServiceFeeIncrease',
  StatusChange = 'StatusChange',
  TalentRateDecrease = 'TalentRateDecrease',
  TalentRateIncrease = 'TalentRateIncrease'
}

export enum EngagementStatus {
  Applied = 'Applied',
  Archived = 'Archived',
  CancelledClient = 'CancelledClient',
  CancelledFreelancer = 'CancelledFreelancer',
  CancelledUpper = 'CancelledUpper',
  Challenge = 'Challenge',
  ClientAssignment = 'ClientAssignment',
  CreateProfile = 'CreateProfile',
  Ended = 'Ended',
  ExpertReview = 'ExpertReview',
  Hired = 'Hired',
  Inbox = 'Inbox',
  InterviewClient = 'InterviewClient',
  InterviewFellow = 'InterviewFellow',
  InterviewUpperPersonal = 'InterviewUpperPersonal',
  InterviewUpperTechnical = 'InterviewUpperTechnical',
  Invited = 'Invited',
  Matching = 'Matching',
  MemberApplied = 'MemberApplied',
  MemberInvited = 'MemberInvited',
  Onboarding = 'Onboarding',
  Prepare = 'Prepare',
  ProposedClient = 'ProposedClient',
  ProposedFellow = 'ProposedFellow',
  ReadyToPropose = 'ReadyToPropose',
  RejectedClient = 'RejectedClient',
  RejectedFellow = 'RejectedFellow',
  RejectedFreelancer = 'RejectedFreelancer',
  RejectedUpper = 'RejectedUpper',
  UpperOnboarding = 'UpperOnboarding'
}

export type EngagementStatusInput = {
  rejectionReasons?: InputMaybe<Array<Scalars['String']>>;
  sendClientFeedbackEmail?: InputMaybe<Scalars['Boolean']>;
  sendNotifications?: InputMaybe<Scalars['Boolean']>;
  status: EngagementStatus;
  statusExplanation?: InputMaybe<Scalars['String']>;
  statusExtraExplanation?: InputMaybe<Scalars['String']>;
};

export enum EngagementTalentSource {
  Community = 'Community',
  Referral = 'Referral',
  Sourced = 'Sourced',
  Studio = 'Studio'
}

export enum EngagementType {
  Employment = 'Employment',
  Hourly = 'Hourly',
  Retainer = 'Retainer'
}

export type ExpenseAsset = {
  __typename?: 'ExpenseAsset';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationUrl?: Maybe<Scalars['String']>;
  mimeType?: Maybe<MimeType>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Float']>;
  type?: Maybe<ExpenseAssetType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseAssetInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  locationUrl?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<MimeType>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<ExpenseAssetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum ExpenseAssetType {
  Image = 'Image',
  Other = 'Other',
  Receipt = 'Receipt'
}

export type GroupedTalents = {
  __typename?: 'GroupedTalents';
  applied?: Maybe<TalentList>;
  challenge?: Maybe<TalentList>;
  invited?: Maybe<TalentList>;
  onboarding?: Maybe<TalentList>;
  registered?: Maybe<TalentList>;
  rejected?: Maybe<TalentList>;
  screening?: Maybe<TalentList>;
  techInterview?: Maybe<TalentList>;
};


export type GroupedTalentsAppliedArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type GroupedTalentsChallengeArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type GroupedTalentsInvitedArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type GroupedTalentsOnboardingArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type GroupedTalentsRegisteredArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type GroupedTalentsRejectedArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type GroupedTalentsScreeningArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type GroupedTalentsTechInterviewArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};

export type HubspotCompanyToCoreCompany = {
  __typename?: 'HubspotCompanyToCoreCompany';
  contacts?: Maybe<Array<Contact>>;
  description?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type HubspotDeal = {
  __typename?: 'HubspotDeal';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum HubspotDealChannel {
  DirectSales = 'DirectSales',
  ExternalReferral = 'ExternalReferral',
  Fellows = 'Fellows',
  Inbound = 'Inbound',
  Partnerships = 'Partnerships'
}

export type HubspotDealInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum HubspotDealPipeline {
  Default = 'Default',
  FellowAcquisition = 'FellowAcquisition',
  Partnerships = 'Partnerships',
  Sales = 'Sales'
}

export enum HubspotDealPipelineSalesStage {
  AppointmentScheduled = 'AppointmentScheduled',
  ClosedLost = 'ClosedLost',
  ClosedWon = 'ClosedWon',
  DeepDiveScheduled = 'DeepDiveScheduled',
  DiscoCompleted = 'DiscoCompleted',
  Interview = 'Interview',
  OnHold = 'OnHold',
  PreQualification = 'PreQualification',
  Proposed = 'Proposed',
  SowSent = 'SowSent',
  Staffing = 'Staffing'
}

export type HubspotDealToCoreJob = {
  __typename?: 'HubspotDealToCoreJob';
  company?: Maybe<HubspotCompanyToCoreCompany>;
  contractType?: Maybe<JobContractType>;
  dealSource?: Maybe<JobDealSource>;
  hubspotDealId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<JobPriority>;
  project?: Maybe<Project>;
  teamSize?: Maybe<Scalars['Float']>;
};

export enum HubspotDealType {
  New = 'New',
  UpsellOrCrossSell = 'UpsellOrCrossSell'
}

export type InitiatePasswordResetInput = {
  email: Scalars['String'];
};

export type InvitationAccess = {
  __typename?: 'InvitationAccess';
  ability?: Maybe<AbilityAction>;
  entity?: Maybe<Scalars['String']>;
  filters?: Maybe<AbilityAction>;
};

export type InvitationAccessInput = {
  ability?: InputMaybe<AbilityAction>;
  entity?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AbilityAction>;
};

export type InvitationDto = {
  __typename?: 'InvitationDto';
  access?: Maybe<Array<InvitationAccess>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  recipientEmail: Scalars['String'];
  recipientUser?: Maybe<User>;
  recipientUserId?: Maybe<Scalars['String']>;
  retryCount?: Maybe<Scalars['Float']>;
  status: InvitationStatus;
  targetCompanyName?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  token: Scalars['String'];
  type: InvitationType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum InvitationStatus {
  Accepted = 'Accepted',
  Pending = 'Pending'
}

export enum InvitationType {
  ClientApp = 'ClientApp',
  ClientCreateJobCollaborate = 'ClientCreateJobCollaborate',
  ClientEngagementFeedback = 'ClientEngagementFeedback',
  TalentReferral = 'TalentReferral'
}

export type InviteClientResponse = {
  __typename?: 'InviteClientResponse';
  email: Scalars['String'];
  errorCode?: Maybe<InviteClientResponseErrorCode>;
  success: Scalars['Boolean'];
};

export enum InviteClientResponseErrorCode {
  AlreadyInvited = 'AlreadyInvited',
  UserExists = 'UserExists'
}

export type InviteClientsInput = {
  emails: Array<Scalars['String']>;
};

export type InvoiceIncoming = {
  __typename?: 'InvoiceIncoming';
  airtableId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  fileLocation?: Maybe<Scalars['String']>;
  fileMimeType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  outgoingInvoiceItems?: Maybe<Array<InvoiceOutgoingItem>>;
  outgoingInvoices?: Maybe<Array<InvoiceOutgoing>>;
  paidAt?: Maybe<Scalars['DateTime']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Float']>;
  receivedAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceIncomingStatus>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  talentRate?: Maybe<Scalars['Float']>;
  totalGross?: Maybe<Scalars['Float']>;
  totalNet?: Maybe<Scalars['Float']>;
  type?: Maybe<InvoiceIncomingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export enum InvoiceIncomingStatus {
  Hold = 'Hold',
  NotOk = 'NotOk',
  Ok = 'Ok',
  Paid = 'Paid',
  Pending = 'Pending',
  Received = 'Received',
  Voided = 'Voided'
}

export enum InvoiceIncomingType {
  Expense = 'Expense',
  Fellow = 'Fellow',
  Other = 'Other',
  Scout = 'Scout',
  Service = 'Service',
  Talent = 'Talent'
}

export type InvoiceOutgoing = {
  __typename?: 'InvoiceOutgoing';
  additionalCode?: Maybe<Scalars['String']>;
  additionalInfo?: Maybe<Scalars['String']>;
  adjustment?: Maybe<Scalars['Float']>;
  airtableId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  detachedItems?: Maybe<Array<InvoiceOutgoingDetachedItem>>;
  discount?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Float']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  dueTerm?: Maybe<Scalars['Float']>;
  fellowTotalNet?: Maybe<Scalars['Float']>;
  fileLocation?: Maybe<Scalars['String']>;
  fileMimeType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
  fileUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<InvoiceOutgoingItem>>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<InvoiceOutgoingPaymentStatus>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  receivedAt?: Maybe<Scalars['DateTime']>;
  recipientAddress?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientVatId?: Maybe<Scalars['String']>;
  referrerTotalNet?: Maybe<Scalars['Float']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceOutgoingStatus>;
  totalGross?: Maybe<Scalars['Float']>;
  totalNet?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export type InvoiceOutgoingDetachedItem = {
  __typename?: 'InvoiceOutgoingDetachedItem';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceOutgoing>;
  notes?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceOutgoingFilters = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  fellowId?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['String']>;
};

export type InvoiceOutgoingItem = {
  __typename?: 'InvoiceOutgoingItem';
  adjustment?: Maybe<Scalars['Float']>;
  airtableId?: Maybe<Scalars['String']>;
  clientRate?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dealCloseDate?: Maybe<Scalars['DateTime']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  fellow?: Maybe<User>;
  fellowFeeAdjustment?: Maybe<Scalars['Float']>;
  fellowReferralPercentage?: Maybe<Scalars['Float']>;
  fellowStatus?: Maybe<InvoiceIncomingStatus>;
  fellowTotalNet?: Maybe<Scalars['Float']>;
  fellowVatPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceOutgoing>;
  lateInvoicesIncluded?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  periodEnd?: Maybe<Scalars['DateTime']>;
  periodStart?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Float']>;
  referrerStatus?: Maybe<InvoiceIncomingStatus>;
  referrerTotalNet?: Maybe<Scalars['Float']>;
  serviceFee?: Maybe<Scalars['Float']>;
  talentRate?: Maybe<Scalars['Float']>;
  talentTotalNet?: Maybe<Scalars['Float']>;
  talentVatPercentage?: Maybe<Scalars['Float']>;
  totalNet?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum InvoiceOutgoingPaymentStatus {
  Agreement = 'Agreement',
  Escalation = 'Escalation',
  FirstReminder = 'FirstReminder',
  Lawsuit = 'Lawsuit',
  Paid = 'Paid',
  Partial = 'Partial',
  Pending = 'Pending',
  SecondReminder = 'SecondReminder',
  ThirdReminder = 'ThirdReminder'
}

export enum InvoiceOutgoingStatus {
  Closed = 'Closed',
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  Sent = 'Sent',
  Voided = 'Voided'
}

export enum InvoiceTotal {
  FellowNet = 'FellowNet',
  Gross = 'Gross',
  ItemFellowNet = 'ItemFellowNet',
  ItemNet = 'ItemNet',
  ItemTalentNet = 'ItemTalentNet',
  Net = 'Net',
  ReferrerNet = 'ReferrerNet',
  TalentNet = 'TalentNet'
}

export enum InvoicesGenerateGroupBy {
  Company = 'Company',
  Project = 'Project'
}

export type Job = {
  __typename?: 'Job';
  acceptanceProcess?: Maybe<Scalars['String']>;
  annualSalaryBudget?: Maybe<Scalars['Float']>;
  asanaId?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<Asset>>;
  cancelledReasonComment?: Maybe<Scalars['String']>;
  cancelledReasons?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<JobCategory>;
  client?: Maybe<User>;
  clientId?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  commitment?: Maybe<JobCommitment>;
  commitmentInfo?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  confirmedStatus?: Maybe<JobConfirmedStatus>;
  contractType?: Maybe<JobContractType>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['String']>;
  daysInStaffing?: Maybe<Scalars['Float']>;
  dealSource?: Maybe<JobDealSource>;
  dealType?: Maybe<JobDealType>;
  description?: Maybe<Scalars['String']>;
  em?: Maybe<User>;
  emId?: Maybe<Scalars['String']>;
  engagements?: Maybe<Array<Engagement>>;
  expert?: Maybe<User>;
  expertId?: Maybe<Scalars['String']>;
  fellow?: Maybe<User>;
  fellowId?: Maybe<Scalars['String']>;
  health?: Maybe<JobHealth>;
  hourlyRateBudget?: Maybe<Scalars['Float']>;
  hubspotDeal?: Maybe<HubspotDeal>;
  hubspotDealId?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  idealCandidate?: Maybe<Scalars['String']>;
  isClean?: Maybe<Scalars['Boolean']>;
  isPausedSourcing?: Maybe<Scalars['Boolean']>;
  isPublicPageActive?: Maybe<Scalars['Boolean']>;
  isTeamLead?: Maybe<Scalars['Boolean']>;
  isTechLead?: Maybe<Scalars['Boolean']>;
  jobAssessments?: Maybe<Array<JobAssessment>>;
  jobDescriptionAsset?: Maybe<Array<Asset>>;
  jobDescriptionAssetId?: Maybe<Scalars['String']>;
  jobDescriptionUrl?: Maybe<Scalars['String']>;
  jobInterviewers?: Maybe<Array<JobInterviewer>>;
  jobSourcers?: Maybe<Array<JobSourcer>>;
  languageIds?: Maybe<Array<Scalars['String']>>;
  languages?: Maybe<Array<Language>>;
  lastCleanedAt?: Maybe<Scalars['DateTime']>;
  lastCleanedBy?: Maybe<User>;
  lastCleanedById?: Maybe<Scalars['String']>;
  leadSourcer?: Maybe<JobSourcer>;
  length?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationOnSite?: Maybe<Scalars['String']>;
  locationOnSitePercentage?: Maybe<Scalars['String']>;
  meetings?: Maybe<Array<Meeting>>;
  mindset?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  niceToHaveExperience?: Maybe<Scalars['String']>;
  niceToHaveSkillIds?: Maybe<Array<Scalars['String']>>;
  niceToHaveSkills?: Maybe<Array<Skill>>;
  notes?: Maybe<Array<Note>>;
  other?: Maybe<Scalars['String']>;
  position?: Maybe<Position>;
  positionId?: Maybe<Scalars['String']>;
  primaryLanguage?: Maybe<Language>;
  primaryLanguageId?: Maybe<Scalars['String']>;
  priority?: Maybe<JobPriority>;
  priorityOverride?: Maybe<JobPriority>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['String']>;
  publicPageAboutTheClient?: Maybe<Scalars['String']>;
  publicPageDescription?: Maybe<Scalars['String']>;
  publicPageHeadline?: Maybe<Scalars['String']>;
  publicPageHowToApply?: Maybe<Scalars['String']>;
  publicPageRateDetails?: Maybe<Scalars['String']>;
  publicPageReferralFee?: Maybe<Scalars['String']>;
  referrals?: Maybe<Array<Referral>>;
  requiredExperience?: Maybe<Scalars['String']>;
  requiredSkillIds?: Maybe<Array<Scalars['String']>>;
  requiredSkills?: Maybe<Array<Skill>>;
  requirements?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Scalars['String']>;
  rfsLink?: Maybe<Scalars['String']>;
  roleDescription?: Maybe<Scalars['String']>;
  roleGoals?: Maybe<Scalars['String']>;
  showRatePublicly?: Maybe<Scalars['Boolean']>;
  sourcedInvitedCount?: Maybe<Scalars['Float']>;
  sourcerIds?: Maybe<Array<Scalars['String']>>;
  staffingDeadline?: Maybe<Scalars['DateTime']>;
  staffingStartedAt?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<JobStatus>;
  statusHistory?: Maybe<Array<JobStatusHistory>>;
  teamSize?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobAssessment = {
  __typename?: 'JobAssessment';
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  authorRole?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<JobAssessmentDimension>>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<AssessmentType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobAssessmentDimension = {
  __typename?: 'JobAssessmentDimension';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Dimension>;
  dimensionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jobAssessment?: Maybe<JobAssessment>;
  jobAssessmentId?: Maybe<Scalars['String']>;
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  required?: Maybe<Scalars['Boolean']>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobAssessmentDimensionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<DimensionInput>;
  dimensionId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  jobAssessment?: InputMaybe<JobAssessmentInput>;
  jobAssessmentId?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageInput>;
  languageId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  required?: InputMaybe<Scalars['Boolean']>;
  skill?: InputMaybe<SkillInput>;
  skillId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobAssessmentFilters = {
  jobId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<AssessmentStatus>>;
  types?: InputMaybe<Array<AssessmentType>>;
};

export type JobAssessmentInput = {
  assignee?: InputMaybe<UserInput>;
  assigneeId?: InputMaybe<Scalars['String']>;
  authorName?: InputMaybe<Scalars['String']>;
  authorRole?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Array<JobAssessmentDimensionInput>>;
  id?: InputMaybe<Scalars['String']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobInput>;
  jobId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssessmentType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum JobCategory {
  Design = 'Design',
  Engineering = 'Engineering',
  Other = 'Other',
  Product = 'Product'
}

export enum JobCommitment {
  FullTime = 'FullTime',
  PartTime = 'PartTime'
}

export enum JobConfirmedStatus {
  Confirmed = 'Confirmed',
  Tentative = 'Tentative'
}

export enum JobContractType {
  Employment = 'Employment',
  Hourly = 'Hourly',
  Retainer = 'Retainer'
}

export type JobCreateHubspotInput = {
  closeDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['String']>;
  dealChannel?: InputMaybe<HubspotDealChannel>;
  dealId?: InputMaybe<Scalars['String']>;
  dealStage?: InputMaybe<HubspotDealPipelineSalesStage>;
  dealType?: InputMaybe<HubspotDealType>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  pipeline?: InputMaybe<HubspotDealPipeline>;
  shouldCreate?: InputMaybe<Scalars['Boolean']>;
};

export enum JobDealSource {
  DirectSales = 'DirectSales',
  Fellows = 'Fellows',
  Inbound = 'Inbound',
  Partnerships = 'Partnerships',
  Referral = 'Referral'
}

export enum JobDealType {
  CrossSell = 'CrossSell',
  Extension = 'Extension',
  New = 'New',
  UpSell = 'UpSell'
}

export type JobFilters = {
  airtableId?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<JobCategory>;
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  commitment?: InputMaybe<JobCommitment>;
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  contractTypes?: InputMaybe<Array<JobContractType>>;
  creatorIds?: InputMaybe<Array<Scalars['String']>>;
  engagementStatuses?: InputMaybe<Array<EngagementStatus>>;
  globalName?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  interviewerIds?: InputMaybe<Array<Scalars['String']>>;
  isPausedSourcing?: InputMaybe<Scalars['Boolean']>;
  isTeamLead?: InputMaybe<Scalars['Boolean']>;
  isTechLead?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  placedByIds?: InputMaybe<Array<Scalars['String']>>;
  positionIds?: InputMaybe<Array<Scalars['String']>>;
  priority?: InputMaybe<JobPriority>;
  projectIds?: InputMaybe<Array<Scalars['String']>>;
  requiredSkillIds?: InputMaybe<Array<Scalars['String']>>;
  skills?: InputMaybe<Array<JobSkillsFilterDto>>;
  statuses?: InputMaybe<Array<JobStatus>>;
  usersByRole?: InputMaybe<Array<UserWithRoleFilter>>;
};

export type JobGroupedCount = {
  __typename?: 'JobGroupedCount';
  high?: Maybe<Scalars['Float']>;
  low?: Maybe<Scalars['Float']>;
  medium?: Maybe<Scalars['Float']>;
  none?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type JobHealth = {
  __typename?: 'JobHealth';
  appliedCount?: Maybe<Scalars['Float']>;
  appliedPercentage?: Maybe<Scalars['Float']>;
  daysToStaffingDeadline?: Maybe<Scalars['Float']>;
  deadlineInDays?: Maybe<Scalars['Float']>;
  healthPercentage?: Maybe<Scalars['Float']>;
  lastCandidateProposedDate?: Maybe<Scalars['DateTime']>;
  matchingCount?: Maybe<Scalars['Float']>;
  matchingPercentage?: Maybe<Scalars['Float']>;
  proposedCount?: Maybe<Scalars['Float']>;
  proposedPercentage?: Maybe<Scalars['Float']>;
  sourcingCount?: Maybe<Scalars['Float']>;
  sourcingPercentage?: Maybe<Scalars['Float']>;
  speedPercentage?: Maybe<Scalars['Float']>;
  staffingStartedAt?: Maybe<Scalars['DateTime']>;
};

export type JobHealthInput = {
  appliedCount?: InputMaybe<Scalars['Float']>;
  appliedPercentage?: InputMaybe<Scalars['Float']>;
  daysToStaffingDeadline?: InputMaybe<Scalars['Float']>;
  deadlineInDays?: InputMaybe<Scalars['Float']>;
  healthPercentage?: InputMaybe<Scalars['Float']>;
  lastCandidateProposedDate?: InputMaybe<Scalars['DateTime']>;
  matchingCount?: InputMaybe<Scalars['Float']>;
  matchingPercentage?: InputMaybe<Scalars['Float']>;
  proposedCount?: InputMaybe<Scalars['Float']>;
  proposedPercentage?: InputMaybe<Scalars['Float']>;
  sourcingCount?: InputMaybe<Scalars['Float']>;
  sourcingPercentage?: InputMaybe<Scalars['Float']>;
  speedPercentage?: InputMaybe<Scalars['Float']>;
  staffingStartedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobHubspotSearchResult = {
  __typename?: 'JobHubspotSearchResult';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type JobHubspotSearchResultDtoHubspotForwardPaging = {
  __typename?: 'JobHubspotSearchResultDtoHubspotForwardPaging';
  next?: Maybe<JobHubspotSearchResultDtoHubspotPaging>;
};

export type JobHubspotSearchResultDtoHubspotPaging = {
  __typename?: 'JobHubspotSearchResultDtoHubspotPaging';
  after?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type JobHubspotSearchResults = {
  __typename?: 'JobHubspotSearchResults';
  paging?: Maybe<JobHubspotSearchResultDtoHubspotForwardPaging>;
  results?: Maybe<Array<JobHubspotSearchResult>>;
  total?: Maybe<Scalars['Float']>;
};

export type JobInput = {
  acceptanceProcess?: InputMaybe<Scalars['String']>;
  annualSalaryBudget?: InputMaybe<Scalars['Float']>;
  asanaId?: InputMaybe<Scalars['String']>;
  assets?: InputMaybe<Array<AssetInput>>;
  cancelledReasonComment?: InputMaybe<Scalars['String']>;
  cancelledReasons?: InputMaybe<Array<Scalars['String']>>;
  category?: InputMaybe<JobCategory>;
  client?: InputMaybe<UserInput>;
  clientId?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  commitment?: InputMaybe<JobCommitment>;
  commitmentInfo?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  confirmedStatus?: InputMaybe<JobConfirmedStatus>;
  contractType?: InputMaybe<JobContractType>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserInput>;
  creatorId?: InputMaybe<Scalars['String']>;
  daysInStaffing?: InputMaybe<Scalars['Float']>;
  dealSource?: InputMaybe<JobDealSource>;
  dealType?: InputMaybe<JobDealType>;
  description?: InputMaybe<Scalars['String']>;
  em?: InputMaybe<UserInput>;
  emId?: InputMaybe<Scalars['String']>;
  engagements?: InputMaybe<Array<EngagementInput>>;
  expert?: InputMaybe<UserInput>;
  expertId?: InputMaybe<Scalars['String']>;
  fellow?: InputMaybe<UserInput>;
  fellowId?: InputMaybe<Scalars['String']>;
  health?: InputMaybe<JobHealthInput>;
  hourlyRateBudget?: InputMaybe<Scalars['Float']>;
  hubspotDeal?: InputMaybe<HubspotDealInput>;
  hubspotDealId?: InputMaybe<Scalars['String']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  idealCandidate?: InputMaybe<Scalars['String']>;
  isClean?: InputMaybe<Scalars['Boolean']>;
  isPausedSourcing?: InputMaybe<Scalars['Boolean']>;
  isPublicPageActive?: InputMaybe<Scalars['Boolean']>;
  isTeamLead?: InputMaybe<Scalars['Boolean']>;
  isTechLead?: InputMaybe<Scalars['Boolean']>;
  jobAssessments?: InputMaybe<Array<JobAssessmentInput>>;
  jobDescriptionAsset?: InputMaybe<Array<AssetInput>>;
  jobDescriptionAssetId?: InputMaybe<Scalars['String']>;
  jobDescriptionUrl?: InputMaybe<Scalars['String']>;
  jobInterviewers?: InputMaybe<Array<JobInterviewerInput>>;
  jobSourcers?: InputMaybe<Array<JobSourcerInput>>;
  languageIds?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<LanguageInput>>;
  lastCleanedAt?: InputMaybe<Scalars['DateTime']>;
  lastCleanedBy?: InputMaybe<UserInput>;
  lastCleanedById?: InputMaybe<Scalars['String']>;
  leadSourcer?: InputMaybe<JobSourcerInput>;
  length?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  locationOnSite?: InputMaybe<Scalars['String']>;
  locationOnSitePercentage?: InputMaybe<Scalars['String']>;
  meetings?: InputMaybe<Array<MeetingInput>>;
  mindset?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  niceToHaveExperience?: InputMaybe<Scalars['String']>;
  niceToHaveSkillIds?: InputMaybe<Array<Scalars['String']>>;
  niceToHaveSkills?: InputMaybe<Array<SkillInput>>;
  notes?: InputMaybe<Array<NoteInput>>;
  other?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<PositionInput>;
  positionId?: InputMaybe<Scalars['String']>;
  primaryLanguage?: InputMaybe<LanguageInput>;
  primaryLanguageId?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<JobPriority>;
  priorityOverride?: InputMaybe<JobPriority>;
  project?: InputMaybe<ProjectInput>;
  projectId?: InputMaybe<Scalars['String']>;
  publicPageAboutTheClient?: InputMaybe<Scalars['String']>;
  publicPageDescription?: InputMaybe<Scalars['String']>;
  publicPageHeadline?: InputMaybe<Scalars['String']>;
  publicPageHowToApply?: InputMaybe<Scalars['String']>;
  publicPageRateDetails?: InputMaybe<Scalars['String']>;
  publicPageReferralFee?: InputMaybe<Scalars['String']>;
  referrals?: InputMaybe<Array<ReferralInput>>;
  requiredExperience?: InputMaybe<Scalars['String']>;
  requiredSkillIds?: InputMaybe<Array<Scalars['String']>>;
  requiredSkills?: InputMaybe<Array<SkillInput>>;
  requirements?: InputMaybe<Scalars['String']>;
  responsibilities?: InputMaybe<Scalars['String']>;
  rfsLink?: InputMaybe<Scalars['String']>;
  roleDescription?: InputMaybe<Scalars['String']>;
  roleGoals?: InputMaybe<Scalars['String']>;
  showRatePublicly?: InputMaybe<Scalars['Boolean']>;
  sourcedInvitedCount?: InputMaybe<Scalars['Float']>;
  sourcerIds?: InputMaybe<Array<Scalars['String']>>;
  staffingDeadline?: InputMaybe<Scalars['DateTime']>;
  staffingStartedAt?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<JobStatus>;
  statusHistory?: InputMaybe<Array<JobStatusHistoryInput>>;
  teamSize?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobInterviewer = {
  __typename?: 'JobInterviewer';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  interviewer?: Maybe<User>;
  interviewerId?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobInterviewerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  interviewer?: InputMaybe<UserInput>;
  interviewerId?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobInput>;
  jobId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type JobOrProjectFilter = {
  jobId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type JobPerformanceAnalytics = {
  __typename?: 'JobPerformanceAnalytics';
  applications: JobPerformanceAnalyticsTable;
  sourcing: JobPerformanceAnalyticsTable;
};

export type JobPerformanceAnalyticsRow = {
  __typename?: 'JobPerformanceAnalyticsRow';
  applied: Scalars['Float'];
  appliedPercentage: Scalars['Float'];
  clientInterview: Scalars['Float'];
  clientInterviewPercentage: Scalars['Float'];
  hired: Scalars['Float'];
  hiredPercentage: Scalars['Float'];
  invited: Scalars['Float'];
  invitedPercentage: Scalars['Float'];
  name: Scalars['String'];
  proposed: Scalars['Float'];
  proposedPercentage: Scalars['Float'];
  rejected: Scalars['Float'];
  rejectedPercentage: Scalars['Float'];
  total: Scalars['Float'];
};

export type JobPerformanceAnalyticsTable = {
  __typename?: 'JobPerformanceAnalyticsTable';
  rows: Array<JobPerformanceAnalyticsRow>;
};

export enum JobPriority {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  None = 'None'
}

export type JobReferralInput = {
  clientEmail: Scalars['String'];
  clientFirstName: Scalars['String'];
  clientLastName: Scalars['String'];
  clientPhone?: InputMaybe<Scalars['String']>;
  companyDescription: Scalars['String'];
  companyName: Scalars['String'];
  introMessage: Scalars['String'];
  projectDescription: Scalars['String'];
  websiteUrl: Scalars['String'];
};

export type JobSkillsFilterDto = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  operator?: InputMaybe<ConditionalOperator>;
};

export type JobSourcer = {
  __typename?: 'JobSourcer';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isLead?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  sourcer?: Maybe<User>;
  sourcerId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobSourcerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isLead?: InputMaybe<Scalars['Boolean']>;
  job?: InputMaybe<JobInput>;
  jobId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  sourcer?: InputMaybe<UserInput>;
  sourcerId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum JobStatus {
  Archived = 'Archived',
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Hired = 'Hired',
  InReview = 'InReview',
  InStaffing = 'InStaffing',
  OnHold = 'OnHold'
}

export type JobStatusChangeEmailTemplateDto = {
  __typename?: 'JobStatusChangeEmailTemplateDto';
  createdAt?: Maybe<Scalars['DateTime']>;
  engagementStatuses: Array<EngagementStatus>;
  group: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  jobStatus?: Maybe<JobStatus>;
  key: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type JobStatusDto = {
  cancelledReasonComment?: InputMaybe<Scalars['String']>;
  cancelledReasons?: InputMaybe<Array<Scalars['String']>>;
  status: JobStatus;
};

export type JobStatusHistory = {
  __typename?: 'JobStatusHistory';
  cancelledReasonComment?: Maybe<Scalars['String']>;
  cancelledReasons?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<JobStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobStatusHistoryInput = {
  cancelledReasonComment?: InputMaybe<Scalars['String']>;
  cancelledReasons?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobInput>;
  jobId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<JobStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Language = {
  __typename?: 'Language';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LanguageFilters = {
  code?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type LanguageInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginSuccessResponse = {
  __typename?: 'LoginSuccessResponse';
  token: Scalars['String'];
  user: User;
};

export type Meeting = {
  __typename?: 'Meeting';
  airtableId?: Maybe<Scalars['String']>;
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['String']>;
  attendees?: Maybe<Array<User>>;
  client?: Maybe<User>;
  clientId?: Maybe<Scalars['String']>;
  clientTimezone?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Contact>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  expert?: Maybe<User>;
  expertId?: Maybe<Scalars['String']>;
  fellow?: Maybe<User>;
  fellowId?: Maybe<Scalars['String']>;
  fellowTimezone?: Maybe<Scalars['String']>;
  host?: Maybe<Contact>;
  hostEmail?: Maybe<Scalars['String']>;
  hostName?: Maybe<Scalars['String']>;
  hostRole?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Note>>;
  proposedTimes?: Maybe<Array<Scalars['String']>>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<MeetingStatus>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  talentTimezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<MeetingType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MeetingConfirmInterviewDto = {
  meetingType: MeetingType;
  scheduledAt: Scalars['DateTime'];
  talentTimezone: Scalars['String'];
};

export type MeetingFilters = {
  clientId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  engagementId?: InputMaybe<Scalars['String']>;
  fellowId?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MeetingType>;
};

export type MeetingInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  assignee?: InputMaybe<UserInput>;
  assigneeId?: InputMaybe<Scalars['String']>;
  attendees?: InputMaybe<Array<UserInput>>;
  client?: InputMaybe<UserInput>;
  clientId?: InputMaybe<Scalars['String']>;
  clientTimezone?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<ContactInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserInput>;
  creatorId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  expert?: InputMaybe<UserInput>;
  expertId?: InputMaybe<Scalars['String']>;
  fellow?: InputMaybe<UserInput>;
  fellowId?: InputMaybe<Scalars['String']>;
  fellowTimezone?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<ContactInput>;
  hostEmail?: InputMaybe<Scalars['String']>;
  hostName?: InputMaybe<Scalars['String']>;
  hostRole?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobInput>;
  jobId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Array<NoteInput>>;
  proposedTimes?: InputMaybe<Array<Scalars['String']>>;
  scheduledAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<MeetingStatus>;
  talent?: InputMaybe<TalentInput>;
  talentId?: InputMaybe<Scalars['String']>;
  talentTimezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MeetingType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum MeetingStatus {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  InProgress = 'InProgress',
  Pending = 'Pending',
  Proposed = 'Proposed',
  Scheduled = 'Scheduled'
}

export enum MeetingType {
  ClientInterview = 'ClientInterview',
  Discovery = 'Discovery',
  FellowInterview = 'FellowInterview',
  UpperInterview = 'UpperInterview',
  UpperInterviewAndMatching = 'UpperInterviewAndMatching',
  UpperMatching = 'UpperMatching'
}

export enum MimeType {
  Doc = 'Doc',
  Docx = 'Docx',
  Jpeg = 'Jpeg',
  Jpg = 'Jpg',
  Pdf = 'Pdf',
  Png = 'Png',
  Webp = 'Webp'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation: UserDataClient;
  applyToJob: Engagement;
  approveTimesheet: Timesheet;
  attachInvoiceOutgoingItems: AttachInvoiceOutgoingItemsResponse;
  bulkDeleteJobAssessmentDimension: Scalars['Boolean'];
  bulkDeleteTalentAssessmentDimension: Scalars['Boolean'];
  clientSignup: UserDataClient;
  cloneJob: Job;
  companiesCreateHarvestClient: Array<CompanyCreateHarvestClientOutput>;
  confirmInterview: Meeting;
  confirmRevisionAction: EngagementRevisionAction;
  createAbsence: TimesheetAbsence;
  createAbsenceInterval: Array<TimesheetAbsence>;
  createAgency: Agency;
  createAgencyFrameworkAgreement: Document;
  createAndSendTalentFrameworkAgreement: Document;
  createAssessmentTemplate: AssessmentTemplate;
  createAuditEntry: AuditEntry;
  createCompany: Company;
  createCompanyMasterServiceAgreement: Document;
  createContact: Contact;
  createEngagement: Engagement;
  createEngagementFeedback: Scalars['String'];
  createEngagementProjectAgreement: Document;
  createEngagementStatementOfWork: Document;
  createExpense: TimesheetExpense;
  createInterview: Meeting;
  createJob: Job;
  createJobAssessment: JobAssessment;
  createJobAssessmentDimension: JobAssessmentDimension;
  createMeeting: Meeting;
  createNote: Note;
  createNotification: Notification;
  createPandaDocDocuments: Document;
  createPasswordResetToken: CreatePasswordResetTokenResponse;
  createPosition: Position;
  createProject: Project;
  createReferral: Referral;
  createRevisionAction: EngagementRevisionAction;
  createSkill: Skill;
  createTag: Tag;
  createTalent: Talent;
  createTalentAssessment: TalentAssessment;
  createTalentAssessmentDimension: TalentAssessmentDimension;
  createTalentFrameworkAgreement: Document;
  createTalentProfile: TalentProfile;
  createTalentProfileFromTalent: TalentProfile;
  createTimeEntry: TimeEntry;
  createUsersFromTalents: Scalars['Boolean'];
  deleteAbsence: Scalars['Boolean'];
  deleteAsset: Scalars['Boolean'];
  deleteExpense: Scalars['Boolean'];
  deleteExpenseAsset: Scalars['Boolean'];
  deleteJob: Scalars['Boolean'];
  deleteJobAssessmentDimension: Scalars['Boolean'];
  deleteProfileItem: Scalars['Boolean'];
  deleteProfileItems: Scalars['Boolean'];
  deleteRevisionAction: Scalars['String'];
  deleteTalentAssessmentDimension: Scalars['Boolean'];
  deleteTalentProfile: Scalars['Boolean'];
  deleteTimeEntry: Scalars['Boolean'];
  duplicateTalentProfile: TalentProfile;
  engagementsAttachHarvestProjects: Array<EngagementAttachHarvestProjectOutput>;
  engagementsCreateHarvestProjects: Array<EngagementCreateHarvestProjectOutput>;
  engagementsGenerateCode: Array<Engagement>;
  fillClientEngagementFeedback: TalentAssessment;
  generateProfileFromResumes: Scalars['Boolean'];
  initiatePasswordReset: Scalars['Boolean'];
  inviteToCollaborateOnJob: Scalars['String'];
  inviteToCompany: Array<InviteClientResponse>;
  invoicesBatchCalculateTotals: Array<InvoiceOutgoing>;
  invoicesBatchComplete: Array<InvoiceOutgoing>;
  invoicesBatchGeneratePdf: Array<InvoiceOutgoing>;
  invoicesBatchSend: Array<InvoiceOutgoing>;
  invoicesCorrect: InvoiceOutgoing;
  invoicesCreate: Scalars['Boolean'];
  invoicesGenerate: Scalars['Boolean'];
  invoicesGenerateNewFromItems: InvoiceOutgoing;
  invoicesPayFellow: InvoiceIncoming;
  invoicesSend: InvoiceOutgoing;
  invoicesVoid: InvoiceOutgoing;
  login: LoginSuccessResponse;
  mapSkills: Skill;
  /** Expects a CSV with 2 columns: Source Skill and Target Skill and will replace all instances of the source with the target and remove the source. The CSV expects to have a header and will ignore the first row. */
  mapSkillsFromCsv: Scalars['Boolean'];
  meetingsGenerateFromEngagements: Array<Meeting>;
  noteToTalentAssessment: Scalars['Boolean'];
  notesSendToSlack: Scalars['Boolean'];
  openSlackConversation: OpenSlackConversationResponse;
  projectsCreateSlackChannel: Project;
  recalculateTalentScoreCard?: Maybe<TalentScoreCard>;
  referJob: Scalars['Boolean'];
  /** Expects a CSV with 2 columns: Source Skill and Target Name, renaming the source to target. The CSV expects to have a header and will ignore the first row. */
  renameSkillsFromCsv: Scalars['Boolean'];
  reopenTimesheet: Timesheet;
  replaceAllSimilarSkills: Scalars['String'];
  reportTimeEntries: Scalars['Boolean'];
  resendInvitation: InvitationDto;
  resendUserVerification: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  sendDocument: Document;
  sendProposeToClientEmail: Scalars['Boolean'];
  sendRFS: Scalars['Boolean'];
  /** Matches Slack users with platform users by email and updates the Slack ID of the platform users which don't have one already set. */
  setUserSlackIds: Array<User>;
  submitTimesheet: Timesheet;
  talentSignup: Talent;
  talentsAttachHarvestUser: Array<TalentAttachHarvestUserOutput>;
  talentsCreateHarvestUser: Array<TalentCreateHarvestUserOutput>;
  timeEntriesImportFromHarvest: Scalars['Boolean'];
  timesheetsSend: Scalars['Boolean'];
  triggerCron: Scalars['String'];
  updateAbsence: TimesheetAbsence;
  updateAgency: Agency;
  updateAssessmentTemplate: AssessmentTemplate;
  updateCompany: Company;
  updateContact: Contact;
  updateEngagement: Engagement;
  updateEngagementDealData: Scalars['Boolean'];
  updateEngagementStatus: Engagement;
  updateExpense: TimesheetExpense;
  updateJob: Job;
  updateJobAssessment: JobAssessment;
  updateJobAssessmentDimension: JobAssessmentDimension;
  updateJobStatus: Job;
  updateMeeting: Meeting;
  updateNote: Note;
  updateNotification: Notification;
  updatePosition: Position;
  updateProject: Project;
  updateRevisionAction: EngagementRevisionAction;
  updateSkill: Skill;
  updateTalent: Talent;
  updateTalentAssessment: TalentAssessment;
  updateTalentAssessmentDimension: TalentAssessmentDimension;
  updateTalentAvailability: Scalars['Boolean'];
  updateTalentProfile: TalentProfile;
  updateTalentStatus: Talent;
  updateTimeEntry: TimeEntry;
  uploadTalentProfile: TalentProfile;
  usersAttachHubspotId: Scalars['Boolean'];
  verifyEmail: Scalars['Boolean'];
};


export type MutationAcceptInvitationArgs = {
  role: Scalars['String'];
  token: Scalars['String'];
};


export type MutationApplyToJobArgs = {
  jobId: Scalars['String'];
  talentId?: InputMaybe<Scalars['String']>;
};


export type MutationApproveTimesheetArgs = {
  end: Scalars['DateTime'];
  engagementId: Scalars['String'];
  start: Scalars['DateTime'];
};


export type MutationAttachInvoiceOutgoingItemsArgs = {
  data: AttachInvoiceOutgoingItems;
};


export type MutationBulkDeleteJobAssessmentDimensionArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationBulkDeleteTalentAssessmentDimensionArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationClientSignupArgs = {
  data: ClientSignup;
};


export type MutationCloneJobArgs = {
  id: Scalars['String'];
};


export type MutationCompaniesCreateHarvestClientArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationConfirmInterviewArgs = {
  data: MeetingConfirmInterviewDto;
  id: Scalars['String'];
};


export type MutationConfirmRevisionActionArgs = {
  data: EngagementRevisionActionInput;
  id: Scalars['String'];
};


export type MutationCreateAbsenceArgs = {
  data: TimesheetAbsenceInput;
};


export type MutationCreateAbsenceIntervalArgs = {
  data: TimesheetAbsenceInterval;
};


export type MutationCreateAgencyArgs = {
  data: AgencyInput;
};


export type MutationCreateAgencyFrameworkAgreementArgs = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAndSendTalentFrameworkAgreementArgs = {
  talentId: Scalars['String'];
};


export type MutationCreateAssessmentTemplateArgs = {
  data: AssessmentTemplateInput;
};


export type MutationCreateAuditEntryArgs = {
  data: AuditEntryInput;
};


export type MutationCreateCompanyArgs = {
  data: CompanyInput;
  hubspot?: InputMaybe<CompanyCreateHubspotInput>;
};


export type MutationCreateCompanyMasterServiceAgreementArgs = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateContactArgs = {
  data: ContactInput;
};


export type MutationCreateEngagementArgs = {
  data: EngagementInput;
  sendNotifications?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateEngagementFeedbackArgs = {
  engagementId: Scalars['String'];
  talentId: Scalars['String'];
};


export type MutationCreateEngagementProjectAgreementArgs = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateEngagementStatementOfWorkArgs = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateExpenseArgs = {
  data: TimesheetExpenseInput;
};


export type MutationCreateInterviewArgs = {
  data: MeetingInput;
};


export type MutationCreateJobArgs = {
  data: JobInput;
  hubspot?: InputMaybe<JobCreateHubspotInput>;
};


export type MutationCreateJobAssessmentArgs = {
  data: JobAssessmentInput;
};


export type MutationCreateJobAssessmentDimensionArgs = {
  data: JobAssessmentDimensionInput;
};


export type MutationCreateMeetingArgs = {
  data: MeetingInput;
};


export type MutationCreateNoteArgs = {
  data: NoteInput;
};


export type MutationCreateNotificationArgs = {
  data: NotificationInput;
};


export type MutationCreatePandaDocDocumentsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationCreatePasswordResetTokenArgs = {
  data: InitiatePasswordResetInput;
};


export type MutationCreatePositionArgs = {
  data: PositionInput;
};


export type MutationCreateProjectArgs = {
  data: ProjectInput;
};


export type MutationCreateReferralArgs = {
  data: ReferralInput;
};


export type MutationCreateRevisionActionArgs = {
  data: EngagementRevisionActionInput;
  revisionId: Scalars['String'];
};


export type MutationCreateSkillArgs = {
  data: SkillInput;
  skipMapping?: Scalars['Boolean'];
};


export type MutationCreateTagArgs = {
  data: TagInput;
};


export type MutationCreateTalentArgs = {
  data: TalentInput;
};


export type MutationCreateTalentAssessmentArgs = {
  data: TalentAssessmentInput;
};


export type MutationCreateTalentAssessmentDimensionArgs = {
  data: TalentAssessmentDimensionInput;
};


export type MutationCreateTalentFrameworkAgreementArgs = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateTalentProfileArgs = {
  data: TalentProfileCreateInput;
};


export type MutationCreateTalentProfileFromTalentArgs = {
  talentId: Scalars['String'];
};


export type MutationCreateTimeEntryArgs = {
  data: TimeEntryInput;
};


export type MutationCreateUsersFromTalentsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteAbsenceArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAssetArgs = {
  id: Scalars['String'];
};


export type MutationDeleteExpenseArgs = {
  id: Scalars['String'];
};


export type MutationDeleteExpenseAssetArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJobArgs = {
  id: Scalars['String'];
};


export type MutationDeleteJobAssessmentDimensionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProfileItemArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProfileItemsArgs = {
  data: DeleteManyInput;
};


export type MutationDeleteRevisionActionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTalentAssessmentDimensionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTalentProfileArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTimeEntryArgs = {
  id: Scalars['String'];
};


export type MutationDuplicateTalentProfileArgs = {
  id: Scalars['String'];
};


export type MutationEngagementsAttachHarvestProjectsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationEngagementsCreateHarvestProjectsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationEngagementsGenerateCodeArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationFillClientEngagementFeedbackArgs = {
  data: TalentAssessmentInput;
};


export type MutationGenerateProfileFromResumesArgs = {
  talentAssetIds?: InputMaybe<Array<Scalars['String']>>;
  talentId: Scalars['String'];
};


export type MutationInitiatePasswordResetArgs = {
  data: InitiatePasswordResetInput;
};


export type MutationInviteToCollaborateOnJobArgs = {
  jobId: Scalars['String'];
};


export type MutationInviteToCompanyArgs = {
  data: InviteClientsInput;
};


export type MutationInvoicesBatchCalculateTotalsArgs = {
  ids: Array<Scalars['String']>;
  totals: Array<InvoiceTotal>;
};


export type MutationInvoicesBatchCompleteArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationInvoicesBatchGeneratePdfArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationInvoicesBatchSendArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationInvoicesCorrectArgs = {
  correctionReason: Scalars['String'];
  id: Scalars['String'];
};


export type MutationInvoicesCreateArgs = {
  companyIds: Array<Scalars['String']>;
  date: Scalars['DateTime'];
};


export type MutationInvoicesGenerateArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  discountFellowEarnings?: Scalars['Boolean'];
  from: Scalars['DateTime'];
  generateGroupBy?: InvoicesGenerateGroupBy;
  onlyApproved?: Scalars['Boolean'];
  onlyUnbilled?: Scalars['Boolean'];
  to?: InputMaybe<Scalars['DateTime']>;
};


export type MutationInvoicesGenerateNewFromItemsArgs = {
  invoiceId: Scalars['String'];
  itemIds: Array<Scalars['String']>;
};


export type MutationInvoicesPayFellowArgs = {
  id: Scalars['String'];
};


export type MutationInvoicesSendArgs = {
  id: Scalars['String'];
};


export type MutationInvoicesVoidArgs = {
  id: Scalars['String'];
  voidedReason: Scalars['String'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationMapSkillsArgs = {
  sourceIds: Array<Scalars['String']>;
  strategy: ToolsSkillReplaceStrategy;
  targetId: Scalars['String'];
  targetType?: InputMaybe<SkillType>;
};


export type MutationMapSkillsFromCsvArgs = {
  strategy: ToolsSkillReplaceStrategy;
  valueString: Scalars['String'];
};


export type MutationMeetingsGenerateFromEngagementsArgs = {
  assigneeId?: InputMaybe<Scalars['String']>;
  creatorId?: InputMaybe<Scalars['String']>;
  engagementIds: Array<Scalars['String']>;
  time?: InputMaybe<Scalars['DateTime']>;
  type: MeetingType;
};


export type MutationNoteToTalentAssessmentArgs = {
  noteId: Scalars['String'];
  type: AssessmentType;
};


export type MutationNotesSendToSlackArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  channelLink?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  updateAssociatedProject?: InputMaybe<Scalars['Boolean']>;
};


export type MutationOpenSlackConversationArgs = {
  userId: Scalars['String'];
};


export type MutationProjectsCreateSlackChannelArgs = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  shouldInviteTeam?: InputMaybe<Scalars['Boolean']>;
};


export type MutationRecalculateTalentScoreCardArgs = {
  talentId: Scalars['String'];
};


export type MutationReferJobArgs = {
  data: JobReferralInput;
};


export type MutationRenameSkillsFromCsvArgs = {
  valueString: Scalars['String'];
};


export type MutationReopenTimesheetArgs = {
  end: Scalars['DateTime'];
  engagementId: Scalars['String'];
  start: Scalars['DateTime'];
};


export type MutationReportTimeEntriesArgs = {
  companyId: Scalars['String'];
  emails: Scalars['String'];
};


export type MutationResendInvitationArgs = {
  id: Scalars['String'];
};


export type MutationResendUserVerificationArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationSendDocumentArgs = {
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  senderId?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};


export type MutationSendProposeToClientEmailArgs = {
  email: Scalars['String'];
  engagementId: Scalars['String'];
};


export type MutationSendRfsArgs = {
  data: Rfs;
};


export type MutationSetUserSlackIdsArgs = {
  userIds: Array<Scalars['String']>;
};


export type MutationSubmitTimesheetArgs = {
  end: Scalars['DateTime'];
  engagementId: Scalars['String'];
  start: Scalars['DateTime'];
};


export type MutationTalentSignupArgs = {
  invitation?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  referredById?: InputMaybe<Scalars['String']>;
  talent: TalentInput;
};


export type MutationTalentsAttachHarvestUserArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationTalentsCreateHarvestUserArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationTimeEntriesImportFromHarvestArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  from: Scalars['DateTime'];
  to?: InputMaybe<Scalars['DateTime']>;
};


export type MutationTimesheetsSendArgs = {
  companyId: Scalars['String'];
  from: Scalars['String'];
  recipientEmails: Scalars['String'];
  to?: Scalars['String'];
};


export type MutationTriggerCronArgs = {
  cron: Scalars['String'];
};


export type MutationUpdateAbsenceArgs = {
  data: TimesheetAbsenceInput;
  id: Scalars['String'];
};


export type MutationUpdateAgencyArgs = {
  data: AgencyInput;
  id: Scalars['String'];
};


export type MutationUpdateAssessmentTemplateArgs = {
  data: AssessmentTemplateInput;
  id: Scalars['String'];
};


export type MutationUpdateCompanyArgs = {
  data: CompanyInput;
  id: Scalars['String'];
};


export type MutationUpdateContactArgs = {
  data: ContactInput;
  id: Scalars['String'];
};


export type MutationUpdateEngagementArgs = {
  data: EngagementInput;
  id: Scalars['String'];
};


export type MutationUpdateEngagementDealDataArgs = {
  valueString: Scalars['String'];
};


export type MutationUpdateEngagementStatusArgs = {
  data: EngagementStatusInput;
  id: Scalars['String'];
};


export type MutationUpdateExpenseArgs = {
  data: TimesheetExpenseInput;
  id: Scalars['String'];
};


export type MutationUpdateJobArgs = {
  data: JobInput;
  hubspot?: InputMaybe<JobCreateHubspotInput>;
  id?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateJobAssessmentArgs = {
  data: JobAssessmentInput;
  id: Scalars['String'];
};


export type MutationUpdateJobAssessmentDimensionArgs = {
  data: JobAssessmentDimensionInput;
  id: Scalars['String'];
};


export type MutationUpdateJobStatusArgs = {
  data: JobStatusDto;
  id: Scalars['String'];
};


export type MutationUpdateMeetingArgs = {
  data: MeetingInput;
  id: Scalars['String'];
};


export type MutationUpdateNoteArgs = {
  data: NoteInput;
  id: Scalars['String'];
};


export type MutationUpdateNotificationArgs = {
  data: NotificationInput;
  id: Scalars['String'];
};


export type MutationUpdatePositionArgs = {
  data: PositionInput;
  id: Scalars['String'];
};


export type MutationUpdateProjectArgs = {
  data: ProjectInput;
  id: Scalars['String'];
};


export type MutationUpdateRevisionActionArgs = {
  data: EngagementRevisionActionInput;
  id: Scalars['String'];
};


export type MutationUpdateSkillArgs = {
  data: SkillInput;
  id: Scalars['String'];
};


export type MutationUpdateTalentArgs = {
  data: TalentInput;
  id: Scalars['String'];
};


export type MutationUpdateTalentAssessmentArgs = {
  data: TalentAssessmentInput;
  id: Scalars['String'];
};


export type MutationUpdateTalentAssessmentDimensionArgs = {
  data: TalentAssessmentDimensionInput;
  id: Scalars['String'];
};


export type MutationUpdateTalentAvailabilityArgs = {
  token: Scalars['String'];
};


export type MutationUpdateTalentProfileArgs = {
  data: TalentProfileUpdateInput;
  id: Scalars['String'];
};


export type MutationUpdateTalentStatusArgs = {
  data: TalentStatusInput;
  id: Scalars['String'];
};


export type MutationUpdateTimeEntryArgs = {
  data: TimeEntryInput;
  id: Scalars['String'];
};


export type MutationUploadTalentProfileArgs = {
  data: TalentProfileUploadInput;
};


export type MutationUsersAttachHubspotIdArgs = {
  userId: Scalars['String'];
};


export type MutationVerifyEmailArgs = {
  data: VerifyEmailInput;
};

export type Note = {
  __typename?: 'Note';
  airtableId?: Maybe<Scalars['String']>;
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['String']>;
  attendees?: Maybe<Array<User>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  contacts?: Maybe<Contact>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['String']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  hubspotId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']>;
  meeting?: Maybe<Meeting>;
  meetingId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['String']>;
  status?: Maybe<NoteStatus>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NoteType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NoteFilters = {
  companyId?: InputMaybe<Scalars['String']>;
  engagementId?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  jobId?: InputMaybe<Scalars['String']>;
  jobOrProject?: InputMaybe<JobOrProjectFilter>;
  meetingId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<NoteType>>;
};

export type NoteInput = {
  airtableId?: InputMaybe<Scalars['String']>;
  assignee?: InputMaybe<UserInput>;
  assigneeId?: InputMaybe<Scalars['String']>;
  attendees?: InputMaybe<Array<UserInput>>;
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<ContactInput>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserInput>;
  creatorId?: InputMaybe<Scalars['String']>;
  dueAt?: InputMaybe<Scalars['DateTime']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  hubspotId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobInput>;
  jobId?: InputMaybe<Scalars['String']>;
  meeting?: InputMaybe<MeetingInput>;
  meetingId?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<ProjectInput>;
  projectId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<NoteStatus>;
  talent?: InputMaybe<TalentInput>;
  talentId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NoteType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum NoteStatus {
  Complete = 'Complete',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export enum NoteType {
  ClientCall = 'ClientCall',
  ClientDeepDive = 'ClientDeepDive',
  ClientDiscovery = 'ClientDiscovery',
  ClientNote = 'ClientNote',
  GeneralFeedback = 'GeneralFeedback',
  Interview = 'Interview',
  Matching = 'Matching',
  Other = 'Other',
  Screening = 'Screening',
  TalentCall = 'TalentCall',
  TalentNote = 'TalentNote'
}

export type Notification = {
  __typename?: 'Notification';
  checkedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActionable: Scalars['Boolean'];
  isChecked?: Maybe<Scalars['Boolean']>;
  isViewed?: Maybe<Scalars['Boolean']>;
  target?: Maybe<NotificationTarget>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  viewedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationFilters = {
  target?: InputMaybe<NotificationTarget>;
  user?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type NotificationInput = {
  checkedAt?: InputMaybe<Scalars['DateTime']>;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActionable: Scalars['Boolean'];
  isChecked?: InputMaybe<Scalars['Boolean']>;
  isViewed?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<NotificationTarget>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
  viewedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum NotificationTarget {
  Client = 'Client',
  Fellow = 'Fellow',
  Internal = 'Internal',
  Talent = 'Talent'
}

export type OpenSlackConversationResponse = {
  __typename?: 'OpenSlackConversationResponse';
  channelId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Position = {
  __typename?: 'Position';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PositionFilters = {
  name?: InputMaybe<Scalars['String']>;
};

export type PositionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type Project = {
  __typename?: 'Project';
  communicationModel?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  em?: Maybe<User>;
  emId?: Maybe<Scalars['String']>;
  fellowType?: Maybe<ProjectFellowType>;
  hubspotDealId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  jobs?: Maybe<Array<Job>>;
  managementMethodology?: Maybe<Scalars['String']>;
  managementMethodologyOther?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onboardingProcess?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  platforms?: Maybe<Scalars['String']>;
  shortname?: Maybe<Scalars['String']>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills?: Maybe<Array<Skill>>;
  slackChannelId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<ProjectStatus>;
  teamEnvironment?: Maybe<Scalars['String']>;
  teamEnvironmentOther?: Maybe<Scalars['String']>;
  teamSize?: Maybe<Scalars['String']>;
  teamState?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ProjectFellowType {
  Partner = 'Partner',
  Referral = 'Referral'
}

export type ProjectFilters = {
  company?: InputMaybe<ProjectFiltersCompany>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProjectFiltersCompany = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProjectInput = {
  communicationModel?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<ContactInput>;
  contactId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  em?: InputMaybe<UserInput>;
  emId?: InputMaybe<Scalars['String']>;
  fellowType?: InputMaybe<ProjectFellowType>;
  hubspotDealId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  jobs?: InputMaybe<Array<JobInput>>;
  managementMethodology?: InputMaybe<Scalars['String']>;
  managementMethodologyOther?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  onboardingProcess?: InputMaybe<Scalars['String']>;
  phase?: InputMaybe<Scalars['String']>;
  platforms?: InputMaybe<Scalars['String']>;
  shortname?: InputMaybe<Scalars['String']>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  skills?: InputMaybe<Array<SkillInput>>;
  slackChannelId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectStatus>;
  teamEnvironment?: InputMaybe<Scalars['String']>;
  teamEnvironmentOther?: InputMaybe<Scalars['String']>;
  teamSize?: InputMaybe<Scalars['String']>;
  teamState?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum ProjectStatus {
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Published = 'Published'
}

export type PublicProfile = {
  __typename?: 'PublicProfile';
  accomplishment?: Maybe<Scalars['String']>;
  canTravel?: Maybe<Scalars['Boolean']>;
  canWorkOnsite?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countryId: Scalars['String'];
  employmentYearlySalary?: Maybe<Scalars['Float']>;
  firstName: Scalars['String'];
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interestedInEmployment?: Maybe<Scalars['Boolean']>;
  interestedInFreelance?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<TalentProfileItem>>;
  languages?: Maybe<Array<Language>>;
  lastName: Scalars['String'];
  profilePicUrl?: Maybe<Scalars['String']>;
  setup?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<PublicProfileSkill>>;
  talentId: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  topVettedSkills?: Maybe<Array<PublicProfileSkill>>;
  verificationSteps?: Maybe<Array<VerificationStep>>;
};

export type PublicProfileSkill = {
  __typename?: 'PublicProfileSkill';
  seniority?: Maybe<DimensionSeniority>;
  skill?: Maybe<Skill>;
  type?: Maybe<TalentSkillType>;
  years?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  agencies: Array<Agency>;
  agency?: Maybe<Agency>;
  assessmentSections: Array<AssessmentSection>;
  assessmentTemplates: Array<AssessmentTemplate>;
  calendlyEvent?: Maybe<CalendlyEvent>;
  challenges: Array<Challenge>;
  clientEngagementFeedback: TalentAssessment;
  companies: Array<Company>;
  companiesHubspotSearch?: Maybe<CompanyHubspotSearchResults>;
  company?: Maybe<Company>;
  companySummary?: Maybe<CompanySummary>;
  companyTimesheetTotals: TimesheetTotals;
  contacts: Array<Contact>;
  countries: Array<Country>;
  country?: Maybe<Country>;
  engagement?: Maybe<Engagement>;
  engagementRevisions: Array<EngagementRevision>;
  engagements: Array<Engagement>;
  engagementsCount: Scalars['Float'];
  engagementsLastRevision: Array<EngagementLastRevision>;
  engagementsLastRevisionCount: Scalars['Float'];
  getAgencyFrameworkAgreementEmailTemplate: RenderedTemplateResponseDto;
  getCompanyMasterServiceAgreementEmailTemplate: RenderedTemplateResponseDto;
  getEngagementFeedbackUrl: Scalars['String'];
  getEngagementProjectAgreementEmailTemplate: RenderedTemplateResponseDto;
  getEngagementProjectAgreementTitleTemplate: RenderedTemplateResponseDto;
  getEngagementStatementOfWorkEmailTemplate: RenderedTemplateResponseDto;
  getEngagementStatementOfWorkTitleTemplate: RenderedTemplateResponseDto;
  getTalentFrameworkAgreementEmailTemplate: RenderedTemplateResponseDto;
  hubspotJob: HubspotDealToCoreJob;
  invitation: InvitationDto;
  invitations: Array<InvitationDto>;
  invoice?: Maybe<InvoiceOutgoing>;
  invoices: Array<InvoiceOutgoing>;
  job?: Maybe<Job>;
  jobAssessment: JobAssessment;
  jobAssessments: Array<JobAssessment>;
  jobPerformanceAnalytics: JobPerformanceAnalytics;
  jobStatusChangeEmailTemplates: Array<JobStatusChangeEmailTemplateDto>;
  jobs: Array<Job>;
  jobsCount: Scalars['Float'];
  jobsForBoard: Array<Job>;
  jobsGrouped: Array<Job>;
  jobsGroupedCount: JobGroupedCount;
  jobsHubspotSearch?: Maybe<JobHubspotSearchResults>;
  language?: Maybe<Language>;
  languages: Array<Language>;
  me?: Maybe<User>;
  meeting?: Maybe<Meeting>;
  meetings: Array<Meeting>;
  notes: Array<Note>;
  notification?: Maybe<Notification>;
  notifications: Array<Notification>;
  position?: Maybe<Position>;
  positions: Array<Position>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  projectsGenerateSlackChannelName: Scalars['String'];
  referrals: Array<Referral>;
  referralsCount: Scalars['Float'];
  renderTemplate: RenderedTemplateResponseDto;
  reportingExport: Array<ReportingExport>;
  reportingExportAsCsvString: Scalars['String'];
  searchLanguages: Array<Language>;
  searchSkills: Array<Skill>;
  skill?: Maybe<Skill>;
  skills: Array<Skill>;
  tags: Array<Tag>;
  talent?: Maybe<Talent>;
  talentAssessment: TalentAssessment;
  talentAssessments: Array<TalentAssessment>;
  talentExists: Scalars['Boolean'];
  talentList: TalentList;
  talentProfile?: Maybe<TalentProfile>;
  talentProfiles: Array<TalentProfile>;
  talentPublicEngagementProfile?: Maybe<EngagementPublicProfile>;
  talentPublicProfile?: Maybe<PublicProfile>;
  talentScoreCard?: Maybe<TalentScoreCard>;
  talentSignupEmailExists?: Maybe<Scalars['Boolean']>;
  talents: Array<Talent>;
  talentsCount: Scalars['Float'];
  talentsGrouped: GroupedTalents;
  templates: Array<Template>;
  timeEntries: Array<TimeEntry>;
  timesheets: Array<Timesheet>;
  user?: Maybe<User>;
  userExists?: Maybe<Scalars['Boolean']>;
  users: Array<User>;
  whatsToday?: Maybe<Array<WhatsToday>>;
  whatsTodayRandom?: Maybe<WhatsToday>;
};


export type QueryAgenciesArgs = {
  filters?: InputMaybe<AgencyFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryAgencyArgs = {
  id: Scalars['String'];
};


export type QueryAssessmentSectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryAssessmentTemplatesArgs = {
  filters?: InputMaybe<AssessmentTemplateFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryCalendlyEventArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryChallengesArgs = {
  filters?: InputMaybe<ChallengesFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryCompaniesArgs = {
  filters?: InputMaybe<CompanyFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryCompaniesHubspotSearchArgs = {
  term: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['String'];
};


export type QueryCompanyTimesheetTotalsArgs = {
  filters?: InputMaybe<TimesheetOverviewFilters>;
};


export type QueryContactsArgs = {
  filters?: InputMaybe<ContactFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryCountriesArgs = {
  filters?: InputMaybe<CountryFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryCountryArgs = {
  id: Scalars['String'];
};


export type QueryEngagementArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryEngagementRevisionsArgs = {
  filters?: InputMaybe<EngagementRevisionFiltersInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryEngagementsArgs = {
  filters?: InputMaybe<EngagementFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryEngagementsCountArgs = {
  filters?: InputMaybe<EngagementFilters>;
};


export type QueryEngagementsLastRevisionArgs = {
  filters?: InputMaybe<EngagementLastRevisionFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryEngagementsLastRevisionCountArgs = {
  filters?: InputMaybe<EngagementLastRevisionFilters>;
};


export type QueryGetAgencyFrameworkAgreementEmailTemplateArgs = {
  agencyId: Scalars['String'];
};


export type QueryGetCompanyMasterServiceAgreementEmailTemplateArgs = {
  companyId: Scalars['String'];
};


export type QueryGetEngagementFeedbackUrlArgs = {
  engagementId: Scalars['String'];
};


export type QueryGetEngagementProjectAgreementEmailTemplateArgs = {
  engagementId: Scalars['String'];
};


export type QueryGetEngagementProjectAgreementTitleTemplateArgs = {
  engagementId: Scalars['String'];
};


export type QueryGetEngagementStatementOfWorkEmailTemplateArgs = {
  engagementId: Scalars['String'];
};


export type QueryGetEngagementStatementOfWorkTitleTemplateArgs = {
  engagementId: Scalars['String'];
};


export type QueryGetTalentFrameworkAgreementEmailTemplateArgs = {
  talentId: Scalars['String'];
};


export type QueryHubspotJobArgs = {
  hubspotId: Scalars['String'];
};


export type QueryInvitationArgs = {
  token: Scalars['String'];
};


export type QueryInvitationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryInvoicesArgs = {
  filters?: InputMaybe<InvoiceOutgoingFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryJobArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryJobAssessmentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryJobAssessmentsArgs = {
  filters?: InputMaybe<JobAssessmentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryJobPerformanceAnalyticsArgs = {
  jobId: Scalars['String'];
};


export type QueryJobsArgs = {
  filters?: InputMaybe<JobFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryJobsCountArgs = {
  filters?: InputMaybe<JobFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryJobsForBoardArgs = {
  filters?: InputMaybe<JobFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryJobsGroupedArgs = {
  filters?: InputMaybe<JobFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryJobsGroupedCountArgs = {
  filters?: InputMaybe<JobFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryJobsHubspotSearchArgs = {
  term: Scalars['String'];
};


export type QueryLanguageArgs = {
  id: Scalars['String'];
};


export type QueryLanguagesArgs = {
  filters?: InputMaybe<LanguageFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryMeetingArgs = {
  id: Scalars['String'];
};


export type QueryMeetingsArgs = {
  filters?: InputMaybe<MeetingFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryNotesArgs = {
  filters?: InputMaybe<NoteFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryNotificationArgs = {
  id: Scalars['String'];
};


export type QueryNotificationsArgs = {
  filters?: InputMaybe<NotificationFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryPositionArgs = {
  id: Scalars['String'];
};


export type QueryPositionsArgs = {
  filters?: InputMaybe<PositionFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryProjectArgs = {
  id: Scalars['String'];
};


export type QueryProjectsArgs = {
  filters?: InputMaybe<ProjectFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryProjectsGenerateSlackChannelNameArgs = {
  id: Scalars['String'];
};


export type QueryReferralsArgs = {
  filters?: InputMaybe<ReferralFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryReferralsCountArgs = {
  filters?: InputMaybe<ReferralFilters>;
};


export type QueryRenderTemplateArgs = {
  args?: InputMaybe<Scalars['JSONObject']>;
  key: Scalars['String'];
};


export type QueryReportingExportArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


export type QueryReportingExportAsCsvStringArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};


export type QuerySearchLanguagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  offset?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchSkillsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  offset?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<SkillType>;
};


export type QuerySkillArgs = {
  id: Scalars['String'];
};


export type QuerySkillsArgs = {
  filters?: InputMaybe<SkillFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTagsArgs = {
  filters?: InputMaybe<TagFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTalentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryTalentAssessmentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryTalentAssessmentsArgs = {
  filters?: InputMaybe<TalentAssessmentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTalentExistsArgs = {
  email: Scalars['String'];
};


export type QueryTalentListArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTalentProfileArgs = {
  dynamicId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryTalentProfilesArgs = {
  filters?: InputMaybe<TalentProfileFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTalentPublicEngagementProfileArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryTalentPublicProfileArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryTalentScoreCardArgs = {
  talentId: Scalars['String'];
};


export type QueryTalentSignupEmailExistsArgs = {
  email: Scalars['String'];
};


export type QueryTalentsArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTalentsCountArgs = {
  filters?: InputMaybe<TalentFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryTemplatesArgs = {
  filters?: InputMaybe<TemplateFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTimeEntriesArgs = {
  filters?: InputMaybe<TimeEntriesFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};


export type QueryTimesheetsArgs = {
  filters?: InputMaybe<TimesheetOverviewFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserExistsArgs = {
  email: Scalars['String'];
};


export type QueryUsersArgs = {
  filters?: InputMaybe<UserFilters>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['JSONObject']>;
};

export type Referral = {
  __typename?: 'Referral';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']>;
  recipientEmail?: Maybe<Scalars['String']>;
  recipientUser?: Maybe<User>;
  recipientUserId?: Maybe<Scalars['String']>;
  status?: Maybe<ReferralStatus>;
  type?: Maybe<ReferralType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReferralFilters = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type ReferralInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  job?: InputMaybe<JobInput>;
  jobId?: InputMaybe<Scalars['String']>;
  recipientEmail?: InputMaybe<Scalars['String']>;
  recipientUser?: InputMaybe<UserInput>;
  recipientUserId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReferralStatus>;
  type?: InputMaybe<ReferralType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum ReferralStatus {
  Active = 'Active',
  Hired = 'Hired',
  Pending = 'Pending',
  Registered = 'Registered',
  Rejected = 'Rejected'
}

export enum ReferralType {
  ScoutReferral = 'ScoutReferral',
  TalentReferral = 'TalentReferral'
}

export type RenderedMetadataDto = {
  __typename?: 'RenderedMetadataDto';
  subject?: Maybe<Scalars['String']>;
};

export type RenderedTemplateResponseDto = {
  __typename?: 'RenderedTemplateResponseDto';
  renderedMetadata: RenderedMetadataDto;
  renderedTemplate: Scalars['String'];
  template: Template;
};

export type ReportingExport = {
  __typename?: 'ReportingExport';
  Deal_Close_Date?: Maybe<Scalars['String']>;
  Deal_end_date?: Maybe<Scalars['String']>;
  Deal_start_date?: Maybe<Scalars['String']>;
  Late_invoices_included?: Maybe<Scalars['Boolean']>;
  Talent?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingCountry?: Maybe<Scalars['String']>;
  billingStreet?: Maybe<Scalars['String']>;
  billingZIP?: Maybe<Scalars['String']>;
  clientHoursSent?: Maybe<Scalars['Float']>;
  clientRate?: Maybe<Scalars['Float']>;
  clientRate_VAT?: Maybe<Scalars['Float']>;
  clientValue_calc?: Maybe<Scalars['Float']>;
  clientValue_calc_VAT?: Maybe<Scalars['Float']>;
  clientVatPercentage?: Maybe<Scalars['Float']>;
  companyAcquisitionDate?: Maybe<Scalars['String']>;
  companyFullName?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  companySize?: Maybe<Scalars['String']>;
  dealChannel?: Maybe<Scalars['String']>;
  dealID?: Maybe<Scalars['String']>;
  dealName?: Maybe<Scalars['String']>;
  dealType?: Maybe<Scalars['String']>;
  eaEndDate?: Maybe<Scalars['String']>;
  eaStartDate?: Maybe<Scalars['String']>;
  engagementActualEnd?: Maybe<Scalars['String']>;
  engagementActualStartDate?: Maybe<Scalars['String']>;
  engagementID?: Maybe<Scalars['String']>;
  engagement_BaseID?: Maybe<Scalars['String']>;
  engagements_extended_times?: Maybe<Scalars['String']>;
  fellowID_reporting?: Maybe<Scalars['String']>;
  fellowRate?: Maybe<Scalars['Float']>;
  fellowRate_VAT?: Maybe<Scalars['Float']>;
  fellowValue_calc?: Maybe<Scalars['Float']>;
  fellowValue_calc_onValues?: Maybe<Scalars['Float']>;
  fellowValue_calc_onValues_VAT?: Maybe<Scalars['Float']>;
  invoiceCode?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  jobName?: Maybe<Scalars['String']>;
  monthCalc?: Maybe<Scalars['String']>;
  referralPercentage?: Maybe<Scalars['Float']>;
  talentHoursLogged?: Maybe<Scalars['Float']>;
  talentID_reporting?: Maybe<Scalars['String']>;
  talentRate?: Maybe<Scalars['Float']>;
  talentRate_VAT?: Maybe<Scalars['Float']>;
  talentValue_calc?: Maybe<Scalars['Float']>;
  talentValue_calc_VAT?: Maybe<Scalars['Float']>;
  talentVatPercentage?: Maybe<Scalars['Float']>;
  totalDaysStartToEnd?: Maybe<Scalars['Float']>;
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type Rfs = {
  company: CompanyInput;
  contact: ContactInput;
  jobs: Array<JobInput>;
  project: ProjectInput;
};

export type Role = {
  __typename?: 'Role';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<RoleKey>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<RoleKey>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum RoleKey {
  Admin = 'Admin',
  AgencyOwner = 'AgencyOwner',
  Any = 'Any',
  AnyAuthenticated = 'AnyAuthenticated',
  Bdm = 'BDM',
  Csm = 'CSM',
  Client = 'Client',
  ClientEngagementFeedbackInvitee = 'ClientEngagementFeedbackInvitee',
  Em = 'EM',
  Employee = 'Employee',
  Expert = 'Expert',
  ExternalExpert = 'ExternalExpert',
  ExternalSourcer = 'ExternalSourcer',
  Fellow = 'Fellow',
  Finance = 'Finance',
  None = 'None',
  Scout = 'Scout',
  Sourcer = 'Sourcer',
  StudioTalent = 'StudioTalent',
  Talent = 'Talent',
  TechnicalInterviewer = 'TechnicalInterviewer'
}

export type Skill = {
  __typename?: 'Skill';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  mappedTo?: Maybe<Skill>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<SkillType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SkillFilters = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  isMapped?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SkillType>;
};

export type SkillInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  mappedTo?: InputMaybe<SkillInput>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SkillType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum SkillType {
  Framework = 'Framework',
  Industry = 'Industry',
  Language = 'Language',
  Library = 'Library',
  Methodology = 'Methodology',
  Other = 'Other',
  Paradigm = 'Paradigm',
  PersonalCharacteristic = 'PersonalCharacteristic',
  Platform = 'Platform',
  Tool = 'Tool'
}

export enum SkillsCondition {
  All = 'All',
  Any = 'Any'
}

export type Tag = {
  __typename?: 'Tag';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TagType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TagFilters = {
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TagType>;
};

export type TagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TagType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum TagType {
  Job = 'Job',
  Talent = 'Talent'
}

export type Talent = {
  __typename?: 'Talent';
  agency?: Maybe<Agency>;
  agencyId?: Maybe<Scalars['String']>;
  appliedJob?: Maybe<Job>;
  appliedJobId?: Maybe<Scalars['String']>;
  assessments?: Maybe<Array<TalentAssessment>>;
  assets?: Maybe<Array<TalentAsset>>;
  availability?: Maybe<TalentAvailability>;
  availabilityComment?: Maybe<Scalars['String']>;
  availabilityUpdatedAt?: Maybe<Scalars['DateTime']>;
  canTravel?: Maybe<Scalars['Boolean']>;
  canWorkOnsite?: Maybe<Scalars['Boolean']>;
  certifications?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Scalars['String']>;
  completedProfileSteps?: Maybe<Array<TalentProfileSteps>>;
  country?: Maybe<Country>;
  countryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  daysInCurrentStatus?: Maybe<Scalars['Float']>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employmentYearlySalary?: Maybe<Scalars['Float']>;
  engagements?: Maybe<Array<Engagement>>;
  expectedIncome?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fwaLink?: Maybe<Scalars['String']>;
  hasEngagementsOnJob?: Maybe<Scalars['Boolean']>;
  hasFreelancerAppAccess?: Maybe<Scalars['Boolean']>;
  hasUser?: Maybe<Scalars['Boolean']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  hourlyRateUpdatedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  interestedInEmployment?: Maybe<Scalars['Boolean']>;
  interestedInFreelance?: Maybe<Scalars['Boolean']>;
  isClean?: Maybe<Scalars['Boolean']>;
  isFwaSigned?: Maybe<Scalars['Boolean']>;
  languageIds?: Maybe<Array<Scalars['String']>>;
  languages?: Maybe<Array<Language>>;
  lastCleanedAt?: Maybe<Scalars['DateTime']>;
  lastCleanedBy?: Maybe<User>;
  lastCleanedById?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  links?: Maybe<TalentOtherLinks>;
  matchRate?: Maybe<Scalars['Float']>;
  minimumHourlyRate?: Maybe<Scalars['Float']>;
  nextAvailability?: Maybe<Scalars['DateTime']>;
  notes?: Maybe<Array<Note>>;
  onlineLink?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  positions?: Maybe<Array<Position>>;
  primaryPosition?: Maybe<Position>;
  primaryPositionId?: Maybe<Scalars['String']>;
  profileItems?: Maybe<Array<TalentProfileItem>>;
  profilePicFileLocation?: Maybe<Scalars['String']>;
  profilePicFileMimeType?: Maybe<Scalars['String']>;
  profilePicFileName?: Maybe<Scalars['String']>;
  profilePicFileSize?: Maybe<Scalars['Float']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  profiles?: Maybe<Array<TalentProfile>>;
  rateNotes?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['String']>;
  referralData?: Maybe<Referral>;
  referralId?: Maybe<Scalars['String']>;
  referralSource?: Maybe<Scalars['String']>;
  rejectionReasons?: Maybe<Array<Scalars['String']>>;
  showcaseItems?: Maybe<Array<TalentProfileShowcaseItem>>;
  slackLink?: Maybe<Scalars['String']>;
  source?: Maybe<TalentSource>;
  sourcedBy?: Maybe<User>;
  sourcedById?: Maybe<Scalars['String']>;
  status?: Maybe<TalentStatus>;
  statusExplanation?: Maybe<Scalars['String']>;
  statusHistory?: Maybe<Array<TalentStatusHistory>>;
  tags?: Maybe<Array<Tag>>;
  talentSkills?: Maybe<Array<TalentSkill>>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  vatId?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};


export type TalentHasEngagementsOnJobArgs = {
  jobId: Scalars['String'];
};

export type TalentAssessment = {
  __typename?: 'TalentAssessment';
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['String']>;
  authorName?: Maybe<Scalars['String']>;
  authorRole?: Maybe<Scalars['String']>;
  challenge?: Maybe<Challenge>;
  challengeId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  conclusion?: Maybe<AssessmentConclusion>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<TalentAssessmentDimension>>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalNotes?: Maybe<Scalars['String']>;
  noteId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  sendNotifications?: Maybe<Scalars['Boolean']>;
  status?: Maybe<AssessmentStatus>;
  tags?: Maybe<Array<Tag>>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<AssessmentType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type TalentAssessmentDimension = {
  __typename?: 'TalentAssessmentDimension';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dimension?: Maybe<Dimension>;
  dimensionId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  required?: Maybe<Scalars['Boolean']>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['String']>;
  talentAssessment?: Maybe<TalentAssessment>;
  talentAssessmentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type TalentAssessmentDimensionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<DimensionInput>;
  dimensionId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageInput>;
  languageId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  required?: InputMaybe<Scalars['Boolean']>;
  skill?: InputMaybe<SkillInput>;
  skillId?: InputMaybe<Scalars['String']>;
  talentAssessment?: InputMaybe<TalentAssessmentInput>;
  talentAssessmentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type TalentAssessmentFilters = {
  conclusions?: InputMaybe<Array<AssessmentConclusion>>;
  engagementId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<AssessmentStatus>>;
  talentId?: InputMaybe<Scalars['String']>;
  talentName?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<AssessmentType>>;
};

export type TalentAssessmentInput = {
  assignee?: InputMaybe<UserInput>;
  assigneeId?: InputMaybe<Scalars['String']>;
  authorName?: InputMaybe<Scalars['String']>;
  authorRole?: InputMaybe<Scalars['String']>;
  challenge?: InputMaybe<ChallengeInput>;
  challengeId?: InputMaybe<Scalars['String']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  conclusion?: InputMaybe<AssessmentConclusion>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<Array<TalentAssessmentDimensionInput>>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  internalNotes?: InputMaybe<Scalars['String']>;
  noteId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  sendNotifications?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<AssessmentStatus>;
  tags?: InputMaybe<Array<TagInput>>;
  talent?: InputMaybe<TalentInput>;
  talentId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssessmentType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type TalentAsset = {
  __typename?: 'TalentAsset';
  createdAt?: Maybe<Scalars['DateTime']>;
  fileLocation?: Maybe<Scalars['String']>;
  fileMimeType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  type: TalentAssetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type TalentAssetInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileLocation?: InputMaybe<Scalars['String']>;
  fileMimeType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  talent?: InputMaybe<TalentInput>;
  talentId?: InputMaybe<Scalars['String']>;
  type: TalentAssetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export enum TalentAssetType {
  Challenge = 'Challenge',
  Document = 'Document',
  Picture = 'Picture',
  Portfolio = 'Portfolio',
  ProfilePicture = 'ProfilePicture',
  Resume = 'Resume'
}

export type TalentAttachHarvestUserOutput = {
  __typename?: 'TalentAttachHarvestUserOutput';
  harvestId?: Maybe<Scalars['String']>;
  talent?: Maybe<Talent>;
};

export enum TalentAvailability {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
  Unavailable = 'Unavailable'
}

export type TalentCreateHarvestUserOutput = {
  __typename?: 'TalentCreateHarvestUserOutput';
  harvestId?: Maybe<Scalars['String']>;
  talent?: Maybe<Talent>;
};

export type TalentFilters = {
  agencyIds?: InputMaybe<Array<Scalars['String']>>;
  airtableId?: InputMaybe<Scalars['String']>;
  availability?: InputMaybe<TalentAvailability>;
  cityName?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  countryIds?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  emailLike?: InputMaybe<Scalars['String']>;
  excludeByJobId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstNameLike?: InputMaybe<Scalars['String']>;
  hasAgency?: InputMaybe<Scalars['Boolean']>;
  hasAssessmentsCompleted?: InputMaybe<Array<AssessmentType>>;
  hasAssessmentsNotCompleted?: InputMaybe<Array<AssessmentType>>;
  hasPublicProfile?: InputMaybe<Scalars['Boolean']>;
  hourlyRateMax?: InputMaybe<Scalars['Float']>;
  hourlyRateMin?: InputMaybe<Scalars['Float']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  interestedInEmployment?: InputMaybe<Scalars['Boolean']>;
  interestedInFreelance?: InputMaybe<Scalars['Boolean']>;
  jobIds?: InputMaybe<Array<Scalars['String']>>;
  jobSkills?: InputMaybe<TalentJobMatchFilters>;
  languageIds?: InputMaybe<Array<Scalars['String']>>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNameLike?: InputMaybe<Scalars['String']>;
  linkedinLinkLike?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nameLike?: InputMaybe<Scalars['String']>;
  orEmail?: InputMaybe<Scalars['String']>;
  orEmailLike?: InputMaybe<Scalars['String']>;
  orLinkedinLinkLike?: InputMaybe<Scalars['String']>;
  ownerIds?: InputMaybe<Array<Scalars['String']>>;
  positionIds?: InputMaybe<Array<Scalars['String']>>;
  skillGroups?: InputMaybe<Array<TalentFiltersSkillGroupInput>>;
  sourcedByIds?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<TalentStatus>>;
};

export type TalentFiltersSkillGroupInput = {
  condition: SkillsCondition;
  skills: Array<TalentSkillFilterInput>;
};

export type TalentInput = {
  agency?: InputMaybe<AgencyInput>;
  agencyId?: InputMaybe<Scalars['String']>;
  appliedJob?: InputMaybe<JobInput>;
  appliedJobId?: InputMaybe<Scalars['String']>;
  assessments?: InputMaybe<Array<TalentAssessmentInput>>;
  assets?: InputMaybe<Array<TalentAssetInput>>;
  availability?: InputMaybe<TalentAvailability>;
  availabilityComment?: InputMaybe<Scalars['String']>;
  availabilityUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  canTravel?: InputMaybe<Scalars['Boolean']>;
  canWorkOnsite?: InputMaybe<Scalars['Boolean']>;
  certifications?: InputMaybe<Array<Scalars['String']>>;
  city?: InputMaybe<Scalars['String']>;
  completedProfileSteps?: InputMaybe<Array<TalentProfileSteps>>;
  country?: InputMaybe<CountryInput>;
  countryId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  daysInCurrentStatus?: InputMaybe<Scalars['Float']>;
  educationLevel?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employmentYearlySalary?: InputMaybe<Scalars['Float']>;
  engagements?: InputMaybe<Array<EngagementInput>>;
  expectedIncome?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fwaLink?: InputMaybe<Scalars['String']>;
  hasEngagementsOnJob?: InputMaybe<Scalars['Boolean']>;
  hasFreelancerAppAccess?: InputMaybe<Scalars['Boolean']>;
  hasUser?: InputMaybe<Scalars['Boolean']>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  hourlyRateUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  interestedInEmployment?: InputMaybe<Scalars['Boolean']>;
  interestedInFreelance?: InputMaybe<Scalars['Boolean']>;
  isClean?: InputMaybe<Scalars['Boolean']>;
  isFwaSigned?: InputMaybe<Scalars['Boolean']>;
  languageIds?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<LanguageInput>>;
  lastCleanedAt?: InputMaybe<Scalars['DateTime']>;
  lastCleanedBy?: InputMaybe<UserInput>;
  lastCleanedById?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedinLink?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<TalentOtherLinksInput>;
  matchRate?: InputMaybe<Scalars['Float']>;
  minimumHourlyRate?: InputMaybe<Scalars['Float']>;
  nextAvailability?: InputMaybe<Scalars['DateTime']>;
  notes?: InputMaybe<Array<NoteInput>>;
  onlineLink?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserInput>;
  ownerId?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  positions?: InputMaybe<Array<PositionInput>>;
  primaryPosition?: InputMaybe<PositionInput>;
  primaryPositionId?: InputMaybe<Scalars['String']>;
  profileItems?: InputMaybe<Array<TalentProfileItemInput>>;
  profilePicFileLocation?: InputMaybe<Scalars['String']>;
  profilePicFileMimeType?: InputMaybe<Scalars['String']>;
  profilePicFileName?: InputMaybe<Scalars['String']>;
  profilePicFileSize?: InputMaybe<Scalars['Float']>;
  profilePicUrl?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<Array<TalentProfileInput>>;
  rateNotes?: InputMaybe<Scalars['String']>;
  referral?: InputMaybe<Scalars['String']>;
  referralData?: InputMaybe<ReferralInput>;
  referralId?: InputMaybe<Scalars['String']>;
  referralSource?: InputMaybe<Scalars['String']>;
  rejectionReasons?: InputMaybe<Array<Scalars['String']>>;
  showcaseItems?: InputMaybe<Array<TalentProfileShowcaseItemInput>>;
  slackLink?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<TalentSource>;
  sourcedBy?: InputMaybe<UserInput>;
  sourcedById?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TalentStatus>;
  statusExplanation?: InputMaybe<Scalars['String']>;
  statusHistory?: InputMaybe<Array<TalentStatusHistoryInput>>;
  tags?: InputMaybe<Array<TagInput>>;
  talentSkills?: InputMaybe<Array<TalentSkillInput>>;
  timezone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type TalentJobMatchFilters = {
  niceToHaveSkills?: InputMaybe<Array<Scalars['String']>>;
  requiredSkills: Array<Scalars['String']>;
};

export type TalentList = {
  __typename?: 'TalentList';
  results: Array<Talent>;
  total: Scalars['Float'];
};

export type TalentOtherLinks = {
  __typename?: 'TalentOtherLinks';
  github?: Maybe<Scalars['String']>;
  other?: Maybe<Array<Scalars['String']>>;
};

export type TalentOtherLinksInput = {
  github?: InputMaybe<Scalars['String']>;
  other?: InputMaybe<Array<Scalars['String']>>;
};

export type TalentProfile = {
  __typename?: 'TalentProfile';
  accomplishment?: Maybe<Scalars['String']>;
  availability?: Maybe<Scalars['DateTime']>;
  canTravel?: Maybe<Scalars['Boolean']>;
  canWorkOnsite?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  hourlyRate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isFreelancerShareableProfile?: Maybe<Scalars['Boolean']>;
  isHotProfile?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<TalentProfileItem>>;
  /** @deprecated Backwards compatibility */
  itemsString?: Maybe<Scalars['String']>;
  profilePicFileLocation?: Maybe<Scalars['String']>;
  profilePicFileMimeType?: Maybe<Scalars['String']>;
  profilePicFileName?: Maybe<Scalars['String']>;
  profilePicFileSize?: Maybe<Scalars['Float']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  setup?: Maybe<Scalars['String']>;
  showcaseItems?: Maybe<Array<TalentProfileShowcaseItem>>;
  /** @deprecated Backwards compatibility */
  showcaseItemsString?: Maybe<Scalars['String']>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<TalentProfileStatus>;
  talent?: Maybe<Talent>;
  talentAirtableId?: Maybe<Scalars['String']>;
  talentId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topVettedSkills?: Maybe<Array<TalentProfileTopVettedSkill>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  verificationStepOptions?: Maybe<Scalars['String']>;
};

export type TalentProfileCreateInput = {
  accomplishment?: InputMaybe<Scalars['String']>;
  availability?: InputMaybe<Scalars['DateTime']>;
  canTravel?: InputMaybe<Scalars['Boolean']>;
  canWorkOnsite?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  hourlyRate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isFreelancerShareableProfile?: InputMaybe<Scalars['Boolean']>;
  isHotProfile?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<TalentProfileItemInput>>;
  itemsString?: InputMaybe<Scalars['String']>;
  profilePicFileLocation?: InputMaybe<Scalars['String']>;
  profilePicFileMimeType?: InputMaybe<Scalars['String']>;
  profilePicFileName?: InputMaybe<Scalars['String']>;
  profilePicFileSize?: InputMaybe<Scalars['Float']>;
  profilePicUrl?: InputMaybe<Scalars['String']>;
  setup?: InputMaybe<Scalars['String']>;
  showcaseItems?: InputMaybe<Array<TalentProfileShowcaseItemInput>>;
  showcaseItemsString?: InputMaybe<Scalars['String']>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TalentProfileStatus>;
  talent?: InputMaybe<TalentInput>;
  talentAirtableId?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  topVettedSkills?: InputMaybe<Array<TalentProfileTopVettedSkillInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verificationStepOptions?: InputMaybe<Scalars['String']>;
};

export type TalentProfileFilters = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  talentId?: InputMaybe<Scalars['String']>;
};

export type TalentProfileInput = {
  accomplishment?: InputMaybe<Scalars['String']>;
  availability?: InputMaybe<Scalars['DateTime']>;
  canTravel?: InputMaybe<Scalars['Boolean']>;
  canWorkOnsite?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  hourlyRate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isFreelancerShareableProfile?: InputMaybe<Scalars['Boolean']>;
  isHotProfile?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<TalentProfileItemInput>>;
  itemsString?: InputMaybe<Scalars['String']>;
  profilePicFileLocation?: InputMaybe<Scalars['String']>;
  profilePicFileMimeType?: InputMaybe<Scalars['String']>;
  profilePicFileName?: InputMaybe<Scalars['String']>;
  profilePicFileSize?: InputMaybe<Scalars['Float']>;
  profilePicUrl?: InputMaybe<Scalars['String']>;
  setup?: InputMaybe<Scalars['String']>;
  showcaseItems?: InputMaybe<Array<TalentProfileShowcaseItemInput>>;
  showcaseItemsString?: InputMaybe<Scalars['String']>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TalentProfileStatus>;
  talent?: InputMaybe<TalentInput>;
  talentAirtableId?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topVettedSkills?: InputMaybe<Array<TalentProfileTopVettedSkillInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verificationStepOptions?: InputMaybe<Scalars['String']>;
};

export type TalentProfileItem = {
  __typename?: 'TalentProfileItem';
  company?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  employment?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isHighlighted?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<TalentProfile>;
  profileId?: Maybe<Scalars['String']>;
  responsibilities?: Maybe<Array<Scalars['String']>>;
  skillIds?: Maybe<Array<Scalars['String']>>;
  skills?: Maybe<Array<Skill>>;
  start?: Maybe<Scalars['String']>;
  startYear?: Maybe<Scalars['Float']>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TalentProfileItemInput = {
  company?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  employment?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['String']>;
  endYear?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']>;
  profile?: InputMaybe<TalentProfileInput>;
  profileId?: InputMaybe<Scalars['String']>;
  responsibilities?: InputMaybe<Array<Scalars['String']>>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  skills?: InputMaybe<Array<SkillInput>>;
  start?: InputMaybe<Scalars['String']>;
  startYear?: InputMaybe<Scalars['Float']>;
  talent?: InputMaybe<TalentInput>;
  talentId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalentProfileShowcaseItem = {
  __typename?: 'TalentProfileShowcaseItem';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  pictures?: Maybe<Array<TalentProfileShowcaseItemPicture>>;
  profile?: Maybe<TalentProfile>;
  profileId?: Maybe<Scalars['String']>;
  talentAirtableId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TalentProfileShowcaseItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  pictures?: InputMaybe<Array<TalentProfileShowcaseItemPictureInput>>;
  profile?: InputMaybe<TalentProfileInput>;
  profileId?: InputMaybe<Scalars['String']>;
  talentAirtableId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalentProfileShowcaseItemPicture = {
  __typename?: 'TalentProfileShowcaseItemPicture';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  fileLocation?: Maybe<Scalars['String']>;
  fileMimeType?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Float']>;
  fileUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isMain?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  showcaseItem?: Maybe<TalentProfileShowcaseItem>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TalentProfileShowcaseItemPictureInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  fileLocation?: InputMaybe<Scalars['String']>;
  fileMimeType?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['Float']>;
  fileUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  showcaseItem?: InputMaybe<TalentProfileShowcaseItemInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum TalentProfileStatus {
  Complete = 'Complete',
  Disabled = 'Disabled',
  Draft = 'Draft',
  Outdated = 'Outdated'
}

export enum TalentProfileSteps {
  AvailabilityAndRates = 'AvailabilityAndRates',
  Intro = 'Intro',
  ProjectsAndExperience = 'ProjectsAndExperience',
  Skills = 'Skills',
  SocialProfiles = 'SocialProfiles'
}

export type TalentProfileTopVettedSkill = {
  __typename?: 'TalentProfileTopVettedSkill';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  profile?: Maybe<TalentProfile>;
  profileId?: Maybe<Scalars['String']>;
  talentSkill?: Maybe<TalentSkill>;
  talentSkillId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TalentProfileTopVettedSkillInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  profile?: InputMaybe<TalentProfileInput>;
  profileId?: InputMaybe<Scalars['String']>;
  talentSkill?: InputMaybe<TalentSkillInput>;
  talentSkillId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalentProfileUpdateInput = {
  accomplishment?: InputMaybe<Scalars['String']>;
  availability?: InputMaybe<Scalars['DateTime']>;
  canTravel?: InputMaybe<Scalars['Boolean']>;
  canWorkOnsite?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  hourlyRate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  isFreelancerShareableProfile?: InputMaybe<Scalars['Boolean']>;
  isHotProfile?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<TalentProfileItemInput>>;
  itemsString?: InputMaybe<Scalars['String']>;
  profilePicFileLocation?: InputMaybe<Scalars['String']>;
  profilePicFileMimeType?: InputMaybe<Scalars['String']>;
  profilePicFileName?: InputMaybe<Scalars['String']>;
  profilePicFileSize?: InputMaybe<Scalars['Float']>;
  profilePicUrl?: InputMaybe<Scalars['String']>;
  setup?: InputMaybe<Scalars['String']>;
  showcaseItems?: InputMaybe<Array<TalentProfileShowcaseItemInput>>;
  showcaseItemsString?: InputMaybe<Scalars['String']>;
  skillIds?: InputMaybe<Array<Scalars['String']>>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TalentProfileStatus>;
  talent?: InputMaybe<TalentInput>;
  talentAirtableId?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  topVettedSkills?: InputMaybe<Array<TalentProfileTopVettedSkillInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verificationStepOptions?: InputMaybe<Scalars['String']>;
};

export type TalentProfileUploadInput = {
  csv: Scalars['String'];
  isDefault: Scalars['Boolean'];
  talentId: Scalars['String'];
};

export type TalentProfileVerificationStepOptionsIcon = {
  __typename?: 'TalentProfileVerificationStepOptionsIcon';
  description: Scalars['Boolean'];
  externalLink: Scalars['Boolean'];
  externalLinkText: Scalars['Boolean'];
  icon: Scalars['String'];
  name: Scalars['Boolean'];
};

export type TalentProfileVerificationStepOptionsIconInput = {
  description: Scalars['Boolean'];
  externalLink: Scalars['Boolean'];
  externalLinkText: Scalars['Boolean'];
  icon: Scalars['String'];
  name: Scalars['Boolean'];
};

export type TalentScoreCard = {
  __typename?: 'TalentScoreCard';
  createdAt?: Maybe<Scalars['DateTime']>;
  dimensions?: Maybe<Array<TalentScoreCardDimension>>;
  id?: Maybe<Scalars['String']>;
  parent?: Maybe<TalentScoreCard>;
  parentId?: Maybe<Scalars['String']>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TalentScoreCardDimension = {
  __typename?: 'TalentScoreCardDimension';
  assessmentDimension?: Maybe<Dimension>;
  assessmentDimensionId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  history?: Maybe<Array<TalentScoreCardDimension>>;
  id?: Maybe<Scalars['String']>;
  language?: Maybe<Language>;
  languageId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  scoreCard?: Maybe<TalentScoreCard>;
  scoreCardId?: Maybe<Scalars['String']>;
  seniority?: Maybe<DimensionSeniority>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['String']>;
  talent?: Maybe<Talent>;
  talentAssessment?: Maybe<TalentAssessment>;
  talentAssessmentId?: Maybe<Scalars['String']>;
  talentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type TalentScoreCardDimensionInput = {
  assessmentDimension?: InputMaybe<DimensionInput>;
  assessmentDimensionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserInput>;
  createdById?: InputMaybe<Scalars['String']>;
  history?: InputMaybe<Array<TalentScoreCardDimensionInput>>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<LanguageInput>;
  languageId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Float']>;
  scoreCard?: InputMaybe<TalentScoreCardInput>;
  scoreCardId?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<DimensionSeniority>;
  skill?: InputMaybe<SkillInput>;
  skillId?: InputMaybe<Scalars['String']>;
  talent?: InputMaybe<TalentInput>;
  talentAssessment?: InputMaybe<TalentAssessmentInput>;
  talentAssessmentId?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type TalentScoreCardInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dimensions?: InputMaybe<Array<TalentScoreCardDimensionInput>>;
  id?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<TalentScoreCardInput>;
  parentId?: InputMaybe<Scalars['String']>;
  talent?: InputMaybe<TalentInput>;
  talentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalentSkill = {
  __typename?: 'TalentSkill';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['String']>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  type?: Maybe<TalentSkillType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  years?: Maybe<Scalars['Float']>;
};

export type TalentSkillFilterInput = {
  id?: InputMaybe<Scalars['String']>;
  years?: InputMaybe<Scalars['Float']>;
};

export type TalentSkillInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  skill?: InputMaybe<SkillInput>;
  skillId?: InputMaybe<Scalars['String']>;
  talent?: InputMaybe<TalentInput>;
  talentId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TalentSkillType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  years?: InputMaybe<Scalars['Float']>;
};

export enum TalentSkillType {
  Main = 'Main',
  Regular = 'Regular'
}

export enum TalentSource {
  Growth = 'Growth',
  Inbound = 'Inbound',
  PreVetted = 'PreVetted',
  Referred = 'Referred',
  Scout = 'Scout',
  Sourced = 'Sourced',
  Studio = 'Studio'
}

export enum TalentStatus {
  Active = 'Active',
  Applied = 'Applied',
  AppliedToUpper = 'AppliedToUpper',
  Challenge = 'Challenge',
  Deactivated = 'Deactivated',
  Deleted = 'Deleted',
  Invited = 'Invited',
  Onboarding = 'Onboarding',
  PersonalInterview = 'PersonalInterview',
  Registered = 'Registered',
  Rejected = 'Rejected',
  TechnicalInterview = 'TechnicalInterview',
  Unknown = 'Unknown',
  Waitlist = 'Waitlist'
}

export type TalentStatusHistory = {
  __typename?: 'TalentStatusHistory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rejectionReasons?: Maybe<Array<Scalars['String']>>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<TalentStatus>;
  statusExplanation?: Maybe<Scalars['String']>;
  talent?: Maybe<Talent>;
  talentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TalentStatusHistoryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  rejectionReasons?: InputMaybe<Array<Scalars['String']>>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TalentStatus>;
  statusExplanation?: InputMaybe<Scalars['String']>;
  talent?: InputMaybe<TalentInput>;
  talentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalentStatusInput = {
  engagementRejectionReasons?: InputMaybe<Array<Scalars['String']>>;
  rejectEngagements?: InputMaybe<Scalars['Boolean']>;
  rejectionReasons?: InputMaybe<Array<Scalars['String']>>;
  sendNotifications?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<TalentStatus>;
  statusExplanation?: InputMaybe<Scalars['String']>;
};

export type Template = {
  __typename?: 'Template';
  createdAt?: Maybe<Scalars['DateTime']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  metadata?: Maybe<Scalars['JSONObject']>;
  type?: Maybe<TemplateType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type TemplateFilters = {
  groups?: InputMaybe<Array<Scalars['String']>>;
  keys?: InputMaybe<Array<Scalars['String']>>;
  trigger?: InputMaybe<EmailNotificationTrigger>;
  triggerEntity?: InputMaybe<EmailNotificationTriggerEntity>;
  triggerStatus?: InputMaybe<Scalars['String']>;
  triggerType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TemplateType>;
};

export enum TemplateType {
  App = 'App',
  Email = 'Email',
  Notification = 'Notification',
  SlackMessage = 'SlackMessage'
}

export type TimeEntriesFilters = {
  companyId?: InputMaybe<Scalars['String']>;
  engagementId?: InputMaybe<Scalars['String']>;
  /** Greater then or equal */
  gte?: InputMaybe<Scalars['String']>;
  /** Lower then or equal */
  lte?: InputMaybe<Scalars['String']>;
};

export type TimeEntry = {
  __typename?: 'TimeEntry';
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['DateTime']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  isBilled?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<TimeEntryStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TimeEntryInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  day?: InputMaybe<Scalars['DateTime']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isBilled?: InputMaybe<Scalars['Boolean']>;
  notes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TimeEntryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum TimeEntryStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Submitted = 'Submitted'
}

export type Timesheet = {
  __typename?: 'Timesheet';
  absences?: Maybe<Array<TimesheetAbsence>>;
  clientTotal?: Maybe<Scalars['Float']>;
  company?: Maybe<CompanyTimesheet>;
  engagement?: Maybe<Engagement>;
  expenses?: Maybe<Array<TimesheetExpense>>;
  id?: Maybe<Scalars['String']>;
  talentTotal?: Maybe<Scalars['Float']>;
  timeEntries?: Maybe<Array<TimeEntry>>;
  totalHours?: Maybe<Scalars['Float']>;
};

export type TimesheetAbsence = {
  __typename?: 'TimesheetAbsence';
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['DateTime']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<TimeEntryStatus>;
  type?: Maybe<TimesheetAbsenceType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TimesheetAbsenceInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  day?: InputMaybe<Scalars['DateTime']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  hours?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TimeEntryStatus>;
  type?: InputMaybe<TimesheetAbsenceType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TimesheetAbsenceInterval = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  engagementIds?: InputMaybe<Array<Scalars['String']>>;
  firstDayHours?: InputMaybe<Scalars['Float']>;
  from: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  lastDayHours?: InputMaybe<Scalars['Float']>;
  notes: Scalars['String'];
  to: Scalars['DateTime'];
  type: TimesheetAbsenceType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export enum TimesheetAbsenceType {
  Other = 'Other',
  SickLeave = 'SickLeave',
  SpecialLeave = 'SpecialLeave',
  Vacation = 'Vacation'
}

export type TimesheetExpense = {
  __typename?: 'TimesheetExpense';
  amount?: Maybe<Scalars['Float']>;
  assets?: Maybe<Array<ExpenseAsset>>;
  category?: Maybe<TimesheetExpenseCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['DateTime']>;
  engagement?: Maybe<Engagement>;
  engagementId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<TimeEntryStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum TimesheetExpenseCategory {
  Accommodation = 'Accommodation',
  BusinessSuppliesAndEquipment = 'BusinessSuppliesAndEquipment',
  HomeOfficeEquipment = 'HomeOfficeEquipment',
  MealsAndEntertainment = 'MealsAndEntertainment',
  MembershipsAndDues = 'MembershipsAndDues',
  PhoneAndInternet = 'PhoneAndInternet',
  PostageAndShipping = 'PostageAndShipping',
  ProfessionalDevelopment = 'ProfessionalDevelopment',
  SoftwareAndSubscriptions = 'SoftwareAndSubscriptions',
  Transportation = 'Transportation'
}

export type TimesheetExpenseInput = {
  amount?: InputMaybe<Scalars['Float']>;
  assets?: InputMaybe<Array<ExpenseAssetInput>>;
  category?: InputMaybe<TimesheetExpenseCategory>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  day?: InputMaybe<Scalars['DateTime']>;
  engagement?: InputMaybe<EngagementInput>;
  engagementId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TimeEntryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TimesheetOverviewFilters = {
  companyId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  engagementId?: InputMaybe<Scalars['String']>;
  hasHoursLogged?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
  timesheetStatus?: InputMaybe<TimeEntryStatus>;
};

export type TimesheetTotals = {
  __typename?: 'TimesheetTotals';
  clientTotal?: Maybe<Scalars['Float']>;
  talentTotal?: Maybe<Scalars['Float']>;
  totalCompanies?: Maybe<Scalars['Float']>;
  totalEngagements?: Maybe<Scalars['Float']>;
  totalHours?: Maybe<Scalars['Float']>;
};

export enum ToolsSkillReplaceStrategy {
  Map = 'Map',
  Replace = 'Replace',
  ReplaceAndAddKeyword = 'ReplaceAndAddKeyword'
}

export type User = {
  __typename?: 'User';
  asanaId?: Maybe<Scalars['String']>;
  calendlyLink?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientData?: Maybe<UserDataClient>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fellowData?: Maybe<UserDataFellow>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  interviewerData?: Maybe<UserDataInterviewer>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBetaTester?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  ownedAgency?: Maybe<Agency>;
  profilePicFileLocation?: Maybe<Scalars['String']>;
  profilePicFileMimeType?: Maybe<Scalars['String']>;
  profilePicFileName?: Maybe<Scalars['String']>;
  profilePicFileSize?: Maybe<Scalars['Float']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  scoutData?: Maybe<UserDataScout>;
  slackId?: Maybe<Scalars['String']>;
  talentData?: Maybe<Talent>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userCompanies?: Maybe<Array<UserCompany>>;
  userRoles?: Maybe<Array<UserRole>>;
};

export type UserCompany = {
  __typename?: 'UserCompany';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type UserCompanyInput = {
  company?: InputMaybe<CompanyInput>;
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserDataClient = {
  __typename?: 'UserDataClient';
  createdAt?: Maybe<Scalars['DateTime']>;
  em?: Maybe<User>;
  emId?: Maybe<Scalars['String']>;
  hideHotProfiles?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isOnboarded?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type UserDataClientInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  em?: InputMaybe<UserInput>;
  emId?: InputMaybe<Scalars['String']>;
  hideHotProfiles?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserDataFellow = {
  __typename?: 'UserDataFellow';
  agreementLink?: Maybe<Scalars['String']>;
  airtableId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deal?: Maybe<Scalars['String']>;
  dealExpirationInMonths?: Maybe<Scalars['Float']>;
  em?: Maybe<User>;
  emId?: Maybe<Scalars['String']>;
  fellowReferrals?: Maybe<Scalars['String']>;
  hasSignedFellowAgreement?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  referral?: Maybe<Scalars['String']>;
  reportingId?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  setup?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export type UserDataFellowInput = {
  agreementLink?: InputMaybe<Scalars['String']>;
  airtableId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deal?: InputMaybe<Scalars['String']>;
  dealExpirationInMonths?: InputMaybe<Scalars['Float']>;
  em?: InputMaybe<UserInput>;
  emId?: InputMaybe<Scalars['String']>;
  fellowReferrals?: InputMaybe<Scalars['String']>;
  hasSignedFellowAgreement?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  linkedinLink?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  referral?: InputMaybe<Scalars['String']>;
  reportingId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  setup?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type UserDataInterviewer = {
  __typename?: 'UserDataInterviewer';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  expert?: Maybe<User>;
  expertId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  linkedinLink?: Maybe<Scalars['String']>;
  schedulingLink?: Maybe<Scalars['String']>;
  status?: Maybe<UserInterviewerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type UserDataInterviewerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  expert?: InputMaybe<UserInput>;
  expertId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  linkedinLink?: InputMaybe<Scalars['String']>;
  schedulingLink?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserInterviewerStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserDataScout = {
  __typename?: 'UserDataScout';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

export type UserDataScoutInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
  vatPercentage?: InputMaybe<Scalars['Float']>;
};

export type UserFilters = {
  firstName?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<RoleKey>>;
};

export type UserInput = {
  asanaId?: InputMaybe<Scalars['String']>;
  calendlyLink?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  clientData?: InputMaybe<UserDataClientInput>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fellowData?: InputMaybe<UserDataFellowInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  interviewerData?: InputMaybe<UserDataInterviewerInput>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBetaTester?: InputMaybe<Scalars['Boolean']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  linkedinLink?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  ownedAgency?: InputMaybe<AgencyInput>;
  profilePicFileLocation?: InputMaybe<Scalars['String']>;
  profilePicFileMimeType?: InputMaybe<Scalars['String']>;
  profilePicFileName?: InputMaybe<Scalars['String']>;
  profilePicFileSize?: InputMaybe<Scalars['Float']>;
  profilePicUrl?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  scoutData?: InputMaybe<UserDataScoutInput>;
  slackId?: InputMaybe<Scalars['String']>;
  talentData?: InputMaybe<TalentInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userCompanies?: InputMaybe<Array<UserCompanyInput>>;
  userRoles?: InputMaybe<Array<UserRoleInput>>;
};

export enum UserInterviewerStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Invited = 'Invited',
  Onboarded = 'Onboarded',
  Prospect = 'Prospect'
}

export type UserRole = {
  __typename?: 'UserRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Role>;
  roleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type UserRoleInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<RoleInput>;
  roleId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserWithRoleFilter = {
  id: Scalars['String'];
  role: RoleKey;
};

export type VerificationStep = {
  __typename?: 'VerificationStep';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  reviewers?: Maybe<Array<VerificationStepReviewer>>;
};

export type VerificationStepReviewer = {
  __typename?: 'VerificationStepReviewer';
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  urlType?: Maybe<VerificationStepReviewerUrlType>;
};

export enum VerificationStepReviewerUrlType {
  Linkedin = 'Linkedin',
  Website = 'Website'
}

export type VerifyEmailInput = {
  email: Scalars['String'];
  token: Scalars['String'];
};

export type WhatsToday = {
  __typename?: 'WhatsToday';
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type InitiatePasswordResetMutationVariables = Exact<{
  data: InitiatePasswordResetInput;
}>;


export type InitiatePasswordResetMutation = { __typename?: 'Mutation', initiatePasswordReset: boolean };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };


export const InitiatePasswordResetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"InitiatePasswordReset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"InitiatePasswordResetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"initiatePasswordReset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}]}]}}]} as unknown as DocumentNode<InitiatePasswordResetMutation, InitiatePasswordResetMutationVariables>;
export const ResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ResetPasswordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}]}]}}]} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;