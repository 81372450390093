import { IconProps } from '.'

export function ExcludeTalentsIcon(props: IconProps) {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.745 9.991l-2.026 1.01-2.625-5.021 2.05-1.026a.648.648 0 01.87.28l2.018 3.863a.655.655 0 01-.287.894v0zM3.281 10.918L1.255 9.901a.648.648 0 01-.287-.886L2.986 5.15a.656.656 0 01.87-.287l2.05 1.026-2.625 5.028z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.719 11l-1.313 1.542-3.018 3.019a.697.697 0 01-.624.172l-4.758-1.19a.658.658 0 01-.238-.122l-4.487-3.503"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.406 12.543l-3.61-2.625-1.05.788a2.633 2.633 0 01-3.15 0l-.442-.337a.664.664 0 01-.074-.992l3.216-3.208a.648.648 0 01.46-.189h3.338"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.955 5.89l4.209-1.23a.656.656 0 01.45.032l2.84 1.288M9.188 17.465l-2.47-.624a.607.607 0 01-.27-.14l-1.854-1.607"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
