import * as React from 'react'
import { FormHelpText } from '../lib/form-help-text'
import { FieldBase, FieldBaseProps } from './field-base'
import { FormErrorMessage } from './form-error-message'
import { FormLabel } from './form-label'
import type { InvalidProps } from './types'

export type TextFieldProps = {
  label?: React.ReactNode
  helpText?: string | React.ReactNode
} & InvalidProps &
  FieldBaseProps &
  React.ComponentPropsWithoutRef<'input'>
type Ref = HTMLInputElement

export const TextField = React.forwardRef<Ref, TextFieldProps>(
  (
    {
      id,
      name,
      type = 'text',
      placeholder,
      className,
      label,
      isInvalid,
      invalidMessage,
      value,
      onChange,
      helpText,
      ...restProps
    },
    ref
  ) => {
    const title =
      type === 'password'
        ? undefined
        : value
        ? String(value)
        : placeholder || ''
    return (
      <div className={className}>
        {label && (
          <FormLabel htmlFor={id || name} required={restProps.required}>
            {label}
          </FormLabel>
        )}
        <FieldBase
          ref={ref}
          isInvalid={isInvalid}
          showClear={!!value}
          {...restProps}
        >
          <input
            id={id || name}
            placeholder={placeholder}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            title={title}
            // accessibility
            aria-label={label as string}
            aria-required={restProps.required}
          />
        </FieldBase>
        {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
        {helpText && <FormHelpText>{helpText}</FormHelpText>}
      </div>
    )
  }
)
