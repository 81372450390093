/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormErrorMessage } from '@upper/ui'
import { useField } from 'formik'
import { DayPicker, DayPickerProps } from 'react-day-picker'
import { FormikFieldProps } from './types'

type FormikDayPickerProps = FormikFieldProps &
  Omit<DayPickerProps, 'selected' | 'onSelect'>

export function FormikDayPicker({
  name,
  fast,
  validate,
  mode = 'single',
  ...restProps
}: FormikDayPickerProps) {
  const [_, meta, helpers] = useField(name)

  const { value } = meta
  const { setValue } = helpers

  return (
    <div>
      {/* @ts-ignore TODO: investigate DayPickerProps issue */}
      <DayPicker
        mode={mode}
        selected={value}
        onDayClick={(date) => {
          setValue(date)
        }}
        {...restProps}
      />
      {meta.error && meta.touched && (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      )}
    </div>
  )
}
