import type { IconProps } from './types'

export function AlertIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7812 7.75C14.7812 4.03125 11.7188 0.96875 8 0.96875C4.25391 0.96875 1.21875 4.03125 1.21875 7.75C1.21875 11.4961 4.25391 14.5312 8 14.5312C11.7188 14.5312 14.7812 11.4961 14.7812 7.75ZM8 9.11719C8.68359 9.11719 9.25781 9.69141 9.25781 10.375C9.25781 11.0859 8.68359 11.6328 8 11.6328C7.28906 11.6328 6.74219 11.0859 6.74219 10.375C6.74219 9.69141 7.28906 9.11719 8 9.11719ZM6.79688 4.60547C6.76953 4.41406 6.93359 4.25 7.125 4.25H8.84766C9.03906 4.25 9.20312 4.41406 9.17578 4.60547L8.98438 8.32422C8.95703 8.51562 8.82031 8.625 8.65625 8.625H7.31641C7.15234 8.625 7.01562 8.51562 6.98828 8.32422L6.79688 4.60547Z"
        fill="currentColor"
      />
    </svg>
  )
}
