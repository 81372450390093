/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty-pattern */
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as Tooltip from '@radix-ui/react-tooltip'
import {
  forwardRef,
  HTMLAttributes,
  KeyboardEvent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react'
import { classNames } from '../classnames'
import { FilterDisplayProps } from './display'

/* eslint-disable @typescript-eslint/ban-types */
type Props = {
  icon?: ReactNode
  label: string
  tooltip?: string
  display?: ReactNode
  disabled?: boolean
  disabledReason?: string
} & FilterDisplayProps

export function FilterField({
  icon,
  tooltip,
  label,
  children,
  display,
  hasSelection,
  disabled,
  disabledReason,
  clearable = true,
  onClear,
}: PropsWithChildren<Props>) {
  const [isOpen, setIsOpen] = useState(false)
  const handleContentKeyUp = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    // setIsOpen(false)
  }, [])

  if (disabled) {
    return (
      <DisabledToolTip title={disabledReason}>
        <div className="border-gray-light rounded border p-3 opacity-50">
          <span className="pointer-events-none flex h-6 w-6 flex-none items-center justify-center">
            {icon}
          </span>
        </div>
      </DisabledToolTip>
    )
  }

  return (
    <div className="relative max-w-full">
      <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen}>
        <ToolTip title={tooltip || label} hidden={hasSelection}>
          <div
            className={classNames(
              'group appearance-none gap-3 rounded border border-transparent p-3',
              'relative z-auto flex w-full cursor-pointer items-start',
              hasSelection
                ? 'bg-blue-lightest text-blue'
                : 'text-gray-dark bg-white'
            )}
          >
            <DropdownMenu.Trigger asChild>
              <button
                className={classNames(
                  'absolute -top-[1px] -left-[1px] z-0 h-full w-full appearance-none rounded',
                  'focus:ring-blue outline-none focus:ring-2 focus:ring-offset-2',
                  'border-gray-light box-content border',
                  hasSelection ? 'border-opacity-0' : ''
                )}
              ></button>
            </DropdownMenu.Trigger>
            <span className="pointer-events-none flex h-6 w-6 flex-none items-center justify-center">
              {icon}
            </span>
            {hasSelection && display && (
              <div
                className={classNames(
                  'pointer-events-none relative z-[1] w-full overflow-hidden leading-6',
                  'overflow-ellipsis whitespace-normal text-left text-sm',
                  'group-hover:overflow-visible group-hover:whitespace-normal'
                )}
              >
                <span className="pointer-events-none mr-1 text-black">
                  {label && `${label}:`}
                </span>
                <span className="text-blue pointer-events-auto font-medium">
                  {display}
                </span>
              </div>
            )}
          </div>
        </ToolTip>
        {hasSelection && clearable && <ClearButton onClick={onClear} />}
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="border-gray-light z-[50] overflow-auto rounded border bg-white shadow"
            sideOffset={4}
            style={{ maxHeight: 300 }}
            align="start"
            onKeyUp={handleContentKeyUp}
          >
            {children}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  )
}

type ToolTipProps = { title?: string } & Tooltip.TooltipContentProps
const ToolTip = forwardRef<any, PropsWithChildren<ToolTipProps>>(
  ({ children, title, ...props }, ref): any => {
    return title ? (
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild ref={ref}>
          {children}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            sideOffset={4}
            side="bottom"
            {...props}
            style={{ zIndex: 100 }}
          >
            <Tooltip.Arrow
              className="fill-blue"
              width={14}
              height={7}
              offset={8}
            ></Tooltip.Arrow>
            <div className="bg-blue rounded-md px-3 py-1 text-xs font-light tracking-wide text-white">
              {title}
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    ) : (
      children
    )
  }
)

const DisabledToolTip = forwardRef<any, PropsWithChildren<ToolTipProps>>(
  ({ children, title, ...props }, ref): any => {
    return title ? (
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild ref={ref}>
          {children}
        </Tooltip.Trigger>
        <Tooltip.Content
          sideOffset={4}
          side="bottom"
          {...props}
          style={{ zIndex: 100 }}
        >
          <Tooltip.Arrow
            className="fill-red"
            width={14}
            height={7}
            offset={8}
          ></Tooltip.Arrow>
          <div className="bg-red max-w-[272px] rounded-md px-3 py-1 text-xs font-light tracking-wide text-white">
            {title}
          </div>
        </Tooltip.Content>
      </Tooltip.Root>
    ) : (
      children
    )
  }
)

const ClearButton = (props: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="bg-blue hover:bg-blue-dark absolute -right-1.5 -top-1.5 rounded-full border-2 border-white p-1 transition-colors"
      {...props}
    >
      <svg
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66016 3.625L6.41797 1.86719C6.64648 1.65625 6.64648 1.30469 6.41797 1.09375L6.03125 0.707031C5.82031 0.478516 5.46875 0.478516 5.25781 0.707031L3.5 2.46484L1.72461 0.707031C1.51367 0.478516 1.16211 0.478516 0.951172 0.707031L0.564453 1.09375C0.335938 1.30469 0.335938 1.65625 0.564453 1.86719L2.32227 3.625L0.564453 5.40039C0.335938 5.61133 0.335938 5.96289 0.564453 6.17383L0.951172 6.56055C1.16211 6.78906 1.51367 6.78906 1.72461 6.56055L3.5 4.80273L5.25781 6.56055C5.46875 6.78906 5.82031 6.78906 6.03125 6.56055L6.41797 6.17383C6.64648 5.96289 6.64648 5.61133 6.41797 5.40039L4.66016 3.625Z"
          fill="white"
        />
      </svg>
    </button>
  )
}

export type { Props as FilterFieldProps }
