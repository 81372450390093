import 'quill/dist/quill.core.css'
import { FormLabel } from './form-label'

import { FormErrorMessage } from './form-error-message'
import { FormHelpText } from './form-help-text'
import { Editor } from './text-editor'

export type RichTextEditorProps = {
  id?: string
  label?: string
  initialValue: string
  helpText?: string
  className?: string
  invalidMessage?: string
  required?: boolean
  showCount?: boolean
  minChars?: number
  isInvalid?: string | boolean
  isReadonly?: boolean
  placeholder?: JSX.Element
  onChange: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  editorClassName?: string
}

export function RichTextEditor({
  id,
  label,
  initialValue,
  className,
  editorClassName,
  required,
  showCount,
  minChars,
  isInvalid,
  isReadonly,
  placeholder,
  invalidMessage,
  helpText,
  onChange,
  onBlur,
  onFocus,
}: RichTextEditorProps) {
  // const quillRef = useRef<Quill>(null)

  return (
    <div className={className}>
      {label && (
        <FormLabel htmlFor={id} required={required}>
          {label}
        </FormLabel>
      )}
      <Editor
        placeholder={placeholder}
        initialValue={initialValue}
        onChange={(htmlString) => {
          onChange?.(htmlString)
        }}
        className={editorClassName}
      />
      {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
      {helpText && <FormHelpText>{helpText}</FormHelpText>}
    </div>
  )
}
