import * as Icons from '@upper/sapphire/icons'
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  cn,
} from '@upper/sapphire/ui'
import { useCallback, useState } from 'react'
import { capitalize, words } from 'voca'
import { EntryHeader } from './entry-header'
import { EntryNote } from './entry-note'

import { useToggle } from '@upper/hooks'
import { useValidatorField } from '@upper/providers'
import { UploadExpenseAssetDialog } from './UploadExpenseAssetDialog'
import { TimesheetExpense } from './types'

/**
 * Props for the ExpenseCard component.
 *
 * @typedef {Object} Props
 *
 * @property {T} data - The expense data to display.
 * @property {function} [onDelete] - A callback function triggered when an expense needs to be deleted.
 * @property {function} [onUpdate] - A callback function triggered when the value, category, or note field changes.
 *                                    Note: This function is deprecated and will be removed in the release version.
 */
interface Props<T> {
  // `data` prop is expected to be an instance of `TimesheetExpense`.
  data: T
  categoryOptions?: string[]
  disabled?: boolean
  onAssetUploaded?: (expenseId?: string | null, assetId?: string | null) => void
  onAssetDelete?: (expenseId?: string | null, assetId?: string | null) => void
  // `onDelete` is an optional callback which will be triggered when an expense needs to be deleted.
  onDelete?: (id: string | null | undefined) => void
  // `onUpdate` is a callback that updates the expense. This function is deprecated and will be removed in the release version.
  onUpdate?: (id: string, data: Partial<T>) => void
}

/**
 * ExpenseCard Component. This component displays the details of an expense entry.
 * It provides the ability to delete the entry or update its amount, category, or note.
 *
 * @example
 * // Usage example
 * <ExpenseCard
 *    data={expenseData}
 *    onDelete={(id) => console.log(`Delete expense with id ${id}`)} //temporary
 *    onUpdate={(id, data) => console.log(`Update expense with id ${id}`, data)} //temporary
 * />
 *
 * @param {Props} props - The properties that define the component's behavior and display.
 *
 * @returns {JSX.Element} The rendered ExpenseCard component.
 */
export const ExpenseCard = <T extends TimesheetExpense>({
  data,
  categoryOptions,
  disabled,
  onAssetUploaded,
  onAssetDelete,
  onUpdate,
  onDelete,
}: Props<T>): JSX.Element => {
  const validatorField = useValidatorField(data.id ?? '', {
    schemaPath: 'expense',
    defaultValue: data,
  })

  const showUploadFileDialog = useToggle()

  const [amount, setAmount] = useState(() => String(data.amount) ?? '0')

  const handleUpdate = useCallback(
    (newData: Partial<T>) => {
      onUpdate?.(data.id ?? '', newData)
    },
    [data.id, onUpdate]
  )

  const handleAmountChange = useCallback(() => {
    handleUpdate({ amount: parseFloat(amount) } as Partial<T>)
    validatorField.onChange({ amount: amount, notes: data.notes })
  }, [amount, handleUpdate, validatorField, data.notes])

  const handleNotesChange = useCallback(
    (notes: string) => {
      handleUpdate({ amount: parseFloat(amount), notes } as Partial<T>)

      validatorField.onChange({ ammount: amount, notes })
    },
    [amount, handleUpdate, validatorField]
  )

  const assets = data.assets

  function downloadFile(url: any, name: string) {
    window.open(url, '_top', `download=${name}`)
  }

  return (
    <>
      {data.id && showUploadFileDialog.value && (
        <UploadExpenseAssetDialog
          expenseId={data.id}
          onClose={showUploadFileDialog.setFalse}
          onUploaded={(assetId) => {
            onAssetUploaded?.(data.id, assetId)
            showUploadFileDialog.setFalse()
          }}
        />
      )}
      <div
        className={cn(
          'border-gray-light rounded-xl border border-dotted bg-white',
          disabled && 'pointer-events-none'
        )}
      >
        <EntryHeader
          icon={<Icons.Euro />}
          tooltip="Expense"
          value={amount}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={(v) => {}}
          placeholder=""
          onDelete={() => onDelete?.(data.id)}
          customInput={
            <input
              className="!ring-primary font-mono-chivo w-full appearance-none rounded-md border-none py-0 px-1 text-sm font-light"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(String(e.target.value))}
              onBlur={handleAmountChange}
              disabled={disabled}
              type="number"
            />
          }
          disabled={disabled}
        />
        <div className="min-h-[20px] space-y-1 px-1 py-1">
          <select
            className="!ring-blue-lighter w-full appearance-none rounded-md border-none p-1 text-xs"
            placeholder="Type"
            value={data.category ?? undefined}
            onChange={(e) =>
              handleUpdate({
                category: e.target.value as string,
              } as T)
            }
            disabled={disabled}
          >
            {categoryOptions?.map((key) => (
              <option key={key} value={key}>
                {words(key)
                  .map((w) => capitalize(w))
                  .join(' ')}
              </option>
            ))}
          </select>
          <div className="bg-gray-lightest mx-1 h-[1px]" />
          <EntryNote
            value={data.notes ?? ''}
            onChange={handleNotesChange}
            disabled={disabled}
            error={
              validatorField.errors?.find((e: any) => e.path.includes('notes'))
                ?.message
            }
          />
          <div className="bg-gray-lightest mx-1 h-[1px]" />
          <div
            className={cn(
              'flex items-center gap-1 p-1',
              assets?.length ?? 0 > 0 ? 'flex-wrap' : ''
            )}
          >
            {!assets && (
              <span className="text-gray w-full text-xs">No files</span>
            )}
            {assets &&
              assets?.map((a) => (
                <Tooltip key={a.id}>
                  <TooltipTrigger asChild>
                    <div className="bg-gray-lightest flex items-center gap-0 rounded-full px-1 py-0 text-xs leading-5">
                      <button
                        className="p-1"
                        onClick={() =>
                          downloadFile(a.locationUrl, a?.name ?? '')
                        }
                      >
                        {a.type}
                      </button>
                      {!disabled && (
                        <button
                          className="bg-gray-lightest hover:bg-gray-light rounded-full p-1"
                          onClick={() => onAssetDelete?.(data.id, a.id)}
                        >
                          <Icons.X size={12} />
                        </button>
                      )}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>{a.name}</TooltipContent>
                </Tooltip>
              ))}
            {!disabled && (
              <Button
                size={'xs'}
                variant={'ghost'}
                tooltip="Upload file"
                className="flex-none"
                onClick={showUploadFileDialog.setTrue}
              >
                <Icons.Upload />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export enum ExpenseAssetType {
  Receipt = 'receipt',
  Image = 'image',
  Other = 'other',
}
export type UploadExpenseAssetDialogProps = {
  expenseId: string
  onClose?: () => void
  onUploaded?: (assetId?: string | null) => void
}
