/* eslint-disable @typescript-eslint/ban-types */
import { DatePicker, DatePickerProps } from '../date-picker'
import { MonthPicker, MonthPickerProps } from '../month-picker'
import { FilterField, FilterFieldProps } from './field'
const locale = 'en-DE'
type Props =
  | (FilterFieldProps & { type?: 'date' } & DatePickerProps)
  | (FilterFieldProps & { type?: 'month' } & MonthPickerProps)

export function FilterDate<T = Props>({
  value,
  type = 'date',
  onClear,
  onChange,
  ...props
}: Props) {
  const displayMarkup = value
    ? type === 'date'
      ? `${Intl.DateTimeFormat(locale).format(value)}`
      : `${Intl.DateTimeFormat(locale, { month: 'long' }).format(value)}`
    : undefined

  return (
    <FilterField
      hasSelection={!!value}
      display={displayMarkup}
      onClear={onClear}
      {...props}
    >
      <div className="p-3">
        {type === 'date' && (
          <DatePicker
            value={value}
            onChange={onChange as DatePickerProps['onChange']}
          />
        )}
        {type === 'month' && (
          <MonthPicker
            value={value}
            onChange={onChange as MonthPickerProps['onChange']}
          />
        )}
      </div>
    </FilterField>
  )
}
