import { TooltipProvider } from '@radix-ui/react-tooltip'
import {
  AuthProvider,
  createUrqlClient,
  WithAuthRedirect,
  WithRoleCheck,
} from '@upper/auth'
import { RoleKey } from '@upper/graphql/studio'
import { PortalsProvider } from '@upper/providers'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import React from 'react'
import { Toaster } from 'react-hot-toast'
import { cacheExchange, Provider } from 'urql'
import Modals from '../components/modals/Modals'
import { APP_NAME } from '../const'
import ModalsProvider from '../providers/modals/ModalsProvider'
import NavProvider from '../providers/navigation/NavProvider'
import './styles.css'

const urqlClient = createUrqlClient(APP_NAME, cacheExchange)

type NextPageWithLayoutAndAuth = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
  authenticate?: boolean
  redirectAuthenticatedTo?: string
}

type AppPropsWithLayoutAndAuth = AppProps & {
  Component: NextPageWithLayoutAndAuth
}

function CustomApp({ Component, pageProps }: AppPropsWithLayoutAndAuth) {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
             (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
             new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
             j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
             'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
             })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_STUDIO}');
           `,
        }}
      />

      <Provider value={urqlClient}>
        <AuthProvider appName={APP_NAME}>
          <WithAuthRedirect
            authenticate={Component.authenticate}
            redirectAuthenticatedTo={Component.redirectAuthenticatedTo}
          >
            <WithRoleCheck roles={[RoleKey.AgencyOwner, RoleKey.Admin]}>
              <PortalsProvider>
                <ModalsProvider>
                  <NavProvider>
                    <TooltipProvider>
                      {getLayout(<Component {...pageProps}></Component>)}
                    </TooltipProvider>
                    <Toaster />
                  </NavProvider>
                  <Modals />
                </ModalsProvider>
              </PortalsProvider>
            </WithRoleCheck>
          </WithAuthRedirect>
        </AuthProvider>
      </Provider>
    </>
  )
}

export default CustomApp
