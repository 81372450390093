'use client'

import {
  $createCodeNode,
  $isCodeNode,
  getCodeLanguages,
  getDefaultCodeLanguage,
} from '@lexical/code'
import { $isLinkNode } from '@lexical/link'
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  HeadingTagType,
} from '@lexical/rich-text'
import {
  $isAtNodeEnd,
  $isParentElementRTL,
  $wrapNodes,
} from '@lexical/selection'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
} from '@upper/sapphire/ui'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  ChevronDownIcon,
  ItalicIcon,
  ListIcon,
  ListOrderedIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from 'lucide-react'
import {
  ButtonHTMLAttributes,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { twMerge } from 'tailwind-merge'

const LowPriority = 1

const supportedBlockTypes = new Set<string>([
  'paragraph',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  // 'ul',
  // 'ol',
  // 'quote',
  // 'code',
])

type AlignOptionType = 'left' | 'center' | 'right' | 'justify'

const alignOptions: Array<{
  value: AlignOptionType
  label: string
  icon: React.ReactNode
}> = [
  {
    value: 'left',
    label: 'Left',
    icon: <AlignLeftIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />,
  },
  {
    value: 'center',
    label: 'Center',
    icon: <AlignCenterIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />,
  },
  {
    value: 'right',
    label: 'Right',
    icon: <AlignRightIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />,
  },
  {
    value: 'justify',
    label: 'Justify',
    icon: <AlignJustifyIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />,
  },
]

const blockTypeToBlockName: Record<string, string> = {
  paragraph: 'Normal',
  h1: 'H1',
  h2: 'H2',
  h3: 'H3',
  h4: 'H4',
  h5: 'H5',
  h6: 'H6',
  ol: 'Numbered List',
  ul: 'Bulleted List',
  quote: 'Quote',
  code: 'Code Block',
}

function Divider() {
  return <Separator className="h-5" orientation="vertical" />
}

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor
  const focus = selection.focus
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()
  if (anchorNode === focusNode) {
    return anchorNode
  }
  const isBackward = selection.isBackward()
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode
  }
}

function AlignOptionsDropdownList({
  editor,
  value,
}: {
  value: 'left' | 'center' | 'right' | 'justify'
} & any) {
  editor.update(() => {
    const selection = $getSelection()
    console.log(selection)
  })

  return (
    <Select
      value={value}
      onValueChange={(v) => {
        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, v)
      }}
    >
      <SelectTrigger className="h-auto w-auto p-1 text-sm capitalize">
        <SelectValue className="capitalize" placeholder="Align" />
      </SelectTrigger>
      <SelectContent>
        {alignOptions.map((block) => (
          <SelectItem value={block.value} className="text-sm capitalize">
            {block.icon}
            {block.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

function BlockOptionsDropdownList({
  editor,
  value,
}: {
  value: keyof typeof blockTypeToBlockName
} & any) {
  const formatParagraph = () => {
    if (value !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode())
        }
      })
    }
  }

  const formatHeading = (type: HeadingTagType) => {
    if (value !== type) {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(type))
        }
      })
    }
  }

  const formatBulletList = () => {
    if (value !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND)
    }
  }

  const formatNumberedList = () => {
    if (value !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND)
    }
  }

  const formatQuote = () => {
    if (value !== 'quote') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode())
        }
      })
    }
  }

  const formatCode = () => {
    if (value !== 'code') {
      editor.update(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode())
        }
      })
    }
  }

  return (
    <Select
      value={value}
      onValueChange={(v) => {
        switch (v) {
          case 'paragraph':
            formatParagraph()
            break
          case 'h1':
            formatHeading('h1')
            break
          case 'h2':
            formatHeading('h2')
            break
          case 'h3':
            formatHeading('h3')
            break
          case 'h4':
            formatHeading('h4')
            break
          case 'h5':
            formatHeading('h5')
            break
          case 'h6':
            formatHeading('h6')
            break
          // case 'ul':
          //   formatBulletList()
          //   break
          // case 'ol':
          //   formatNumberedList()
          //   break
          // case 'code':
          //   formatCode()
          //   break
          // case 'quote':
          //   formatQuote()
          //   break
        }
      }}
    >
      <SelectTrigger className="h-auto w-auto p-1 text-sm capitalize">
        <SelectValue className="capitalize" />
      </SelectTrigger>
      <SelectContent>
        {Array.from(supportedBlockTypes).map((block) => (
          <SelectItem key={block} value={block} className="text-sm capitalize">
            {blockTypeToBlockName[block]}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [blockType, setBlockType] = useState('paragraph')
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(
    null
  )
  const [codeLanguage, setCodeLanguage] = useState('')
  const [isRTL, setIsRTL] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isCode, setIsCode] = useState(false)

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          setBlockType(type)
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage())
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))
      setIsCode(selection.hasFormat('code'))
      setIsRTL($isParentElementRTL(selection))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar()
          return false
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        LowPriority
      )
    )
  }, [editor, updateToolbar])

  const codeLanguges = useMemo(() => getCodeLanguages(), [])
  // const onCodeLanguageSelect = useCallback(
  //   (e) => {
  //     editor.update(() => {
  //       if (selectedElementKey !== null) {
  //         const node = $getNodeByKey(selectedElementKey)
  //         if ($isCodeNode(node)) {
  //           node.setLanguage(e.target.value)
  //         }
  //       }
  //     })
  //   },
  //   [editor, selectedElementKey]
  // )

  const formatList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatOrderedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  return (
    <div
      className="shadow-bottom-stroke sticky top-0 z-[1] flex min-h-[48px] flex-wrap items-center gap-1 rounded-t-md bg-white p-2 text-slate-500"
      ref={toolbarRef}
    >
      {supportedBlockTypes.has(blockType) && (
        <BlockOptionsDropdownList
          editor={editor}
          toolbarRef={toolbarRef}
          value={blockType}
        />
      )}
      {blockType === 'code' ? (
        <>
          {/* <Select
            className="toolbar-item code-language"
            onChange={onCodeLanguageSelect}
            options={codeLanguges}
            value={codeLanguage}
          /> */}
          <ChevronDownIcon size={20} strokeWidth={1} absoluteStrokeWidth />
        </>
      ) : (
        <>
          <ToolbarButton
            onClick={formatList}
            isActive={blockType === 'ul'}
            aria-label="Format Unordered List"
          >
            <ListIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton>
          <ToolbarButton
            onClick={formatOrderedList}
            isActive={blockType === 'ol'}
            aria-label="Format Ordered List"
          >
            <ListOrderedIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton>
          <Divider />
          <ToolbarButton
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
            }}
            isActive={isBold}
            aria-label="Format Bold"
          >
            <BoldIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
            }}
            isActive={isItalic}
            aria-label="Format Italics"
          >
            <ItalicIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
            }}
            isActive={isUnderline}
            aria-label="Format Underline"
          >
            <UnderlineIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
            }}
            isActive={isStrikethrough}
            aria-label="Format Strikethrough"
          >
            <StrikethroughIcon
              size={16}
              strokeWidth={1.4}
              absoluteStrokeWidth
            />
          </ToolbarButton>
          <Divider />
          {/* <AlignOptionsDropdownList editor={editor} /> */}
          <ToolbarButton
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
            }}
            aria-label="Left Align"
          >
            <AlignLeftIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
            }}
            aria-label="Center Align"
          >
            <AlignCenterIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
            }}
            aria-label="Right Align"
          >
            <AlignRightIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton>
          {/* <ToolbarButton
            onClick={() => {
              editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
            }}
            aria-label="Justify Align"
          >
            <AlignJustifyIcon size={16} strokeWidth={1.4} absoluteStrokeWidth />
          </ToolbarButton> */}
        </>
      )}
    </div>
  )
}

type ToolbarButtonProps = {
  isActive?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>
function ToolbarButton({
  children,
  className,
  isActive,
  type = 'button',
  ...props
}: PropsWithChildren<ToolbarButtonProps>) {
  return (
    <button
      tabIndex={-1}
      type={type}
      {...props}
      className={twMerge(
        'rounded-md p-1 hover:bg-slate-100',
        isActive && 'bg-blue-100 text-blue-600',
        className
      )}
    >
      {children}
    </button>
  )
}
