import { fromZonedTime, toZonedTime } from 'date-fns-tz'
import { addDays } from 'date-fns/addDays'
import { eachDayOfInterval } from 'date-fns/eachDayOfInterval'
import { formatDate } from 'date-fns/format'
import { isWeekend } from 'date-fns/isWeekend'
import { setHours } from 'date-fns/setHours'
import { startOfTomorrow } from 'date-fns/startOfTomorrow'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import timezones from 'timezones-list'
import { classNames } from './classnames'
import { Select } from './select'

export type TimeSlotPickerProps = {
  value: string[]
  onChange: (value: string[]) => void
}

function formatHours(hours: number) {
  return `${hours < 10 ? '0' : ''}${hours}:00`
}

export function TimeSlotPicker({ value, onChange }: TimeSlotPickerProps) {
  const [selectedTimezone, setSelectedTimezone] = React.useState(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone
  )
  const [week, setWeek] = React.useState(0)
  const tomorrow = startOfTomorrow()
  const tomorrowPlus6Days = addDays(tomorrow, 6)
  const daysList = eachDayOfInterval({
    start: addDays(tomorrow, week * 7),
    end: addDays(tomorrowPlus6Days, week * 7),
  })
  const hoursList = [...Array(11).keys()].map((i) => i + 8)

  return (
    <div className="mx-auto w-full max-w-xs overflow-hidden rounded-xl bg-slate-100 md:max-w-xl lg:max-w-full">
      <div>
        <div className="flex gap-2 px-3 pt-3 text-sm">
          <button
            type="button"
            onClick={() => setWeek(0)}
            className={twMerge(
              'rounded-md px-2 py-1',
              week === 0 ? 'bg-blue-700 text-white' : 'bg-transparent'
            )}
          >
            This week
          </button>
          <button
            type="button"
            onClick={() => setWeek(1)}
            className={twMerge(
              'rounded-md px-2 py-1',
              week === 1 ? 'bg-blue-700 text-white' : 'bg-transparent'
            )}
          >
            Next week
          </button>
          <button
            type="button"
            onClick={() => setWeek(2)}
            className={twMerge(
              'rounded-md px-2 py-1',
              week === 2 ? 'bg-blue-700 text-white' : 'bg-transparent'
            )}
          >
            In 2 weeks
          </button>
        </div>
        <div className="items-center justify-between bg-slate-100 p-3 md:flex md:px-3">
          <div className="">
            <Select
              className="w-full text-sm"
              value={selectedTimezone}
              onChange={(event) => {
                const newTimezone = event.target.value
                onChange(
                  value.map((date) =>
                    fromZonedTime(
                      toZonedTime(date, selectedTimezone),
                      newTimezone
                    ).toISOString()
                  )
                )
                setSelectedTimezone(newTimezone)
              }}
            >
              {timezones.map((timezone) => (
                <option key={timezone.tzCode} value={timezone.tzCode}>
                  {timezone.label}
                </option>
              ))}
            </Select>
          </div>
          <div className="text-gray-dark p-4 text-center text-sm leading-tight md:p-0">
            {formatDate(daysList[0], 'MMM dd')} -{' '}
            {formatDate(daysList[6], 'PP')}
          </div>
        </div>
      </div>
      <div className="bg-gray-lightest flex w-full">
        <div className="mr-2 py-5 pl-3 md:pl-5">
          <div className="h-8" />
          <div className="mt-2 space-y-[3px]">
            {hoursList.map((hours) => (
              <div
                key={hours}
                className="text-gray-dark font-mono-2 flex h-8 items-center text-xs"
              >
                {formatHours(hours)}
              </div>
            ))}
          </div>
        </div>

        <div className="grid flex-1 grid-cols-[repeat(7,minmax(80px,1fr))] gap-[3px] overflow-y-auto py-5 pr-3 md:pr-5">
          {daysList.map((day, idx) => {
            const isOnWeekend = isWeekend(day)

            return (
              <div
                key={idx}
                className={classNames(
                  isOnWeekend && 'pointer-events-none opacity-50'
                )}
              >
                <div className="h-8 text-center">
                  <h3 className="text-gray text-xs font-medium uppercase">
                    {formatDate(day, 'E')}
                  </h3>
                  <p className="text-gray-dark font-mono-2 text-xs">
                    {formatDate(day, 'dd.MM')}
                  </p>
                </div>
                <div className="mt-2 space-y-[3px]">
                  {hoursList.map((hours) => {
                    const date = setHours(day, hours)
                    const utcDate = fromZonedTime(date, selectedTimezone)
                    const isoDate = utcDate.toISOString()
                    const isSelected = value.includes(isoDate)

                    return (
                      <button
                        key={hours}
                        type="button"
                        className={classNames(
                          'flex h-8 w-full flex-col items-center justify-center rounded border border-slate-200 text-center text-[10px] leading-tight text-white',
                          isSelected
                            ? 'bg-blue hover:bg-blue-dark border-blue-700'
                            : 'border-slate-200 bg-white hover:border-blue-200 hover:bg-blue-100'
                        )}
                        onClick={() => {
                          if (isSelected) {
                            onChange(value.filter((date) => date !== isoDate))
                          } else {
                            onChange([...value, isoDate])
                          }
                        }}
                      >
                        {isSelected && (
                          <>
                            <span>Block</span>
                            <span>
                              {formatHours(hours)} - {formatHours(hours + 1)}
                            </span>
                          </>
                        )}
                      </button>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
