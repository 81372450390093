import update from 'immutability-helper'
import { ChangeEvent, useCallback, useState } from 'react'
import { FilterField, FilterFieldProps } from '.'
import { TextField } from '../text-field'

type Props = {
  values?: any[]
  currency?: string
  onChange?: (range: any[] | undefined) => void
} & FilterFieldProps
export function FilterRange({
  values: defaultValues,
  currency = '€',
  onChange,
  onClear,
  ...props
}: Props) {
  const [value, setValue] = useState<any[] | undefined>(defaultValues)
  const handleClear = useCallback(() => {
    setValue(undefined)
    onClear?.()
  }, [onClear])

  const handleMinChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let state = undefined
      if (value) state = update(value, { $splice: [[0, 1, e.target.value]] })
      else state = update(value, { $set: [e.target.value, e.target.value] })
      setValue(state)
      //   onChange?.(state)
    },
    [value]
  )

  const handleMaxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let state = undefined
      if (value) state = update(value, { $splice: [[1, 1, e.target.value]] })
      else state = update(value, { $set: [e.target.value, e.target.value] })
      setValue(state)
      //   onChange?.(state)
    },
    [value]
  )

  const handleApply = useCallback(() => {
    onChange?.(value)
  }, [onChange, value])

  const displayMarkup = (
    <span className="text-blue hover:text-blue-dark">
      {defaultValues && defaultValues.map((v) => `${v}${currency}`).join(' — ')}
    </span>
  )

  return (
    <FilterField
      display={displayMarkup}
      hasSelection={!!value}
      onClear={handleClear}
      {...props}
    >
      <div className="text-sm p-3">
        <form className="flex gap-3 items-center">
          <TextField
            label="Min"
            suffix={currency}
            className="w-24"
            value={value?.[0]}
            onChange={handleMinChange}
          />
          <TextField
            label="Max"
            suffix={currency}
            className="w-24"
            value={value?.[1]}
            onChange={handleMaxChange}
          />
        </form>
      </div>
      <button
        className="bg-gray-lightest w-full rounded-b text-sm text-gray-dark p-2 hover:bg-blue-lightest"
        onClick={handleApply}
      >
        Apply
      </button>
    </FilterField>
  )
}
