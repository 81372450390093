import * as React from 'react'
import { classNames } from './classnames'

type SkillTagProps = {
  children: React.ReactNode
  size?: 'small' | 'large'
  type?: 'primary' | 'secondary'
}

export function SkillTag({
  children,
  size = 'small',
  type = 'primary',
}: SkillTagProps) {
  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-xl font-mono',
        size === 'small' ? 'text-xs px-2 py-0.5' : 'text-base px-3 py-1',
        type === 'primary'
          ? 'bg-gray-dark text-white'
          : 'bg-gray-lightest text-gray-dark'
      )}
    >
      {children}
    </span>
  )
}
