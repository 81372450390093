import { PropsWithChildren } from 'react'
import { globalIdGeneratorFactory, UniqueId } from '.'
import { Context } from './context'

// eslint-disable-next-line @typescript-eslint/ban-types
export type UniqueIdProviderProps = {}

export function Provider({
  children,
}: PropsWithChildren<UniqueIdProviderProps>) {
  const uniqueIdFactory: UniqueId = new UniqueId(globalIdGeneratorFactory)
  return <Context.Provider value={uniqueIdFactory}>{children}</Context.Provider>
}
