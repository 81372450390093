import { InfoIcon } from '@upper/icons'
import * as React from 'react'
import { classNames } from './classnames'
import { FormErrorMessage } from './form-error-message'
import { FormLabel } from './form-label'
import { Tooltip } from './tooltip'
import type { InvalidProps } from './types'

export type SegmentedControlProps = {
  label?: React.ReactNode
  value: string | boolean | number
  options: {
    value: string | boolean | number
    label: React.ReactNode
    tooltip?: string
  }[]
  required?: boolean
  onChange: (value: string | boolean | number) => void
} & InvalidProps

export function SegmentedControl({
  label,
  value,
  options,
  isInvalid,
  invalidMessage,
  required,
  onChange,
}: SegmentedControlProps) {
  return (
    <div>
      {label && <FormLabel required={required}>{label}</FormLabel>}

      <div className="flex gap-2">
        {options.map((option) => (
          <button
            key={String(option.value)}
            type="button"
            onClick={() => {
              onChange(option.value)
            }}
            className={classNames(
              'inline-flex items-center gap-2 px-4 py-3 text-base font-medium rounded-md shadow-sm',
              value === option.value
                ? 'bg-gray-dark text-white'
                : 'bg-white text-gray'
            )}
          >
            {option.label}
            {option.tooltip && (
              <Tooltip
                content={option.tooltip}
                className="max-w-[272px]"
                triggerProps={{ asChild: true }}
              >
                <span>
                  <InfoIcon className="w-4 h-4 flex-shrink-0" />
                </span>
              </Tooltip>
            )}
          </button>
        ))}
      </div>

      {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
    </div>
  )
}
