import { ModalsContext } from '@upper/providers'
import { useContext, useEffect } from 'react'


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useModalsManager<T = any>(name: string) {
  const modals = useContext(ModalsContext)
  if (!modals) {
    throw new Error('You have to wrap the app in ModalProvider.')
  }

  const { state, show, hide, add, remove } = modals
  const visible = !!state[name]
  const payload: T = state[name]

  useEffect(() => {
    add(name)
    return () => { remove(name) }
  }, [name, add, remove])

  return { visible, payload, show: (payload: T) => show<T>(name, payload), hide: () => hide(name) }
}