import type { IconProps } from './types'

export function LogoutIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5898 8.21484C14.8359 7.96875 14.8359 7.55859 14.5898 7.28516L9.99609 2.69141C9.55859 2.28125 8.875 2.58203 8.875 3.15625V5.78125H5.15625C4.77344 5.78125 4.5 6.08203 4.5 6.4375V9.0625C4.5 9.44531 4.77344 9.71875 5.15625 9.71875H8.875V12.3438C8.875 12.9453 9.58594 13.2188 9.99609 12.8086L14.5898 8.21484ZM6.25 12.6719V11.5781C6.25 11.4141 6.08594 11.25 5.92188 11.25H3.625C3.13281 11.25 2.75 10.8672 2.75 10.375V5.125C2.75 4.66016 3.13281 4.25 3.625 4.25H5.92188C6.08594 4.25 6.25 4.11328 6.25 3.92188V2.82812C6.25 2.66406 6.08594 2.5 5.92188 2.5H3.625C2.17578 2.5 1 3.67578 1 5.125V10.375C1 11.8242 2.17578 13 3.625 13H5.92188C6.08594 13 6.25 12.8633 6.25 12.6719Z"
        fill="currentColor"
      />
    </svg>
  )
}
