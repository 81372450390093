export function TeamIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 10C16.6562 10 18 8.65625 18 7C18 5.34375 16.6562 4 15 4C13.3438 4 12 5.34375 12 7C12 8.65625 13.3438 10 15 10ZM15 5C16.0938 5 17 5.90625 17 7C17 8.125 16.0938 9 15 9C13.875 9 13 8.125 13 7C13 5.90625 13.875 5 15 5ZM6 10C7.90625 10 9.5 8.4375 9.5 6.5C9.5 4.59375 7.90625 3 6 3C4.0625 3 2.5 4.59375 2.5 6.5C2.5 8.4375 4.0625 10 6 10ZM6 4C7.375 4 8.5 5.125 8.5 6.5C8.5 7.90625 7.375 9 6 9C4.59375 9 3.5 7.90625 3.5 6.5C3.5 5.125 4.59375 4 6 4ZM8.5 10.625C7.4375 10.625 7.1875 11 6 11C4.78125 11 4.53125 10.625 3.46875 10.625C2.34375 10.625 1.25 11.1562 0.59375 12.0938C0.21875 12.6875 0 13.375 0 14.125V15.5C0 16.3438 0.65625 17 1.5 17H10.5C11.3125 17 12 16.3438 12 15.5V14.125C12 13.375 11.75 12.6875 11.375 12.0938C10.7188 11.1562 9.625 10.625 8.5 10.625ZM11 15.5C11 15.7812 10.75 16 10.5 16H1.5C1.21875 16 1 15.7812 1 15.5V14.125C1 13.5938 1.125 13.0938 1.4375 12.6562C1.84375 12.0312 2.625 11.625 3.46875 11.625C4.34375 11.625 4.65625 12 6 12C7.34375 12 7.625 11.625 8.5 11.625C9.34375 11.625 10.125 12.0312 10.5312 12.6562C10.8438 13.0938 10.9688 13.5938 10.9688 14.125V15.5H11ZM19.4688 11.9375C18.9375 11.125 18.0312 10.6875 17.0625 10.6875C16.2188 10.6875 16 11 15 11C14 11 13.7812 10.6875 12.9062 10.6875C12.5 10.6875 12.0938 10.7812 11.7188 10.9688C12.1875 11.4375 12.2812 11.6875 12.3438 11.7812C12.5312 11.75 12.6875 11.7188 12.9062 11.7188C13.5938 11.7188 13.8438 12.0312 15 12.0312C16.125 12.0312 16.4062 11.7188 17.0625 11.7188C17.7188 11.7188 18.3125 12 18.6562 12.5C18.875 12.8125 19 13.1875 19 13.5938V14.75C19 14.9062 18.875 15 18.75 15H13C13 15.5625 13 15.7188 12.9375 16H18.75C19.4375 16 20 15.4688 20 14.75V13.5938C20 12.9688 19.8125 12.4062 19.4688 11.9375Z"
        fill="currentColor"
      />
    </svg>
  )
}
