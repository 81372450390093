import { IconProps } from '.'

export function CalendarCheckIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M13.5 2H12V0.375C12 0.167906 11.8321 0 11.625 0H11.375C11.1679 0 11 0.167906 11 0.375V2H5V0.375C5 0.167906 4.83209 0 4.625 0H4.375C4.16791 0 4 0.167906 4 0.375V2H2.5C1.67156 2 1 2.67156 1 3.5V14.5C1 15.3284 1.67156 16 2.5 16H13.5C14.3284 16 15 15.3284 15 14.5V3.5C15 2.67156 14.3284 2 13.5 2ZM2.5 3H13.5C13.7757 3 14 3.22431 14 3.5V5H2V3.5C2 3.22431 2.22431 3 2.5 3ZM13.5 15H2.5C2.22431 15 2 14.7757 2 14.5V6H14V14.5C14 14.7757 13.7757 15 13.5 15ZM11.4229 8.90822L7.21069 13.0867C7.06381 13.2324 6.82669 13.2317 6.68059 13.0852L4.57566 10.9742C4.42931 10.8274 4.42966 10.5898 4.57641 10.4434L4.84219 10.1784C4.98897 10.0321 5.22659 10.0324 5.37294 10.1792L6.94925 11.76L10.6299 8.10888C10.7771 7.96291 11.0147 7.96384 11.1607 8.111L11.425 8.37747C11.571 8.52463 11.5701 8.76225 11.4229 8.90822V8.90822Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_101_5">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
