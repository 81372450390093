import { UniqueIdContext } from '@upper/providers'
import { useContext, useRef } from 'react'

export function useUniqueId(prefix = '', overrideId = '') {
  const idManager = useContext(UniqueIdContext)

  // By using a ref to store the uniqueId for each invocation of the hook and
  // checking that it is not already populated we ensure that we don’t generate
  // a new ID on every re-render of a component.
  const uniqueIdRef = useRef<string | null>(null)

  if (!idManager) {
    throw new Error('No UniqueIdProvider was provided.')
  }

  // If an override was specified, then use that instead of using a unique ID
  // Hooks can’t be called conditionally so this has to go after all use* calls
  if (overrideId) {
    return overrideId
  }

  // If a unique id has not yet been generated, then get a new one
  if (!uniqueIdRef.current) {
    uniqueIdRef.current = idManager?.nextId(prefix) || null
  }

  return uniqueIdRef.current
}
