import * as React from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { FormErrorMessage } from './form-error-message'
import { FormLabel } from './form-label'
import type { InvalidProps } from './types'

export type CreatableComboBoxProps = {
  label?: React.ReactNode
  required?: boolean
} & InvalidProps &
  React.ComponentProps<typeof AsyncCreatableSelect>

export function CreatableComboBox({
  label,
  isInvalid,
  invalidMessage,
  required,
  ...restProps
}: CreatableComboBoxProps) {
  return (
    <div>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <AsyncCreatableSelect
        cacheOptions
        openMenuOnClick={false}
        noOptionsMessage={() => null}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: 50,
            borderColor: isInvalid
              ? '#E9705B'
              : state.isFocused
              ? '#5982F3'
              : '#e7e9ef',
            outline: isInvalid ? '1px solid #E9705B' : undefined,
            borderRadius: 6,
            boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.02)',
            '&:hover': {
              borderColor: isInvalid
                ? '#E9705B'
                : state.isFocused
                ? '#5982F3'
                : '#e7e9ef',
            },
          }),
          input: (base) => ({
            ...base,
            input: {
              fontWeight: 500,
            },
            'input:focus': {
              boxShadow: 'none',
            },
          }),
        }}
        {...restProps}
      />
      {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
    </div>
  )
}
