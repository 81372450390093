import type { IconProps } from './types'

export function VerificationTechnicalInterviewIcon(props: IconProps) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.8697 9.34782C21.8697 9.62457 21.7597 9.88998 21.564 10.0857C21.3683 10.2814 21.1029 10.3913 20.8262 10.3913H16.6523L13.5218 13.5217V10.3913H6.21749C5.94074 10.3913 5.67533 10.2814 5.47964 10.0857C5.28395 9.88998 5.17401 9.62457 5.17401 9.34782V2.04348C5.17401 1.76673 5.28395 1.50132 5.47964 1.30563C5.67533 1.10994 5.94074 1 6.21749 1H20.8262C21.1029 1 21.3683 1.10994 21.564 1.30563C21.7597 1.50132 21.8697 1.76673 21.8697 2.04348V9.34782Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.57043 20.3043C7.15525 20.3043 8.43999 19.0196 8.43999 17.4347C8.43999 15.8499 7.15525 14.5652 5.57043 14.5652C3.98561 14.5652 2.70087 15.8499 2.70087 17.4347C2.70087 19.0196 3.98561 20.3043 5.57043 20.3043Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1398 25.0001C9.90543 23.9632 9.32537 23.0368 8.49491 22.3731C7.66446 21.7094 6.63299 21.3479 5.56991 21.3479C4.50684 21.3479 3.47536 21.7094 2.64491 22.3731C1.81446 23.0368 1.2344 23.9632 1 25.0001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4297 20.3043C22.0145 20.3043 23.2992 19.0196 23.2992 17.4347C23.2992 15.8499 22.0145 14.5652 20.4297 14.5652C18.8449 14.5652 17.5601 15.8499 17.5601 17.4347C17.5601 19.0196 18.8449 20.3043 20.4297 20.3043Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0002 25.0001C24.7658 23.9632 24.1858 23.0368 23.3553 22.3731C22.5249 21.7094 21.4934 21.3479 20.4303 21.3479C19.3672 21.3479 18.3358 21.7094 17.5053 22.3731C16.6749 23.0368 16.0948 23.9632 15.8604 25.0001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1306 3.60864L18.2175 5.6956L16.1306 7.78255"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9131 3.60864L8.82611 5.6956L10.9131 7.78255"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5655 3.60864L12.4785 7.78255"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
