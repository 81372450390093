import { forwardRef, HTMLAttributes } from "react"
import { classNames } from "../classnames"

export const ActionButton = forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      className={classNames('p-[1px] group', className)}
      {...props}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="bg-transparent group-hover:bg-gray-lightest rounded"
      >
        <rect
          x="0.5"
          y="0.5"
          width="47"
          height="47"
          rx="3.5"
          stroke="#D0D4DF"
          strokeLinejoin="round"
          strokeDasharray="4 4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 18C24.5523 18 25 18.4477 25 19V23H29C29.5523 23 30 23.4477 30 24C30 24.5523 29.5523 25 29 25H25V29C25 29.5523 24.5523 30 24 30C23.4477 30 23 29.5523 23 29V25H19C18.4477 25 18 24.5523 18 24C18 23.4477 18.4477 23 19 23H23V19C23 18.4477 23.4477 18 24 18Z"
          fill="currentColor"
        />
      </svg>
    </button>
  )
})
