'use client'

import { ChevronLeft, ChevronRight } from 'lucide-react'
import * as React from 'react'
import { DayPicker } from 'react-day-picker'

import { twMerge } from 'tailwind-merge'
import { buttonVariants } from './button'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={twMerge('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        month_caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        button_next: twMerge(
          buttonVariants({ variant: 'ghost' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute right-3 top-3 z-10'
        ),
        button_previous: twMerge(
          buttonVariants({ variant: 'ghost' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute left-3 top-3 z-10'
        ),
        month_grid: 'w-full border-collapse space-y-1',
        weekdays: 'flex',
        weekday:
          'text-muted-foreground rounded-lg w-9 font-normal text-[0.8rem]',
        week: 'flex w-full mt-2',
        day: 'group text-center text-sm p-0 relative first:[&:has([aria-selected])]:rounded-l-lg last:[&:has([aria-selected])]:rounded-r-lg focus-within:relative focus-within:z-20',
        day_button: twMerge(
          'text-center rounded-lg hover:bg-slate-100 transition-all',
          'h-9 w-9 p-0 font-normal group-aria-selected:hover:bg-blue-800 group-aria-selected:text-white'
        ),
        selected: 'bg-primary text-white hover:bg-primary rounded-lg',
        today: 'text-primary',
        outside:
          'text-muted-foreground !opacity-0 !pointer-events-none !bg-none',
        disabled: 'text-muted-foreground opacity-50',
        range_middle:
          'aria-selected:bg-primary aria-selected:text-white rounded-none',
        hidden: 'invisible',
        range_start: 'rounded-r-none',
        range_end: 'rounded-l-none',
        ...classNames,
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === 'left') {
            return <ChevronLeft className="h-4 w-4" />
          }
          return <ChevronRight className="h-4 w-4" />
        },
        ...(props.components ?? {}),
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
