import { DetailedHTMLProps, DetailsHTMLAttributes, FC, ReactNode } from 'react'
import { classNames } from '../classnames'
import { Spinner } from '../spinner'

export type MenuOption = {
  url?: string
  isExternal?: boolean
  label: ReactNode
  selected?: boolean
  disabled?: boolean
  loading?: boolean
  extra?: ReactNode
  onAction?: () => void
}
type Props = {
  options: MenuOption[]
  noStyles?: boolean
} & DetailedHTMLProps<DetailsHTMLAttributes<HTMLUListElement>, HTMLUListElement>

export const Menu: FC<Props> = ({ options, className, noStyles, ...props }) => {
  return (
    <ul
      className={classNames(
        'min-w-[180px] space-y-1',
        !noStyles && 'rounded-lg bg-white p-1 shadow-xl'
      )}
      {...props}
    >
      {options.map((o, i) => (
        <li key={`menu-item-${i}`} className="flex w-full gap-1 text-sm">
          {!o.url && (
            <button
              className={classNames(
                'flex items-center gap-2',
                'hover:bg-gray-lightest text-gray-dark w-full rounded-md bg-white px-3 py-1',
                o.selected && 'bg-gray-lightest hover:bg-gray-light',
                o.disabled && 'pointer-events-none opacity-50',
                o.loading && 'pointer-events-none'
              )}
              onClick={o.onAction}
            >
              {o.loading && (
                <Spinner noSize className="text-blue h-3 w-3 transition-all" />
              )}
              <span className={classNames(o.loading && 'text-gray')}>
                {o.label}
              </span>
            </button>
          )}
          {o.url && (
            <a
              className={classNames(
                'flex items-center gap-2',
                'hover:bg-gray-lightest text-gray-dark w-full rounded-md bg-white px-3 py-1',
                o.disabled && 'pointer-events-none opacity-50'
              )}
              href={o.url}
              target={o.isExternal ? '_blank' : undefined}
              rel="noreferrer"
            >
              <span className={classNames(o.loading && 'text-gray')}>
                {o.label}
              </span>
            </a>
          )}
          {o.extra}
        </li>
      ))}
    </ul>
  )
}
