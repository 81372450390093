import { IconProps } from '.'

export function HistoryIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15"
          stroke="currentcolor"
          strokeWidth="1.5"
        />
        <path
          d="M15 8C15 11.866 11.866 15 8 15"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeDasharray="2 2"
        />
        <path
          d="M8 4V8L10 10"
          stroke="currentcolor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
