import React, { PropsWithChildren, useEffect, useCallback } from 'react'
import { ModalContentProps } from '../../reducers/ModalsReducer'
import IconClose from '../icons/IconClose'
import styles from './modal.module.css'

type ModalPropTypes = {
  modalId?: string
  zIndex?: number
  onClose?: (modalId: string) => void
  ModalContentNode: React.ComponentType<ModalContentProps>
  additionalData?: any
  type?: 'modal' | 'alert'
}

export default function Modal({
  zIndex,
  onClose,
  modalId,
  ModalContentNode,
  additionalData,
  type,
}: PropsWithChildren<ModalPropTypes>) {
  if (type === 'alert') {
    return (
      <div
        className="fixed top-0 left-0 w-full h-full flex items-center justify-center"
        style={{ zIndex: zIndex }}
      >
        <div
          className={`overlay fixed top-0 left-0 w-full opacity-0 h-full transition-all duration-1000 ${styles.modal_overlay_bg}`}
          style={{ opacity: 0.8 }}
        ></div>
        <div
          className="relative rounded-lg bg-white w-11/12 md:w-96 opacity-0 
                transition-all duration-500 delay-150"
          style={{ opacity: 1 }}
        >
          {
            <ModalContentNode
              onCloseRequest={() => onClose(modalId)}
              additionalData={additionalData}
            />
          }
        </div>
      </div>
    )
  }
  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex items-center justify-center"
      style={{ zIndex: zIndex }}
    >
      <div
        className={`overlay fixed top-0 left-0 w-full opacity-0 h-full transition-all duration-1000 ${styles.modal_overlay_bg}`}
        style={{ opacity: 0.8 }}
      ></div>
      <div
        className="relative rounded-lg bg-white w-11/12 md:w-9/12 h-4/5 opacity-0 
            transition-all duration-500 delay-150"
        style={{ opacity: 1 }}
      >
        <button
          onClick={() => onClose(modalId)}
          className={`flex items-center justify-center top-9 ${styles.modal_close_btn}`}
        >
          <IconClose />
        </button>

        {
          <ModalContentNode
            onCloseRequest={() => onClose(modalId)}
            additionalData={additionalData}
          />
        }
      </div>
    </div>
  )
}
