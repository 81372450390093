import { HTMLAttributes } from "react"

export const ClearButton = (props: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="p-1 rounded-full bg-blue absolute -right-1.5 -top-1.5 hover:bg-blue-dark transition-colors border-white border-2"
      {...props}
    >
      <svg
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66016 3.625L6.41797 1.86719C6.64648 1.65625 6.64648 1.30469 6.41797 1.09375L6.03125 0.707031C5.82031 0.478516 5.46875 0.478516 5.25781 0.707031L3.5 2.46484L1.72461 0.707031C1.51367 0.478516 1.16211 0.478516 0.951172 0.707031L0.564453 1.09375C0.335938 1.30469 0.335938 1.65625 0.564453 1.86719L2.32227 3.625L0.564453 5.40039C0.335938 5.61133 0.335938 5.96289 0.564453 6.17383L0.951172 6.56055C1.16211 6.78906 1.51367 6.78906 1.72461 6.56055L3.5 4.80273L5.25781 6.56055C5.46875 6.78906 5.82031 6.78906 6.03125 6.56055L6.41797 6.17383C6.64648 5.96289 6.64648 5.61133 6.41797 5.40039L4.66016 3.625Z"
          fill="white"
        />
      </svg>
    </button>
  )
}
