import { useField } from 'formik'
import { CreatableComboBox, CreatableComboBoxProps } from '@upper/ui'
import { FormikFieldProps } from './types'

type FormikCreatableComboBoxProps = FormikFieldProps & CreatableComboBoxProps

export function FormikCreatableComboBox(props: FormikCreatableComboBoxProps) {
  // @ts-expect-error: Formik doesn't play nice with props from react-select
  const [field, meta, helpers] = useField(props)

  const { value } = meta
  const { setValue, setTouched } = helpers

  return (
    <CreatableComboBox
      id={field.name}
      instanceId={field.name}
      value={value}
      onBlur={() => {
        setTouched(true)
      }}
      onChange={(newValue) => {
        setValue(newValue)
      }}
      isInvalid={meta.error && meta.touched}
      invalidMessage={meta.error}
      {...props}
    />
  )
}
