import { RoleKey } from '@upper/graphql/auth'
import * as React from 'react'
import type { User } from './types'

export interface AuthContextInterface {
  user?: User | null
  userHasRole: (role: RoleKey | RoleKey[]) => boolean
  login: ({
    email,
    password,
  }: {
    email: string
    password: string
  }) => Promise<void>
  logout: () => void
}

export const AuthContext = React.createContext({} as AuthContextInterface)
