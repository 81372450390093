import { IconProps } from '.'

export function EndDateIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.15234 15.6392H11.207C11.3711 15.6392 11.4883 15.7798 11.4883 15.9204V17.2329C11.4883 17.397 11.3711 17.5142 11.207 17.5142H4.15234V18.6157C4.15234 19.1079 3.54297 19.3657 3.19141 19.0142L1.17578 16.9751C0.941406 16.7642 0.941406 16.4126 1.17578 16.2017L3.19141 14.1626C3.54297 13.811 4.15234 14.0688 4.15234 14.561V15.6392Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4H15.5C16.3125 4 17 4.6875 17 5.5V16.5C17 17.3438 16.3125 18 15.5 18H12.5002C12.7185 17.7093 12.875 17.3694 12.95 17H15.5C15.75 17 16 16.7812 16 16.5V8H4V12.0854C3.84361 12.0301 3.67532 12 3.5 12C3.32468 12 3.15639 12.0301 3 12.0854V5.5C3 4.6875 3.65625 4 4.5 4H6V2.375C6 2.1875 6.15625 2 6.375 2H6.625C6.8125 2 7 2.1875 7 2.375V4H13V2.375C13 2.1875 13.1562 2 13.375 2H13.625C13.8125 2 14 2.1875 14 2.375V4ZM8 14H6V13.375C6 13.1875 6.15625 13 6.375 13H7.625C7.8125 13 8 13.1875 8 13.375V14ZM11 14.05C10.8384 14.0172 10.6712 14 10.5 14H9V13.375C9 13.1875 9.15625 13 9.375 13H10.625C10.8125 13 11 13.1875 11 13.375V14.05ZM12.5002 15C12.3579 14.8106 12.1894 14.6421 12 14.4998V13.375C12 13.1875 12.1562 13 12.375 13H13.625C13.8125 13 14 13.1875 14 13.375V14.625C14 14.8438 13.8125 15 13.625 15H12.5002ZM15.5 5H4.5C4.21875 5 4 5.25 4 5.5V7H16V5.5C16 5.25 15.75 5 15.5 5ZM8 11.625C8 11.8438 7.8125 12 7.625 12H6.375C6.15625 12 6 11.8438 6 11.625V10.375C6 10.1875 6.15625 10 6.375 10H7.625C7.8125 10 8 10.1875 8 10.375V11.625ZM11 11.625C11 11.8438 10.8125 12 10.625 12H9.375C9.15625 12 9 11.8438 9 11.625V10.375C9 10.1875 9.15625 10 9.375 10H10.625C10.8125 10 11 10.1875 11 10.375V11.625ZM14 11.625C14 11.8438 13.8125 12 13.625 12H12.375C12.1562 12 12 11.8438 12 11.625V10.375C12 10.1875 12.1562 10 12.375 10H13.625C13.8125 10 14 10.1875 14 10.375V11.625Z"
        fill="currentColor"
      />
    </svg>
  )
}
