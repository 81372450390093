import type { IconProps } from './types'

export function UploadIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.09375 11.25C9.44922 11.25 9.75 10.9766 9.75 10.5938V6H12.1289C12.6211 6 12.8672 5.42578 12.5117 5.07031L8.35547 0.914062C8.16406 0.722656 7.80859 0.722656 7.61719 0.914062L3.46094 5.07031C3.10547 5.42578 3.35156 6 3.84375 6H6.25V10.5938C6.25 10.9766 6.52344 11.25 6.90625 11.25H9.09375ZM15 11.0312C15 10.6758 14.6992 10.375 14.3438 10.375H10.625V10.5938C10.625 11.4414 9.91406 12.125 9.09375 12.125H6.90625C6.05859 12.125 5.375 11.4414 5.375 10.5938V10.375H1.65625C1.27344 10.375 1 10.6758 1 11.0312V14.0938C1 14.4766 1.27344 14.75 1.65625 14.75H14.3438C14.6992 14.75 15 14.4766 15 14.0938V11.0312ZM11.6094 13.4375C11.6094 13.7383 11.3633 13.9844 11.0625 13.9844C10.7617 13.9844 10.5156 13.7383 10.5156 13.4375C10.5156 13.1367 10.7617 12.8906 11.0625 12.8906C11.3633 12.8906 11.6094 13.1367 11.6094 13.4375ZM13.3594 13.4375C13.3594 13.7383 13.1133 13.9844 12.8125 13.9844C12.5117 13.9844 12.2656 13.7383 12.2656 13.4375C12.2656 13.1367 12.5117 12.8906 12.8125 12.8906C13.1133 12.8906 13.3594 13.1367 13.3594 13.4375Z"
        fill="currentColor"
      />
      <path d="M1 14.3672H15V15.0508H1V14.3672Z" fill="currentColor" />
    </svg>
  )
}
