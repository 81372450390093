import axios, { Options } from 'redaxios'
import type { User } from './types'

export class Api {
  private options: Options

  constructor(public appName: string) {
    this.options = {
      withCredentials: true,
      headers: {
        'x-app': this.appName,
      },
    }
  }

  login({ email, password }: { email: string; password: string }) {
    return axios.post<{ token: string }>(
      `${process.env.NEXT_PUBLIC_API_URL}/login`,
      {
        email,
        password,
      },
      this.options
    )
  }

  logout() {
    return axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/logout`,
      null,
      this.options
    )
  }

  me() {
    return axios.get<User>(
      `${process.env.NEXT_PUBLIC_API_URL}/me`,
      this.options
    )
  }
}
