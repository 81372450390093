import { FormikSubmitButton, FormikTextField } from '@upper/formik'
import { InitiatePasswordResetDocument } from '@upper/graphql/auth'
import { ChevronLeftIcon } from '@upper/icons'
import { Form, Formik } from 'formik'
import Link from 'next/link'
import * as React from 'react'
import toast from 'react-hot-toast'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import Layout from './layout'

type ForgotPasswordPageProps = {
  appName?: string
  background?: React.ReactNode
  noCredentials?: boolean
  noDescription?: boolean
  description?: string
  noBackToUpper?: boolean
}

export function ForgotPasswordPage({
  appName,
  background,
  noCredentials,
  noDescription = false,
  description,
  noBackToUpper = false,
}: ForgotPasswordPageProps) {
  const [hasSubmitted, setHasSubmitted] = React.useState(false)

  return (
    <Layout
      appName={appName}
      background={background}
      noCredentials={noCredentials}
      noDescription={noDescription}
      description={description}
      noBackToUpper={noBackToUpper}
    >
      <div>
        <Link
          href="/login"
          className="text-gray-dark inline-flex items-center text-sm underline hover:no-underline"
        >
          <ChevronLeftIcon className="mr-2 h-4 w-4" />
          back to login
        </Link>
      </div>

      {hasSubmitted ? (
        <>
          <h1 className="mt-6 text-2xl font-semibold leading-none">
            Email has been sent.
          </h1>
          <p className="text-gray-dark mt-2 text-sm">
            Check your email for the confirmation link, then visit the{' '}
            <Link
              href="/login"
              className="text-blue underline hover:no-underline"
            >
              login page
            </Link>
          </p>
        </>
      ) : (
        <>
          <h1 className="mt-6 text-2xl font-semibold leading-none">
            Forgot Password
          </h1>
          <p className="text-gray-dark mt-2 text-sm">
            Enter your e-mail to reset your password
          </p>
          <div className="mt-8">
            <ForgotPasswordForm
              onSubmitSuccessful={() => {
                setHasSubmitted(true)
              }}
            />
          </div>
        </>
      )}
    </Layout>
  )
}

interface ForgotPasswordFormValues {
  email: string
}

function ForgotPasswordForm({
  onSubmitSuccessful,
}: {
  onSubmitSuccessful: () => void
}) {
  const [, initiatePasswordReset] = useMutation(InitiatePasswordResetDocument)

  const initialValues: ForgotPasswordFormValues = {
    email: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
      })}
      onSubmit={async (values: ForgotPasswordFormValues) => {
        const result = await initiatePasswordReset({
          data: {
            email: values.email,
          },
        })

        if (result.error) {
          toast.error(
            result.error.graphQLErrors?.[0].extensions?.code === '404'
              ? 'Email not found'
              : result.error.message
          )
        } else {
          onSubmitSuccessful()
        }
      }}
    >
      <Form>
        <div className="space-y-8">
          <FormikTextField name="email" placeholder="Email" />
          <FormikSubmitButton fullWidth>Reset password</FormikSubmitButton>
        </div>
      </Form>
    </Formik>
  )
}
