import type { IconProps } from './types'
export const FileIcon = (props: IconProps) => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.125 3.71875V0H0.65625C0.292578 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.292578 14 0.65625 14H9.84375C10.2074 14 10.5 13.7074 10.5 13.3438V4.375H6.78125C6.42031 4.375 6.125 4.07969 6.125 3.71875ZM7.875 10.1719C7.875 10.3523 7.72734 10.5 7.54688 10.5H2.95312C2.77266 10.5 2.625 10.3523 2.625 10.1719V9.95312C2.625 9.77266 2.77266 9.625 2.95312 9.625H7.54688C7.72734 9.625 7.875 9.77266 7.875 9.95312V10.1719ZM7.875 8.42188C7.875 8.60234 7.72734 8.75 7.54688 8.75H2.95312C2.77266 8.75 2.625 8.60234 2.625 8.42188V8.20312C2.625 8.02266 2.77266 7.875 2.95312 7.875H7.54688C7.72734 7.875 7.875 8.02266 7.875 8.20312V8.42188ZM7.875 6.45312V6.67188C7.875 6.85234 7.72734 7 7.54688 7H2.95312C2.77266 7 2.625 6.85234 2.625 6.67188V6.45312C2.625 6.27266 2.77266 6.125 2.95312 6.125H7.54688C7.72734 6.125 7.875 6.27266 7.875 6.45312ZM10.5 3.3332V3.5H7V0H7.1668C7.3418 0 7.50859 0.0683594 7.63164 0.191406L10.3086 2.87109C10.4316 2.99414 10.5 3.16094 10.5 3.3332Z"
      fill="currentColor"
    />
  </svg>
)
