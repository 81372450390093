import { useEffect, useMemo, useState } from 'react'

export function useResponsive(smallScreenSize = 768) {
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  useEffect(() => {
    if (typeof window === 'undefined') return
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < smallScreenSize)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [smallScreenSize])
  const isSS = useMemo(() => isSmallScreen, [isSmallScreen])
  return { isSmallScreen: isSS }
}
