import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
} from '@upper/sapphire/ui'
import { PropsWithChildren } from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ApproveConfirmationDialogProps {
  //
  onApprove?: () => void
}

export function ApproveConfirmationDialog({
  children,
  onApprove,
}: PropsWithChildren<ApproveConfirmationDialogProps>) {
  return (
    <AlertDialog>
      {children}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Approve all entries?</AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={onApprove}>Approve</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
