import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Context } from './context'

export function Provider({ children }: PropsWithChildren) {
  const context = useContext(Context)

  const [expanded, setExpanded] = useState(false)

  const handleToggle = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  useEffect(() => {
    if (!context) return
    setExpanded(context.expanded)
  }, [context])

  return (
    <Context.Provider
      value={{ expanded, setValue: setExpanded, toggle: handleToggle }}
    >
      {children}
    </Context.Provider>
  )
}
