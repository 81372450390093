import { IconProps } from './types'

export const UserPlusIcon = (props: IconProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 13 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.1875 4.3125H10.9375V3.0625C10.9375 2.90625 10.7812 2.75 10.625 2.75H10C9.82422 2.75 9.6875 2.90625 9.6875 3.0625V4.3125H8.4375C8.26172 4.3125 8.125 4.46875 8.125 4.625V5.25C8.125 5.42578 8.26172 5.5625 8.4375 5.5625H9.6875V6.8125C9.6875 6.98828 9.82422 7.125 10 7.125H10.625C10.7812 7.125 10.9375 6.98828 10.9375 6.8125V5.5625H12.1875C12.3438 5.5625 12.5 5.42578 12.5 5.25V4.625C12.5 4.46875 12.3438 4.3125 12.1875 4.3125ZM4.375 5.25C5.74219 5.25 6.875 4.13672 6.875 2.75C6.875 1.38281 5.74219 0.25 4.375 0.25C2.98828 0.25 1.875 1.38281 1.875 2.75C1.875 4.13672 2.98828 5.25 4.375 5.25ZM6.11328 5.875H5.78125C5.35156 6.08984 4.88281 6.1875 4.375 6.1875C3.86719 6.1875 3.37891 6.08984 2.94922 5.875H2.61719C1.17188 5.875 0 7.06641 0 8.51172V9.3125C0 9.83984 0.410156 10.25 0.9375 10.25H7.8125C8.32031 10.25 8.75 9.83984 8.75 9.3125V8.51172C8.75 7.06641 7.55859 5.875 6.11328 5.875Z"
        fill="currentColor"
      />
    </svg>
  )
}
