import type { IconProps } from './types'

export function HelpIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7812 7.75C14.7812 4.03125 11.7188 0.96875 8 0.96875C4.25391 0.96875 1.21875 4.03125 1.21875 7.75C1.21875 11.4961 4.25391 14.5312 8 14.5312C11.7188 14.5312 14.7812 11.4961 14.7812 7.75ZM8.16406 3.21094C9.72266 3.21094 11.3633 4.41406 11.3633 6C11.3633 8.13281 9.09375 8.16016 9.09375 8.92578V8.95312C9.09375 9.14453 8.92969 9.28125 8.76562 9.28125H7.23438C7.04297 9.28125 6.90625 9.14453 6.90625 8.95312V8.84375C6.90625 7.72266 7.75391 7.28516 8.38281 6.92969C8.92969 6.62891 9.28516 6.41016 9.28516 6C9.28516 5.45312 8.57422 5.09766 8.02734 5.09766C7.28906 5.09766 6.96094 5.45312 6.46875 6.08203C6.35938 6.21875 6.14062 6.24609 6.00391 6.13672L5.04688 5.42578C4.91016 5.31641 4.88281 5.125 4.99219 4.96094C5.73047 3.83984 6.6875 3.21094 8.16406 3.21094ZM8 9.99219C8.68359 9.99219 9.25781 10.5664 9.25781 11.25C9.25781 11.9609 8.68359 12.5078 8 12.5078C7.28906 12.5078 6.74219 11.9609 6.74219 11.25C6.74219 10.5664 7.28906 9.99219 8 9.99219Z"
        fill="currentColor"
      />
    </svg>
  )
}
