import { PropsWithChildren, useCallback, useState } from 'react'
import { Context, ContextProps } from './context'

export function Provider({
  children,
  schema,
}: PropsWithChildren<Pick<ContextProps, 'schema'>>) {
  const [fields, setFields] = useState<ContextProps['fields']>([])
  const [fieldValues, setFieldValues] = useState<Record<string, any>>({})
  const updateFieldValue = useCallback((identifier: string, value: any) => {
    setFieldValues((v) => ({ ...v, [identifier]: value }))
  }, [])

  const registerField = useCallback((identifier: string, value: any) => {
    setFields((v) => [
      ...v,
      { identifier, value, errors: null, isValid: false, schema: null },
    ])
  }, [])

  const unregisterField = useCallback((identifier: string) => {
    setFields((v) => v.filter((f) => f.identifier === identifier))
  }, [])

  return (
    <Context.Provider
      value={{
        schema,
        errors: [],
        fields,
        fieldValues,
        isValid: false,
        updateFieldValue,
        registerField,
        unregisterField,
      }}
    >
      {children}
    </Context.Provider>
  )
}
