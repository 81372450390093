import type { IconProps } from './types'

export function PersonalInterviewIcon(props: IconProps) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.9 40.4333L24.3333 43V37.6667H16.3333C15.9797 37.6667 15.6406 37.5262 15.3905 37.2761C15.1405 37.0261 15 36.687 15 36.3333V16.3333C15 15.9797 15.1405 15.6406 15.3905 15.3905C15.6406 15.1405 15.9797 15 16.3333 15H40.3333C40.687 15 41.0261 15.1405 41.2761 15.3905C41.5262 15.6406 41.6667 15.9797 41.6667 16.3333V25.6667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.666 38.9997C29.666 39.3533 29.8065 39.6924 30.0565 39.9425C30.3066 40.1925 30.6457 40.333 30.9993 40.333H36.3327L41.666 45.6663V40.333H44.3327C44.6863 40.333 45.0254 40.1925 45.2755 39.9425C45.5255 39.6924 45.666 39.3533 45.666 38.9997V29.6663C45.666 29.3127 45.5255 28.9736 45.2755 28.7235C45.0254 28.4735 44.6863 28.333 44.3327 28.333H30.9993C30.6457 28.333 30.3066 28.4735 30.0565 28.7235C29.8065 28.9736 29.666 29.3127 29.666 29.6663V38.9997Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.6663 34C33.7323 34 33.7967 34.0195 33.8515 34.0562C33.9063 34.0928 33.9491 34.1449 33.9743 34.2058C33.9995 34.2667 34.0061 34.3337 33.9933 34.3984C33.9804 34.463 33.9487 34.5224 33.902 34.569C33.8554 34.6157 33.796 34.6474 33.7314 34.6603C33.6667 34.6731 33.5997 34.6665 33.5388 34.6413C33.4779 34.6161 33.4258 34.5733 33.3892 34.5185C33.3526 34.4637 33.333 34.3993 33.333 34.3333C33.333 34.2449 33.3681 34.1601 33.4306 34.0976C33.4932 34.0351 33.5779 34 33.6663 34Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.6663 34C37.7323 34 37.7967 34.0195 37.8515 34.0562C37.9063 34.0928 37.9491 34.1449 37.9743 34.2058C37.9995 34.2667 38.0061 34.3337 37.9933 34.3984C37.9804 34.463 37.9487 34.5224 37.902 34.569C37.8554 34.6157 37.796 34.6474 37.7314 34.6603C37.6667 34.6731 37.5997 34.6665 37.5388 34.6413C37.4779 34.6161 37.4258 34.5733 37.3892 34.5185C37.3526 34.4637 37.333 34.3993 37.333 34.3333C37.333 34.2449 37.3681 34.1601 37.4306 34.0976C37.4932 34.0351 37.5779 34 37.6663 34Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6663 34C41.7323 34 41.7967 34.0195 41.8515 34.0562C41.9063 34.0928 41.9491 34.1449 41.9743 34.2058C41.9995 34.2667 42.0061 34.3337 41.9933 34.3984C41.9804 34.463 41.9487 34.5224 41.902 34.569C41.8554 34.6157 41.796 34.6474 41.7314 34.6603C41.6667 34.6731 41.5997 34.6665 41.5388 34.6413C41.4779 34.6161 41.4258 34.5733 41.3892 34.5185C41.3526 34.4637 41.333 34.3993 41.333 34.3333C41.333 34.2449 41.3681 34.1601 41.4306 34.0976C41.4932 34.0351 41.5779 34 41.6663 34Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.333 20.333V22.9997"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.333 20.333V22.9997"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9638 32.3328C25.3387 32.0523 23.8406 31.2748 22.6758 30.1074"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
