import type { IconProps } from './types'

export function EyeIcon(props: IconProps) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9756 7.36719C14.4991 4.46875 11.5459 2.5 8.21 2.5C4.84672 2.5 1.89359 4.46875 0.417031 7.36719C0.362344 7.47656 0.335 7.61328 0.335 7.75C0.335 7.91406 0.362344 8.05078 0.417031 8.16016C1.89359 11.0586 4.84672 13 8.21 13C11.5459 13 14.4991 11.0586 15.9756 8.16016C16.0303 8.05078 16.0577 7.91406 16.0577 7.77734C16.0577 7.61328 16.0303 7.47656 15.9756 7.36719ZM8.21 11.6875C6.0225 11.6875 4.2725 9.9375 4.2725 7.75C4.2725 5.58984 6.0225 3.8125 8.21 3.8125C10.3702 3.8125 12.1475 5.58984 12.1475 7.75V7.77734C12.1475 9.9375 10.3702 11.7148 8.21 11.7148V11.6875ZM8.21 5.125C7.96391 5.15234 7.71781 5.17969 7.49906 5.23438C7.66313 5.45312 7.74516 5.72656 7.74516 6.02734C7.74516 6.73828 7.17094 7.3125 6.46 7.3125C6.15922 7.3125 5.88578 7.23047 5.66703 7.06641C5.61234 7.28516 5.585 7.53125 5.585 7.75C5.585 9.19922 6.76078 10.375 8.21 10.375C9.65922 10.375 10.835 9.19922 10.835 7.75C10.835 6.30078 9.65922 5.15234 8.21 5.15234V5.125Z"
        fill="currentColor"
      />
    </svg>
  )
}
