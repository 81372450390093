import { SpinnerIcon } from '@upper/icons'
import * as React from 'react'
import type {
  PolymorphicPropsWithRef
} from 'react-polymorphic-types'
import { classNames } from './classnames'

const ButtonDefaultElement = 'button'

const classesForButtonSize: Record<ButtonSize, string> = {
  xxs: 'px-1 py-0 text-xs rounded',
  xs: 'px-1 py-0 text-sm rounded',
  sm: 'px-3 py-1.5 text-base font-regular rounded',
  md: 'px-6 py-3 text-base font-medium rounded-md',
  lg: 'px-9 py-3.5 text-lg font-medium rounded-md',
}

const stylesForButtonSize: Record<ButtonSize, React.CSSProperties> = {
  xxs: { minWidth: 24, height: 24 },
  xs: { minWidth: 30, height: 30 },
  sm: { minWidth: 38, height: 38 },
  md: { minWidth: 50, height: 50 },
  lg: { minWidth: 58, height: 58 },
}

type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg'

type ButtonOwnProps = {
  variant?: 'solid' | 'outline' | 'icon'
  size?: ButtonSize
  disabled?: boolean
  isLoading?: boolean
  loadingContent?: React.ReactNode
  fullWidth?: boolean
}

export type ButtonProps<
  T extends React.ElementType = typeof ButtonDefaultElement
> = PolymorphicPropsWithRef<ButtonOwnProps, T>

export const Button = React.forwardRef(function <
  T extends React.ElementType = typeof ButtonDefaultElement
>(
  {
    as,
    size = 'md',
    variant = 'solid',
    className,
    disabled,
    isLoading,
    loadingContent,
    children,
    fullWidth,
    ...restProps
  }: ButtonProps<T>,
  ref: React.ForwardedRef<Element>
) {
  const Element: React.ElementType = as || ButtonDefaultElement

  return (
    <Element
      ref={ref}
      disabled={disabled || isLoading}
      className={classNames(
        variant !== 'icon' ? classesForButtonSize[size] : 'rounded',
        'flex justify-center items-center border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light',
        'disabled:opacity-50 disabled:pointer-events-none',
        'transition-colors ease-in-out duration-300',
        variant === 'solid' &&
          'border-transparent text-white bg-blue hover:bg-blue-dark',
        variant === 'outline' && 'text-blue bg-white hover:text-blue-dark',
        variant === 'icon' &&
          'border-transparent text-white bg-blue hover:bg-blue-dark',
        fullWidth && 'w-full justify-center',
        className
      )}
      {...restProps}
      style={{ ...stylesForButtonSize[size], ...(restProps.style || {}) }}
    >
      {isLoading && <SpinnerIcon className="w-5 h-5 mr-3 -ml-1 animate-spin" />}
      {isLoading && loadingContent ? loadingContent : children}
    </Element>
  )
})
