import { StringSchema, string } from "yup";

/**
 * Regular expression for validating phone number in E.164 format.
 * @type {RegExp}
 */
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const phoneNumberRegEx: RegExp = /^\+\d{1,14}$/;

/**
 * Creates a Yup validation schema for phone numbers.
 * This schema validates that the input is a string and matches the E.164 phone number format.
 * 
 * @returns {Yup.StringSchema} A Yup string schema with a regex match for phone numbers.
 */
export const phone = (): StringSchema => string()
    .matches(phoneNumberRegEx, {
        message: 'The value should match this format, +41446681800',
    })