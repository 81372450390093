import type { IconProps } from './types'

export function BulletedListIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.125 3C2.49219 3 2 3.51562 2 4.125C2 4.75781 2.49219 5.25 3.125 5.25C3.73438 5.25 4.25 4.75781 4.25 4.125C4.25 3.51562 3.73438 3 3.125 3ZM3.125 6.75C2.49219 6.75 2 7.26562 2 7.875C2 8.50781 2.49219 9 3.125 9C3.73438 9 4.25 8.50781 4.25 7.875C4.25 7.26562 3.73438 6.75 3.125 6.75ZM3.125 10.5C2.49219 10.5 2 11.0156 2 11.625C2 12.2578 2.49219 12.75 3.125 12.75C3.73438 12.75 4.25 12.2578 4.25 11.625C4.25 11.0156 3.73438 10.5 3.125 10.5ZM13.625 10.875H6.125C5.91406 10.875 5.75 11.0625 5.75 11.25V12C5.75 12.2109 5.91406 12.375 6.125 12.375H13.625C13.8125 12.375 14 12.2109 14 12V11.25C14 11.0625 13.8125 10.875 13.625 10.875ZM13.625 3.375H6.125C5.91406 3.375 5.75 3.5625 5.75 3.75V4.5C5.75 4.71094 5.91406 4.875 6.125 4.875H13.625C13.8125 4.875 14 4.71094 14 4.5V3.75C14 3.5625 13.8125 3.375 13.625 3.375ZM13.625 7.125H6.125C5.91406 7.125 5.75 7.3125 5.75 7.5V8.25C5.75 8.46094 5.91406 8.625 6.125 8.625H13.625C13.8125 8.625 14 8.46094 14 8.25V7.5C14 7.3125 13.8125 7.125 13.625 7.125Z"
        fill="currentColor"
      />
    </svg>
  )
}
