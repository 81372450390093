import { IconProps } from '.'

export function PublicProfileIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.76 3H2.76001C1.63501 3 0.76001 3.90625 0.76001 5V15C0.76001 16.125 1.63501 17 2.76001 17H16.76C17.8538 17 18.76 16.125 18.76 15V5C18.76 3.90625 17.8538 3 16.76 3ZM17.76 15C17.76 15.5625 17.2913 16 16.76 16H2.76001C2.19751 16 1.76001 15.5625 1.76001 15V5C1.76001 4.46875 2.19751 4 2.76001 4H16.76C17.2913 4 17.76 4.46875 17.76 5V15ZM15.51 11H12.01C11.8538 11 11.76 11.125 11.76 11.25V11.75C11.76 11.9062 11.8538 12 12.01 12H15.51C15.635 12 15.76 11.9062 15.76 11.75V11.25C15.76 11.125 15.635 11 15.51 11ZM15.51 9H12.01C11.8538 9 11.76 9.125 11.76 9.25V9.75C11.76 9.90625 11.8538 10 12.01 10H15.51C15.635 10 15.76 9.90625 15.76 9.75V9.25C15.76 9.125 15.635 9 15.51 9ZM15.51 7H12.01C11.8538 7 11.76 7.125 11.76 7.25V7.75C11.76 7.90625 11.8538 8 12.01 8H15.51C15.635 8 15.76 7.90625 15.76 7.75V7.25C15.76 7.125 15.635 7 15.51 7ZM7.26001 11C8.63501 11 9.76001 9.90625 9.76001 8.5C9.76001 7.125 8.63501 6 7.26001 6C5.85376 6 4.76001 7.125 4.76001 8.5C4.76001 9.90625 5.85376 11 7.26001 11ZM7.26001 7C8.07251 7 8.76001 7.6875 8.76001 8.5C8.76001 9.34375 8.07251 10 7.26001 10C6.41626 10 5.76001 9.34375 5.76001 8.5C5.76001 7.6875 6.41626 7 7.26001 7ZM8.69751 11.5C8.10376 11.5 7.94751 11.7188 7.26001 11.7188C6.54126 11.7188 6.38501 11.5 5.79126 11.5C5.13501 11.5 4.47876 11.8125 4.10376 12.375C3.88501 12.7188 3.76001 13.0938 3.76001 13.5312V14.25C3.76001 14.4062 3.85376 14.5 4.01001 14.5H4.51001C4.63501 14.5 4.76001 14.4062 4.76001 14.25V13.5312C4.76001 13.3125 4.82251 13.125 4.94751 12.9375C5.10376 12.6562 5.41626 12.5312 5.79126 12.5312C6.16626 12.5312 6.44751 12.75 7.26001 12.75C8.07251 12.75 8.32251 12.5312 8.69751 12.5312C9.07251 12.5312 9.38501 12.6875 9.57251 12.9375C9.69751 13.125 9.76001 13.3125 9.76001 13.5312V14.25C9.76001 14.4062 9.85376 14.5 10.01 14.5H10.51C10.635 14.5 10.76 14.4062 10.76 14.25V13.5312C10.76 13.0938 10.635 12.7188 10.385 12.375C10.01 11.8125 9.35376 11.5 8.69751 11.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
