import { IconProps } from '.'

export function JobIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.26 6H12.76V3.75C12.76 3.34375 12.4163 3 12.01 3H7.51001C7.07251 3 6.76001 3.34375 6.76001 3.75V6H3.26001C2.41626 6 1.76001 6.6875 1.76001 7.5V15.5C1.76001 16.3438 2.41626 17 3.26001 17H16.26C17.0725 17 17.76 16.3438 17.76 15.5V7.5C17.76 6.6875 17.0725 6 16.26 6ZM7.76001 4H11.76V6H7.76001V4ZM16.76 15.5C16.76 15.7812 16.51 16 16.26 16H3.26001C2.97876 16 2.76001 15.7812 2.76001 15.5V11H7.76001V12.25C7.76001 12.6875 8.07251 13 8.51001 13H11.01C11.4163 13 11.76 12.6875 11.76 12.25V11H16.76V15.5ZM8.76001 12V11H10.76V12H8.76001ZM16.76 10H2.76001V7.5C2.76001 7.25 2.97876 7 3.26001 7H16.26C16.51 7 16.76 7.25 16.76 7.5V10Z"
        fill="currentColor"
      />
    </svg>
  )
}
