import type { IconProps } from './types'
export const UndoIcon = (props: IconProps) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M6.73628503,7.12168254 L0.380701165,7.12168254 C0.170458946,7.12168254 0,6.95111111 0,6.74073016 L0,0.380952381 C0,0.170571429 0.170458946,0 0.380701165,0 L1.90350582,0 C2.11374804,0 2.28420699,0.170571429 2.28420699,0.380952381 L2.28420699,2.86069841 C3.73635986,1.24695238 5.84566638,0.237142857 8.19062693,0.254190476 C12.5339831,0.285746032 16.0092137,3.79777778 16,8.14409524 C15.9907496,12.484381 12.4717702,16 8.1321893,16 C6.09895956,16 4.24599181,15.2281587 2.84964339,13.9614286 C2.68787712,13.8146984 2.68042173,13.5628889 2.83482777,13.4084127 L3.91243414,12.3300952 C4.05437223,12.1880635 4.28241223,12.1803492 4.43275746,12.3134286 C5.41737757,13.1852698 6.71239604,13.7142857 8.1321893,13.7142857 C11.218026,13.7142857 13.7158064,11.2153651 13.7158064,8.12698413 C13.7158064,5.03914286 11.2185336,2.53968254 8.1321893,2.53968254 C6.27639803,2.53968254 4.6335456,3.44368254 3.61853285,4.83596825 L6.73628503,4.83596825 C6.94652725,4.83596825 7.1169862,5.00653968 7.1169862,5.21692063 L7.1169862,6.74073016 C7.1169862,6.95111111 6.94652725,7.12168254 6.73628503,7.12168254 Z"
        id="Path"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)
