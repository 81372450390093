import * as React from 'react'
import { classNames } from './classnames'

type FormErrorMessageProps = React.ComponentPropsWithoutRef<'p'>

export function FormErrorMessage({
  className,
  children,
  ...restProps
}: FormErrorMessageProps) {
  if (!children) return null

  return (
    <p
      className={classNames('mt-2 text-sm font-medium text-red-500', className)}
      {...restProps}
    >
      {children}
    </p>
  )
}
