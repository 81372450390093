export type NavInitialStateType = {
    mobileMenuState: boolean;
    desktopMenuState: boolean;
    handleMobileMenu: (payload: boolean) => void;
    handleDesktopMenu: (payload: boolean) => void;
}

export const navInitialState: NavInitialStateType = {
    mobileMenuState: false,
    desktopMenuState: true,
    handleMobileMenu: null,
    handleDesktopMenu: null,
};

export enum NavActionTypes {
    SetMobileMenuState = 'SET_MOBILE_MENU',
    SetDesktopMenuState = 'SET_DESKTOP_MENU',
}

export type NavAction = {
    type: NavActionTypes,
    payload: boolean
}

export const reducer = (state: NavInitialStateType, action: NavAction) => {
    switch (action.type) {
      case NavActionTypes.SetMobileMenuState:
        return { mobileMenuState: action.payload }
      case NavActionTypes.SetDesktopMenuState:
        return { desktopMenuState: action.payload }
      default:
        return state;
    }
};