import { format } from 'date-fns'
import { formatInTimeZone, toZonedTime } from 'date-fns-tz'

export function utcDate(date: Date) {
  return toZonedTime(date, 'GMT')
}

export function formatDateIntl(
  date: Date | string,
  dateStyle: 'short' | 'medium' | 'long' | 'full' | undefined = 'short',
): string | null {
  try {
    const intl = new Intl.DateTimeFormat(undefined, { dateStyle })
    if (date) return intl.format(new Date(date))
  } catch (e) {
    console.log(e, date)
    return null
  }
  return null
}

export function formatDate(
  date: Date | string,
  type = 'dd.MM.yyyy'
): string | null {
  try {
    if (date) return format(new Date(date), type || 'dd.MM.yyyy')
  } catch (e) {
    console.log(e, date)
    return null
  }
  return null
}

export function formatDateUTC(
  date: Date | string,
  type = 'dd.MM.yyyy',
  tz = '+00:00'
): string | null {
  try {
    if (date) return formatInTimeZone(date, tz, type || 'dd.MM.yyyy')
  } catch (e) {
    console.log(e, date)
    return null
  }
  return null
}

export function formatTime(date: Date | string, tz?: string): string | null {
  try {
    if (date && tz) return formatInTimeZone(new Date(date), tz, 'HH:mm')
    if (date) return format(new Date(date), 'HH:mm')
  } catch (e) {
    console.log(e, date)
    return null
  }
  return null
}

// 700 to 7:00
export function numberStringToTime(input: string): string {
  if (input.length > 4) return input.slice(0, 4)

  let val = input.replace(/\D/g, '') // remove non-digit characters
  if (val.length > 2) {
    const hours = val.slice(0, -2)
    let minutes = val.slice(-2)

    // limit minutes to 59 if it's more than that
    if (parseInt(minutes, 10) >= 60) {
      minutes = '59'
    }
    val = hours + ':' + minutes
  }

  // Validate the input
  const parts = val.split(':')
  if (parts.length === 2) {
    const hours = parseInt(parts[0], 10)
    const minutes = parseInt(parts[1], 10)
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return input // return the original input if the final value is not correct
    }
  }
  return val
}

// 7:00 to 700
export function timeToNumberString(input: string): string {
  let val = input.replace(/\D/g, '') // remove non-digit characters
  if (val.length > 2) {
    val = val.slice(0, -2) + ':' + val.slice(-2)
  }

  // Validate the input
  const parts = val.split(':')
  if (parts.length === 2) {
    const hours = parseInt(parts[0], 10)
    const minutes = parseInt(parts[1], 10)
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return val
    }
  }
  return val
}

export function hoursToTimeString(decimalHours: number): string {
  // Get the integer part (hours)
  const hours = Math.floor(decimalHours)

  // Get the decimal part (minutes)
  const decimalMinutes = decimalHours % 1

  // Convert the decimal part to minutes
  const minutes = Math.round(decimalMinutes * 60)

  // Pad the hours and minutes with leading zeros if necessary and return
  return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0')
}

export function timeStringToHours(
  timeString: string
): number | null {
  // Split the input string into hours and minutes
  if (!timeString) return null
  if (timeString.length === 1 && isNaN(Number(timeString))) {
    return null
  }
  if (timeString.length === 1) {
    return Number(timeString)
  }
  const [hours, minutes] = timeString.split(':').map(Number)
  if ([hours, minutes].some(v => isNaN(v))) return null
  // Convert the hours and minutes to decimal
  const decimalHours = hours + minutes / 60

  return decimalHours
}

export function isSameWeekDay(
  date1: Date | undefined,
  date2: Date | undefined
): boolean {
  if (!date1 || !date2) return false
  return date1.getDay() === date2.getDay()
}

export function timeAgo(date: Date | string): string {
  if (typeof date === 'string') date = new Date(date)
  const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'long' });

  const seconds = (new Date().getTime() - date.getTime()) / 1000
  let interval = seconds / 31536000

  if (Math.abs(interval) > 1) {
    return rtf1.format(-Math.floor(interval), 'year')
  }
  interval = seconds / 2592000
  if (Math.abs(interval) > 1) {
    return rtf1.format(-Math.floor(interval), 'month')
  }
  interval = seconds / 86400
  if (Math.abs(interval) > 1) {
    return rtf1.format(-Math.floor(interval), 'day')
  }
  interval = seconds / 3600
  if (Math.abs(interval) > 1) {
    return rtf1.format(-Math.floor(interval), 'hour')
  }
  interval = seconds / 60
  if (Math.abs(interval) > 1) {
    return rtf1.format(-Math.floor(interval), 'minute')
  }
  return 'just now'
}