import { SpinnerIcon } from '@upper/icons'
import * as React from 'react'
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef
} from 'react-polymorphic-types'
import { classNames } from './classnames'

const ButtonDefaultElement = 'button'

const classesForButtonSize: Record<ButtonSize, string> = {
  xs: 'px-1 py-0 text-sm rounded',
  sm: 'px-3 py-1.5 text-base font-regular rounded',
  md: 'px-6 py-3 text-base font-medium rounded-md',
  lg: 'px-9 py-3.5 text-lg font-medium rounded-md',
}

const stylesForButtonSize: Record<ButtonSize, React.CSSProperties> = {
  xs: { minWidth: 30, height: 30 },
  sm: { minWidth: 38, height: 38 },
  md: { minWidth: 50, height: 50 },
  lg: { minWidth: 58, height: 58 },
}

type ButtonSize = 'xs' | 'sm' | 'md' | 'lg'

type ButtonOwnProps = {
  variant?: 'solid' | 'outline' | 'icon'
  size?: ButtonSize
  disabled?: boolean
  isLoading?: boolean
  loadingContent?: React.ReactNode
  fullWidth?: boolean
}

export type ButtonSecondaryProps<
  T extends React.ElementType = typeof ButtonDefaultElement
> = PolymorphicPropsWithRef<ButtonOwnProps, T>

export const ButtonSecondary: PolymorphicForwardRefExoticComponent<
ButtonOwnProps,
typeof ButtonDefaultElement
> = React.forwardRef<any, ButtonOwnProps>(function Button<
  T extends React.ElementType = typeof ButtonDefaultElement
>(
  {
    as,
    size = 'md',
    variant = 'solid',
    className,
    disabled,
    isLoading,
    loadingContent,
    children,
    fullWidth,
    ...restProps
  }: PolymorphicPropsWithoutRef<ButtonOwnProps, T>,
  ref: React.ForwardedRef<Element>
) {
  const Element: React.ElementType = as || ButtonDefaultElement

  return (
    <Element
      ref={ref}
      disabled={disabled || isLoading}
      className={classNames(
        classesForButtonSize[size],
        'flex justify-center items-center border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light',
        'disabled:opacity-50 disabled:pointer-events-none',
        'transition-colors ease-in-out duration-300',
        variant === 'solid' &&
          'border-transparent text-gray-dark bg-gray-lightest hover:bg-gray-light',
        variant === 'outline' &&
          'text-gray-dark bg-white hover:text-gray-darkest',
        fullWidth && 'w-full justify-center',
        className
      )}
      {...restProps}
      style={{ ...stylesForButtonSize[size], ...(restProps.style || {}) }}
    >
      {isLoading && <SpinnerIcon className="w-5 h-5 mr-3 -ml-1 animate-spin" />}
      {isLoading && loadingContent ? loadingContent : children}
    </Element>
  )
}) as PolymorphicForwardRefExoticComponent<
ButtonOwnProps,
typeof ButtonDefaultElement
>
