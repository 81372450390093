import type { IconProps } from './types'

export function CalendarIcon(props: IconProps) {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 4.68455H19.4211"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="square"
      />
      <rect
        x="6.78955"
        y="6.78955"
        width="0.526316"
        height="0.526315"
        rx="0.263158"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="6.78955"
        y="9.4209"
        width="0.526316"
        height="0.526314"
        rx="0.263157"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="6.78955"
        y="12.0527"
        width="0.526316"
        height="0.526318"
        rx="0.263158"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="3.63159"
        y="9.4209"
        width="0.526316"
        height="0.526314"
        rx="0.263157"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="3.63159"
        y="12.0527"
        width="0.526316"
        height="0.526318"
        rx="0.263158"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="9.94727"
        y="6.78955"
        width="0.526316"
        height="0.526315"
        rx="0.263158"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="9.94727"
        y="9.4209"
        width="0.526316"
        height="0.526314"
        rx="0.263157"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="9.94727"
        y="12.0527"
        width="0.526316"
        height="0.526318"
        rx="0.263158"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="13.1052"
        y="6.78955"
        width="0.526318"
        height="0.526315"
        rx="0.263158"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="13.1052"
        y="9.4209"
        width="0.526318"
        height="0.526314"
        rx="0.263157"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="16.2632"
        y="6.78955"
        width="0.526318"
        height="0.526315"
        rx="0.263158"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <rect
        x="16.2632"
        y="9.4209"
        width="0.526318"
        height="0.526314"
        rx="0.263157"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8422 17.8417C19.5863 17.8417 21.0001 16.4278 21.0001 14.6838C21.0001 12.9397 19.5863 11.5259 17.8422 11.5259C16.0982 11.5259 14.6843 12.9397 14.6843 14.6838C14.6843 16.4278 16.0982 17.8417 17.8422 17.8417Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        d="M19.421 14.683H17.842V13.104"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7256 14.6843H1.52632C1.23564 14.6843 1 14.4487 1 14.158V2.57905C1 2.28837 1.23564 2.05273 1.52632 2.05273V2.05273H18.8948C19.1854 2.05273 19.4211 2.28837 19.4211 2.57905V12.0854"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        d="M4.94724 1V3.10527"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M15.2104 1V3.10527"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
    </svg>
  )
}
