import { useToggle } from '@upper/hooks'
import { PropsWithChildren } from 'react'
import { Context } from './context'

const margin = 24
// eslint-disable-next-line @typescript-eslint/ban-types
export function Provider({ children }: PropsWithChildren<{}>) {
  const condensed = useToggle(true)
  const size = condensed.value ? 60 : 220
  const paddedSize = size + margin

  return (
    <Context.Provider value={{ size, margin, paddedSize, condensed }}>
      {children}
    </Context.Provider>
  )
}
