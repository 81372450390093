import type { IconProps } from './types'

export function VerificationPersonalInterviewIcon(props: IconProps) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.3131 20.9044L8.30437 22.9131V18.7392H2.04348C1.76673 18.7392 1.50132 18.6292 1.30563 18.4335C1.10994 18.2379 1 17.9724 1 17.6957V2.04348C1 1.76673 1.10994 1.50132 1.30563 1.30563C1.50132 1.10994 1.76673 1 2.04348 1H20.8261C21.1029 1 21.3683 1.10994 21.564 1.30563C21.7597 1.50132 21.8696 1.76673 21.8696 2.04348V9.34785"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4778 19.7827C12.4778 20.0594 12.5877 20.3248 12.7834 20.5205C12.9791 20.7162 13.2445 20.8261 13.5213 20.8261H17.6952L21.8691 25.0001V20.8261H23.9561C24.2328 20.8261 24.4982 20.7162 24.6939 20.5205C24.8896 20.3248 24.9996 20.0594 24.9996 19.7827V12.4783C24.9996 12.2015 24.8896 11.9361 24.6939 11.7404C24.4982 11.5448 24.2328 11.4348 23.9561 11.4348H13.5213C13.2445 11.4348 12.9791 11.5448 12.7834 11.7404C12.5877 11.9361 12.4778 12.2015 12.4778 12.4783V19.7827Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6085 15.8696C15.6601 15.8696 15.7106 15.8849 15.7535 15.9136C15.7964 15.9423 15.8298 15.983 15.8495 16.0307C15.8693 16.0783 15.8744 16.1308 15.8644 16.1814C15.8543 16.232 15.8295 16.2785 15.793 16.315C15.7565 16.3514 15.71 16.3763 15.6594 16.3864C15.6088 16.3964 15.5564 16.3913 15.5087 16.3715C15.461 16.3518 15.4203 16.3183 15.3916 16.2754C15.363 16.2325 15.3477 16.1821 15.3477 16.1305C15.3477 16.0613 15.3751 15.995 15.4241 15.946C15.473 15.8971 15.5393 15.8696 15.6085 15.8696Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7389 15.8696C18.7905 15.8696 18.8409 15.8849 18.8838 15.9136C18.9267 15.9423 18.9602 15.983 18.9799 16.0307C18.9997 16.0783 19.0048 16.1308 18.9948 16.1814C18.9847 16.232 18.9598 16.2785 18.9234 16.315C18.8869 16.3514 18.8404 16.3763 18.7898 16.3864C18.7392 16.3964 18.6867 16.3913 18.6391 16.3715C18.5914 16.3518 18.5507 16.3183 18.522 16.2754C18.4933 16.2325 18.478 16.1821 18.478 16.1305C18.478 16.0613 18.5055 15.995 18.5544 15.946C18.6034 15.8971 18.6697 15.8696 18.7389 15.8696Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8694 15.8696C21.921 15.8696 21.9714 15.8849 22.0143 15.9136C22.0572 15.9423 22.0907 15.983 22.1104 16.0307C22.1301 16.0783 22.1353 16.1308 22.1252 16.1814C22.1152 16.232 22.0903 16.2785 22.0539 16.315C22.0174 16.3514 21.9709 16.3763 21.9203 16.3864C21.8697 16.3964 21.8172 16.3913 21.7696 16.3715C21.7219 16.3518 21.6812 16.3183 21.6525 16.2754C21.6238 16.2325 21.6085 16.1821 21.6085 16.1305C21.6085 16.0613 21.636 15.995 21.6849 15.946C21.7339 15.8971 21.8002 15.8696 21.8694 15.8696Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.30408 5.17383V7.26079"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5649 5.17383V7.26079"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.363 14.5653C9.09124 14.3458 7.9188 13.7373 7.0072 12.8237"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
