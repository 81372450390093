import {
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
} from 'react'
import { Text } from '../text'
import { formatCurrency, formatDate } from '@upper/utils'
import { CompanyCardContext } from './company-card'
import { useToggle } from '@upper/hooks'
import React from 'react'
import { Tooltip, TooltipContent, TooltipTrigger } from '@upper/sapphire/ui'
import { classNames } from '../classnames'
import * as Icons from '@upper/sapphire/icons'

export type EngagementCardRate = {
  amount: number
  from: Date | null
  to: Date | null
}

interface Props {
  title?: string | ReactNode
  titleStudio?: string
  subtitle?: string | ReactNode
  code: string | ReactNode
  clientAmount?: number
  talentAmount?: number
  totalHours?: number
  rates?: EngagementCardRate[]
  isFreelancer?: boolean
  actions?: ReactNode
}
export const EngagementCard = ({
  children,
  code,
  title,
  titleStudio,
  subtitle,
  talentAmount,
  clientAmount,
  totalHours,
  rates,
  isFreelancer = false,
  actions,
}: PropsWithChildren<Props>) => {
  const foldedContext = useContext(CompanyCardContext)
  const folded = useToggle(foldedContext?.folded ?? false)

  useEffect(() => {
    if (foldedContext !== undefined) folded.setValue(foldedContext.folded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foldedContext])

  return (
    <section className="shadow-outer-stroke overflow-hidden rounded-2xl bg-white">
      <header className="text-gray-dark border-gray-dark/5 flex flex-wrap items-center gap-5 rounded-t-2xl border-b bg-[#F4F5FA] py-2 pl-5 pr-2">
        <div className="mr-auto flex flex-col items-baseline sm:flex-row sm:gap-6">
          <div className="flex items-center gap-2">
            <Text variant="h6">{title}</Text>
            {titleStudio && (
              <p className="flex items-center gap-1 rounded bg-[#DFE1EB] px-2 py-1 text-xs">
                <Icons.Building />
                <span>{titleStudio}</span>
              </p>
            )}
          </div>
          <div className="flex items-baseline gap-3">
            <p>{subtitle}</p>
          </div>
          <EngagementCode
            code={code}
            rates={rates}
            isFreelancer={isFreelancer}
          />
        </div>
        {actions && <div className="md:ml-auto">{actions}</div>}

        {!isFreelancer && (
          <Tooltip>
            <TooltipContent>Talent / Client</TooltipContent>
            <TooltipTrigger asChild>
              <p className="text-gray font-mono text-sm">
                <span>{formatCurrency(talentAmount, 0)}</span>
                <span className="mx-2">/</span>
                <span className="font-bold">
                  {formatCurrency(clientAmount, 0)}
                </span>
              </p>
            </TooltipTrigger>
          </Tooltip>
        )}

        {!isFreelancer && (
          <div className="flex w-[143px] items-center justify-between">
            <p className="font-mono">{(totalHours ?? 0).toFixed(2)}h</p>

            {foldedContext && (
              <FoldToggleButton
                className="ml-auto"
                onClick={folded.toggle}
                folded={folded.value}
              />
            )}
          </div>
        )}
      </header>
      <JobCardContext.Provider
        value={{ folded: folded.value, setFalse: folded.setFalse }}
      >
        <div className="min-h-[40px] overflow-hidden rounded-b-2xl">
          {children}
        </div>
      </JobCardContext.Provider>
    </section>
  )
}

type FoldToggleButtonProps = HTMLAttributes<HTMLButtonElement> & {
  folded?: boolean
}
const FoldToggleButton = ({
  folded,
  className,
  ...props
}: FoldToggleButtonProps) => {
  return (
    <Tooltip>
      <TooltipContent>{folded ? 'Unfold' : 'Fold'}</TooltipContent>
      <TooltipTrigger asChild>
        <button
          {...props}
          className={classNames('rounded-full bg-white p-2', className)}
        >
          {!folded ? <Icons.ChevronsDownUp /> : <Icons.ChevronsUpDown />}
        </button>
      </TooltipTrigger>
    </Tooltip>
  )
}

type JobCardContext = {
  folded: boolean
  setFalse: () => void
}

export const JobCardContext = React.createContext<JobCardContext>(
  undefined as any
)

type EngagementCodeProps = {
  rates?: EngagementCardRate[]
  code: string | ReactNode
  isFreelancer: boolean
}

function EngagementCode({ code, rates, isFreelancer }: EngagementCodeProps) {
  return (
    <Tooltip>
      <TooltipContent className="space-y-1 font-mono text-xs" side="bottom">
        {rates && rates?.length > 0
          ? rates?.map((r, i) => (
              <p key={`rate-${i}`}>
                <span>{formatCurrency(r.amount, 2)}</span>
                <span className="!text-gray">{` from `}</span>
                <span>{formatDate(r.from ?? new Date())}</span>
                {r.to ? (
                  <>
                    <span className="!text-gray">{` to `}</span>
                    <span>{formatDate(r.to)}</span>
                  </>
                ) : (
                  <>
                    <span className="!text-gray">{` to `}</span>
                    <span>present</span>
                  </>
                )}
              </p>
            ))
          : '—'}
      </TooltipContent>
      <TooltipTrigger>
        <p className="text-gray font-mono text-sm">{code}</p>
      </TooltipTrigger>
    </Tooltip>
  )
}
