import React, { useContext } from 'react';
import { ModalContext } from '../../providers/modals/ModalsProvider';
import Modal from './Modal';



export default function Modals() {
    const { modals, close } = useContext(ModalContext);

    const hasModals: boolean = modals.length > 0;
    if (!hasModals) {
        return <></>
    }

    return(
        <div className='modals_container' style={{ position: 'fixed', left: 0, top: 0, zIndex: 100, background: '#CCCCCC' }}>
            { modals.map(({zIndex, id, modalContent}, index) => {
                return(
                    <Modal key={index} zIndex={zIndex} modalId={id} 
                    onClose={(modalId) => close(modalId)} ModalContentNode={modalContent.ModalContentNode} type={modalContent?.type} additionalData={modalContent?.additionalData}>
                    </Modal>
                )
            }) }

        </div>
    )
}