import { Checkbox, CheckboxProps } from '@upper/ui'
import { useField } from 'formik'
import { FormikFieldProps } from './types'

type FormikCheckboxProps = FormikFieldProps & CheckboxProps

export function FormikCheckbox(props: FormikCheckboxProps) {
  const [field, meta] = useField(props)

  return (
    <Checkbox
      {...field}
      {...props}
      isInvalid={meta.error && meta.touched}
      invalidMessage={meta.error}
      checked={field.value}
      onChange={field.onChange}
    />
  )
}
