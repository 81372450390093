import type { IconProps } from './types'

export function RunningIcon(props: IconProps) {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.19189 9.77104C7.38695 8.57598 7.38695 6.63842 6.19189 5.44336"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1707 11.8972C15.3115 12.6031 15.6985 13.236 16.2625 13.6832L18.1239 15.1588C18.588 15.5268 18.7262 16.1721 18.4535 16.6978V16.6978C17.834 17.8922 16.4888 18.5206 15.1752 18.2294L13.0379 17.7555C12.3171 17.5957 11.6569 17.2335 11.1349 16.7115L5.53946 11.1161C4.15308 9.72968 4.15309 7.48193 5.53946 6.09555L6.65542 4.97959C7.50856 4.12645 8.9697 4.62915 9.11734 5.82661V5.82661C9.31254 7.40985 10.6483 8.60485 12.2435 8.62322L13.2455 8.63477C13.9901 8.64334 14.627 9.17191 14.7727 9.90211L15.1707 11.8972Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        d="M4.76124 6.87426C4.16615 7.46935 4.74242 8.24393 4.74242 8.24393L12.2461 15.7476C12.6727 16.1742 13.2166 16.4641 13.8086 16.5803L16.3263 17.0748C17.0264 17.2122 17.752 17.1261 18.4005 16.8285V16.8285"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <path
        d="M8.69345 4.5416L12.1518 1.08322"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M13.7777 8.62748C13.3869 7.9169 13.5378 7.36072 13.817 6.75615C14.2264 5.8692 16.444 4.04332 16.444 4.04332"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M15.0849 11.7219L14.1512 12.6557"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M14.895 10.8027L13.8649 11.8328"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M14.6596 9.8613L13.6295 10.8914"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
      />
      <path
        d="M11.1021 18.3536L4.12029 11.5521C3.59877 11.0441 3.57512 10.2137 4.06689 9.67676V9.67676"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4985 17.6407V17.6407C12.8245 17.8856 12.6482 18.4045 12.2405 18.4001L3.92273 18.3096C3.27399 18.3026 2.84598 17.6318 3.11293 17.0405L5.09253 12.6555"
        stroke="currentColor"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
