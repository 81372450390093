type Props = {
  value?: string
  onAccept?: () => void
  onDeny?: () => void
}
export function CreatableSkillPrompt({ value, onAccept, onDeny }: Props) {
  return (
    <div className="space-y-1 rounded-b-md border border-t-0 border-slate-200 bg-yellow-50 px-3 py-2 text-sm">
      We don't have <span className="font-semibold">"{value}"</span>
      skill in our database. Do you want to add it?
      <div className="flex gap-3">
        <button
          className="rounded-md bg-yellow-300 px-2 py-1"
          onClick={onAccept}
        >
          Yes
        </button>
        <button className="" onClick={onDeny}>
          No
        </button>
      </div>
    </div>
  )
}
