import { useEffect, useState } from 'react'

const defaultDebounceTime = 250

type DebounceValue = any

export function useDebouncedValue(
  value: DebounceValue,
  options: { delay: number } = { delay: defaultDebounceTime }
): DebounceValue {
  const [debouncedValue, setDebouncedValue] = useState<DebounceValue>()

  useEffect(() => {
    const interval = setInterval(() => setDebouncedValue(value), options.delay)
    return () => clearInterval(interval)
  }, [value, options])

  return debouncedValue
}
