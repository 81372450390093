import type { IconProps } from './types'

export function VerifyAccountIcon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 14.2352H32"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.6944 10.3101C31.4987 10.1116 31.2333 10 30.9565 10H9.04348C8.76673 10 8.50132 10.1116 8.30563 10.3101C8.10994 10.5087 8 10.778 8 11.0588V26.9412C8 27.222 8.10994 27.4913 8.30563 27.6899C8.50132 27.8884 8.76673 28 9.04348 28H30.9565C31.2333 28 31.4987 27.8884 31.6944 27.6899C31.8901 27.4913 32 27.222 32 26.9412V11.0588C32 10.778 31.8901 10.5087 31.6944 10.3101Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1304 19.5294H29.3913"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1304 22.7059H26.7826"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0049 25.3532C20.5791 24.6861 19.1673 24.2012 17.3996 23.5373C16.9009 23.3488 16.9822 22.0274 17.2035 21.7807C17.5553 21.394 17.8225 20.9362 17.9876 20.4373C18.1527 19.9383 18.2119 19.4096 18.1614 18.8859C18.1916 18.5595 18.1533 18.2303 18.0488 17.92C17.9444 17.6098 17.7762 17.3256 17.5555 17.0862C17.3347 16.8468 17.0664 16.6577 16.7683 16.5313C16.4702 16.4049 16.149 16.3442 15.8261 16.3532C15.5031 16.3442 15.182 16.4049 14.8838 16.5313C14.5857 16.6577 14.3174 16.8468 14.0967 17.0862C13.8759 17.3256 13.7078 17.6098 13.6033 17.92C13.4989 18.2303 13.4605 18.5595 13.4908 18.8859C13.4402 19.4096 13.4994 19.9383 13.6645 20.4373C13.8296 20.9362 14.0969 21.394 14.4487 21.7807C14.6699 22.0274 14.7513 23.3488 14.2525 23.5373C12.4869 24.2012 11.073 24.6861 10.6473 25.3532"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="26"
        y="22"
        width="12"
        height="12"
        rx="6"
        fill="white"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 28.1332L31.3893 30L35 26.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
