import type { IconProps } from './types'

export function CheckIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.05469 12.8125C6.28906 13.0469 6.6875 13.0469 6.92188 12.8125L13.8125 5.92188C14.0469 5.6875 14.0469 5.28906 13.8125 5.05469L12.9688 4.21094C12.7344 3.97656 12.3594 3.97656 12.125 4.21094L6.5 9.83594L3.85156 7.21094C3.61719 6.97656 3.24219 6.97656 3.00781 7.21094L2.16406 8.05469C1.92969 8.28906 1.92969 8.6875 2.16406 8.92188L6.05469 12.8125Z"
        fill="currentColor"
      />
    </svg>
  )
}
