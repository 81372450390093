import { IconProps } from '.'

export function PrimaryPositionIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.96875 10C9.1875 10 10.9688 8.21875 10.9688 6C10.9688 3.8125 9.1875 2 6.96875 2C4.78125 2 3 3.8125 3 6C3 8.21875 4.78125 10 6.96875 10ZM6.96875 3C8.625 3 9.96875 4.375 9.96875 6C9.96875 7.65625 8.625 9 6.96875 9C5.34375 9 3.96875 7.65625 3.96875 6C4 4.375 5.34375 3 6.96875 3ZM19.6875 13.4062L16.875 10.5938C16.5 10.2188 16 10 15.4688 10H12.9688C12.4375 10 11.9688 10.4688 11.9688 11V13.5C11.9688 14.0312 12.1875 14.5312 12.5625 14.9062L15.375 17.7188C15.5938 17.9062 15.8438 18.0312 16.0938 18.0312C16.3438 18.0312 16.5938 17.9062 16.8125 17.7188L19.6875 14.8438C20.0938 14.4375 20.0938 13.8125 19.6875 13.4062ZM16.0938 17L13.2812 14.1875C13.0938 14 12.9688 13.75 12.9688 13.5V11H15.4688C15.7188 11 15.9688 11.125 16.1562 11.3125L19 14.125L16.0938 17ZM14.4688 12C14.2188 12 13.9688 12.25 13.9688 12.5C13.9688 12.7812 14.2188 13 14.4688 13C14.75 13 14.9688 12.7812 14.9688 12.5C14.9688 12.25 14.75 12 14.4688 12ZM12.4688 17H1.5C1.21875 17 1 16.7812 1 16.5V15.2188C1 13.4375 2.40625 12 4.1875 12C4.8125 12 5.40625 12.5 7 12.5C8.5625 12.5 9.1875 12 9.78125 12C10.2188 12 10.625 12.0938 11 12.25V11.2188C10.5938 11.0938 10.2188 11 9.78125 11C8.875 11 8.46875 11.5 7 11.5C5.5 11.5 5.09375 11 4.1875 11C1.875 11 0 12.9062 0 15.2188V16.5C0 17.3438 0.65625 18 1.5 18H12.4688C12.9688 18 13.4062 17.7812 13.6875 17.4062L12.9375 16.6875C12.875 16.875 12.6875 17 12.4688 17Z"
        fill="currentColor"
      />
    </svg>
  )
}
