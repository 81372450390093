import * as React from 'react'
import { useAuth } from './provider'

export function WithAuthRedirect({
  children,
  authenticate,
  redirectAuthenticatedTo,
}: {
  children: React.ReactNode
  authenticate?: boolean
  redirectAuthenticatedTo?: string
}) {
  const { user } = useAuth()

  if (typeof window === 'undefined') return null

  if (user) {
    if (redirectAuthenticatedTo) {
      window.location.replace(redirectAuthenticatedTo)
      return null
    }
  } else {
    if (authenticate) {
      const url = new URL('/login', window.location.href)
      url.searchParams.append(
        'returnTo',
        window.location.pathname + window.location.search
      )
      window.location.replace(url.toString())
      return null
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
