/* eslint-disable @typescript-eslint/no-explicit-any */
// import { Spinner } from '@upper/ui'
import { createElement, CSSProperties, PropsWithChildren } from 'react'
import { useNavigation } from './navigation/use-navigation'
import { cn } from './utils'

type Props = {
  loading?: boolean
  noPadding?: boolean
  className?: string
  style?: CSSProperties
}
export const PageContent = ({
  children,
  noPadding,
  loading,
  className,
  style: initialStyle,
}: PropsWithChildren<Props>) => {
  const navigation = useNavigation()
  const paddingLeft = navigation.margin + navigation.paddedSize
  const style: any = { ...(initialStyle ?? {}) }
  if (!noPadding) {
    style.transition = 'all 300ms ease'
    style.paddingLeft = paddingLeft
  }
  const element = createElement(
    'section',
    {
      style,
      className: cn('overflow-auto block pr-6 pb-6', className),
    },
    children
  )
  return (
    <>
      {loading && (
        <div
          style={style}
          className="bg-gray-lightest/30 fixed left-0 right-0 bottom-0 top-0 z-[2] grid content-center justify-center"
        >
          {/* <Spinner /> */}
          Loading ...
        </div>
      )}
      {element}
    </>
  )
}
