import type { IconProps } from './types'

export function VerificationBusinessCheckIcon(props: IconProps) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.7391 25C22.1969 25 25 22.1969 25 18.7391C25 15.2814 22.1969 12.4783 18.7391 12.4783C15.2814 12.4783 12.4783 15.2814 12.4783 18.7391C12.4783 22.1969 15.2814 25 18.7391 25Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5294 16.9197L18.4981 20.9611C18.4306 21.0506 18.3448 21.1247 18.2463 21.1783C18.1479 21.2319 18.039 21.2638 17.9272 21.2718C17.8154 21.2798 17.7031 21.2638 17.598 21.2248C17.4929 21.1857 17.3974 21.1246 17.3179 21.0456L15.7527 19.4804"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.30435 9.86957C10.7536 9.86957 12.7391 7.88405 12.7391 5.43478C12.7391 2.98552 10.7536 1 8.30435 1C5.85509 1 3.86957 2.98552 3.86957 5.43478C3.86957 7.88405 5.85509 9.86957 8.30435 9.86957Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.34783 18.7394H1C0.999949 17.4279 1.35303 16.1406 2.02216 15.0126C2.6913 13.8846 3.6518 12.9575 4.80281 12.3288C5.95383 11.7001 7.2529 11.3929 8.56361 11.4394C9.87431 11.486 11.1483 11.8846 12.2518 12.5934"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
