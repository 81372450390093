import type { IconProps } from './types'

export function LockIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8125 6.875H12.1562V4.90625C12.1562 2.63672 10.2695 0.75 8 0.75C5.70312 0.75 3.84375 2.63672 3.84375 4.90625V6.875H3.1875C2.44922 6.875 1.875 7.47656 1.875 8.1875V13.4375C1.875 14.1758 2.44922 14.75 3.1875 14.75H12.8125C13.5234 14.75 14.125 14.1758 14.125 13.4375V8.1875C14.125 7.47656 13.5234 6.875 12.8125 6.875ZM9.96875 6.875H6.03125V4.90625C6.03125 3.83984 6.90625 2.9375 8 2.9375C9.06641 2.9375 9.96875 3.83984 9.96875 4.90625V6.875Z"
        fill="currentColor"
      />
    </svg>
  )
}
