import { ReactNode } from 'react'
import { classNames } from './classnames'

type BannerProps = {
  attached?: 'bottom'
  content: string | ReactNode
}
export function Banner({ content, attached }: BannerProps): JSX.Element {
  const isAttatched = !!attached
  const isAttatchedBottom = isAttatched && attached === 'bottom'
  const className = classNames(
    'text-sm py-2 px-2 bg-yellow text-gray-darkest',
    !isAttatched && 'rounded',
    isAttatched && 'mx-3',
    isAttatchedBottom && 'rounded-b'
  )
  return <aside className={className}>{content}</aside>
}
