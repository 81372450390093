import { createContext } from "react";


export type ContextField<T = any> = {
    identifier: string;
    value: any;
    isValid: boolean;
    errors: any;
    schema: T extends (infer R) ? R : any;
}

export type ContextProps<T = any> = {
    isValid: boolean;
    errors: Record<string, string>[]
    fields: ContextField<T>[]
    fieldValues: Record<string, any>
    schema: T extends (infer R) ? R : any;
    registerField: (identifier: string, value: any) => void;
    unregisterField: (identifier: string) => void;
    updateFieldValue: (identifier: string, value: any) => void;
}

const Context = createContext<ContextProps | undefined>(undefined)

export { Context }