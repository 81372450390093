import { useCallback, useRef } from "react"

export function useDebouncedCallback(f: any, debounce = 100) {
  const ref = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
  const setValue = useCallback(
    (params: any) => {
      if (ref.current) clearTimeout(ref.current)
      ref.current = setTimeout(() => f(params), debounce)
    },
    [debounce, f]
  )
  return setValue
}