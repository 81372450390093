import * as React from 'react'
import { FormikPasswordField, FormikSubmitButton } from '@upper/formik'
import { ResetPasswordDocument } from '@upper/graphql/auth'
import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import toast from 'react-hot-toast'
import { useClient } from 'urql'
import * as Yup from 'yup'
import Layout from './layout'

type ForgotPasswordPageProps = {
  appName: string
  background?: React.ReactNode
  noCredentials?: boolean
  noDescription?: boolean
  description?: string
  noBackToUpper?: boolean
}

export function ResetPasswordPage({
  appName,
  background,
  noCredentials,
  noDescription = false,
  description,
  noBackToUpper = false,
}: ForgotPasswordPageProps) {
  return (
    <Layout
      appName={appName}
      background={background}
      noCredentials={noCredentials}
      noDescription={noDescription}
      description={description}
      noBackToUpper={noBackToUpper}
    >
      <h1 className="mt-6 text-2xl font-semibold leading-none">
        Reset Password
      </h1>
      <p className="mt-2 text-sm text-gray-dark">Enter your new password</p>
      <div className="mt-8">
        <ResetPasswordForm />
      </div>
    </Layout>
  )
}

interface ForgotPasswordFormValues {
  email: string
  password: string
}

function ResetPasswordForm() {
  const router = useRouter()
  const client = useClient()
  const { email, token } = router.query as {
    token: string
    email: string
  }

  const initialValues: ForgotPasswordFormValues = {
    email: email as string,
    password: '',
  }

  React.useEffect(() => {
    if (!token || !email) {
      router.replace('/forgot-password')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, email])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string()
          .required('Required')
          .min(9, 'Should be at least 9 characters long')
          .test({
            name: 'minUppercase',
            exclusive: true,
            message: 'Should have at least 1 uppercase character',
            test(value) {
              return !value || (value?.match(/[A-Z]/g) || []).length >= 1
            },
          })
          .test({
            name: 'minSymbol',
            exclusive: true,
            message: 'Should have at least 1 special character',
            test(value) {
              return !value || (value?.match(/[@$!%*#?&]/g) || []).length >= 1
            },
          }),
      })}
      onSubmit={async (
        values: ForgotPasswordFormValues,
        { setSubmitting, setStatus }
      ) => {
        setSubmitting(true)
        try {
          const submitedResult = await client
            .mutation(ResetPasswordDocument, {
              data: { ...values, token, email },
            })
            .toPromise()
          const submited = !!submitedResult?.data?.resetPassword

          if (submitedResult.error) {
            setStatus(false)
            setSubmitting(false)
            return
          }
          if (submited) {
            router.push('/login')
          } else {
            toast.error('Reset password link is expired')
          }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          toast.error(error?.data?.message || 'Error, please try again', {
            id: 'forgot-password-error',
          })
          setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="space-y-8">
            <FormikPasswordField label="Password" name="password" />
            <FormikSubmitButton type="submit" fullWidth disabled={isSubmitting}>
              Save new password
            </FormikSubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}
