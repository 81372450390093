/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import update from 'immutability-helper'
import { md5 } from 'pure-md5'

export const pick = <T extends Record<string, any>, K extends keyof T>(
  value: T,
  ...keys: K[]
): Pick<T, K> => {
  if (!value || !keys.length) return {} as Pick<T, K>
  return keys.reduce((acc, k) => {
    if (k in value) acc[k] = value[k]
    return acc
  }, {} as Pick<T, K>)
}

// export const omit = <T extends Record<string, any>, K extends keyof T>(
//   value: T,
//   ...keys: K[]
// ): Omit<T, K> => {
//   if (!value || !keys) return value
//   // @ts-ignore: fixed value type
//   return update(value, { $unset: keys }) as Omit<T, K>
// }

export const omit = <T extends Record<string, any>>(
  value: T,
  ...keys: ReadonlyArray<keyof T>
) => {
  if (!value || !keys) return value
  // @ts-ignore: fixed value type
  return update(value, { $unset: keys })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const omitEmpty = <T extends Record<string, any>>(value: T) => {
  if (!value) return value
  // if (isArray(value) && value.length === 0) return null
  const keys = Object.keys(value).filter(
    (k) =>
      value[k] === '' ||
      value[k] === null ||
      (typeof value[k] === 'object' && value[k]?.length === 0)
  )

  // @ts-ignore: fixed value type
  return update(value, { $unset: keys })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nullifyEmpty = <T extends Record<string, any>>(value: T) => {
  if (!value) return value
  if (isArray(value) && value.length === 0) return null
  const keys = Object.keys(value).filter(
    (k) =>
      value[k] === '' ||
      value[k] === null ||
      (typeof value[k] === 'object' && value[k]?.length === 0)
  )

  // @ts-ignore: fixed value type
  return update(value, {
    // @ts-ignore: fixed value type
    $merge:
      // @ts-ignore: fixed value type
      keys?.reduce((acc: any, v) => {
        acc[v] = null
        return acc
      }, {}) ?? {},
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const omitUndefined = <T extends Record<string, any>>(value: T) => {
  if (!value) return value
  const keys = Object.keys(value).filter((v) => value[v] === undefined)

  // @ts-ignore: fixed value type
  return update(value, { $unset: keys })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const omitNull = <T extends Record<string, any>>(value: T) => {
  if (!value) return value
  const keys = Object.keys(value).filter((v) => value[v] === null)
  // @ts-ignore: fixed value type
  return update(value, { $unset: keys })
}

export const omitTypename = <T extends { __typename?: string }>(value: T) => {
  return omit<T>(value, '__typename')
}

export const merge = <T1, T2>(value1: T1, value2: T2) => {
  // @ts-ignore: fixed value type
  return update(value1, { $merge: value2 })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const countKeys = (
  object: Record<string, any>,
  comparator: (k: string, v: any) => boolean = () => true
) => {
  const result = 0
  if (!object) return result
  return Object.keys(object).reduce(function (r, k) {
    r = r || 0
    if (comparator(k, object[k])) r++
    if (isObject(object[k]) && !isArray(object[k])) {
      r = r + countKeys(object[k], comparator)
    }
    return r
  }, result)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepEqual = (
  object1: Record<string, any>,
  object2: Record<string, any>
) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
  return true
}

export const deepDiff = (
  object1: Record<string, any>,
  object2: Record<string, any>
) => {
  const diff: any = isArray(object1) ? [] : {}
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return null
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]

    console.log(key, val1, val2)

    const areArrays = isArray(val1) && isArray(val2)
    const areObjects = isObject(val1) && isObject(val2)

    if (areArrays) {
      const arrayDiff = deepDiff(val1, val2)
      diff[key] = arrayDiff
    } else if (areObjects) {
      const objectsDiff = deepDiff(val1, val2)
      if (objectsDiff) diff[key] = objectsDiff
    } else if (val1 !== val2) {
      diff[key] = val2
    }
  }
  return diff
}

export const isObject = (object: Record<string, any>) => {
  return object != null && typeof object === 'object'
}

export const isArray = (object: Record<string, any>) => {
  return Array.isArray(object)
}

export const hash = (object: Record<string, any>) => {
  const jsonString = JSON.stringify(object)

  return md5(jsonString)
}

export function objectsAreEqual(
  a: Record<string, any>,
  b: Record<string, any>
): boolean {
  function sortedObject(obj: Record<string, any>): Record<string, any> {
    return Object.keys(obj)
      .sort()
      .reduce((result, key) => {
        if (
          typeof obj[key] === 'object' &&
          obj[key] !== null &&
          !Array.isArray(obj[key])
        ) {
          result[key] = sortedObject(obj[key])
        } else {
          result[key] = obj[key]
        }
        return result
      }, {} as Record<string, any>)
  }

  return JSON.stringify(sortedObject(a)) === JSON.stringify(sortedObject(b))
}
