import { RefObject, useCallback, useEffect, useState } from 'react';

interface Size {
  width: number;
  height: number;
}

interface ElementSize extends Size {
  isOverflowing: boolean;
  scrollWidth: number;
  scrollHeight: number;
}

export function useElementSize(ref: RefObject<HTMLElement>): ElementSize {
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
    isOverflowing: false,
    scrollWidth: 0,
    scrollHeight: 0
  });

  const updateSize = useCallback(() => {
    if (ref.current) {
      const { offsetWidth, offsetHeight, scrollWidth, scrollHeight } = ref.current;
      console.log(scrollWidth, scrollHeight, offsetWidth, ref.current?.getBoundingClientRect())

      setSize({
        width: offsetWidth,
        height: offsetHeight,
        isOverflowing: scrollHeight > offsetHeight || scrollWidth > offsetWidth,
        scrollWidth,
        scrollHeight
      });
    }
  }, [ref]);

  useEffect(() => {
    updateSize(); // Initial size update

    const resizeObserver = new ResizeObserver(() => {
      updateSize();
    });

    const element = ref.current;
    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [ref, updateSize]);
  return size;
}

export default useElementSize;