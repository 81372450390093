import * as React from 'react'
import { classNames } from './classnames'

export type LabelProps = {
  color: 'gray' | 'green' | 'orange' | 'red'
  className?: string
  children: React.ReactNode
}

const LABEL_BG_COLORS: Record<LabelProps['color'], string> = {
  gray: 'bg-gray',
  green: 'bg-green',
  orange: 'bg-orange',
  red: 'bg-red',
}

export function Label({ color = 'gray', className, children }: LabelProps) {
  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-full text-xs px-2 py-0.5 text-white',
        LABEL_BG_COLORS[color],
        className
      )}
    >
      {children}
    </span>
  )
}
