import { IconProps } from '.'

export function StartDateIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0959 15.6392H9.04126C8.8772 15.6392 8.76001 15.7798 8.76001 15.9204V17.2329C8.76001 17.397 8.8772 17.5142 9.04126 17.5142H16.0959V18.6157C16.0959 19.1079 16.7053 19.3657 17.0569 19.0142L19.0725 16.9751C19.3069 16.7642 19.3069 16.4126 19.0725 16.2017L17.0569 14.1626C16.7053 13.811 16.0959 14.0688 16.0959 14.561V15.6392Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.76 4H15.26C16.0725 4 16.76 4.6875 16.76 5.5V12.0225C16.6756 12.0077 16.5887 12 16.5 12C16.2309 12 15.9784 12.0709 15.76 12.1949V8H3.76001V16.5C3.76001 16.7812 3.97876 17 4.26001 17H7.05001C7.125 17.3694 7.28146 17.7093 7.49982 18H4.26001C3.41626 18 2.76001 17.3438 2.76001 16.5V5.5C2.76001 4.6875 3.41626 4 4.26001 4H5.76001V2.375C5.76001 2.1875 5.91626 2 6.13501 2H6.38501C6.57251 2 6.76001 2.1875 6.76001 2.375V4H12.76V2.375C12.76 2.1875 12.9163 2 13.135 2H13.385C13.5725 2 13.76 2.1875 13.76 2.375V4ZM7.74878 14.7158C7.66689 14.7962 7.59051 14.8822 7.52026 14.9732C7.47702 14.9906 7.43102 15 7.38501 15H6.13501C5.91626 15 5.76001 14.8438 5.76001 14.625V13.375C5.76001 13.1875 5.91626 13 6.13501 13H7.38501C7.57251 13 7.76001 13.1875 7.76001 13.375V14.625C7.76001 14.6567 7.75608 14.6871 7.74878 14.7158ZM10.76 14H9.5C9.24238 14 8.99386 14.039 8.76001 14.1113V13.375C8.76001 13.1875 8.91626 13 9.13501 13H10.385C10.5725 13 10.76 13.1875 10.76 13.375V14ZM13.76 14H11.76V13.375C11.76 13.1875 11.9163 13 12.135 13H13.385C13.5725 13 13.76 13.1875 13.76 13.375V14ZM15.26 5H4.26001C3.97876 5 3.76001 5.25 3.76001 5.5V7H15.76V5.5C15.76 5.25 15.51 5 15.26 5ZM7.76001 11.625C7.76001 11.8438 7.57251 12 7.38501 12H6.13501C5.91626 12 5.76001 11.8438 5.76001 11.625V10.375C5.76001 10.1875 5.91626 10 6.13501 10H7.38501C7.57251 10 7.76001 10.1875 7.76001 10.375V11.625ZM10.76 11.625C10.76 11.8438 10.5725 12 10.385 12H9.13501C8.91626 12 8.76001 11.8438 8.76001 11.625V10.375C8.76001 10.1875 8.91626 10 9.13501 10H10.385C10.5725 10 10.76 10.1875 10.76 10.375V11.625ZM13.76 11.625C13.76 11.8438 13.5725 12 13.385 12H12.135C11.9163 12 11.76 11.8438 11.76 11.625V10.375C11.76 10.1875 11.9163 10 12.135 10H13.385C13.5725 10 13.76 10.1875 13.76 10.375V11.625Z"
        fill="currentColor"
      />
    </svg>
  )
}
