import { Time } from '@internationalized/date'
import * as Icons from '@upper/sapphire/icons'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
  Button,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@upper/sapphire/ui'
import {
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useMemo,
  useState,
} from 'react'
import { DateInput, DateSegment, TimeField } from 'react-aria-components'

type HeaderProps = {
  icon?: ReactNode
  tooltip?: string
  placeholder?: string
  value: string
  deletable?: boolean
  disabled?: boolean
  onChange: (value: string) => void
  onBlur?: () => void
  onDelete: () => void
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  customInput?: ReactNode
}

export const EntryHeader = ({
  icon,
  tooltip,
  value,
  deletable = true,
  disabled,
  onChange,
  onBlur,
  onDelete,
  inputProps,
  customInput,
}: HeaderProps) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleDelete = () => {
    setShowDialog(false)
    onDelete()
  }

  const handleCancelDelete = () => {
    setShowDialog(false)
  }

  const time = useMemo(() => {
    return new Time(
      parseInt(value.split(':')?.[0]),
      parseInt(value.split(':')?.[1])
    )
  }, [value])

  return (
    <header className="border-gray-lightest flex items-center gap-1 border-b px-2 py-1">
      <Tooltip>
        <TooltipContent>{tooltip}</TooltipContent>
        <TooltipTrigger disabled={disabled} tabIndex={20}>
          <span className="block flex-none">{icon}</span>
        </TooltipTrigger>
      </Tooltip>
      {customInput ?? (
        <TimeField
          hourCycle={24}
          className={'font-mono-chivo !ring-primary w-full text-sm font-light'}
          isReadOnly={disabled}
          aria-label="Time"
          value={time}
          hideTimeZone
          onChange={(value) => {
            const time = value.toString().split(':').slice(0, 2).join(':')
            onChange?.(time)
          }}
          onBlur={onBlur}
        >
          <DateInput className="!ring-primary flex w-full flex-initial !appearance-none gap-0 rounded-md border-none focus:!ring-1">
            {(segment) => (
              <DateSegment
                segment={segment}
                className={'!ring-primary data-[focused=true]:ring-primary'}
              />
            )}
          </DateInput>
        </TimeField>
        // <input
        //   tabIndex={0}
        //   type="text"
        //   placeholder={placeholder}
        //   className="!ring-blue-lighter flex w-full flex-initial !appearance-none rounded-md border-none !px-1 !py-0 font-mono-chivo !outline-none focus:!ring-1"
        //   value={value}
        //   onChange={(e) => onChange(e.target.value)}
        //   onBlur={onBlur}
        //   pattern="[0-9]{2}:[0-9]{2}"
        //   minLength={5}
        //   maxLength={5}
        //   {...inputProps}
        //   disabled={disabled}
        // />
      )}
      {deletable && !disabled && (
        <AlertDialog open={showDialog} onOpenChange={setShowDialog}>
          <AlertDialogContent className="text-center">
            <p className="text-gray-dark text-xl font-medium">
              Are you sure you want to delete this entry?
            </p>
            <span className="text-red">This action cannot be undone.</span>
            <Button onClick={handleDelete} variant={'destructive'}>
              Delete
            </Button>
            <Button onClick={handleCancelDelete} variant={'secondary'}>
              Cancel
            </Button>
          </AlertDialogContent>
          <AlertDialogTrigger asChild tabIndex={10}>
            <DeleteButton tooltip={'Delete entry'} />
          </AlertDialogTrigger>
        </AlertDialog>
      )}
    </header>
  )
}

const DeleteButton = forwardRef<
  HTMLButtonElement,
  { tooltip?: string } & HTMLAttributes<HTMLButtonElement>
>(({ children, tooltip, ...props }, ref) => (
  <Tooltip>
    <TooltipContent>{tooltip}</TooltipContent>
    <TooltipTrigger asChild>
      <button
        ref={ref}
        type="button"
        className="text-gray flex-none rounded p-1"
        {...props}
      >
        <Icons.Trash />
      </button>
    </TooltipTrigger>
  </Tooltip>
))
