import type { IconProps } from './types'

export function SearchIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.8359 12.8828L11.4922 10.5391C11.375 10.4453 11.2344 10.375 11.0938 10.375H10.7188C11.3516 9.55469 11.75 8.52344 11.75 7.375C11.75 4.70312 9.54688 2.5 6.875 2.5C4.17969 2.5 2 4.70312 2 7.375C2 10.0703 4.17969 12.25 6.875 12.25C8 12.25 9.03125 11.875 9.875 11.2188V11.6172C9.875 11.7578 9.92188 11.8984 10.0391 12.0156L12.3594 14.3359C12.5938 14.5703 12.9453 14.5703 13.1562 14.3359L13.8125 13.6797C14.0469 13.4688 14.0469 13.1172 13.8359 12.8828ZM6.875 10.375C5.21094 10.375 3.875 9.03906 3.875 7.375C3.875 5.73438 5.21094 4.375 6.875 4.375C8.51562 4.375 9.875 5.73438 9.875 7.375C9.875 9.03906 8.51562 10.375 6.875 10.375Z"
        fill="currentColor"
      />
    </svg>
  )
}
