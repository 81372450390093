import { useField } from 'formik'
import { NumberField, NumberFieldProps } from '@upper/ui'
import { FormikFieldProps } from './types'

type FormikNumberFieldProps = FormikFieldProps & NumberFieldProps

export function FormikNumberField(props: FormikNumberFieldProps) {
  const [field, meta, helpers] = useField(props)

  const { value } = meta
  const { setValue } = helpers

  return (
    <NumberField
      value={value}
      onBlur={field.onBlur}
      onValueChange={(values) => {
        setValue(values.floatValue)
      }}
      isInvalid={meta.error && meta.touched}
      invalidMessage={meta.error}
      {...props}
    />
  )
}
