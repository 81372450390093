import { useCallback, useRef, useState } from 'react'

export function useDebouncedState(initialValue?: any, debounce = 100) {
  const [debouncedValue, setDebouncedValue] = useState(initialValue)
  const ref = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
  const setValue = useCallback(
    (v: any) => {
      if (ref.current) clearTimeout(ref.current)
      ref.current = setTimeout(() => {
        setDebouncedValue(v)
      }, debounce)
    },
    [debounce]
  )
  return [debouncedValue, setValue]
}
