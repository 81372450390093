/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikSubmitButton, FormikTextField } from '@upper/formik'
import { Form, Formik } from 'formik'
import Link from 'next/link'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import Layout from './layout'
import { useAuth } from './provider'

type LoginPageProps = {
  appName?: string
  background?: React.ReactNode
  noCredentials?: boolean
  noDescription?: boolean
  description?: string
  noBackToUpper?: boolean
  onSubmit?: (values: LoginFormValues) => Promise<void>
}

export function LoginPage({
  appName,
  background,
  noCredentials,
  noDescription = false,
  description,
  noBackToUpper = false,
  onSubmit,
}: LoginPageProps) {
  return (
    <Layout
      appName={appName}
      background={background}
      noCredentials={noCredentials}
      noDescription={noDescription}
      description={description}
      noBackToUpper={noBackToUpper}
    >
      <h1 className="text-2xl font-semibold leading-none">Login</h1>
      <div className="mt-8 md:mt-12">
        <LoginForm onSubmit={onSubmit} />
      </div>
    </Layout>
  )
}

interface LoginFormValues {
  email: string
  password: string
}

function LoginForm({
  onSubmit,
}: {
  onSubmit?: (values: LoginFormValues) => Promise<void>
}) {
  const { login } = useAuth()

  const initialValues: LoginFormValues = {
    email: '',
    password: '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string().required('Required'),
      })}
      onSubmit={async (values: LoginFormValues) => {
        if (onSubmit) {
          await onSubmit(values)
        } else {
          try {
            await login(values)
          } catch (error: any) {
            toast.error(
              error?.data?.message || 'Error logging in, please try again',
              { id: 'login-error' }
            )
          }
        }
      }}
    >
      <Form>
        <div className="space-y-8">
          <FormikTextField name="email" placeholder="Email" />
          <div className="relative">
            <FormikTextField
              name="password"
              type="password"
              placeholder="Password"
              extra={
                <Link href="/forgot-password"
                    className="text-sm hover:underline text-muted bg-white p-1 whitespace-nowrap"
                  >
                    Forgot password?
                </Link>
              }
            />
          </div>
          <FormikSubmitButton fullWidth>Login</FormikSubmitButton>
        </div>
      </Form>
    </Formik>
  )
}
