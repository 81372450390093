import type { IconProps } from './types'

export function DotsHorizontalIcon(props: IconProps) {
  return (
    <svg
      width="10"
      height="3"
      viewBox="0 0 10 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.9375 1.25C5.9375 0.742188 5.50781 0.3125 5 0.3125C4.47266 0.3125 4.0625 0.742188 4.0625 1.25C4.0625 1.77734 4.47266 2.1875 5 2.1875C5.50781 2.1875 5.9375 1.77734 5.9375 1.25ZM8.28125 0.3125C7.75391 0.3125 7.34375 0.742188 7.34375 1.25C7.34375 1.77734 7.75391 2.1875 8.28125 2.1875C8.78906 2.1875 9.21875 1.77734 9.21875 1.25C9.21875 0.742188 8.78906 0.3125 8.28125 0.3125ZM1.71875 0.3125C1.19141 0.3125 0.78125 0.742188 0.78125 1.25C0.78125 1.77734 1.19141 2.1875 1.71875 2.1875C2.22656 2.1875 2.65625 1.77734 2.65625 1.25C2.65625 0.742188 2.22656 0.3125 1.71875 0.3125Z"
        fill="currentColor"
      />
    </svg>
  )
}
