import type { IconProps } from './types'

export function NavigationVerificationsIcon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.7391 32C29.1969 32 32 29.1969 32 25.7391C32 22.2814 29.1969 19.4783 25.7391 19.4783C22.2814 19.4783 19.4783 22.2814 19.4783 25.7391C19.4783 29.1969 22.2814 32 25.7391 32Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5293 23.9194L25.498 27.9608C25.4305 28.0504 25.3447 28.1245 25.2462 28.1781C25.1477 28.2317 25.0389 28.2636 24.9271 28.2716C24.8153 28.2796 24.703 28.2635 24.5979 28.2245C24.4928 28.1855 24.3973 28.1244 24.3178 28.0453L22.7526 26.4801"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3044 16.8696C17.7537 16.8696 19.7392 14.884 19.7392 12.4348C19.7392 9.98552 17.7537 8 15.3044 8C12.8551 8 10.8696 9.98552 10.8696 12.4348C10.8696 14.884 12.8551 16.8696 15.3044 16.8696Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3478 25.7394H8C7.99995 24.4279 8.35303 23.1406 9.02216 22.0126C9.6913 20.8846 10.6518 19.9575 11.8028 19.3288C12.9538 18.7001 14.2529 18.3929 15.5636 18.4394C16.8743 18.486 18.1483 18.8846 19.2518 19.5934"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
