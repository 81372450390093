import { IconProps } from '.'

export function CurrentDateIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 4H14V2.375C14 2.1875 13.8125 2 13.625 2H13.375C13.1562 2 13 2.1875 13 2.375V4H7V2.375C7 2.1875 6.8125 2 6.625 2H6.375C6.15625 2 6 2.1875 6 2.375V4H4.5C3.65625 4 3 4.6875 3 5.5V16.5C3 17.3438 3.65625 18 4.5 18H6.08535C6.03008 17.8436 6 17.6753 6 17.5C6 17.3247 6.03008 17.1564 6.08535 17H4.5C4.21875 17 4 16.7812 4 16.5V8H10H16V16.5C16 16.7812 15.75 17 15.5 17H13.9146C13.9699 17.1564 14 17.3247 14 17.5C14 17.6753 13.9699 17.8436 13.9146 18H15.5C16.3125 18 17 17.3438 17 16.5V5.5C17 4.6875 16.3125 4 15.5 4ZM8 14.625L8 14.6255C7.9997 14.844 7.81235 15 7.625 15H6.375C6.15625 15 6 14.8438 6 14.625V13.375C6 13.1875 6.15625 13 6.375 13H7.625C7.81237 13 7.99974 13.1872 8 13.3746L8 13.375V14.625ZM8 11.625V11.6253C7.99982 11.8439 7.81241 12 7.625 12H6.375C6.15625 12 6 11.8438 6 11.625V10.375C6 10.1875 6.15625 10 6.375 10H7.625C7.81246 10 7.99991 10.1874 8 10.3749V10.375V11.625ZM12.375 12C12.1562 12 12 11.8438 12 11.625V10.375C12 10.1875 12.1562 10 12.375 10H13.625C13.8125 10 14 10.1875 14 10.375V11.625C14 11.8438 13.8125 12 13.625 12H12.375ZM12 14.625V13.375C12 13.1875 12.1562 13 12.375 13H13.625C13.8125 13 14 13.1875 14 13.375V14.625C14 14.8438 13.8125 15 13.625 15H12.375C12.1562 15 12 14.8438 12 14.625ZM4.5 5H15.5C15.75 5 16 5.25 16 5.5V7H4V5.5C4 5.25 4.21875 5 4.5 5ZM8.97655 11.2812L8.97655 16.3359H7.89843C7.40624 16.3359 7.14843 16.9453 7.49999 17.2969L9.53905 19.3125C9.74999 19.5469 10.1016 19.5469 10.3125 19.3125L12.3516 17.2969C12.7031 16.9453 12.4453 16.3359 11.9531 16.3359H10.8516V11.2812C10.8516 11.1172 10.7344 11 10.5703 11H9.2578C9.11718 11 8.97655 11.1172 8.97655 11.2812Z"
        fill="currentColor"
      />
    </svg>
  )
}
