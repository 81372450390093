import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import {
  modalsInitialState,
  reducer,
  ModalsInitialStateType,
  ModalsActionTypes,
  ModalContent,
} from '../../reducers/ModalsReducer'

export const ModalContext =
  createContext<ModalsInitialStateType>(modalsInitialState)

export default function ModalsProvider({ children }: PropsWithChildren<{}>) {
  const [state, dispatch] = React.useReducer(reducer, modalsInitialState)

  const { modals, currentZ } = state

  const contextValue = useMemo(() => {
    return {
      modals,
      currentZ,
      open: (C: ModalContent) => {
        dispatch({ type: ModalsActionTypes.Open, payload: C })
      },
      close: (modalId: string) => {
        dispatch({ type: ModalsActionTypes.Close, payload: modalId })
      },
    }
  }, [state, dispatch])

  // const handleEscape = useCallback((event: KeyboardEvent) => {
  //     if (event.code === 'Escape') {
  //         if (Array.isArray(modals) && modals.length > 0) {
  //             dispatch({ type: ModalsActionTypes.Close, payload: modals[modals.length -1].id });
  //         }
  //         return false;
  //     }
  // }, [modals]);

  // useEffect(() => {
  //     if (typeof window !== 'undefined') {
  //         document.addEventListener('keydown', handleEscape, false);
  //     }
  //     return () => {
  //         document.removeEventListener('keydown', handleEscape, false);
  //     }
  // });

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  )
}
