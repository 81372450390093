import update from 'immutability-helper'
import { PropsWithChildren, useCallback, useState } from 'react'
import { Context } from './context'

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {}
export const Provider = ({ children }: PropsWithChildren<Props>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, setState] = useState<Context['state']>({})

  const add = useCallback((name: string) => {
    setState((s) => update(s, { $merge: { [name]: false } }))
  }, [])

  const remove = useCallback((name: string) => {
    setState((s) => update(s, { $unset: [name] }))
  }, [])

  const show = useCallback(
    <T,>(name: string, payload?: T) => {
      console.log(name)
      if (state[name] === undefined) return

      setState((s) =>
        update(s, { $merge: { [name]: payload ? payload : true } })
      )
    },
    [state]
  )

  const hide = useCallback((name: string) => {
    setState((s) =>
      s[name] !== undefined ? update(s, { [name]: { $set: false } }) : s
    )
  }, [])

  return (
    <Context.Provider value={{ state, add, remove, show, hide }}>
      {children}
    </Context.Provider>
  )
}
