import { IconProps } from './types'

export function AgencyIcon(props: IconProps) {
  return (
    <svg
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.2188 11.75H9.75V1.0625C9.75 0.757812 9.49219 0.5 9.1875 0.5H1.3125C0.984375 0.5 0.75 0.757812 0.75 1.0625V11.75H0.28125C0.117188 11.75 0 11.8906 0 12.0312V12.5H10.5V12.0312C10.5 11.8906 10.3594 11.75 10.2188 11.75ZM3 2.28125C3 2.14062 3.11719 2 3.28125 2H4.21875C4.35938 2 4.5 2.14062 4.5 2.28125V3.21875C4.5 3.38281 4.35938 3.5 4.21875 3.5H3.28125C3.11719 3.5 3 3.38281 3 3.21875V2.28125ZM3 4.53125C3 4.39062 3.11719 4.25 3.28125 4.25H4.21875C4.35938 4.25 4.5 4.39062 4.5 4.53125V5.46875C4.5 5.63281 4.35938 5.75 4.21875 5.75H3.28125C3.11719 5.75 3 5.63281 3 5.46875V4.53125ZM4.21875 8H3.28125C3.11719 8 3 7.88281 3 7.71875V6.78125C3 6.64062 3.11719 6.5 3.28125 6.5H4.21875C4.35938 6.5 4.5 6.64062 4.5 6.78125V7.71875C4.5 7.88281 4.35938 8 4.21875 8ZM6 11.75H4.5V9.78125C4.5 9.64062 4.61719 9.5 4.78125 9.5H5.71875C5.85938 9.5 6 9.64062 6 9.78125V11.75ZM7.5 7.71875C7.5 7.88281 7.35938 8 7.21875 8H6.28125C6.11719 8 6 7.88281 6 7.71875V6.78125C6 6.64062 6.11719 6.5 6.28125 6.5H7.21875C7.35938 6.5 7.5 6.64062 7.5 6.78125V7.71875ZM7.5 5.46875C7.5 5.63281 7.35938 5.75 7.21875 5.75H6.28125C6.11719 5.75 6 5.63281 6 5.46875V4.53125C6 4.39062 6.11719 4.25 6.28125 4.25H7.21875C7.35938 4.25 7.5 4.39062 7.5 4.53125V5.46875ZM7.5 3.21875C7.5 3.38281 7.35938 3.5 7.21875 3.5H6.28125C6.11719 3.5 6 3.38281 6 3.21875V2.28125C6 2.14062 6.11719 2 6.28125 2H7.21875C7.35938 2 7.5 2.14062 7.5 2.28125V3.21875Z"
        fill="currentColor"
      />
    </svg>
  )
}
