import type { IconProps } from './types'

export function NavigationMyJobsIcon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 21.8V16.9H15.7C16.6283 16.9 17.5185 16.5313 18.1749 15.8749C18.8313 15.2185 19.2 14.3283 19.2 13.4C19.2 13.0287 19.3475 12.6726 19.6101 12.4101C19.8726 12.1475 20.2287 12 20.6 12C20.8495 12 21.0966 12.0491 21.3271 12.1446C21.5576 12.2401 21.7671 12.3801 21.9435 12.5565C22.1199 12.7329 22.2599 12.9424 22.3554 13.1729C22.4509 13.4034 22.5 13.6505 22.5 13.9V14.8C22.5 14.9857 22.5738 15.1637 22.705 15.295C22.8363 15.4263 23.0143 15.5 23.2 15.5H23.4C23.6934 15.5001 23.9834 15.5616 24.2516 15.6807C24.5197 15.7997 24.7599 15.9736 24.9567 16.1911C25.1535 16.4087 25.3025 16.665 25.3942 16.9437C25.4859 17.2223 25.5182 17.5171 25.489 17.809L25.272 19.979C25.2028 20.6698 24.8794 21.3103 24.3646 21.7762C23.8498 22.242 23.1803 22.5 22.486 22.5H19.592C19.3319 22.5002 19.073 22.4638 18.823 22.392L17.123 21.908C16.873 21.8362 16.6141 21.7998 16.354 21.8H15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 27H19.5L13.5 31.5V27H10.5C10.1022 27 9.72064 26.842 9.43934 26.5607C9.15804 26.2794 9 25.8978 9 25.5V10.5C9 10.1022 9.15804 9.72064 9.43934 9.43934C9.72064 9.15804 10.1022 9 10.5 9H30C30.3978 9 30.7794 9.15804 31.0607 9.43934C31.342 9.72064 31.5 10.1022 31.5 10.5V25.5C31.5 25.8978 31.342 26.2794 31.0607 26.5607C30.7794 26.842 30.3978 27 30 27Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
