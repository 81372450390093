import * as DialogPrimitive from '@radix-ui/react-dialog'
import { CrossIcon } from '@upper/icons'
import { classNames } from '@upper/ui'
import * as React from 'react'

export type SlideOverProps = React.ComponentProps<
  typeof DialogPrimitive.Root
> & {
  children: React.ReactNode
}

export function SlideOver({ children, ...props }: SlideOverProps) {
  return (
    <DialogPrimitive.Root {...props}>
      <DialogPrimitive.Overlay className="fixed inset-0 bg-gray-darkest/75 state-open:animate-fade-in state-closed:animate-fade-out" />
      {children}
    </DialogPrimitive.Root>
  )
}
export const SlideOverContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof DialogPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <DialogPrimitive.Content
    {...props}
    ref={forwardedRef}
    className={classNames(
      'flex flex-col fixed inset-y-0 left-0 right-0 bg-white shadow-xl state-open:animate-slide-in state-closed:animate-slide-out sm:left-auto',
      className
    )}
  >
    {children}
    <div className="absolute top-0 right-0 flex pt-5 sm:pt-8 sm:left-0 sm:right-auto sm:-ml-8">
      <DialogPrimitive.Close className="inline-flex items-center justify-center w-8 h-8 text-white rounded-l-md bg-blue">
        <CrossIcon className="w-4 h-4" />
      </DialogPrimitive.Close>
    </div>
  </DialogPrimitive.Content>
))

export const SlideOverTrigger = DialogPrimitive.Trigger

export const SlideOverTitle = DialogPrimitive.Title

export const SlideOverClose = DialogPrimitive.Close

export function SliderOverActions({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex-shrink-0 px-5 py-4 bg-white shadow-lg sm:px-8">
      <div className="flex items-center space-x-2 sm:space-x-4 pb-safe">
        {children}
      </div>
    </div>
  )
}
