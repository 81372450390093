import { IconProps } from '.'

export function AgencyIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 19V2C17 1.44772 16.5523 1 16 1H4C3.44772 1 3 1.44772 3 2V19"
        stroke="currentColor"
      />
      <path d="M12 19V15H8V19" stroke="currentColor" />
      <path d="M3 4H17M17 12H3" stroke="currentColor" />
      <path d="M10 15V19" stroke="currentColor" strokeOpacity="0.4" />
      <path d="M10 4V12" stroke="currentColor" strokeOpacity="0.4" />
      <path d="M6 4V12" stroke="currentColor" strokeOpacity="0.4" />
      <path d="M14 4V12" stroke="currentColor" strokeOpacity="0.4" />
    </svg>
  )
}
