import * as RT from '@radix-ui/react-tooltip'
import React, { PropsWithChildren, forwardRef } from 'react'
import { classNames } from '..'

type TooltipProps = {
  content: React.ReactNode
  className?: string
  arrowClassName?: string
  arrowProps?: RT.TooltipArrowProps
  triggerProps?: RT.TooltipTriggerProps
  contentProps?: RT.TooltipContentProps
} & RT.TooltipProps

export const Tooltip = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<TooltipProps>
>(
  (
    {
      children,
      content,
      className,
      arrowClassName,
      arrowProps = {},
      triggerProps = {},
      contentProps = {},
      ...props
    },
    ref
  ) => {
    return (
      <RT.Root {...props}>
        <RT.Trigger ref={ref} type="button" {...triggerProps}>
          {children}
        </RT.Trigger>
        <RT.Portal>
          <RT.Content
            sideOffset={4}
            side="bottom"
            style={{ zIndex: 9999 }}
            collisionPadding={8}
            {...contentProps}
            className={classNames(
              'bg-blue rounded-md border-none px-2.5 py-1.5 text-xs text-white',
              className,
              contentProps.className
            )}
          >
            {content}
            <RT.Arrow
              offset={4}
              {...arrowProps}
              className={classNames(
                arrowClassName,
                arrowClassName ? 'fill-current' : 'fill-blue',
                arrowProps.className
              )}
            />
          </RT.Content>
        </RT.Portal>
      </RT.Root>
    )
  }
)
