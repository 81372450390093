import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { z } from "zod"
import { Context, ContextField } from "../context"

type Options = { defaultValue?: any, validateOnMount?: boolean, schema?: any, schemaPath?: string }
const DEFAULT_OPTIONS: Options = {
    validateOnMount: true,
    schema: z.any(),
}

export function useField(identifier: string, options?: Options): ContextField & { onChange: (value: any) => void } {
    const context = useContext(Context)

    if (!context) {
        throw new Error('useField must be used within a ValidatorProvider')
    }

    const [value, setValue] = useState(options?.defaultValue ?? null)
    const [isValid, setIsValid] = useState(false)
    const [errors, setErrors] = useState([])

    const memoOptions = useMemo(() => ({ ...DEFAULT_OPTIONS, ...options }), [options])

    const schema = useMemo(() => options?.schema ?? options?.schemaPath ? context.schema?.[options?.schemaPath ?? ''] : z.any(), [context.schema, options?.schema, options?.schemaPath])

    const memo = useMemo(() => {
        return {
            identifier,
            value,
            isValid,
            errors,
            schema,
        }
    }, [errors, identifier, isValid, schema, value])

    const validate = useCallback((v: any) => {
        const parse = schema.safeParse(v)
        setIsValid(parse?.success)
        setErrors(parse?.error?.issues)
        return parse?.success
    }, [schema])

    const handleChange = useCallback((value: any) => {
        validate(value)
        setValue(value)
    }, [validate])

    useEffect(() => {
        context.updateFieldValue(identifier, { value, isValid })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, isValid])

    useEffect(() => {
        if (memoOptions?.validateOnMount) {
            validate(value)
        }

        context.registerField(identifier, memoOptions?.defaultValue)
        return () => context.unregisterField(identifier)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { ...memo, onChange: handleChange }
}