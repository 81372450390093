import React, { useMemo, useState } from 'react'
import { PortalsContext } from './'
import { PortalsContainer } from './portals-container'
import { PortalsContainerElement } from './types'

export interface PortalsManagerProps {
  children: React.ReactNode
  container?: PortalsContainerElement
}

export function Provider({ children, container }: PortalsManagerProps) {
  const [portalContainerElement, setPortalContainerElement] =
    useState<PortalsContainerElement>(null)

  const currentContainer = container ?? portalContainerElement
  const contextValue = useMemo(
    () => ({ container: currentContainer }),
    [currentContainer]
  )

  return (
    <PortalsContext.Provider value={contextValue}>
      {children}
      {container ? null : <PortalsContainer ref={setPortalContainerElement} />}
    </PortalsContext.Provider>
  )
}
