import * as PO from '@radix-ui/react-popover'
import { forwardRef, PropsWithChildren, ReactNode } from 'react'
import { classNames } from '../classnames'

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {
  content: ReactNode
  triggerProps?: PO.PopoverTriggerProps
  contentProps?: PO.PopoverContentProps
  arrowProps?: PO.PopperArrowProps
} & PO.PopoverProps

export const Popover = forwardRef<any, PropsWithChildren<Props>>(
  (
    { content, children, triggerProps, contentProps, arrowProps, ...props },
    forwardedRef
  ) => {
    const { className: contentClassName, ...contentRestProps } =
      contentProps ?? {}
    return (
      <PO.Root {...props}>
        <PO.Trigger {...triggerProps}>{children}</PO.Trigger>
        <PO.Portal>
          <PO.Content
            sideOffset={8}
            collisionPadding={8}
            align="start"
            className={classNames(
              'rounded-md bg-white text-white shadow-lg',
              contentClassName
            )}
            style={{ zIndex: 100 }}
            {...contentRestProps}
            ref={forwardedRef}
          >
            {content}
            <PO.Arrow className="fill-current" {...arrowProps} />
          </PO.Content>
        </PO.Portal>
      </PO.Root>
    )
  }
)
