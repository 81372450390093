/* eslint-disable @typescript-eslint/no-namespace */
export function CompanyOffIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.54345 14.9566C2.34819 14.7613 2.34819 14.4447 2.54345 14.2494L15.9785 0.814415C16.1737 0.619153 16.4903 0.619153 16.6856 0.814416C16.8808 1.00968 16.8808 1.32626 16.6856 1.52152L3.25056 14.9566C3.05529 15.1518 2.73871 15.1518 2.54345 14.9566Z"
        fill="currentColor"
      />
      <path
        d="M4 15.907V17H3.375C3.15625 17 3 17.1875 3 17.375V18H17V17.375C17 17.1875 16.8125 17 16.625 17H16V3.90695L15 4.90695V17H11V14.3438C11 14.1562 10.8125 13.9688 10.625 13.9688H9.375C9.15625 13.9688 9 14.1562 9 14.3438V17H5V14.907L4 15.907Z"
        fill="currentColor"
      />
      <path
        d="M9 6.01215L8.0434 6.96875H7.375C7.15625 6.96875 7 6.8125 7 6.59375V5.34375C7 5.15625 7.15625 4.96875 7.375 4.96875H8.625C8.8125 4.96875 9 5.15625 9 5.34375V6.01215Z"
        fill="currentColor"
      />
      <path
        d="M12.0121 3H5V10.0121L4 11.0121V2.75C4 2.34375 4.3125 2 4.75 2H13.0121L12.0121 3Z"
        fill="currentColor"
      />
      <path
        d="M7.07654 12.8304C7.14312 12.9166 7.24863 12.9688 7.375 12.9688H8.625C8.8125 12.9688 9 12.8125 9 12.5938V11.3438C9 11.2362 8.93834 11.1287 8.85039 11.0566L7.07654 12.8304Z"
        fill="currentColor"
      />
      <path
        d="M11 8.90695V9.59375C11 9.8125 11.1562 9.96875 11.375 9.96875H12.625C12.8125 9.96875 13 9.8125 13 9.59375V8.34375C13 8.15625 12.8125 7.96875 12.625 7.96875H11.9382L11 8.90695Z"
        fill="currentColor"
      />
      <path
        d="M11.375 10.9688H12.625C12.8125 10.9688 13 11.1562 13 11.3438V12.5938C13 12.8125 12.8125 12.9688 12.625 12.9688H11.375C11.1562 12.9688 11 12.8125 11 12.5938V11.3438C11 11.1562 11.1562 10.9688 11.375 10.9688Z"
        fill="currentColor"
      />
    </svg>
  )
}
