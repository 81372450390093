'use client'

import { differenceInMinutes, startOfDay } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { useEffect, useState } from 'react'

// TODO: be able to accept a timezone
export function useTimeCursor(timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) {
  const [currentTime, setCurrentTime] = useState(new Date())
  const [minutesSinceStartOfDay, setMinutesSinceStartOfDay] = useState(0)

  useEffect(() => {
    const updateTime = () => {
      const now = timezone ? toZonedTime(new Date(), timezone) : new Date()
      setCurrentTime(now)
      setMinutesSinceStartOfDay(differenceInMinutes(now, startOfDay(now)))
    }

    updateTime() // Initial update

    const intervalId = setInterval(updateTime, 15000) // Update every 15 seconds

    return () => clearInterval(intervalId)
  }, [timezone])

  return { currentTime, minutesSinceStartOfDay }
}
