import { forwardRef, HTMLAttributes, useMemo } from 'react'
import { classNames } from '../classnames'
import { FilterFieldProps } from './field'

type Props = Pick<HTMLAttributes<HTMLInputElement>, 'placeholder'> &
  Pick<FilterFieldProps, 'label' | 'tooltip' | 'icon' | 'onClear'> & {
    value?: string
    onChange?: (value: string) => void
  }

export const SearchFilter = forwardRef<any, Props>(
  ({ label, value, icon, onClear, onChange, ...props }, ref) => {
    const paddingRight = useMemo(() => {
      let padding = 'pr-0'
      if (icon || onClear) {
        padding = 'pr-10'
      } else {
        padding = 'pr-16'
      }
      return padding
    }, [icon, onClear])

    const showClearButton = onChange && value

    return (
      <div className="relative">
        <input
          ref={ref}
          type="text"
          value={value}
          className={classNames(
            'inline-flex text-sm placeholder-gray min-w-[200px]',
            'p-3.5 rounded border border-gray-light focus:border-gray-light',
            'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue',
            'focus:text-blue',
            paddingRight
          )}
          {...props}
          onChange={(e) => onChange?.(e.target.value)}
        />
        <div className="absolute right-4 top-4 flex items-center gap-1">
          {showClearButton && (
            <span className="block h-5">
              <ClearButton onClick={onClear} />
            </span>
          )}
          {icon && <span className="block h-5">{icon}</span>}
        </div>
      </div>
    )
  }
)
SearchFilter.displayName = 'SearchFilter'

const ClearButton = (props: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className="p-1.5 block text-gray hover:text-gray-darkest transition-colors hover:bg-gray-lightest rounded-full"
      {...props}
    >
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.66016 3.625L6.41797 1.86719C6.64648 1.65625 6.64648 1.30469 6.41797 1.09375L6.03125 0.707031C5.82031 0.478516 5.46875 0.478516 5.25781 0.707031L3.5 2.46484L1.72461 0.707031C1.51367 0.478516 1.16211 0.478516 0.951172 0.707031L0.564453 1.09375C0.335938 1.30469 0.335938 1.65625 0.564453 1.86719L2.32227 3.625L0.564453 5.40039C0.335938 5.61133 0.335938 5.96289 0.564453 6.17383L0.951172 6.56055C1.16211 6.78906 1.51367 6.78906 1.72461 6.56055L3.5 4.80273L5.25781 6.56055C5.46875 6.78906 5.82031 6.78906 6.03125 6.56055L6.41797 6.17383C6.64648 5.96289 6.64648 5.61133 6.41797 5.40039L4.66016 3.625Z"
          fill="currentColor"
        />
      </svg>
    </button>
  )
}
