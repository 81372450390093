import { useValidatorField } from '@upper/providers'
import * as Icons from '@upper/sapphire/icons'
import { cn } from '@upper/sapphire/ui'
import {
  hoursToTimeString,
  numberStringToTime,
  timeStringToHours,
} from '@upper/utils'
import { useCallback, useEffect, useState } from 'react'
import { capitalize, words } from 'voca'
import { EntryHeader } from './entry-header'
import { EntryNote } from './entry-note'
import { TimesheetAbsence } from './types'

/**
 * Props for the AbsenceCard component.
 *
 * @typedef {Object} Props
 *
 * @property {TimesheetAbsence} data - The absence data to be displayed in the card.
 * @property {(id: string | null | undefined) => void} [onDelete] - Optional function that is called when the delete action is triggered.
 * @property {(id: string, data: Partial<TimesheetAbsence>) => void} [onUpdate] - Optional function that is called when the absence data needs to be updated.
 *                                                                               Note: This function is deprecated and will be removed in the release version.
 */
type Props = {
  data: TimesheetAbsence
  typeOptions?: string[]
  disabled?: boolean
  onDelete?: (id: string | null | undefined) => void
  onUpdate?: (id: string, data: Partial<TimesheetAbsence>) => void
}

/**
 * AbsenceCard Component. This component displays the details of an absence entry.
 * It provides the ability to delete the entry or update its duration, type, or note.
 *
 * @example
 * // Usage example
 * <AbsenceCard
 *    data={absenceData}
 *    onDelete={(id) => console.log(`Delete absence with id ${id}`)} //temporary
 *    onUpdate={(id, data) => console.log(`Update absence with id ${id}`, data)} //temporary
 * />
 *
 * @param {Props} props - The properties that define the component's behavior and display.
 *
 * @returns {JSX.Element} The rendered AbsenceCard component.
 */

enum TimesheetAbsenceType {
  Other = 'Other',
  SickLeave = 'SickLeave',
  SpecialLeave = 'SpecialLeave',
  Vacation = 'Vacation',
}

export const AbsenceCard = ({
  data,
  typeOptions,
  disabled,
  onUpdate,
  onDelete,
}: Props): JSX.Element => {
  const validatorField = useValidatorField(data.id ?? '', {
    schemaPath: 'absence',
    defaultValue: data,
  })

  const [time, setTime] = useState(hoursToTimeString(data.hours ?? 0))

  const handleUpdate = useCallback(
    (newData: Partial<TimesheetAbsence>) => {
      onUpdate?.(data.id ?? '', newData)
    },
    [data.id, onUpdate]
  )

  const handleHoursChange = useCallback(() => {
    const hours = timeStringToHours(time)
    handleUpdate({ hours })
    validatorField.onChange({ hours, notes: data.notes })
  }, [time, handleUpdate, validatorField, data.notes])

  const handleNotesChange = useCallback(
    (v: string) => {
      const hours = timeStringToHours(time)
      handleUpdate({ notes: v })
      validatorField.onChange({ hours, notes: v })
    },
    [handleUpdate, time, validatorField]
  )

  useEffect(() => {
    setTime(hoursToTimeString(data.hours ?? 0))
  }, [data.hours])

  return (
    <div
      className={cn(
        'border-gray-light rounded-xl border border-dashed bg-white',
        disabled && 'pointer-events-none'
      )}
    >
      <EntryHeader
        icon={<Icons.CircleOff />}
        tooltip="Absence"
        value={time}
        onChange={(v) => setTime(numberStringToTime(v))}
        onBlur={() => handleHoursChange}
        onDelete={() => onDelete?.(data.id)}
        customInput={
          <select
            className="!ring-primary font-mono-chivo w-full appearance-none rounded-md border-none px-1 py-0 text-sm font-light"
            placeholder="Type"
            value={data.hours ?? 8}
            onChange={(e) => handleUpdate({ hours: parseInt(e.target.value) })}
            disabled={disabled}
          >
            <option value={4}>Half Day</option>
            <option value={8}>All Day</option>
          </select>
        }
        disabled={disabled}
      />
      <div className="min-h-[20px] space-y-1 px-1 py-1">
        <select
          className="!ring-blue-lighter w-full appearance-none rounded-md border-none p-1 text-xs"
          placeholder="Type"
          value={data.type ?? TimesheetAbsenceType.Vacation}
          onChange={(e) => handleUpdate({ type: e.target.value })}
          disabled={disabled}
        >
          {typeOptions?.map((key) => (
            <option key={key} value={key}>
              {words(key)
                .map((w) => capitalize(w))
                .join(' ')}
            </option>
          ))}
        </select>
        <div className="bg-gray-lightest mx-1 h-[1px]" />
        <EntryNote
          value={data.notes ?? ''}
          onChange={(v) => handleNotesChange(v)}
          disabled={disabled}
          error={
            validatorField.errors?.find((e: any) => e.path.includes('notes'))
              ?.message
          }
        />
      </div>
    </div>
  )
}
