import type { IconProps } from './types'

export function Logo(props: IconProps) {
  return (
    <svg
      height="100%"
      viewBox="0 0 98 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3413 0.89209V8.78804C13.3413 11.7273 10.9788 14.1185 8.075 14.1185C5.17116 14.1185 2.8087 11.7273 2.8087 8.78804V0.89209H0V8.78804C0 13.316 3.62244 16.9999 8.075 16.9999C12.5276 16.9999 16.15 13.316 16.15 8.78804V0.89209H13.3413Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98 2.5933C97.2332 1.79546 96.3554 1.18373 95.4185 0.757308C94.3701 0.271115 93.2094 0 91.9876 0C89.8285 0 87.9091 0.79213 86.4485 2.10374C86.4089 2.13937 86.3474 2.11961 86.3346 2.06672L86.011 0.731557H83.55V17.85H86.3626V12.7251V10.2672C86.3626 8.19586 86.4836 6.19483 88.0164 4.65746C88.8449 3.82656 89.9179 3.25302 91.051 3.04364C92.2066 2.8301 93.4147 3.00004 94.473 3.52881C95.0426 3.81339 95.5644 4.19753 96.0113 4.66243"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.5734 7.664L76.5029 7.68304C76.003 5.18453 74.1055 2.9983 70.5385 2.97107C70.5229 2.971 70.5069 2.97093 70.4912 2.97093C68.8809 2.97093 67.3649 3.59643 66.2175 4.73556C65.3942 5.55281 64.832 6.56274 64.5734 7.664ZM70.7633 17.9999C70.6228 17.9999 70.5184 17.996 70.4567 17.9928C65.5536 17.9555 61.5843 13.9487 61.6001 9.04264C61.6078 6.65442 62.5455 4.41255 64.2405 2.72995C65.918 1.06469 68.1355 0.149902 70.4905 0.149902C70.5138 0.149902 70.5367 0.149973 70.5599 0.150184C73.19 0.170143 75.4433 1.09135 77.0763 2.81394C78.6147 4.43681 79.4577 6.66945 79.4499 9.10054L79.4453 10.5087L64.577 10.485C65.216 13.1619 67.6286 15.1627 70.5052 15.172L70.6001 15.1756C70.7917 15.187 73.9659 15.3285 75.6889 12.7652L78.0194 14.3428C75.7492 17.7198 71.957 17.9999 70.7633 17.9999Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0333 8.55943C44.0333 5.41298 46.5754 2.85313 49.7 2.85313C52.8246 2.85313 55.3666 5.41298 55.3666 8.55943C55.3666 11.7059 52.8246 14.2657 49.7 14.2657C46.5754 14.2657 44.0333 11.7059 44.0333 8.55943ZM44.1164 2.10625C44.0774 2.14049 44.0167 2.12301 44.0023 2.07301L43.6083 0.71327H41.2V20.4H44.0333V14.9387C45.5371 16.2942 47.5228 17.1189 49.7 17.1189C54.5041 17.1189 58.3774 13.1054 58.1937 8.22647C58.023 3.69438 54.313 0.0578303 49.8095 0.00069601C47.6295 -0.0269795 45.6354 0.77233 44.1164 2.10625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9334 8.55943C22.9334 5.41298 25.4755 2.85313 28.6001 2.85313C31.7247 2.85313 34.2668 5.41298 34.2668 8.55943C34.2668 11.7059 31.7247 14.2657 28.6001 14.2657C25.4755 14.2657 22.9334 11.7059 22.9334 8.55943ZM23.0166 2.10625C22.9776 2.14049 22.9169 2.12301 22.9024 2.07301L22.5084 0.71327H20.1001V20.4H22.9334V14.9387C24.4372 16.2942 26.423 17.1189 28.6001 17.1189C33.4042 17.1189 37.2776 13.1054 37.0938 8.22647C36.9232 3.69438 33.2131 0.0578303 28.7097 0.00069601C26.5296 -0.0269795 24.5355 0.77233 23.0166 2.10625Z"
        fill="currentColor"
      />
    </svg>
  )
}
