import { Tooltip, TooltipContent, TooltipTrigger } from '@upper/sapphire/ui'
import { ReactNode } from 'react'

type Props = { node: ReactNode }
export function CreatableSkillTooltip({ node }: Props) {
  return (
    <Tooltip>
      <TooltipContent className="max-w-sm bg-yellow-100">
        This skill doesn't exist in our database. It will be added as proposed.
      </TooltipContent>
      <TooltipTrigger>{node}</TooltipTrigger>
    </Tooltip>
  )
}
