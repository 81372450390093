import { PortalsContext } from '@upper/providers';
import { useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UsePortals {
}

export function usePortals() {
  const portalsManager = useContext(PortalsContext);

  if (!portalsManager) {
    throw new Error(
      'No portals manager was provided. The application must be wrapped in an <PortalsProvider> component.',
    );
  }

  return portalsManager;
}
