import {
  createElement,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactHTML,
} from 'react'
import { classNames } from './classnames'

type HeadingVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'title'
  | 'subtitle'

type Props = {
  as?: keyof ReactHTML
  variant?: HeadingVariant
  className?: string
} & Pick<HTMLAttributes<HTMLElement>, 'style'>

const Variant: Record<HeadingVariant, string> = {
  h1: 'text-4xl',
  h2: 'text-3xl',
  h3: 'text-2xl',
  h4: 'text-xl',
  h5: 'text-lg',
  h6: 'text-md',
  title: 'text-normal',
  subtitle: 'text-sm',
}

export const Text = forwardRef<any, PropsWithChildren<Props>>(
  ({ as = 'p', variant: style = 'h6', className, children, ...props }, ref) => {
    const component = createElement(
      as,
      {
        ...props,
        className: classNames('font-medium', Variant[style], className),
        ref,
      },
      children
    )
    return component
  }
)
