import { PropsWithChildren } from 'react'
import { classNames } from '../'

// eslint-disable-next-line @typescript-eslint/ban-types
export const FormHelpText = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <span
      className={classNames(
        'mt-1 block text-sm italic text-slate-400',
        className
      )}
    >
      {children}
    </span>
  )
}
