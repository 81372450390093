import { classNames } from '@upper/ui'
import { PropsWithChildren } from 'react'

type DisplayTextVariant = 'Title' | 'Subtitle'
type Props = {
  variant?: DisplayTextVariant
  className?: string
}

const STYLE: Record<DisplayTextVariant, string> = {
  Title: 'text-lg',
  Subtitle: 'text-base',
}

export function DisplayText({
  children,
  variant = 'Title',
  className,
}: PropsWithChildren<Props>) {
  return (
    <p className={classNames(STYLE[variant], 'font-semibold', className)}>
      {children}
    </p>
  )
}
