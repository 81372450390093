import { useCallback, useState } from 'react'
import { FilterField, FilterFieldProps } from '.'
import { classNames } from '../classnames'

type Props = {
  value?: boolean
  onChange?: (value: boolean | null) => void
} & FilterFieldProps
export function FilterBoolean({
  value: defaultValue,
  onChange,
  onClear,
  ...props
}: Props) {
  const [value, setValue] = useState<boolean | undefined | null>(defaultValue)
  const handleClear = useCallback(() => {
    setValue(null)
    onClear?.()
  }, [onClear])

  const handleYes = useCallback(() => {
    setValue(true)
    onChange?.(true)
  }, [onChange])

  const handleNo = useCallback(() => {
    setValue(false)
    onChange?.(false)
  }, [onChange])

  const displayMarkup = (
    <span className="text-blue hover:text-blue-dark">
      {value === true && 'Yes'}
      {value === false && 'No'}
    </span>
  )

  return (
    <FilterField
      display={displayMarkup}
      hasSelection={value === true || value === false}
      onClear={handleClear}
      {...props}
    >
      <div className="text-sm grid grid-cols-2">
        <button
          className={classNames(
            'py-3 px-6 border-r border-gray-light',
            value === true && 'bg-blue text-white'
          )}
          onClick={handleYes}
        >
          Yes
        </button>
        <button
          className={classNames(
            'py-3 px-6',
            value === false && 'bg-blue text-white'
          )}
          onClick={handleNo}
        >
          No
        </button>
      </div>
    </FilterField>
  )
}
