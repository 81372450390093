import { IconProps } from './types'

export const TalentVettingIcon = (props: IconProps) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6747 4.8916L3.26099 9.78321L14.6747 14.6748L26.0885 9.78321L14.6747 4.8916Z"
        fill="currentColor"
      />
      <path
        d="M22.8274 19.5665C22.0122 20.7894 7.33736 20.7894 6.52209 19.5665C5.97857 18.7512 6.52206 13.0443 6.52206 13.0443L14.6747 16.3054L22.8274 13.0443C22.8274 13.0443 23.3709 18.7512 22.8274 19.5665Z"
        fill="currentColor"
      />
      <path
        d="M3.66871 9.7832H4.89161V17.9359C4.89161 17.9359 5.02036 19.422 4.89161 20.3817C4.74963 21.4401 3.66871 22.8275 3.66871 22.8275L0 21.197C0 21.197 1.63674 20.3808 2.44581 19.5664C3.0068 19.0018 3.66871 17.9359 3.66871 17.9359V9.7832Z"
        fill="currentColor"
      />
    </svg>
  )
}
