import { forwardRef, ReactNode, useCallback } from 'react'
import { classNames } from './classnames'
import { Slot } from '@radix-ui/react-slot'

type ValueType = string | number | boolean | null | undefined

type Props = {
  options: ValueType[]
  value: ValueType
  onChange?: (value: ValueType) => void
  className?: string
  viewForState: (state: Props['value']) => ReactNode
}
export const MultiToggle = forwardRef<any, Props>(
  ({ options, value, onChange, viewForState, className }, ref) => {
    const toggle = useCallback(() => {
      const index = options.indexOf(value)
      let option = options[0]
      if (index === -1 || index + 1 === options.length) option = options[0]
      else option = options[index + 1]

      onChange?.(option)
    }, [value, options, onChange])

    if (value === undefined) return null

    return (
      <Slot ref={ref} className={classNames(className)} onClick={toggle}>
        {viewForState?.(value)}
      </Slot>
    )
  }
)

export type { ValueType as MultiToggleValue }
