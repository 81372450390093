import React, { ForwardedRef, forwardRef } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
export type PortalContainerProps = {}

function PortalsContainerComponent(
  _props: PortalContainerProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return <div id="PortalsContainer" ref={ref} />
}

export const PortalsContainer = forwardRef<
  HTMLDivElement,
  PortalContainerProps
>(PortalsContainerComponent)
