import { IconProps } from "."

export const ChatBubbleIcon = (props: IconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.25 0.75H2.75C1.76562 0.75 1 1.54297 1 2.5V10.375C1 11.3594 1.76562 12.125 2.75 12.125H5.375V14.4219C5.375 14.6953 5.67578 14.8594 5.89453 14.6953L9.3125 12.125H13.25C14.207 12.125 15 11.3594 15 10.375V2.5C15 1.54297 14.207 0.75 13.25 0.75ZM8.875 7.96875C8.875 8.10547 8.76562 8.1875 8.65625 8.1875H4.71875C4.58203 8.1875 4.5 8.10547 4.5 7.96875V7.53125C4.5 7.42188 4.58203 7.3125 4.71875 7.3125H8.65625C8.76562 7.3125 8.875 7.42188 8.875 7.53125V7.96875ZM11.5 5.34375C11.5 5.48047 11.3906 5.5625 11.2812 5.5625H4.71875C4.58203 5.5625 4.5 5.48047 4.5 5.34375V4.90625C4.5 4.79688 4.58203 4.6875 4.71875 4.6875H11.2812C11.3906 4.6875 11.5 4.79688 11.5 4.90625V5.34375Z"
        fill="currentColor"
      />
    </svg>
  )
}