import type { IconProps } from './types'

export function ContactPlusIcon(props: IconProps) {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.3125 6.4375H15.5625V4.6875C15.5625 4.46875 15.3438 4.25 15.125 4.25H14.25C14.0039 4.25 13.8125 4.46875 13.8125 4.6875V6.4375H12.0625C11.8164 6.4375 11.625 6.65625 11.625 6.875V7.75C11.625 7.99609 11.8164 8.1875 12.0625 8.1875H13.8125V9.9375C13.8125 10.1836 14.0039 10.375 14.25 10.375H15.125C15.3438 10.375 15.5625 10.1836 15.5625 9.9375V8.1875H17.3125C17.5312 8.1875 17.75 7.99609 17.75 7.75V6.875C17.75 6.65625 17.5312 6.4375 17.3125 6.4375ZM6.375 7.75C8.28906 7.75 9.875 6.19141 9.875 4.25C9.875 2.33594 8.28906 0.75 6.375 0.75C4.43359 0.75 2.875 2.33594 2.875 4.25C2.875 6.19141 4.43359 7.75 6.375 7.75ZM8.80859 8.625H8.34375C7.74219 8.92578 7.08594 9.0625 6.375 9.0625C5.66406 9.0625 4.98047 8.92578 4.37891 8.625H3.91406C1.89062 8.625 0.25 10.293 0.25 12.3164V13.4375C0.25 14.1758 0.824219 14.75 1.5625 14.75H11.1875C11.8984 14.75 12.5 14.1758 12.5 13.4375V12.3164C12.5 10.293 10.832 8.625 8.80859 8.625Z"
        fill="currentColor"
      />
    </svg>
  )
}
