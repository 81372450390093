export const TALENT_SOURCE_INFO: Record<string, string> = {
  Sourced:
    'Sourcer adds the talent or talent signs up via job link from sourcer',
  Referred: 'Community referral',
  Inbound: 'Sign up on website/app directly',
  Studio: 'Studio adds them',
  Scout: 'Scout refers them',
  'Pre-vetted': 'From the old database. This wont grow in the future',
  Growth: 'Outreach, marketing, etc',
}
