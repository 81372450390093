import * as React from 'react'
import { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import colors from 'tailwindcss/colors'
import { FormErrorMessage } from './form-error-message'
import { FormHelpText } from './form-help-text'
import { FormLabel } from './form-label'
import type { InvalidProps } from './types'

function MultiValueRemove(
  props: React.ComponentProps<typeof components.MultiValueRemove>
) {
  return (
    <components.MultiValueRemove {...props}>
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginTop: 1 }}
      >
        <path
          d="M5.52344 4L7.9375 1.60938L8.42969 1.11719C8.5 1.04688 8.5 0.929688 8.42969 0.835938L7.91406 0.320312C7.82031 0.25 7.70312 0.25 7.63281 0.320312L4.75 3.22656L1.84375 0.320312C1.77344 0.25 1.65625 0.25 1.5625 0.320312L1.04688 0.835938C0.976562 0.929688 0.976562 1.04688 1.04688 1.11719L3.95312 4L1.04688 6.90625C0.976562 6.97656 0.976562 7.09375 1.04688 7.1875L1.5625 7.70312C1.65625 7.77344 1.77344 7.77344 1.84375 7.70312L4.75 4.79688L7.14062 7.21094L7.63281 7.70312C7.70312 7.77344 7.82031 7.77344 7.91406 7.70312L8.42969 7.1875C8.5 7.09375 8.5 6.97656 8.42969 6.90625L5.52344 4Z"
          fill="#0F44D7"
        />
      </svg>
    </components.MultiValueRemove>
  )
}

function MultiValueContainer(
  props: React.ComponentProps<typeof components.MultiValueContainer> & {
    isCreated?: (o?: any) => boolean
    createdTooltip?: (node: React.ReactNode) => React.ReactNode
  }
) {
  const isCreate = props.isCreated?.(props.data)

  if (isCreate && props.createdTooltip)
    return props.createdTooltip?.(<components.MultiValueContainer {...props} />)
  else return <components.MultiValueContainer {...props} />
}

export type CreatableMultiComboBoxProps = {
  label?: React.ReactNode
  helpText?: React.ReactNode
  creatablePromopt?: React.ReactNode
  required?: boolean
  isCreated?: (o?: any) => boolean
  createdTooltip?: (node: React.ReactNode) => React.ReactNode
} & InvalidProps &
  React.ComponentProps<typeof AsyncCreatableSelect>

export function CreatableMultiComboBox({
  label,
  creatablePromopt: createPromopt,
  isInvalid,
  invalidMessage,
  styles,
  helpText,
  isCreated,
  createdTooltip,
  ...restProps
}: CreatableMultiComboBoxProps) {
  return (
    <div>
      {label && <FormLabel required={restProps.required}>{label}</FormLabel>}
      <AsyncCreatableSelect
        isMulti
        cacheOptions
        openMenuOnClick={false}
        noOptionsMessage={() => null}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          MultiValueRemove,
          MultiValueContainer: (props) =>
            MultiValueContainer({ ...props, createdTooltip, isCreated }) as any,
        }}
        styles={{
          ...styles,
          control: (base, state) => ({
            ...base,
            minHeight: 50,
            borderColor: state.isFocused
              ? '#5982F3'
              : isInvalid
              ? colors.red['500']
              : '#e7e9ef',
            outline: state.isFocused
              ? '1px solid #5982F3'
              : isInvalid
              ? `1px solid ${colors.red['500']}`
              : 'transparent',
            borderRadius: `${createPromopt ? `6px 6px 0 0` : '6px'}`,
            // boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.02)',
            background: 'white',
            '&:hover': {
              borderColor: state.isFocused
                ? '#5982F3'
                : isInvalid
                ? colors.red['500']
                : '#e7e9ef',
            },
          }),
          input: (base) => ({
            ...base,
            input: {
              fontWeight: 500,
            },
            'input:focus': {
              boxShadow: 'none',
            },
          }),
          placeholder: (base, state) => ({
            ...base,
            color: state.isDisabled ? '#e7e9ef' : '#a3a9bc',
            whiteSpace: 'nowrap',
            fontWeight: 500,
          }),
          multiValue: (base, props) => {
            const isCreate = isCreated?.(props.data)

            return {
              ...base,
              margin: 4,
              backgroundColor: isCreate ? '#FFF5D0' : '#F4F5F9',
              border: `1px solid ${
                isCreate ? 'rgba(70, 84, 122, 0.2)' : '#D0D4DF'
              }`,
              borderRadius: 9999,
            }
          },
          multiValueLabel: (base) => ({
            ...base,
            fontSize: 14,
            fontFamily:
              'Roboto mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
            color: '#46547A',
            padding: 5,
            paddingLeft: 14,
            paddingRight: 2,
          }),
          multiValueRemove: (base, props) => {
            const isCreate = isCreated?.(props.data)
            return {
              ...base,
              paddingLeft: 6,
              paddingRight: 10,
              borderTopRightRadius: 9999,
              borderBottomRightRadius: 9999,
              '&:hover': {
                backgroundColor: isCreate ? '#f6ecc2' : '#e7e9ef',
              },
            }
          },
        }}
        {...restProps}
      />
      {createPromopt}
      {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
      {helpText && <FormHelpText>{helpText}</FormHelpText>}
    </div>
  )
}
