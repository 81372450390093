import { ArrowUpToLine as ArrowUpToLineIcon, LucideProps } from 'lucide-react'
export function ArrowUpToLine(props: LucideProps) {
  return (
    <ArrowUpToLineIcon
      size={16}
      strokeWidth={1}
      absoluteStrokeWidth
      {...props}
    />
  )
}
