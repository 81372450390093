import type { IconProps } from './types'

export function ItalicIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5 3.375C11.5 3.1875 11.3125 3 11.125 3H6.625C6.41406 3 6.25 3.1875 6.25 3.375V4.125C6.25 4.33594 6.41406 4.5 6.625 4.5H7.70312L5.82812 12H4.375C4.16406 12 4 12.1875 4 12.375V13.125C4 13.3359 4.16406 13.5 4.375 13.5H8.875C9.0625 13.5 9.25 13.3359 9.25 13.125V12.375C9.25 12.1875 9.0625 12 8.875 12H7.77344L9.64844 4.5H11.125C11.3125 4.5 11.5 4.33594 11.5 4.125V3.375Z"
        fill="currentColor"
      />
    </svg>
  )
}
