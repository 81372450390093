import { useContext } from 'react'
import { NavigationContext } from '.'

export function useNavigation() {
  const navigation = useContext(NavigationContext)

  if (!navigation.condensed) {
    throw new Error('Please wrap your app in NavigationProvider')
  }
  return navigation
}
