/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, ReactNode } from 'react'
import Select from 'react-select'
import SelectAsync from 'react-select/async'
import colors from 'tailwindcss/colors'
import { FormErrorMessage } from './form-error-message'
import { FormHelpText } from './form-help-text'
import { FormLabel } from './form-label'
import { InvalidProps } from './types'

type BaseFieldProps = {
  label?: ReactNode
  menuWidth?: number | string
  asSkills?: boolean
  required?: boolean
  selectClassName?: string
  isAsync?: boolean
  helpText?: ReactNode
  beforeElementStyle?: (option: any) => any & CSSProperties
}

export type SelectFieldProps = BaseFieldProps &
  InvalidProps &
  React.ComponentProps<typeof Select>

export type SelectFieldAsyncProps = {
  isAsync?: boolean
} & BaseFieldProps &
  InvalidProps &
  React.ComponentProps<typeof SelectAsync>

export function SelectField({
  label,
  menuWidth,
  isAsync = false,
  isInvalid,
  invalidMessage,
  className,
  selectClassName,
  asSkills = true,
  beforeElementStyle = () => ({}),
  styles,
  helpText,
  ...restProps
}: SelectFieldProps | SelectFieldAsyncProps) {
  const selectSyncMarkup = (
    <Select
      className={selectClassName}
      styles={{
        ...defaultSelectStyles(styles, {
          isInvalid,
          menuWidth,
          asSkills,
          beforeElementStyle,
        }),
      }}
      components={defaultSelectComponents}
      {...restProps}
    />
  )
  const selectAsyncMarkup = (
    <SelectAsync
      className={selectClassName}
      styles={{
        ...defaultSelectStyles(styles, {
          isInvalid,
          menuWidth,
          asSkills,
          beforeElementStyle,
        }),
      }}
      components={defaultSelectComponents}
      {...restProps}
      noOptionsMessage={({ inputValue }) =>
        !inputValue ? 'Type to search ...' : 'No results found'
      }
    />
  )

  const selectMarkup = isAsync ? selectAsyncMarkup : selectSyncMarkup

  return (
    <div className={className}>
      {/* label */}
      {label && <FormLabel required={restProps.required}>{label}</FormLabel>}
      {/* select */}
      {selectMarkup}
      {/* error */}
      {isInvalid && <FormErrorMessage>{invalidMessage}</FormErrorMessage>}
      {/* help text */}
      {helpText && <FormHelpText>{helpText}</FormHelpText>}
    </div>
  )
}

const defaultSelectComponents = { IndicatorSeparator: () => null }

const defaultSelectStyles = (
  override: any,
  {
    isInvalid,
    menuWidth,
    asSkills,
    beforeElementStyle,
  }: {
    isInvalid: ReactNode | undefined
    menuWidth: BaseFieldProps['menuWidth']
    asSkills?: boolean
    beforeElementStyle: BaseFieldProps['beforeElementStyle']
  }
): SelectFieldProps['styles'] => ({
  singleValue: (base, state) => ({
    ...base,
    color: state.isDisabled ? '#a3a9bc' : '#46547A',
    fontWeight: 500,
    ...beforeElementStyle?.(state.data),
  }),
  multiValue: (base) => ({
    ...base,
    ...(asSkills
      ? {
          margin: 2,
          backgroundColor: '#F4F5F9',
          border: '1px solid #D0D4DF',
          borderRadius: 24,
        }
      : {
          margin: 2,
          backgroundColor: '#F4F5F9',
          borderRadius: 24,
        }),
  }),
  control: (base, state) => ({
    ...base,
    transition: 'all 300ms ease',
    color: '#46547A',
    fontWeight: 500,
    minHeight: 50,
    width: '100%',
    outline: isInvalid ? '1px solid' : '',
    outlineColor: state.isFocused
      ? colors.blue['500']
      : isInvalid
      ? colors.red['500']
      : '',
    borderColor: state.isFocused
      ? colors.blue['500']
      : isInvalid
      ? colors.red['500']
      : state.isDisabled
      ? '#e7e9ef'
      : '#e7e9ef',
    borderRadius: 6,
    // boxShadow: isInvalid
    //   ? '0 0 0 1px #E9705B, 0px 5px 5px rgba(0, 0, 0, 0.02)'
    //   : state.isFocused
    //   ? '0 0 0 1px #5982F3, 0px 5px 5px rgba(0, 0, 0, 0.02)'
    //   : '0 0 0 0 #000, 0px 5px 5px rgba(0, 0, 0, 0.02)',
    '&:hover': {
      borderColor: state.isFocused
        ? colors.blue['500']
        : isInvalid
        ? colors.red['500']
        : state.isDisabled
        ? '#F4F5F9'
        : '#e7e9ef',
    },
    background: 'white',
    pointerEvents: state.isDisabled ? 'none' : 'auto',
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isDisabled ? '#e7e9ef' : '#a3a9bc',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  }),
  valueContainer: (base, state) => ({
    ...base,
    whiteSpace: 'nowrap',
  }),
  input: (base, state) => ({
    ...base,
    input: {
      fontWeight: 500,
    },
    color: state.isDisabled ? '#a3a9bc' : '#46547A',
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: '#46547A',
    padding: 3,
    paddingLeft: 14,
    paddingRight: 2,
    fontSize: '1rem',
    ...(asSkills && {
      fontSize: 14,
      fontFamily:
        'Roboto mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    }),
  }),
  multiValueRemove: (base) => ({
    ...base,
    paddingLeft: 6,
    paddingRight: 10,
    borderTopRightRadius: 9999,
    borderBottomRightRadius: 9999,
    '&:hover': {
      backgroundColor: '#e7e9ef',
    },
  }),
  menu: (base) => ({
    ...base,
    width: menuWidth || '100%',
    color: '#46547A',
    fontWeight: 500,
  }),
  groupHeading: (base) => ({
    ...base,
    flex: '1 1',
    padding: '4px 12px',
    letterSpacing: '0.06rem',
    color: '#46547A',
    backgroundColor: '#F4F5F9',
    margin: 0,
  }),
  group: (base) => ({ ...base, margin: 0, padding: 0 }),
  menuList: (base) => ({ ...base, margin: 0, padding: 0, borderRadius: 4 }),
  ...override,
})
