import type { IconProps } from './types'

export function MinusIcon(props: IconProps) {
  return (
    <svg
      width={14}
      height={4}
      viewBox="0 0 14 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.25.438H1.75a.881.881 0 00-.875.875v.875c0 .492.383.875.875.875h10.5a.881.881 0 00.875-.875v-.875a.9.9 0 00-.875-.875z"
        fill="currentColor"
      />
    </svg>
  )
}
