import type { IconProps } from './types'

export function CrossIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.68946 8.11914L12.0332 5.77539C12.3379 5.49414 12.3379 5.02539 12.0332 4.74414L11.5176 4.22852C11.2364 3.92383 10.7676 3.92383 10.4864 4.22852L8.14258 6.57226L5.77539 4.22852C5.49414 3.92383 5.02539 3.92383 4.74414 4.22852L4.22852 4.74414C3.92383 5.02539 3.92383 5.49414 4.22852 5.77539L6.57227 8.11914L4.22852 10.4863C3.92383 10.7675 3.92383 11.2363 4.22852 11.5175L4.74414 12.0332C5.02539 12.3379 5.49414 12.3379 5.77539 12.0332L8.14258 9.68944L10.4864 12.0332C10.7676 12.3379 11.2364 12.3379 11.5176 12.0332L12.0332 11.5175C12.3379 11.2363 12.3379 10.7675 12.0332 10.4863L9.68946 8.11914Z"
        fill="currentColor"
      />
    </svg>
  )
}
