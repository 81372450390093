import { IconProps } from '.'

export function NextStepsIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83078 2H2L2 14H4.83078L7.87839 8L4.83078 2ZM4.83078 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H4.83078C5.20727 15 5.55185 14.7885 5.72235 14.4529L8.76997 8.45287C8.91453 8.16826 8.91453 7.83174 8.76997 7.54713L5.72235 1.54713C5.55185 1.21146 5.20727 1 4.83078 1Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8308 2H8.62954L11.2171 7.09427C11.5062 7.66348 11.5062 8.33652 11.2171 8.90573L8.62954 14H10.8308L13.8784 8L10.8308 2ZM10.8308 1H8.62954C7.88339 1 7.40006 1.78761 7.73796 2.45287L10.3255 7.54713C10.4701 7.83174 10.4701 8.16826 10.3255 8.45287L7.73796 13.5471C7.40006 14.2124 7.88339 15 8.62954 15H10.8308C11.2073 15 11.5519 14.7885 11.7224 14.4529L14.77 8.45287C14.9145 8.16826 14.9145 7.83174 14.77 7.54713L11.7224 1.54713C11.5519 1.21146 11.2073 1 10.8308 1Z"
        fill="currentColor"
      />
    </svg>
  )
}
