import { IconProps } from '.'

export function ProjectAgreementIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6205_1599)">
        <path
          d="M19.4501 8.45135C18.4188 8.7326 17.0438 9.4201 16.0438 10.0139C15.5751 10.2951 15.1063 10.5764 14.7313 10.7326C13.7626 11.2014 12.9188 11.6076 12.1063 11.6076C11.7001 11.6076 11.3876 11.4826 11.1688 11.2326C10.8251 10.7951 10.8251 10.1076 10.9501 9.2326C11.0438 8.4201 10.9501 7.76385 10.7001 7.45135C10.5751 7.2951 10.3876 7.2326 10.1063 7.26385C9.35631 7.2951 7.79381 8.57635 5.04381 11.3889L3.35631 13.1389L5.54381 7.2951C5.91881 6.2951 5.63756 5.2326 4.88756 4.5451C4.29381 4.01385 3.26256 3.70135 2.16881 4.4201L-0.143692 5.9826C-0.237442 6.07635 -0.268692 6.20135 -0.206192 6.32635L0.0750577 6.82635C0.137558 6.95135 0.293808 6.9826 0.418808 6.9201L2.79381 5.2951C3.01256 5.1701 3.23131 5.1076 3.45006 5.1076C3.76256 5.1076 4.01256 5.20135 4.23131 5.38885C4.63756 5.76385 4.79381 6.3576 4.60631 6.88885L1.48131 15.2326C1.41881 15.3889 1.45006 15.6389 1.54381 15.7951C1.60631 15.9201 1.76256 15.9826 1.88756 15.9826C2.04381 16.0139 2.20006 15.9514 2.29381 15.8576C3.66881 14.3889 7.23131 10.7014 9.07506 9.13885L9.70006 8.57635C9.79381 8.51385 9.95006 8.57635 9.95006 8.7326L9.88756 9.57635C9.82506 10.4514 9.82506 11.3264 10.4188 12.0451C10.7938 12.5139 11.3876 12.7326 12.1063 12.7326C13.1376 12.7326 14.1376 12.2639 15.1688 11.7639C15.4813 11.6076 15.9501 11.3264 16.3876 11.0451C17.3563 10.4514 18.6376 9.76385 19.5751 9.5451C19.6688 9.51385 19.7626 9.4201 19.7626 9.32635V8.76385C19.7626 8.5451 19.6063 8.4201 19.4501 8.45135Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6205_1599">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
