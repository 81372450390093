import { IconProps } from '.'

export function ActionStatusIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <circle cx="4.35294" cy="2.35294" r="1.85294" stroke="currentColor" />
        <circle cx="4.35294" cy="13.6471" r="1.85294" stroke="currentColor" />
        <circle cx="11.8824" cy="5.17647" r="1.85294" stroke="currentColor" />
        <line
          x1="4.38235"
          y1="3.76471"
          x2="4.38235"
          y2="12.2353"
          stroke="currentColor"
        />
        <path
          d="M4.35294 11.7647C6.70588 8.47059 11.8823 10.8235 11.8823 7.05882"
          stroke="currentColor"
        />
      </g>
    </svg>
  )
}
