/* eslint-disable @typescript-eslint/no-explicit-any */
export function unique<T = { id: string }>(items: T[] = [], key: keyof T): T[] {
  const unique: T[keyof T][] = [];
  const distinct: T[] = [];
  for (let i = 0; i < items.length; i++) {
    if (!unique.includes(items[i][key])) {
      distinct.push(items[i]);
      unique.push(items[i][key]);
    }
  }
  return distinct
}
export function uniqueStrings<T = string>(items: T[] = []): T[] {
  const unique: T[] = [];
  const distinct: T[] = [];
  for (let i = 0; i < items.length; i++) {
    if (!unique.includes(items[i])) {
      distinct.push(items[i]);
      unique.push(items[i]);
    }
  }
  return distinct
}
export const arrayUniqueByKey = <T = any>(array: T[], key: keyof T) => ([...new Map(array.map(item =>
  [item[key], item])).values()])