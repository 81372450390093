import chroma from 'chroma-js'
import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { classNames } from '../classnames'

const COLORS = [
  '#F53636',
  '#F05528',
  '#F17418',
  '#F19A0D',
  '#EBB711',
  '#D8CB0D',
  '#B8D917',
  '#8FE237',
  '#65D562',
  '#04D26F',
]

type Props = {
  className?: string
  labelForValue?: (value?: number) => string
  segments?: number
  value?: number
  readonly?: boolean
  onChange?: (value?: number) => void
  onClick?: () => void
}
export const RateSlider = ({
  className,
  segments = 10,
  value: initialValue = 0,
  readonly,
  labelForValue = defaultLabelForValue,
  onChange,
  onClick,
}: Props) => {
  const segmentsArray = Array.from(Array(segments).keys())
  const [isHover, setIsHover] = useState(false)
  const [hoverValue, setHoverValue] = useState(0)
  const [value, setValue] = useState<undefined | number>(initialValue)
  const getRatio = useCallback(
    (target: HTMLElement, x: number) => {
      const w = target?.getBoundingClientRect().width
      const ratio = Math.ceil((x / w) * segments)
      return ratio
    },
    [segments]
  )

  const handleMouseMove = useCallback(
    (e: ReactMouseEvent<HTMLButtonElement>) => {
      const ratio = getRatio(
        e.target as HTMLElement,
        e.clientX - (e.target as HTMLElement).getBoundingClientRect().x
      )
      if (ratio !== hoverValue) {
        setHoverValue(ratio)
      }
    },
    [getRatio, hoverValue]
  )

  const handleMouseEnter = (e: ReactMouseEvent<HTMLButtonElement>) => {
    if (readonly) return
    const ratio = getRatio(
      e.target as HTMLElement,
      e.clientX - (e.target as HTMLElement).getBoundingClientRect().x
    )
    setIsHover(true)
    setHoverValue(ratio)
  }

  const handleMouseLeave = (e: ReactMouseEvent<HTMLButtonElement>) => {
    if (readonly) return
    setIsHover(false)
    const ratio = getRatio(
      e.target as HTMLElement,
      e.clientX - (e.target as HTMLElement).getBoundingClientRect().x
    )
    setHoverValue(ratio)
  }

  const handleMouseClick = (e: ReactMouseEvent<HTMLButtonElement>) => {
    if (readonly) return
    const ratio = getRatio(
      e.target as HTMLElement,
      e.clientX - (e.target as HTMLElement).getBoundingClientRect().x
    )
    if (ratio === value) {
      setValue(undefined)
      onChange?.(undefined)
    } else {
      setValue(ratio)
      onChange?.(ratio)
    }
    onClick?.()
    e.stopPropagation()
  }

  const color = useMemo(() => {
    const colorRange = chroma.scale(COLORS).colors(segments)
    if (readonly) return `${colorRange[(value ?? 0) - 1]}`
    return isHover
      ? `${colorRange[hoverValue - 1]}`
      : `${colorRange[(value ?? 0) - 1]}`
  }, [hoverValue, isHover, readonly, segments, value])

  const width = useMemo(() => {
    if (readonly) return `${100 - 100 * ((value ?? 0) / segments)}%`
    return isHover
      ? `${100 - 100 * (hoverValue / segments)}%`
      : `${100 - 100 * ((value ?? 0) / segments)}%`
  }, [hoverValue, isHover, readonly, segments, value])

  const label = readonly
    ? labelForValue(value)
    : isHover
    ? labelForValue(hoverValue)
    : value !== 0
    ? labelForValue(value)
    : ''

  return (
    <div
      className={classNames(
        'flex items-center gap-2',
        readonly && 'pointer-events-none',
        className
      )}
    >
      <button
        type="button"
        className="relative z-0 h-4 w-full overflow-hidden rounded-full bg-[#F1F4FA] transition-colors"
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onClick={handleMouseClick}
        style={{
          background: color,
        }}
      >
        <div className="pointer-events-none relative z-[2] h-4 w-full rounded-full border border-black/10"></div>
        <div
          className="pointer-events-none absolute top-0 z-[1] h-4 w-full rounded-full"
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.06) 100%)`,
          }}
        ></div>
        <div
          className={classNames(
            `pointer-events-none absolute right-0 top-0 z-[0] h-4 bg-white`
          )}
          style={{
            width,
          }}
        ></div>
        <div className="pointer-events-none absolute bottom-px top-0 flex w-full">
          {segmentsArray?.map((s, i) => (
            <div
              key={`segment-${i}`}
              className="mt-1 h-2 w-full border-r border-black/10 last:border-none"
            ></div>
          ))}
        </div>
        {isHover && value === hoverValue && (
          <div
            className="pointer-events-none absolute bottom-0 scale-90 rounded-full bg-black/60 text-white"
            style={{ right: `calc(${width})`, transformOrigin:'center center' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-x-circle"
            >
              <path d="m15 9-6 6" />
              <path d="m9 9 6 6" />
            </svg>
          </div>
        )}
      </button>
      <span
        className="w-5 text-right font-mono text-sm font-medium leading-none"
        style={{
          color: color,
          opacity:
            (hoverValue || value) && ((hoverValue || value) ?? 0) > 0 ? 1 : 0,
        }}
      >
        {label}
      </span>
    </div>
  )
}

const defaultLabelForValue = (v?: number) => (v ? v?.toString() : '')
