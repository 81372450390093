import { TalentProfile, User } from '@upper/graphql/internal'
import { formatName, formatNameInitials } from '@upper/utils'
import { forwardRef } from 'react'
import { classNames } from './classnames'

const styleForSize: Record<AvatarSize, Record<'width' | 'height', number>> = {
  xs: { width: 32, height: 32 },
  sm: { width: 36, height: 36 },
  md: { width: 40, height: 40 },
  lg: { width: 48, height: 48 },
  xl: { width: 60, height: 60 },
  '2xl': { width: 84, height: 84 },
}

const classesForSize: Record<AvatarSize, string> = {
  xs: 'rounded text-xs',
  sm: 'rounded text-sm',
  md: 'rounded-lg text-sm',
  lg: 'rounded-lg text-lg',
  xl: 'rounded-lg text-lg',
  '2xl': 'rounded-lg text-lg',
}

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export type AvatarProps = {
  user: Pick<User, 'firstName' | 'lastName' | 'profilePicUrl'>
  profile?: Pick<TalentProfile, 'profilePicUrl'>
  size?: AvatarSize
  changeable?: boolean
  className?: string
  onChange?: () => void
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    { user, className, profile, size = 'md', changeable = false, onChange },
    ref
  ) => {
    const imageUrl = user?.profilePicUrl ?? profile?.profilePicUrl
    return (
      <div
        className={classNames(
          classesForSize[size],
          user ? 'bg-slate-600' : 'bg-slate-400',
          changeable ? 'relative' : '',
          'group flex-none overflow-hidden font-mono tracking-wide text-white',
          className
        )}
        ref={ref}
        style={{
          ...styleForSize[size],
        }}
        title={formatName(user)}
      >
        {changeable && (
          <button
            className="absolute bottom-1 right-1 rounded-md bg-white p-2 opacity-0 group-hover:opacity-100"
            onClick={onChange}
          >
            <svg
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.707 3.04297C11.0781 2.67188 11.0781 2.06641 10.707 1.71484L9.53516 0.542969C9.18359 0.171875 8.57812 0.171875 8.20703 0.542969L7.30859 1.44141C7.23047 1.51953 7.23047 1.67578 7.30859 1.77344L9.47656 3.94141C9.57422 4.01953 9.73047 4.01953 9.80859 3.94141L10.707 3.04297ZM6.54688 2.20312L1.41016 7.33984L1 9.70312C0.941406 10.0352 1.21484 10.3086 1.54688 10.25L3.91016 9.83984L9.04688 4.70312C9.14453 4.625 9.14453 4.46875 9.04688 4.37109L6.87891 2.20312C6.78125 2.125 6.625 2.125 6.54688 2.20312ZM3.42188 6.89062C3.30469 6.79297 3.30469 6.61719 3.42188 6.51953L6.42969 3.51172C6.52734 3.39453 6.70312 3.39453 6.80078 3.51172C6.91797 3.60938 6.91797 3.78516 6.80078 3.88281L3.79297 6.89062C3.69531 7.00781 3.51953 7.00781 3.42188 6.89062ZM2.71875 8.53125H3.65625V9.25391L2.38672 9.46875L1.78125 8.86328L1.99609 7.59375H2.71875V8.53125Z"
                fill="#46547A"
              />
            </svg>
          </button>
        )}
        {user &&
          (imageUrl ? (
            <img
              src={imageUrl}
              alt={formatNameInitials({
                firstName: user.firstName ?? '',
                lastName: user.lastName ?? '',
              })}
              className="h-full w-full overflow-hidden object-cover text-xs font-light"
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center text-center">
              {formatNameInitials(user)}
            </div>
          ))}
      </div>
    )
  }
)
