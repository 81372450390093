import { IconProps } from './types'

export function LinkedinIcon(props: IconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.5"
        d="M13 0H0.96875C0.4375 0 0 0.46875 0 1.03125V13C0 13.5625 0.4375 14 0.96875 14H13C13.5312 14 14 13.5625 14 13V1.03125C14 0.46875 13.5312 0 13 0ZM4.21875 12H2.15625V5.34375H4.21875V12ZM3.1875 4.40625C2.5 4.40625 1.96875 3.875 1.96875 3.21875C1.96875 2.5625 2.5 2 3.1875 2C3.84375 2 4.375 2.5625 4.375 3.21875C4.375 3.875 3.84375 4.40625 3.1875 4.40625ZM12 12H9.90625V8.75C9.90625 8 9.90625 7 8.84375 7C7.75 7 7.59375 7.84375 7.59375 8.71875V12H5.53125V5.34375H7.5V6.25H7.53125C7.8125 5.71875 8.5 5.15625 9.5 5.15625C11.5938 5.15625 12 6.5625 12 8.34375V12Z"
        fill="currentColor"
      />
    </svg>
  )
}
