export function StatusIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2913 9.0625L10.6975 2.46875C10.4163 2.1875 10.0413 2 9.63501 2H3.26001C2.41626 2 1.76001 2.6875 1.76001 3.5V9.90625C1.76001 10.3125 1.91626 10.6875 2.19751 10.9688L8.79126 17.5625C9.38501 18.1562 10.3538 18.1562 10.9163 17.5625L17.2913 11.1875C17.885 10.625 17.885 9.65625 17.2913 9.0625ZM16.6038 10.5L10.2288 16.875C10.01 17.0625 9.69751 17.0625 9.51001 16.875L2.88501 10.25C2.79126 10.1562 2.72876 10.0312 2.72876 9.90625H2.76001V3.5C2.76001 3.25 2.97876 3 3.26001 3H9.63501C9.76001 3 9.88501 3.0625 9.97876 3.15625L16.6038 9.78125C16.7913 9.96875 16.7913 10.2812 16.6038 10.5ZM6.26001 5.875C6.60376 5.875 6.88501 6.15625 6.88501 6.5C6.88501 6.875 6.60376 7.125 6.26001 7.125C5.88501 7.125 5.63501 6.875 5.63501 6.5C5.63501 6.15625 5.88501 5.875 6.26001 5.875ZM6.26001 5C5.41626 5 4.76001 5.6875 4.76001 6.5C4.76001 7.34375 5.41626 8 6.26001 8C7.07251 8 7.76001 7.34375 7.76001 6.5C7.76001 5.6875 7.07251 5 6.26001 5Z"
        fill="currentColor"
      />
    </svg>
  )
}
