import { useField } from 'formik'
import { SegmentedControl, SegmentedControlProps } from '@upper/ui'
import { FormikFieldProps } from './types'

type FormikSegmentedControlProps = FormikFieldProps &
  Omit<SegmentedControlProps, 'value' | 'onChange'>

export function FormikSegmentedControl({
  name,
  ...restProps
}: FormikSegmentedControlProps) {
  const [_, meta, helpers] = useField(name)

  const { value } = meta
  const { setValue } = helpers

  return (
    <SegmentedControl
      isInvalid={meta.error && meta.touched}
      invalidMessage={meta.error}
      value={value}
      onChange={(value) => {
        setValue(value)
      }}
      {...restProps}
    />
  )
}
