type Props = { skills?: string[] }
export function CreatableSkillInfo({ skills }: Props) {
  return (
    <div className="flex gap-2 px-3">
      <p className="rounded-full w-6 h-4 bg-[#FFF5D0] border border-gray-dark/20"></p>
      <p className="text-black/60 text-xs">
        <span className="font-semibold">{skills?.join(', ')}</span>{' '}
        {skills?.length === 1 ? 'skill' : 'skills'} will be verified.
      </p>
    </div>
  )
}
