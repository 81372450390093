import { IconProps } from './types'

export function ExternalLinkIcon(props: IconProps) {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.4375 6.5H7.8125C7.63672 6.5 7.5 6.65625 7.5 6.8125V9H1.25V2.75H4.0625C4.21875 2.75 4.375 2.61328 4.375 2.4375V1.8125C4.375 1.65625 4.21875 1.5 4.0625 1.5H0.9375C0.410156 1.5 0 1.92969 0 2.4375V9.3125C0 9.83984 0.410156 10.25 0.9375 10.25H7.8125C8.32031 10.25 8.75 9.83984 8.75 9.3125V6.8125C8.75 6.65625 8.59375 6.5 8.4375 6.5ZM9.25781 0.25H6.17188C5.85938 0.25 5.625 0.503906 5.625 0.796875V1.16797C5.625 1.46094 5.85938 1.69531 6.15234 1.69531C6.17188 1.69531 6.17188 1.69531 6.17188 1.69531L7.5 1.65625L2.63672 6.5C2.53906 6.57812 2.48047 6.69531 2.48047 6.83203C2.48047 6.94922 2.53906 7.06641 2.63672 7.16406L3.08594 7.61328H3.10547C3.18359 7.71094 3.30078 7.75 3.41797 7.75C3.55469 7.75 3.67188 7.71094 3.75 7.61328L8.59375 2.75L8.55469 4.07812C8.55469 4.39062 8.78906 4.625 9.10156 4.625H9.45312C9.74609 4.625 10 4.39062 10 4.07812V0.992188C10 0.582031 9.66797 0.25 9.25781 0.25Z"
        fill="currentColor"
      />
    </svg>
  )
}
