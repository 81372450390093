import { useCallback } from 'react';

type CopyFn = (text: string) => Promise<boolean> // Return success

export function useClipboard(): CopyFn {
  const copyToClipboard = useCallback(async (text: string) => {
    try {
      const result = await navigator.clipboard?.writeText?.(text);
      console.log('Text successfully copied to clipboard');
      return true
    } catch (err) {
      console.error('Failed to copy text to clipboard', err);
      return false
    }
  }, []);


  return copyToClipboard
}
