import type { IconProps } from './types'

export function NotificationIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 14.75C8.95703 14.75 9.72266 13.9844 9.72266 13H6.25C6.25 13.9844 7.01562 14.75 8 14.75ZM13.8789 10.6758C13.3594 10.1016 12.3477 9.25391 12.3477 6.4375C12.3477 4.33203 10.8711 2.63672 8.84766 2.19922V1.625C8.84766 1.16016 8.46484 0.75 8 0.75C7.50781 0.75 7.125 1.16016 7.125 1.625V2.19922C5.10156 2.63672 3.625 4.33203 3.625 6.4375C3.625 9.25391 2.61328 10.1016 2.09375 10.6758C1.92969 10.8398 1.84766 11.0586 1.875 11.25C1.875 11.7148 2.20312 12.125 2.75 12.125H13.2227C13.7695 12.125 14.0977 11.7148 14.125 11.25C14.125 11.0586 14.043 10.8398 13.8789 10.6758Z"
        fill="currentColor"
      />
    </svg>
  )
}
