import { IconProps } from './types'

export function PublicAppIcon(props: IconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.895431 13.1046 0 12 0H2ZM9.625 3.5C9.625 3.01675 10.0168 2.625 10.5 2.625C10.9832 2.625 11.375 3.01675 11.375 3.5V7C11.375 9.45966 9.41238 11.375 7 11.375C4.58763 11.375 2.625 9.45966 2.625 7V3.5C2.625 3.01675 3.01675 2.625 3.5 2.625C3.98325 2.625 4.375 3.01675 4.375 3.5V7C4.375 8.59665 5.42671 9.625 7 9.625C8.57329 9.625 9.625 8.59665 9.625 7V3.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
