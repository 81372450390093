import { useEffect, useState } from 'react'

export function useDebounce<T = string | number | Record<string, unknown>>(value: T, delay = 360) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => { setDebouncedValue(value) }, delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [setDebouncedValue, value, delay])

  return debouncedValue
}