import { IconProps } from '.'

export function RateIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5278 2.51389C14.5583 2.0625 13.5889 1.88889 12.6194 1.88889C9.54167 1.88889 6.45833 3.62166 3.38056 3.62166C2.60861 3.62166 1.83694 3.51055 1.065 3.23972C0.978056 3.20833 0.890556 3.19444 0.806389 3.19444C0.375 3.19444 0 3.56611 0 4.07777V12.8944C0 13.245 0.18125 13.575 0.471944 13.7106C1.44056 14.1611 2.4125 14.3333 3.38056 14.3333C6.45833 14.3333 9.53889 12.6006 12.6194 12.6006C13.3914 12.6006 14.1631 12.7117 14.935 12.9825C15.0225 13.0137 15.11 13.0276 15.1944 13.0276C15.625 13.0278 16 12.6556 16 12.1417V3.33C16 2.97916 15.8194 2.64944 15.5278 2.51389ZM0.893056 4.11944C1.46 4.30833 2.04083 4.425 2.64889 4.47777C2.55972 5.37222 1.81056 6.07777 0.891944 6.07777L0.893056 4.11944ZM0.892778 12.9222C0.890556 12.8917 0.888889 12.9056 0.888889 12.8917L0.889378 11.8567C1.78882 11.8569 2.52688 12.5314 2.64299 13.4C2.02528 13.3306 1.44639 13.175 0.892778 12.9222ZM15.1056 12.1028C14.5367 11.9139 13.9539 11.8222 13.3439 11.7453C13.4031 10.8192 14.1669 10.0822 15.1067 10.0803L15.1056 12.1028ZM15.1083 9.19166C13.6856 9.19334 12.5306 10.3172 12.4583 11.7228C10.8528 11.751 9.28056 12.1842 7.75833 12.6122C6.29028 13.0253 4.9 13.4075 3.53333 13.4353C3.42872 12.0603 2.28917 10.97 0.887778 10.9694L0.889678 6.96944C2.29278 6.96666 3.43056 5.875 3.53611 4.5C5.14389 4.47287 6.71944 4.03861 8.24167 3.61027C9.70417 3.19889 11.0889 2.81861 12.45 2.78833C12.4735 4.23833 13.6558 5.41139 15.1111 5.41222L15.1083 9.19166ZM15.0861 4.525C14.1314 4.52467 13.3556 3.76611 13.3164 2.82027C13.9403 2.88852 14.5242 3.04539 15.0831 3.3C15.1083 3.30694 15.1111 3.3175 15.1111 3.33L15.0861 4.525ZM8 5C6.53056 5 5.33333 6.39722 5.33333 8.11111C5.33333 9.825 6.52944 11.2222 8 11.2222C9.47056 11.2222 10.6667 9.82639 10.6667 8.11111C10.6667 6.39583 9.46944 5 8 5ZM8 10.3333C7.01944 10.3333 6.24722 9.33639 6.24722 8.11111C6.24722 6.88583 7.01944 5.88889 8 5.88889C8.98056 5.88889 9.75278 6.88583 9.75278 8.11111C9.75278 9.33639 8.98056 10.3333 8 10.3333Z"
        fill="currentColor"
      />
    </svg>
  )
}
