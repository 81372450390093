import type { IconProps } from './types'

export function NavigationPerksIcon(props: IconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 15.9531H19C18.7348 15.9531 18.4804 15.8478 18.2929 15.6602C18.1054 15.4727 18 15.2183 18 14.9531V13.9531C18 13.6879 18.1054 13.4336 18.2929 13.246C18.4804 13.0585 18.7348 12.9531 19 12.9531H30C30.2652 12.9531 30.5196 13.0585 30.7071 13.246C30.8946 13.4336 31 13.6879 31 13.9531V14.9531C31 15.2183 30.8946 15.4727 30.7071 15.6602C30.5196 15.8478 30.2652 15.9531 30 15.9531H26"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 15.9531H30V21.9531C30 22.2183 29.8946 22.4727 29.7071 22.6602C29.5196 22.8478 29.2652 22.9531 29 22.9531H20C19.7348 22.9531 19.4804 22.8478 19.2929 22.6602C19.1054 22.4727 19 22.2183 19 21.9531V15.9531H23"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 12.9531V22.9531"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 22.9531V12.9531"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 11.6411C21.6 12.2461 24.5 12.9531 24.5 12.9531C24.5 12.9531 23.791 10.0531 23.188 9.45315C22.8979 9.163 22.5044 9 22.094 9C21.6837 9 21.2902 9.163 21 9.45315C20.7099 9.7433 20.5469 10.1368 20.5469 10.5471C20.5469 10.9575 20.7099 11.351 21 11.6411V11.6411Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 11.6411C27.4 12.2461 24.5 12.9531 24.5 12.9531C24.5 12.9531 25.209 10.0531 25.812 9.45315C26.1021 9.163 26.4957 9 26.906 9C27.3163 9 27.7099 9.163 28 9.45315C28.2901 9.7433 28.4531 10.1368 28.4531 10.5471C28.4531 10.9575 28.2901 11.351 28 11.6411V11.6411Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 31.9531V21.9531"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 23.9531H15C15.7956 23.9531 16.5587 24.2692 17.1213 24.8318C17.6839 25.3944 18 26.1575 18 26.9531H24C24.7956 26.9531 25.5587 27.2692 26.1213 27.8318C26.6839 28.3944 27 29.1575 27 29.9531H8V23.9531Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 26.9531H15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
