export function PausedSourcingIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 10C9.1875 10 11 8.21875 11 6C11 3.8125 9.1875 2 7 2C4.78125 2 3 3.8125 3 6C3 8.21875 4.78125 10 7 10ZM7 3C8.625 3 10 4.375 10 6C10 7.65625 8.625 9 7 9C5.34375 9 4 7.65625 4 6C4 4.375 5.34375 3 7 3ZM15.5 9C13 9 11 11.0312 11 13.5C11 16 13 18 15.5 18C17.9688 18 20 16 20 13.5C20 11.0312 17.9688 9 15.5 9ZM15.5 17C13.5625 17 12 15.4375 12 13.5C12 11.5938 13.5625 10 15.5 10C17.4062 10 19 11.5938 19 13.5C19 15.4375 17.4062 17 15.5 17ZM11.25 17H1.5C1.21875 17 1 16.7812 1 16.5V15.2188C1 13.4375 2.40625 12 4.1875 12C4.8125 12 5.40625 12.5 7 12.5C8.5625 12.5 9.1875 12 9.78125 12C9.9375 12 10.0625 12.0312 10.1875 12.0625C10.2812 11.7188 10.4062 11.4062 10.5625 11.0938C10.3125 11.0312 10.0625 11 9.78125 11C8.90625 11 8.46875 11.5 7 11.5C5.53125 11.5 5.09375 11 4.1875 11C1.875 11 0 12.9062 0 15.2188V16.5C0 17.3438 0.65625 18 1.5 18H12.3125C11.9375 17.7188 11.5625 17.4062 11.25 17ZM17.1875 13H16V11.3125C16 11.1562 15.8438 11 15.6875 11H15.2812C15.125 11 15 11.1562 15 11.3125V13.7188C15 13.875 15.125 14 15.2812 14H17.1875C17.3438 14 17.5 13.875 17.5 13.7188V13.3125C17.5 13.1562 17.3438 13 17.1875 13Z"
        fill="currentColor"
      />
    </svg>
  )
}
