import type { IconProps } from './types'

export function ArrowUpIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5352 10C13.168 10 13.4844 9.22656 13.0273 8.76953L8.52734 4.26953C8.24609 3.98828 7.78906 3.98828 7.50781 4.26953L3.00781 8.76953C2.55078 9.22656 2.86719 10 3.5 10L12.5352 10Z"
        fill="currentColor"
      />
    </svg>
  )
}
