import type { IconProps } from './types'

export function ArrowDownIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.46484 6C2.83203 6 2.51562 6.77344 2.97266 7.23047L7.47266 11.7305C7.75391 12.0117 8.21094 12.0117 8.49219 11.7305L12.9922 7.23047C13.4492 6.77344 13.1328 6 12.5 6H3.46484Z"
        fill="currentColor"
      />
    </svg>
  )
}
