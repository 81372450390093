import { TimePicker } from '@upper/ui'
import { useField } from 'formik'

type FormikTimePickerProps = {
  name: string
}

export function FormikTimePicker({ name }: FormikTimePickerProps) {
  const [_, meta, helpers] = useField(name)

  const { value } = meta
  const { setValue } = helpers

  return (
    <TimePicker
        value={value}
        onChange={(value) => {
          setValue(value)
        }}
      />
  )
}
