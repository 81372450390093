import type { IconProps } from './types'

export function ShortLogo(props: IconProps) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7826 0V14.7058C24.7826 20.1801 20.3941 24.6336 15 24.6336C9.60587 24.6336 5.21739 20.1801 5.21739 14.7058V0H0V14.7058C0 23.1389 6.729 30 15 30C23.271 30 30 23.1389 30 14.7058V0H24.7826Z"
        fill="currentColor"
      />
    </svg>
  )
}
