/* eslint-disable @typescript-eslint/no-explicit-any */
import update from 'immutability-helper'
import {
  ChangeEvent,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { classNames } from '../classnames'
import { Icons } from '../icons'
import { FilterField, FilterFieldProps } from './field'

export type OptionItem =
  | {
      label: string
      value: null | number | boolean | string
    }
  | any

export type GroupItem<T = any> = {
  label: string
  items: (OptionItem | T)[]
}

type LabelForItemFunction<T> = (option: Value<T>) => string
type ValudForItemFunction<T> = (option: Value<T>) => string

type Value<T = any> = (OptionItem | T) | (OptionItem | T)[]
type AsyncResult<T = any> =
  | (OptionItem | GroupItem<T> | T)
  | (OptionItem | GroupItem<T> | T)[]

type Props<T = OptionItem> = {
  options?: (OptionItem | GroupItem<T>)[]
  allowMultiselect?: boolean
  allowSelectAll?: boolean
  labelForOption?: LabelForItemFunction<T>
  valueForOption?: ValudForItemFunction<T>
  searchable?: boolean
  async?: boolean
  loadOptions?: (value: string) => Promise<AsyncResult<T>>
  value?: Value<T>
  onChange?: (value: Value<T>) => void
} & FilterFieldProps

export const FilterList = <T,>({
  options: items,
  allowMultiselect = false,
  allowSelectAll = false,
  label,
  searchable,
  value,
  async,
  labelForOption = optionLabel,
  valueForOption = optionValue,
  loadOptions,
  onChange,
  onClear,
  ...props
}: Props<T>) => {
  const defaultValue = useRef<Value<T>>(value)
  const [searchText, setSearchText] = useState<string | null>(null)
  const [selected, setSelected] = useState<Value<T>>(value)
  const [options, setOptions] = useState<(OptionItem | GroupItem)[]>()
  const [loading, setLoading] = useState(false)

  const selectedValues = useMemo(() => {
    return allowMultiselect
      ? selected?.reduce((acc: string[], ro: Value<T>) => {
          acc.push(valueForOption(ro))
          return acc
        }, [])
      : selected && valueForOption(selected)
  }, [allowMultiselect, selected, valueForOption])

  const hasSelections = !allowMultiselect
    ? !!selected
    : selected && selected?.length > 0

  const listItems = options || items

  const hasSelectionOption = useCallback(
    (o: Value<T>) => {
      if (allowMultiselect) {
        return (
          selected &&
          selected
            .flatMap((o: Value<T>) => valueForOption(o))
            ?.includes(valueForOption(o))
        )
      }
      return selectedValues && selectedValues.includes(valueForOption(o))
    },
    [allowMultiselect, selected, selectedValues, valueForOption]
  )

  const handleSelectAll = useCallback(
    (o: Value<T>) => {
      let newSelection = o
      console.log(listItems)

      if (allowMultiselect) {
        newSelection = update(selected, {
          $set: listItems?.[0]?.items
            ? listItems.flatMap((g) => g.items)
            : listItems,
        })
      }

      if (newSelection || newSelection?.length > 0) {
        setSelected(newSelection)
        onChange?.(newSelection)
      } else {
        setSelected(undefined)
        onChange?.(undefined)
      }
      // console.log(o)
    },
    [allowMultiselect, listItems, onChange, selected]
  )

  const handleSelect = useCallback(
    (o: Value<T>) => {
      let newSelection = o
      if (allowMultiselect) {
        const index = selected?.findIndex(
          (i: Value) => valueForOption(i) === valueForOption(o)
        )
        if (!selected) {
          newSelection = [o]
        } else if (index === -1) {
          newSelection = update(selected, { $push: [o] })
        } else {
          newSelection = update(selected, { $splice: [[index, 1]] })
        }
      }

      if (newSelection || newSelection?.length > 0) {
        setSelected(newSelection)
        onChange?.(newSelection)
      } else {
        setSelected(undefined)
        onChange?.(undefined)
      }
      // console.log(o)
    },
    [allowMultiselect, onChange, selected, valueForOption]
  )

  const handleClear = useCallback(() => {
    setSelected(undefined)
    setOptions(undefined)
    // onChange?.(null)
    onClear?.()
  }, [onClear])

  const handleSearchChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      // console.log(e.target.value)
      const searchValue = e.target.value
      if (async && !searchValue) {
        setLoading(false)
        setOptions(undefined)
        return
      }
      if (async) {
        setLoading(true)
        setOptions(undefined)
        const result = await loadOptions?.(searchValue)
        setLoading(false)
        setOptions(result)
      } else {
        setSearchText(searchValue)
      }
    },
    [async, loadOptions]
  )

  const handleItemRemove = useCallback(
    (index: number) => {
      let newSelection = update(selected, { $splice: [[index, 1]] })
      if (Array.isArray(newSelection) && newSelection.length === 0) {
        newSelection = undefined
      }
      setSelected(newSelection)
      onChange?.(newSelection)
    },
    [onChange, selected]
  )

  const handleFilter = useCallback(
    (item: any, index: number, array: any) => {
      if (!searchText || searchText === '') return true
      const searchValue = searchText?.toLowerCase()
      if (typeof item === 'object' && 'items' in item) {
        return true
      } else {
        return (
          labelForOption(item).toLowerCase().includes(searchValue) ||
          valueForOption(item).toLowerCase().includes(searchValue)
        )
      }
    },
    [labelForOption, searchText, valueForOption]
  )

  const displayMarkup = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {Array.isArray(selected)
        ? selected.map((o, i) => (
            <Fragment key={`selected-${i}`}>
              <button
                className="text-blue cursor-pointer hover:text-[#001C69]"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleItemRemove(i)
                }}
              >
                {labelForOption(o)}
              </button>
              {allowMultiselect && (
                <span className="text-blue last:hidden">{', '}</span>
              )}
            </Fragment>
          ))
        : selected && (
            <button className="text-blue hover:text-blue-dark">
              {labelForOption(selected)}
            </button>
          )}
    </>
  )

  useEffect(() => {
    if (value && value !== defaultValue.current) {
      setSelected(value)
    }
  }, [defaultValue, value])

  return (
    <FilterField
      label={label}
      display={displayMarkup}
      hasSelection={hasSelections}
      onClear={handleClear}
      {...props}
    >
      {(searchable || async) && (
        <header
          className={classNames(
            'group relative mb-3 w-full bg-white px-4',
            !allowSelectAll && 'sticky top-0'
          )}
        >
          <input
            type="text"
            placeholder={`${async ? 'Search for' : 'Filter'} ${
              label && label.toLowerCase()
            }`}
            autoFocus
            className={classNames(
              'inline-flex w-full px-0 py-4 pr-8',
              'outline-none transition-all',
              'text-gray-darkest placeholder-gray text-sm',
              'focus:border-blue focus:outline-none focus:ring-0',
              'border-gray-light focus:text-blue border-l-0 border-t-0 border-r-0'
            )}
            onChange={handleSearchChange}
          />
          <span className="group-focus-within:text-blue absolute right-6 top-4">
            <Icons.Search />
          </span>
        </header>
      )}
      {allowMultiselect && allowSelectAll && (
        <div className="bg-gray-lightest sticky top-0 border-b border-black/5">
          <button
            className="text-blue hover:text-blue-dark px-3 py-2"
            onClick={handleSelectAll}
          >
            Select All
          </button>
        </div>
      )}
      <ul className="min-w-[200px] space-y-0.5">
        {!loading &&
          listItems &&
          listItems?.length > 0 &&
          listItems?.filter(handleFilter)?.map((o, i) => {
            return !('items' in o) ? (
              <Option
                key={`list-item-${i}`}
                onClick={handleSelect}
                isSelected={hasSelectionOption}
                allowMultiselect={allowMultiselect}
                option={o}
              >
                {labelForOption(o)}
              </Option>
            ) : (
              <GroupedOptions key={`list-item-${i}`} group={o}>
                {o.items?.filter(handleFilter)?.map((o: any, j: number) => (
                  <Option
                    key={`list-item-${i}-${j}`}
                    onClick={handleSelect}
                    isSelected={hasSelectionOption}
                    allowMultiselect={allowMultiselect}
                    option={o}
                  >
                    {labelForOption(o)}
                  </Option>
                ))}
              </GroupedOptions>
            )
          })}
        {(!listItems || listItems?.length === 0) && !loading && (
          <span className="text-gray block p-3.5 text-center text-sm">
            No options
          </span>
        )}
        {loading && (
          <span className="text-blue block p-3.5 text-center text-sm">
            Loading...
          </span>
        )}
      </ul>
    </FilterField>
  )
}

type OptionProps = {
  option: OptionItem
  allowMultiselect: boolean
  onClick?: (o: OptionItem) => void
  isSelected?: (o: OptionItem) => boolean
}

function Option({
  option,
  allowMultiselect,
  onClick,
  isSelected,
  children,
}: PropsWithChildren<OptionProps>) {
  return (
    <li>
      <button
        className={classNames(
          'group flex w-full items-center gap-2 overflow-hidden px-3 py-3 text-left text-sm',
          isSelected?.(option)
            ? 'bg-blue text-blue bg-opacity-5'
            : 'text-gray-dark hover:bg-gray-lightest'
        )}
        onClick={() => onClick?.(option)}
      >
        {allowMultiselect && <CheckboxIcon checked={isSelected?.(option)} />}
        {children}
      </button>
    </li>
  )
}

type GroupedOptionsProps = { group: GroupItem }

function GroupedOptions({
  group,
  children,
}: PropsWithChildren<GroupedOptionsProps>) {
  if (children && Array.isArray(children) && children.length === 0) {
    return null
  }
  return (
    <li aria-label={`${group.label}`}>
      <span className="text-gray-darkest inline-flex px-3 py-3 text-sm">
        {group.label}
      </span>
      <ul className="space-y-0.5">{children}</ul>
    </li>
  )
}

function CheckboxIcon({ checked }: { checked?: boolean }) {
  return (
    <input
      type={'checkbox'}
      checked={checked ?? false}
      onChange={() => null}
      className={classNames(
        'pointer-events-none h-4 w-4 rounded border',
        checked ? 'border-blue bg-blue' : 'border-gray-light bg-white'
      )}
    />
  )
}

function optionLabel<T>(object: Value<T>) {
  if (!object) return
  return typeof object === 'string' ? object : object.label
}

function optionValue<T>(object: Value<T>) {
  if (!object) return
  return typeof object === 'string' ? object : object.value
}
